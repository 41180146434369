import { ref, reactive, computed } from 'vue'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'
import { useStore } from '@/src/store/base'
import proxy from '@/src/modules/common/lib/http-common'
import {
  analyzeBrandURL,
  uploadFileURL,
  updateBrandLogoURL,
  updateBrandingURL,
  updateStrategyURL,
  updateTopicsURL,
  getProfileURL,
} from '@/src/modules/publisher/config/api-utils'

// File handling constants
const MAX_FILE_SIZE = 10 * 1024 * 1024 // 10MB
const SUPPORTED_TYPES = ['md', 'txt', 'pdf']

// Setup state
const setupInfo = reactive({
  website: {
    url: '',
  },
  text: {
    content: '',
  },
  uploads: {
    files: [], // Array of {name: string, url: string}
  },
  social: {
    facebook: [],
    twitter: [],
    instagram: [],
    linkedin: [],
    pinterest: [],
    youtube: [],
    tiktok: [],
    tumblr: [],
    gmb: [],
    bluesky: [],
  },
  // usage for add style modal
  custom: {
    id: null, // Add ID for branding updates
    logo: null,
    name: '',
    brandColor: '#436AFF',
    backgroundColor: '#FFFFFF',
    textColor: '#3A4557',
    styleName: '',
    headingFont: 'Poppins',
    bodyFont: 'Roboto',
  },
})

const AIUserProfile = ref(null)

// Brand info state
const brandInfo = reactive({
  branding: {
    id: null, // Add ID for branding updates
    logo: null,
    name: '',
    brandColor: '#436AFF',
    backgroundColor: '#FFFFFF',
    textColor: '#3A4557',
    businessName: '',
    headingFont: 'Poppins',
    bodyFont: 'Roboto',
  },
  strategy: {
    id: null,
    voice: '',
    uniqueSellingProposition: '',
    niche: '',
    audience: '',
    tone: [],
    language: [],
    emotion: [],
    competitors: [],
  },
  topics: [],
  postSettings: {
    style: '',
    brandVoice: '',
    socialPlatform: '',
    language: '',
    postCount: 5,
    postType: '',
    emojiUsage: 'mid',
    hashtagUsage: 'mid',
  },
  profileId: null, // Add profile ID
  brandVoiceId: null, // Add brand voice ID
})

const hasSetup = ref(false)
const isLoading = ref(false)
const showLoader = ref(false)
const isSetupComplete = ref(false)
const getProfileLoader = ref(false)

/**
 * @typedef {Object} SetupInfo
 * @property {Object} website - Website information
 * @property {Object} text - Text content information
 * @property {Object} uploads - File upload information
 * @property {Object} social - Social media accounts
 * @property {Object} custom - Custom branding settings
 */

/**
 * @typedef {Object} BrandInfo
 * @property {Object} branding - Branding settings
 * @property {Object} strategy - Brand strategy information
 * @property {Array} topics - Brand topics
 * @property {Object} postSettings - Post generation settings
 * @property {string|null} profileId - Profile ID
 * @property {string|null} brandVoiceId - Brand voice ID
 */

/**
 * Composable for managing AI content library setup and brand configuration
 * @returns {Object} Setup methods and reactive state
 */
export const useSetup = () => {
  const { dispatch, getters } = useStore()

  const hasPostGenerationSettings = computed(() => {
    return (
      AIUserProfile.value?.post_generation_settings &&
      AIUserProfile.value?.post_generation_settings?.style &&
      AIUserProfile.value?.post_generation_settings?.brand_voice
    )
  })

  // Reset setup info
  const resetSetupInfo = () => {
    setupInfo.website.url = ''
    setupInfo.uploads.files = []
    setupInfo.text.content = ''
    setupInfo.social = {
      facebook: [],
      twitter: [],
      instagram: [],
      linkedin: [],
      pinterest: [],
      youtube: [],
      tiktok: [],
      tumblr: [],
      gmb: [],
    }

    setupInfo.custom = {
      id: null, // Add ID for branding updates
      logo: null,
      name: '',
      brandColor: '#436AFF',
      backgroundColor: '#FFFFFF',
      textColor: '#3A4557',
      styleName: '',
      headingFont: 'Poppins',
      bodyFont: 'Roboto',
    }
  }

  // Reset brand info
  const resetBrandInfo = () => {
    brandInfo.branding = {
      id: null, // Add ID for branding updates
      logo: null,
      name: '',
      brandColor: '#436AFF',
      backgroundColor: '#FFFFFF',
      textColor: '#3A4557',
      businessName: '',
      headingFont: 'Poppins',
      bodyFont: 'Roboto',
    }
    brandInfo.strategy = {
      brandVoice: '',
      uniqueSellingProposition: '',
      niche: '',
      audience: '',
      tone: [],
      language: [],
      emotion: [],
      competitors: [],
    }
    brandInfo.topics = []
  }

  // Brand info mapper
  const BrandInfoMapper = (data) => {
    brandInfo.profileId = data._id

    // Styles
    if (data.styles) {
      const style = data.styles[0]
      brandInfo.branding = {
        id: style?.id,
        logo: style?.logo,
        name: style?.name,
        brandColor: style?.colors?.brand_color || '#436AFF',
        backgroundColor: style?.colors?.background_color || '#FFFFFF',
        textColor: style?.colors?.text_color || '#3A4557',
        businessName: data.business_name,
        headingFont: style?.heading_font || 'Poppins',
        bodyFont: style?.body_font || 'Roboto',
      }
    }

    //  Brand Voice (Strategy and topics)
    if (data.brand_voices) {
      // Get first brand voice
      const brandVoice = data?.brand_voices[0]

      console.debug('Brand Voice:', brandVoice, data?.brand_voices)

      // Get strategy and topics
      const { strategy, topics } = brandVoice

      brandInfo.brandVoiceId = brandVoice?.id

      brandInfo.strategy = {
        id: brandVoice?.id,
        brandVoice: strategy?.voice,
        uniqueSellingProposition: strategy?.unique_selling_proposition,
        niche: strategy?.niche,
        audience: strategy?.audience,
        tone: strategy?.tone,
        language: strategy?.language,
        emotion: strategy?.emotion,
        competitors: strategy?.competitors,
      }

      brandInfo.topics = topics || []
    }

    // Post Settings (might need to be updated)
  }

  /**
   * Uploads a file to the server
   * @param {File} file - File to upload
   * @returns {Promise<string>} Uploaded file URL
   * @throws {Error} If upload fails
   */
  const uploadFile = async (file) => {
    const workspaceId = getters.getActiveWorkspace._id
    try {
      isLoading.value = true
      const formData = new FormData()
      // append workspace id
      formData.append('workspace_id', workspaceId)
      formData.append('file', file)

      // Make the API call
      const response = await proxy.post(uploadFileURL, formData)

      const data = await response.json()

      if (!response.data.status) {
        throw new Error('Failed to upload file')
      }

      // Add file to appropriate section
      // for now just upload urls
      //  TODO: name functionality later on
      setupInfo.uploads.files.push(data.file_url)

      dispatch('toastNotification', {
        message: 'File uploaded successfully',
        type: 'success',
      })

      return data.url
    } catch (error) {
      console.error('Error uploading file:', error)
      dispatch('toastNotification', {
        message: 'Failed to upload file',
        type: 'error',
      })
      throw error
    } finally {
      isLoading.value = false
    }
  }

  const removeFile = (fileName, type = 'uploads') => {
    const fileIndex = setupInfo[type].files.findIndex(
      (file) => file.name === fileName
    )
    if (fileIndex !== -1) {
      setupInfo[type].files.splice(fileIndex, 1)
    }
  }

  /**
   * Saves initial setup information and creates profile
   * @returns {Promise<boolean>} Success status
   */
  const saveSetupInfo = async () => {
    const payload = {
      workspace_id: getters.getActiveWorkspace._id,
      website_url: setupInfo.website.url,
      files: setupInfo.uploads.files.map((file) => file.url),
      text: setupInfo.text.content,
      social_accounts: setupInfo.social,
      type: 'profile', // we will reuse this later for styles and brand voice
    }

    try {
      isLoading.value = true
      showLoader.value = true

      console.debug('saveSetupInfo: Sending payload:', payload)

      // Make the API call
      const response = await proxy.post(analyzeBrandURL, payload)

      console.debug('saveSetupInfo: Response:', response)

      if (!response.data || !response.data.status) {
        throw new Error('Failed to save setup information')
      }

      // Map API response to brandInfo state
      const data = response.data?.data

      if (!data) {
        throw new Error('No profile data returned from the server')
      }

      console.debug('saveSetupInfo: Profile data:', data)

      BrandInfoMapper(data)

      // Set profile data in AIUserProfile
      AIUserProfile.value = data

      dispatch('toastNotification', {
        message: 'Setup information saved successfully',
        type: 'success',
      })

      showLoader.value = false
      isLoading.value = false
      hasSetup.value = true

      return true
    } catch (error) {
      console.error('Error saving setup info:', error)
      dispatch('toastNotification', {
        message: 'Failed to save setup information: ' + error.message,
        type: 'error',
      })
      showLoader.value = false
      isLoading.value = false
      hasSetup.value = false

      return false
    } finally {
      showLoader.value = false
      isLoading.value = false
    }
  }

  /**
   * Fetches user profile data
   * @returns {Promise<Object|null>} Profile data or null
   */
  const fetchProfile = async () => {
    try {
      isLoading.value = true
      const workspaceId = getters.getActiveWorkspace._id

      // Make the API call to fetch profile - using GET with query params
      const response = await proxy.get(
        `${getProfileURL}?workspace_id=${workspaceId}`
      )

      console.debug('fetchProfile:response', response)

      if (!response.data || !response.data.status) {
        hasSetup.value = false
        isLoading.value = false
        isSetupComplete.value = false
        throw new Error('Failed to fetch profile')
      }

      // Extract profile data from response
      const profile = response.data?.profile

      if (!profile) {
        hasSetup.value = false
        isSetupComplete.value = false
        AIUserProfile.value = null
        return null
      }

      // Set the profile
      AIUserProfile.value = profile

      console.debug('fetchProfile:profile', profile)

      // If we have a profile, we have setup
      hasSetup.value = true

      // Map profile
      BrandInfoMapper(profile)

      // Map post generation settings
      initializeFormFromProfile()

      // check if setup is complete
      isSetupComplete.value = profile.is_setup_complete
      return profile
    } catch (error) {
      console.error('Error fetching profile:', error)
      dispatch('toastNotification', {
        message: 'Failed to fetch profile',
        type: 'error',
      })
      hasSetup.value = false
      return null
    } finally {
      isLoading.value = false
    }
  }

  const loadProfile = async (profile) => {
    try {
      isLoading.value = true

      console.debug('loadProfile: Loading profile:', profile)

      // Store the profile ID
      brandInfo.profileId = profile._id

      // Map branding information
      BrandInfoMapper(profile)

      // Set hasSetup to true since we loaded a profile
      hasSetup.value = true

      console.debug('loadProfile: Profile loaded successfully')

      return true
    } catch (error) {
      console.error('Error loading profile:', error)
      dispatch('toastNotification', {
        message: 'Failed to load profile: ' + error.message,
        type: 'error',
      })
      hasSetup.value = false
      return false
    } finally {
      isLoading.value = false
    }
  }

  // Fetch brand info - retrieves profile and loads it
  const fetchBrandInfo = async () => {
    getProfileLoader.value = true

    try {
      // Get profile from API
      const profile = await fetchProfile()

      console.debug('fetchBrandInfo:profile', profile)

      // If profile exists, load it
      if (profile) {
        // Load the profile
        const success = await loadProfile(profile)

        console.debug('fetchBrandInfo:success', success)

        if (success) {
          // If successfully loaded a profile, set hasSetup to true
          hasSetup.value = true
          getProfileLoader.value = false
          return true
        } else {
          // If loading failed, set hasSetup to false
          hasSetup.value = false
          getProfileLoader.value = false
          return false
        }
      } else {
        // No profile found
        console.debug('fetchBrandInfo::No profile found')
        hasSetup.value = false
        getProfileLoader.value = false
        return false
      }
    } catch (error) {
      console.error('Error fetching brand info:', error)
      dispatch('toastNotification', {
        message: 'Failed to fetch brand information: ' + error.message,
        type: 'error',
      })
      hasSetup.value = false
      getProfileLoader.value = false
      return false
    }
  }

  /**
   * Updates data for a specific setup step
   * @param {string} step - Step name ('branding'|'strategy'|'topics')
   * @param {Object} data - Step data
   * @param {boolean} isLastStep - Whether this is the final step
   * @returns {Promise<boolean>} Success status
   */
  const updateStepData = async (step, data, isLastStep = false) => {
    console.debug('updateStepData:step', step, data, isLastStep)
    try {
      isLoading.value = true
      // Update local brandInfo state
      brandInfo[step] = data

      const workspaceId = getters.getActiveWorkspace._id
      let payload = {}
      let endpoint = ''

      // Format the payload and select endpoint according to the step
      switch (step) {
        case 'branding':
          endpoint = updateBrandingURL
          payload = {
            workspace_id: workspaceId,
            profile_id: brandInfo.profileId,
            id: data.id,
            name: data.businessName,
            logo: data.logo,
            colors: {
              brand_color: data.brandColor,
              background_color: data.backgroundColor,
              text_color: data.textColor,
            },
            business_name: data.businessName,
            heading_font: data.headingFont,
            body_font: data.bodyFont,
          }
          break

        case 'strategy':
          endpoint = updateStrategyURL
          payload = {
            workspace_id: workspaceId,
            profile_id: brandInfo.profileId,
            id: data.id,
            strategy: {
              voice: data.brandVoice,
              niche: data.niche,
              audience: data.audience,
              tone: data.tone,
              language: data.language,
              emotion: data.emotion,
              competitors: data.competitors,
              unique_selling_proposition: data.uniqueSellingProposition,
            },
          }
          break

        case 'topics':
          endpoint = updateTopicsURL
          payload = {
            workspace_id: workspaceId,
            profile_id: brandInfo.profileId,
            id: brandInfo.brandVoiceId,
            topics: data,
          }
          // Only set is_setup_complete to true when it's the last step and finishing
          if (isLastStep) {
            payload.is_setup_complete = true
            isSetupComplete.value = true
          }
          break

        default:
          throw new Error(`Unknown step: ${step}`)
      }

      // Make the API call using the appropriate endpoint
      const response = await proxy.post(endpoint, payload)

      if (!response.data || !response.data.status) {
        throw new Error(`Failed to update ${step} info`)
      }

      dispatch('toastNotification', {
        message: 'Changes saved successfully',
        type: 'success',
      })

      return true
    } catch (error) {
      console.error(`Error updating ${step}:`, error)
      dispatch('toastNotification', {
        message: `Failed to save ${step} information`,
        type: 'error',
      })
      return false
    } finally {
      isLoading.value = false
    }
  }

  /**
   * Uploads brand logo
   * @param {File} file - Logo file
   * @returns {Promise<string>} Logo URL
   * @throws {Error} If upload fails
   */
  const uploadLogo = async (file) => {
    try {
      isLoading.value = true
      const workspaceId = getters.getActiveWorkspace._id
      const formData = new FormData()
      formData.append('logo', file)
      formData.append('workspace_id', workspaceId)

      // Make the API call using proxy
      const response = await proxy.post(updateBrandLogoURL, formData)

      console.debug('uploadLogo response', response)

      if (!response.data || !response.data.status) {
        throw new Error('Failed to upload logo')
      }

      const logoUrl = response.data.logo_url
      dispatch('toastNotification', {
        message: 'Logo uploaded successfully',
        type: 'success',
      })

      // Update local state
      brandInfo.branding.logo = logoUrl
      return logoUrl
    } catch (error) {
      console.error('Error uploading logo:', error)
      dispatch('toastNotification', {
        message: 'Failed to upload logo',
        type: 'error',
      })
      throw error
    } finally {
      isLoading.value = false
    }
  }

  /**
   * Validates file before upload
   * @param {File} file - File to validate
   * @returns {Array<string>} Array of error messages
   */
  const validateFile = (file) => {
    const ext = file.name.split('.').pop().toLowerCase()
    const errors = []

    if (!SUPPORTED_TYPES.includes(ext)) {
      errors.push(
        `File type "${ext}" is not supported. Please upload ${SUPPORTED_TYPES.join(
          ', '
        )} files only.`
      )
    }

    if (file.size > MAX_FILE_SIZE) {
      errors.push(
        `File "${file.name}" exceeds maximum size of ${formatBytes(
          MAX_FILE_SIZE
        )}`
      )
    }

    return errors
  }

  const getFileType = (file) => {
    const ext = file.name.split('.').pop().toLowerCase()
    const typeMap = {
      pdf: 'pdf',
      txt: 'text',
      md: 'markdown',
    }
    return typeMap[ext] || 'text'
  }

  const formatBytes = (bytes) => {
    if (bytes < 1024) return `${bytes} bytes`
    else if (bytes < 1024 * 1024) return `${(bytes / 1024).toFixed(2)} KB`
    else return `${(bytes / (1024 * 1024)).toFixed(2)} MB`
  }

  /**
   * Processes files from media library
   * @param {Array|Object} mediaFiles - Files from media library
   */
  const processMediaLibraryFiles = (mediaFiles) => {
    // Ensure mediaFiles is an array
    const filesToProcess = Array.isArray(mediaFiles) ? mediaFiles : [mediaFiles]

    filesToProcess.forEach((file) => {
      // Check if file is PDF
      if (file.mime_type !== 'application/pdf') {
        dispatch('toastNotification', {
          message: `File "${file.name}" is not a PDF file`,
          type: 'error',
        })
        return
      }

      // Check file size
      if (file.size > MAX_FILE_SIZE) {
        dispatch('toastNotification', {
          message: `File "${file.name}" exceeds maximum size of ${formatBytes(
            MAX_FILE_SIZE
          )}`,
          type: 'error',
        })
        return
      }

      // Check if file already exists
      const fileExists = setupInfo.uploads.files.some(
        (existingFile) =>
          existingFile.mediaLibraryId === file._id ||
          existingFile.name === file.name
      )

      if (fileExists) {
        dispatch('toastNotification', {
          message: `File "${file.name}" has already been added`,
          type: 'warning',
        })
        return
      }

      // Add file to the list
      setupInfo.uploads.files.push({
        name: file.name,
        type: 'pdf',
        size: formatBytes(file.size),
        progress: 100,
        timeLeft: 'Completed',
        mediaLibraryId: file._id,
        url: file.link,
        thumbnail: file.thumbnail,
        created_at: file.created_at,
      })
    })

    const successCount = filesToProcess.length
    if (successCount > 0) {
      dispatch('toastNotification', {
        message: `Added ${successCount} PDF${
          successCount > 1 ? 's' : ''
        } from Media Library`,
        type: 'success',
      })
    }
  }

  /**
   * Processes and uploads new files
   * @param {Array<File>} newFiles - Files to process
   */
  const processFiles = async (newFiles) => {
    let hasErrors = false
    const validFiles = []

    newFiles.forEach((file) => {
      const errors = validateFile(file)
      if (errors.length > 0) {
        errors.forEach((error) =>
          dispatch('toastNotification', {
            message: error,
            type: 'error',
          })
        )
        hasErrors = true
      } else {
        validFiles.push(file)
      }
    })

    if (validFiles.length > 0) {
      for (const file of validFiles) {
        const fileSize = formatBytes(file.size)
        const fileType = getFileType(file)

        // Add file to list with initial progress
        const fileIndex = setupInfo.uploads.files.length
        setupInfo.uploads.files.push({
          name: file.name,
          type: fileType,
          size: fileSize,
          progress: 0,
          timeLeft: 'Uploading...',
        })

        try {
          // Create FormData
          const formData = new FormData()
          formData.append('file', file)
          formData.append('workspace_id', getters.getActiveWorkspace._id)

          // Make the API call
          const response = await proxy.post(uploadFileURL, formData, {
            onUploadProgress: (progressEvent) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              )
              setupInfo.uploads.files[fileIndex].progress = percentCompleted
            },
          })

          console.debug('processFiles:response', response)

          if (response.data && response.data.status) {
            setupInfo.uploads.files[fileIndex].timeLeft = 'Completed'
            setupInfo.uploads.files[fileIndex].url = response.data.file_url
            dispatch('toastNotification', {
              message: `File "${file.name}" uploaded successfully`,
              type: 'success',
            })
          } else {
            throw new Error('Upload failed')
          }
        } catch (error) {
          console.error('Error uploading file:', error)
          setupInfo.uploads.files[fileIndex].timeLeft = 'Failed'
          dispatch('toastNotification', {
            message: `Failed to upload "${file.name}"`,
            type: 'error',
          })
        }
      }

      if (hasErrors) {
        dispatch('toastNotification', {
          message: `Processed ${validFiles.length} of ${newFiles.length} files`,
          type: 'info',
        })
      }
    }
  }

  const removeUploadedFile = (index) => {
    setupInfo.uploads.files.splice(index, 1)
  }

  // Social accounts management
  /**
   * Updates social media accounts
   * @param {Object} selectedAccountsByPlatform - Selected accounts grouped by platform
   */
  const updateSocialAccounts = (selectedAccountsByPlatform) => {
    // Update each platform's accounts
    Object.keys(selectedAccountsByPlatform).forEach((platform) => {
      if (platform in setupInfo.social) {
        setupInfo.social[platform] = [...selectedAccountsByPlatform[platform]]
      }
    })
  }

  const getSocialAccountsByPlatform = () => {
    return Object.keys(setupInfo.social).reduce((acc, platform) => {
      acc[platform] = [...setupInfo.social[platform]]
      return acc
    }, {})
  }

  const { initializeFormFromProfile } = useAIPostGeneration(AIUserProfile)

  return {
    setupInfo,
    isLoading,
    showLoader,
    processFiles,
    processMediaLibraryFiles,
    removeUploadedFile,
    updateSocialAccounts,
    getSocialAccountsByPlatform,
    uploadFile,
    removeFile,
    fetchProfile,
    saveSetupInfo,
    updateStepData,
    uploadLogo,
    brandInfo,
    MAX_FILE_SIZE,
    SUPPORTED_TYPES,
    hasSetup,
    fetchBrandInfo,
    loadProfile,
    isSetupComplete,
    hasPostGenerationSettings,
    AIUserProfile,
    resetSetupInfo,
    getProfileLoader,
    resetBrandInfo,
  }
}
