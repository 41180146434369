<script setup>
import { computed, watch } from 'vue'
import MainGraph from '@src/modules/analytics/views/youtube/components/graphs/MainGraph.vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useYoutubeAnalytics from '@/src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'

const props = defineProps({
  label: {
    type: String,
    default: 'Video Views',
  },
})

const {
  barChartOptions,
  legendOptions,
  channelViewsData,
  isReportView,
  dataZoomOptions,
  analyticsDesignSystem,
  SINGLE_GRAPH_TOOLTIPS,
  isLoadingStates,
} = useYoutubeAnalytics(props.label)

const chartTypes = [
  {
    name: 'Subscribers',
    api_response_key: {
      daily: 'subscriber_views_daily',
      total: 'subscriber_views_total',
    },
    color: '#8AC1A2',
  },
  {
    name: 'Non-Subscribers',
    api_response_key: {
      daily: 'non_subscriber_views_daily',
      total: 'non_subscriber_views_total',
    },
    color: '#61BAE4',
  },
]

const noDataFound = computed(() => {
  return !channelViewsData.value?.buckets?.length
})

watch(
  () => channelViewsData,
  async () => {
    barChartOptions.value.series = []
    barChartOptions.value.legend = {
      ...legendOptions,
      data: chartTypes?.map((chartType) => chartType.name),
    }

    const xAxisData = channelViewsData.value?.buckets

    // bar and line chart options
    chartTypes.forEach((chartType) => {
      const apiKey = chartType?.api_response_key

      const seriesStack = {
        stack: 'total',
      }
      const barSeries = {
        name: chartType.name,
        type: 'bar',
        color: chartType.color,
        data: channelViewsData.value[apiKey.daily] || [],
        yAxisIndex: 0,
        barMaxWidth: analyticsDesignSystem?.graphs?.singleBarMaxWidth,
        areaStyle: { opacity: 0.4, cursor: 'auto' },
        cursor: 'auto',
        ...seriesStack,
      }

      barChartOptions.value.series.push({ ...barSeries })
      barChartOptions.value.yAxis = {
        ...barChartOptions.value.yAxis,
        name: `{subscribers|} {nonSubscribers|} {comments|} {shares|} {b|${props.label}}`,
        nameTextStyle: {
          rich: {
            subscribers: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#8AC1A2',
            },
            nonSubscribers: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#61BAE4',
            },
            b: { color: '#979CA0', fontSize: 12, padding: [0, 0, 0, 6] },
          },
        },
      }
    })

    barChartOptions.value.xAxis.data = xAxisData
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper
    :type="`specific-type-${Math.random()}`"
    :enable-modal="true"
    :show-insights-button="!isReportView"
    :enable-insights="true"
    :selected-dropdown-option="label"
    platform="youtube"
  >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <h2 class="text-base font-weight-500 select-none">
            Daily Video Views
          </h2>
          <v-menu
            v-if="!isReportView"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                {{ SINGLE_GRAPH_TOOLTIPS.VIDEO_VIEWS }}
              </p>
              <p class="text-xs mt-2 opacity-70"
                  >Note: These insights are calculated by ContentStudio and are
                  not directly sourced from YouTube.</p
                >
            </template>
          </v-menu>
        </div>
        <div>
          <slot name="header-center" />
        </div>
        <div>
          <slot name="header-right" />
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div v-if="noDataFound" class="analytics-no-data-found">
        <img
          src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt=""
        />
        <p>No data found.</p>
      </div>
      <div v-else class="flex-1">
        <MainGraph
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...barChartOptions,
                  ...dataZoomOptions,
                  grid: { ...barChartOptions?.grid, bottom: 70 },
                }
              : barChartOptions
          "
          :is-modal="slotProps.isModal"
          :custom-modal-height="slotProps.isInsights ? '40vh' : '80vh'"
          :custom-click-handler="() => {}"
        />
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
