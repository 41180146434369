<script setup>
import { watch } from 'vue'
import useTiktokAnalytics from '@src/modules/analytics/views/tiktok/composables/useTiktokAnalytics.js'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'
import TopPostCard from './TopPostCard.vue'
import EngagementIcon from "@/src/assets/img/icons/analytic/plain/total-engagement.svg"
import HeartIcon from "@/src/assets/img/icons/analytic/plain/heart.svg"
import CommentsIcon from "@/src/assets/img/icons/analytic/plain/comments-icon.svg"
import SharesIcon from "@/src/assets/img/icons/analytic/plain/shares.svg"
import ViewsIcon from "@/src/assets/img/icons/analytic/plain/reach.svg"

// Composable destructuring
const {
  routes,
  selectedAccount,
  topEngagingPosts,
  isLoadingStates,
  isReportView
} = useTiktokAnalytics()

// Define metrics rows for the post cards
const rows = [
  {
    icon: EngagementIcon,
    title: 'Engagement',
    api_key: 'engagement',
    iconClass: 'scale-[1.2]'
  },
  {
    icon: ViewsIcon,
    title: 'Views',
    api_key: 'views',
    iconClass: ''
  },
  {
    icon: HeartIcon,
    title: 'Likes',
    api_key: 'likes',
    iconClass: ''
  },
  {
    icon: CommentsIcon,
    title: 'Comments',
    api_key: 'comments',
    iconClass: ''
  },
  {
    icon: SharesIcon,
    title: 'Shares',
    api_key: 'shares',
    iconClass: ''
  }
]

watch(() => topEngagingPosts.value, () => {
  if (topEngagingPosts.value && topEngagingPosts.value.length) {
    topEngagingPosts.value.forEach((post) => {
      post.media_type = post?.media_type
    })
  }
},{
  immediate: true,
  deep: true
})

</script>

<template>
  <AnalyticsCardWrapper
    type="tiktok-top-posts"
    :show-insights-button="!isReportView"
    :enable-modal="true"
    :platform="'tiktok'"
  >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Top Posts
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  The top performing TikTok videos by engagement, published during the selected time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:card-body="">
      <template v-if="!isLoadingStates[routes?.TOP_AND_LEAST_PERFORMING_POSTS] && topEngagingPosts?.length === 0">
        <div class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </template>
      <template v-else>
        <div
          class="grid gap-5"
          :class="{ 'grid-cols-3': isReportView, 'grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 h-[535px] overflow-hidden px-8': !isReportView }"
        >
          <template v-if="isLoadingStates[routes?.TOP_AND_LEAST_PERFORMING_POSTS]">
            <template v-for="i in [1,2,3,4,5]" :key="i">
              <SkeletonBox
                :height="'500px'"
                width="'100%'"
              />
            </template>
          </template>
          <template v-else>
            <template v-for="(post, i) in topEngagingPosts" :key="i">
              <TopPostCard :selected-account="selectedAccount" :post="post" :metrics-rows="rows" />
            </template>
          </template>
        </div>
      </template>
    </template>
  </AnalyticsCardWrapper>
</template>
