<template>
  <div class="p-6 w-[600px]">
    <h1 class="text-lg font-medium text-[#3A4557]"
      >Setup Your Business Profile
      <i
      v-tooltip="'Provide a data source (website, text, file, or social account) for AI to analyze and extract your brand’s key attributes.'"
      class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
    ></i>
      </h1
    >
    <!-- Tabs -->
    <div class="flex items-center gap-x-4 mt-6">
      <!-- Tab Headers -->
      <div
        v-for="tab in tabs"
        :key="tab.id"
        :class="[
          selectedTab === tab.id ? 'cstu-text-blue-500 cstu-bg-blue-50' : '',
          'select-none text-sm rounded-full py-2 hover:cstu-bg-blue-50 px-3 flex items-center hover:cstu-text-blue-500 cursor-pointer gap-x-2',
        ]"
        @click="selectedTab = tab.id"
      >
        <i :class="[tab.icon]" aria-hidden="true"></i>
        <span class="text-base">{{ tab.name }}</span>
      </div>
    </div>

    <!-- Tab Content -->
    <div class="mt-4">
      <WebsiteTab v-if="selectedTab === 'website'" />
      <TextTab v-else-if="selectedTab === 'text'" />
      <UploadsTab v-else-if="selectedTab === 'uploads'" />
      <SocialTab v-else-if="selectedTab === 'social'" />
    </div>

    <!-- Footer -->
    <div
      v-if="!showFooter"
      class="flex items-center justify-between px-2 py-4 mt-6"
    >
      <a
        href="www.contentstudio.com"
        target="_blank"
        class="text-sm text-blue-500"
        >About AI Library</a
      >

      <Button size="sm" :loading="isLoading" @click="handleAnalyze">
        Analyze Your Brand
      </Button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Button } from '@contentstudio/ui'
import WebsiteTab from './tabs/WebsiteTab.vue'
import TextTab from './tabs/TextTab.vue'
import UploadsTab from './tabs/UploadsTab.vue'
import SocialTab from './tabs/SocialTab.vue'
import { useSetup } from '@/src/modules/publisher/ai-content-library/composables/useSetup'
import { useStore } from '@/src/store/base'

const { isLoading, setupInfo, saveSetupInfo } = useSetup()
const { dispatch, getters } = useStore()

const hasSocialSetup = computed(() => {
  // Check if any social platform has selected accounts
  return getters.getSocialAccountsList?.length > 0
})

const selectedTab = ref('website')
const tabs = [
  {
    id: 'website',
    name: 'Website',
    icon: 'far fa-link',
  },
  {
    id: 'text',
    name: 'Add Text',
    icon: 'far fa-pencil',
  },
  {
    id: 'uploads',
    name: 'Uploads',
    icon: 'far fa-cloud-upload',
  },
  {
    id: 'social',
    name: 'Social Accounts',
    icon: 'icon-social-accounts-cs',
  },
]

const showFooter = computed(() => {
  return selectedTab.value === 'social' && !hasSocialSetup.value
})

const validateWebsiteUrl = () => {
  if (!setupInfo.website.url) {
    dispatch('toastNotification', {
      message: 'Please enter a website URL',
      type: 'error',
    })
    return false
  }

  let url = setupInfo.website.url.trim()

  // Add https:// if no protocol is specified
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url
    setupInfo.website.url = url
  }

  try {
    const urlObj = new URL(url)
    if (!['http:', 'https:'].includes(urlObj.protocol)) {
      dispatch('toastNotification', {
        message: 'Please enter a valid http or https URL',
        type: 'error',
      })
      return false
    }
    return true
  } catch {
    dispatch('toastNotification', {
      message: 'Please enter a valid URL',
      type: 'error',
    })
    return false
  }
}

const handleAnalyze = async () => {
  if (showFooter.value) {
    selectedTab.value = 'social'
    return
  }

  // Validate website URL
  if (!validateWebsiteUrl()) {
    return
  }

  const success = await saveSetupInfo()
  if (success) {
    emit('setup-complete')
  }
}

const emit = defineEmits(['setup-complete'])
</script>
