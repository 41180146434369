<template>
  <div class="bg-white rounded-xl">
    <!-- Setup Component -->
    <SetupPopup v-if="!hasSetup && !showLoader" />
    <!-- Loader Component -->
    <div v-if="showLoader" class="w-[600px] h-[600px]">
      <AiGenerationLoader
        text="Our AI Bot is analyzing your business profile!"
        :loading-completed="loadingCompleted"
        :loading-steps="loadingSteps"
        :is-content-library="true"
      />
    </div>

    <!-- Brand Info Section  -->
    <BrandInfoPopup v-if="hasSetup" />
  </div>
</template>

<script setup>
import { ref } from 'vue'
import AiGenerationLoader from '@src/components/common/AiGenerationLoader.vue'
import SetupPopup from './form/SetupPopup.vue'
import BrandInfoPopup from './form/BrandInfoPopup.vue'
import { useSetup } from '@/src/modules/publisher/ai-content-library/composables/useSetup'

const { showLoader, hasSetup } = useSetup()

const loadingSteps = ref([
  { message: 'Initializing', status: false },
  { message: 'Extracting data', status: false },
  { message: `Analyzing brand info`, status: false },
  { message: `Generating!`, status: false },
])
const loadingCompleted = ref(false)
</script>
