<template>
  <b-modal
    id="add-brand-voice-modal"
    title="Add Brand Voice"
    size="lg"
    hide-footer
    hide-header
    centered
    header-class="p-4 border-b"
    dialog-class="!w-[600px]"
    @shown="handleShown"
  >
    <div class="modal_head">
      <div class="flex item-center justify-between w-full">
        <h4 class="text-xl font-semibold">Add Brand Voice</h4>
      </div>
      <button
        type="button"
        class="modal_head__close bg-transparent"
        data-dismiss="modal"
        aria-label="Close"
        @click.stop="handleClose"
      >&times;</button>
    </div>
    <div class="modal_body p-4">
      <div v-if="showLoader" class=" h-[550px]">
        <AiGenerationLoader
          text="Our AI Bot is analyzing your business profile!"
          :loading-completed="loadingCompleted"
          :loading-steps="loadingSteps"
          :is-content-library="true"
        />
      </div>

      <template v-if="voiceAnalyzed">
        <!-- Steps Progress -->
        <div class="flex justify-center items-center mb-8">
          <template v-for="(step, index) in steps" :key="index">
            <div
              class="flex items-center cursor-pointer"
              @click="handleStepClick(index)"
            >
              <div
                class="w-10 h-10 rounded-full flex items-center justify-center border"
                :class="[
                  currentStep === index ? `${step.borderColor}` : 'border-gray-300',
                ]"
              >
                <template
                  v-if="completedSteps.includes(index) && currentStep !== index"
                >
                  <svg
                    :class="step.textColor"
                    class="h-5 w-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    ></path>
                  </svg>
                </template>
                <template v-else>
                  <img :src="step.icon" :alt="step.name" class="w-5 h-5" />
                </template>
              </div>
              <span
                class="ml-2 text-sm"
                :class="[
                  currentStep === index
                    ? `${step.textColor} font-medium`
                    : 'text-gray-900',
                ]"
                >{{ step.name }}</span
              >
            </div>
            <div
              v-if="index < steps.length - 1"
              class="mx-8 w-16 h-px border !border-dashed"
            ></div>
          </template>
        </div>

        <!-- Step Content -->
        <div ref="stepContainer" class="min-h-[400px]">
          <component :is="steps[currentStep].component" v-model="currentStepData" />
        </div>

        <!-- Navigation Buttons -->
        <div class="flex justify-between mt-6 px-4">
          <Button
            v-if="currentStep > 0"
            class="px-4 py-2 text-[#436AFF] hover:bg-[#F5F7FF] rounded-lg"
            size="sm"
            variant="light"
            color="secondary"
            @click="prevStep"
          >
            Back
          </Button>
          <div class="ml-auto">
            <Button size="sm" @click="handleNextStep">
              {{ currentStep === steps.length - 1 ? 'Finish!' : 'Next' }}
            </Button>
          </div>
        </div>
      </template>

      <template v-else-if="!showLoader && !voiceAnalyzed">
        <!-- Tabs -->
        <div class="flex items-center gap-x-4">
          <!-- Tab Headers -->
          <div
            v-for="tab in tabs"
            :key="tab.id"
            :class="[
              selectedTab === tab.id ? 'cstu-text-blue-500 cstu-bg-blue-50' : '',
              'select-none text-sm rounded-full py-2 hover:cstu-bg-blue-50 px-3 flex items-center hover:cstu-text-blue-500 cursor-pointer gap-x-2',
            ]"
            @click="selectedTab = tab.id"
          >
            <i :class="[tab.icon]" aria-hidden="true"></i>
            <span class="text-base">{{ tab.name }}</span>
          </div>
        </div>

        <!-- Tab Content -->
        <div class="mt-4">
          <WebsiteTab v-if="selectedTab === 'website'" />
          <TextTab v-else-if="selectedTab === 'text'" />
          <UploadsTab v-else-if="selectedTab === 'uploads'" />
          <SocialTab v-else-if="selectedTab === 'social'" />
        </div>
      </template>

      <!-- Footer -->
      <div
        v-if="!showFooter && !showLoader && !voiceAnalyzed"
        class="flex items-center justify-between px-2 py-4 mt-6"
      >
        <a href="www.contentstudio.com" target="_blank" class="text-sm text-blue-500"
          >About AI Library</a
        >

        <Button size="sm" :loading="isLoading" @click="handleAnalyze">
          Analyze Your Brand
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import { Button } from '@contentstudio/ui'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { useBrandInfoSetting } from '@modules/publisher/ai-content-library/composables/useBrandInfoSetting'
import { useIcons } from '@modules/publisher/composables/useIcons'
import WebsiteTab from '@/src/modules/publisher/ai-content-library/components/form/tabs/WebsiteTab.vue'
import SocialTab from '@/src/modules/publisher/ai-content-library/components/form/tabs/SocialTab.vue'
import TextTab from '@/src/modules/publisher/ai-content-library/components/form/tabs/TextTab.vue'
import UploadsTab from '@/src/modules/publisher/ai-content-library/components/form/tabs/UploadsTab.vue'
import StrategyStep from '@/src/modules/publisher/ai-content-library/components/form/steps/StrategyStep.vue'
import TopicsStep from '@/src/modules/publisher/ai-content-library/components/form/steps/TopicsStep.vue'
import AiGenerationLoader from '@/src/components/common/AiGenerationLoader.vue'
import { useStore } from '@/src/store/base'

const { isLoading, brandInfo, setupInfo, resetSetupInfo, resetBrandInfo } = useSetup()
const { handleAnalyzeBrandVoice, handleAddBrandVoice, showLoader, voiceAnalyzed } = useBrandInfoSetting()
const { dispatch, getters } = useStore()
const { StrategyIcon, TopicsIcon } = useIcons()

const root = inject('root')
const { $bvModal } = root

// Steps configuration
const currentStep = ref(0)
const completedSteps = ref([])
const stepContainer = ref(null)

const steps = [
  {
    name: 'Strategy',
    component: StrategyStep,
    icon: StrategyIcon,
    textColor: 'text-[#4CCE88]',
    borderColor: '!border-[#4CCE88]',
    color: '#4CCE88',
  },
  {
    name: 'Topics',
    component: TopicsStep,
    icon: TopicsIcon,
    textColor: 'text-[#FF9472]',
    borderColor: '!border-[#FF9472]',
    color: '#FF9472',
  },
]

const currentStepData = computed(() => {
  switch (currentStep.value) {
    case 0:
      return { strategy: brandInfo.strategy }
    case 1:
      return { topics: brandInfo.topics }
    default:
      return {}
  }
})

const handleStepClick = async (index) => {
  if (index === currentStep.value || isLoading.value) return

  if (index > currentStep.value) {
    for (let i = currentStep.value; i < index; i++) {
      if (!completedSteps.value.includes(i)) {
        completedSteps.value.push(i)
      }
    }
  } else if (index < currentStep.value) {
    completedSteps.value = completedSteps.value.filter((step) => step <= index)
  }

  currentStep.value = index
}

const handleNextStep = async () => {
  if (currentStep.value < steps.length - 1) {
    completedSteps.value.push(currentStep.value)
    currentStep.value++
  } else {
    // On final step, save and close
    await handleAddBrandVoice()
    $bvModal.hide('add-brand-voice-modal')
  }
}

const prevStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--
  }
}

const hasSocialSetup = computed(() => {
  return getters.getSocialAccountsList?.length > 0
})

const selectedTab = ref('website')
const tabs = [
  {
    id: 'website',
    name: 'Website',
    icon: 'far fa-link',
  },
  {
    id: 'text',
    name: 'Add Text',
    icon: 'far fa-pencil',
  },
  {
    id: 'uploads',
    name: 'Uploads',
    icon: 'far fa-cloud-upload',
  },
  {
    id: 'social',
    name: 'Social Accounts',
    icon: 'icon-social-accounts-cs',
  },
]

const loadingSteps = ref([
  { message: 'Initializing', status: false },
  { message: 'Extracting data', status: false },
  { message: `Analyzing brand voice`, status: false },
  { message: `Generating!`, status: false },
])

const loadingCompleted = ref(false)

const showFooter = computed(() => {
  return selectedTab.value === 'social' && !hasSocialSetup.value
})

const validateWebsiteUrl = () => {
  if (!setupInfo.website.url) {
    dispatch('toastNotification', {
      message: 'Please enter a website URL',
      type: 'error',
    })
    return false
  }

  let url = setupInfo.website.url.trim()

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url
    setupInfo.website.url = url
  }

  try {
    const urlObj = new URL(url)
    if (!['http:', 'https:'].includes(urlObj.protocol)) {
      dispatch('toastNotification', {
        message: 'Please enter a valid http or https URL',
        type: 'error',
      })
      return false
    }
    return true
  } catch {
    dispatch('toastNotification', {
      message: 'Please enter a valid URL',
      type: 'error',
    })
    return false
  }
}

const handleAnalyze = async () => {
  if (showFooter.value) {
    selectedTab.value = 'social'
    return
  }

  if (!validateWebsiteUrl()) {
    return
  }

  await handleAnalyzeBrandVoice()
}


const handleClose = () => {
  resetSetupInfo()
  resetBrandInfo()
  $bvModal.hide('add-brand-voice-modal')
}

const handleShown = () => {
  resetSetupInfo()
  resetBrandInfo()
  selectedTab.value = 'website'
}
</script>
