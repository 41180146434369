<template>
  <div>
    <div class="media-tab__header">Chunk Upload</div>
    <div
      class="media-tab__body"
      :class="modalBodyClasses"
    >
      <div v-if="images.length > 0" class="media-tab__info">
        <div class="media-tab__count">
          {{ images.length }}
          Item{{ images.length > 1 ? 's' : '' }} of size
          {{ bytesToSize(totalSize) }}
          {{ images.length > 1 ? 'are' : 'is' }} ready to upload.
        </div>
        <div class="media-tab__actions">
          <button
            :disabled="isUploading"
            href="javascript:;"
            @click="clearFiles"
          >Remove All</button>
        </div>
      </div>

      <div class="media-tab__items relative">
        <div ref="dropzoneElement" class="dropzone">
          <div class="dz-message">
            <i class="far fa-cloud-upload"></i>
            <br />
            Drop large files here for chunk upload
          </div>
        </div>
        <div class="grid-flex mt-4">
          <div v-for="(image, k) in images" :key="k" class="cell-md-3 mb-3">
            <Asset
              :id="image._id"
              :hide-delete="isUploading"
              type="secondary"
              :src="image.url"
              :alt="image.name"
              :info="image"
              @delete="deleteItem"
            />
            <div v-if="image.progress && !image.uploaded" class="upload-progress">
              {{ image.progress }}%
              <div class="progress-bar">
                <div :style="{ width: image.progress + '%' }" class="progress-bar__fill"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="media-tab__footer">
      <div class="d-flex align_center">
        <div class="mr-3 font-0-95rem">Folder:</div>
        <div class="media-tab__footer-folders">
          <i class="far fa-folder mr-3"></i>
          <TreeSelect
            v-model="selectedFolderValue"
            :disabled="isUploading"
            placeholder="Select Folder"
            :clearable="false"
            :flatten-search-results="true"
            :options="treeFolders"
            :normalizer="normalizer"
          >
          </TreeSelect>
        </div>
      </div>

      <div class="media-tab__footer-actions d-flex flex-grow-1 justify-content-end flex-shrink-0 ml-2">
        <button
          class="btn btn-primary"
          :disabled="images.length === 0 || isUploading"
          @click.prevent="uploadFiles"
        >
          <template v-if="!isUploading">Upload Files</template>
          <template v-else>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Uploading...
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { TreeSelect } from '@d4nteractive/vue3-treeselect'
import '@d4nteractive/vue3-treeselect/dist/style.css'
import { MediaHelperMixin } from '@src/modules/publish/components/media-library/utils/MediaHelpers'
import { EventBus } from '@common/lib/event-bus'
import Dropzone from 'dropzone'
import Asset from '../Asset'
import 'dropzone/dist/dropzone.css'
import { apiUrl } from '@/src/config/api-utils'
export default {
  name: 'ChunkUploadTab',
  components: {
    TreeSelect,
    Asset
  },
  mixins: [MediaHelperMixin],
  props: {
    type: {
      type: String,
      default: 'media'
    },
    folders: {
      type: Array,
      default() {
        return []
      }
    },
    modalId: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      images: [],
      isUploading: false,
      isDragging: false,
      selectedFolderValue: 'uncategorized',
      dropzone: null,
      fileChunkCookies: new Map()
    }
  },
  computed: {
    ...mapGetters([
      'getActiveWorkspace'
    ]),
    treeFolders() {
      let list = [{ id: 'uncategorized', label: 'Uncategorized' }]
      if (this.folders && this.folders.length) {
        list = [...list, ...this.folders]
      }
      return list
    },
    totalSize() {
      return this.images.reduce((total, img) => total + img.size, 0)
    },
    modalBodyClasses() {
      return {
        'bg-gray-100': this.isDragging
      }
    }
  },
  mounted() {
    this.initializeDropzone()
  },
  beforeUnmount() {
    if (this.dropzone) {
      this.dropzone.destroy()
    }
  },
  methods: {
    initializeDropzone() {
      Dropzone.autoDiscover = false
      this.dropzone = new Dropzone(this.$refs.dropzoneElement, {
        url: apiUrl + 'media_library/chunkUpload',
        chunking: true,
        forceChunking: true,
        chunkSize: 1000000, // 1MB chunks
        parallelChunking: true,
        retryChunks: true,
        retryChunksLimit: 3,
        maxFilesize: 500, // MB
        acceptedFiles: '.jpg,.jpeg,.png,.gif,.avi,.mov,.m4v,.mp4,.heic,application/pdf',
        headers: {
          'Authorization': `Bearer ${this.getJWTToken}`,
        },
        thumbnailWidth: 200,
        addRemoveLinks: true,
        autoProcessQueue: false, // Don't upload automatically
        dictDefaultMessage: '<i class="far fa-cloud-upload"></i><br>Drop large files here for chunk upload',
        previewTemplate: this.dropzoneTemplate()
      })
      this.dropzone.on('addedfile', this.onFileAdded)
      this.dropzone.on('removedfile', this.onFileRemoved)
      this.dropzone.on('uploadprogress', this.onUploadProgress)
      this.dropzone.on('success', this.onSuccess)
      this.dropzone.on('error', this.onError)
      this.dropzone.on('sending', this.onSending)
      this.dropzone.on('queuecomplete', this.onQueueComplete)
    },
    dropzoneTemplate() {
      return `
        <div class="dz-preview dz-file-preview">
          <div class="dz-image">
            <img data-dz-thumbnail />
          </div>
          <div class="dz-details">
            <div class="dz-filename"><span data-dz-name></span></div>
            <div class="dz-size" data-dz-size></div>
          </div>
          <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
          <div class="dz-success-mark"><span>✔</span></div>
          <div class="dz-error-mark"><span>✘</span></div>
          <div class="dz-error-message"><span data-dz-errormessage></span></div>
        </div>
      `
    },
    bytesToSize(bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 Byte'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
    onFileAdded(file) {
      this.images.push({
        _id: file.upload.uuid,
        name: file.name,
        size: file.size,
        type: file.type,
        url: URL.createObjectURL(file)
      })
    },
    onFileRemoved(file) {
      this.deleteItem(file.upload.uuid)
    },
    onUploadProgress(file, progress) {
      const fileIndex = this.images.findIndex(img => img._id === file.upload.uuid)
      if (fileIndex !== -1) {
        this.$set(this.images[fileIndex], 'progress', Math.round(progress))
      }
    },
    onSuccess(file, response) {
      const fileIndex = this.images.findIndex(img => img._id === file.upload.uuid)
      if (fileIndex !== -1) {
        this.$set(this.images[fileIndex], 'uploaded', true)
        this.$set(this.images[fileIndex], 'url', response.url)
      }
      EventBus.$emit('show-toast', {
        type: 'success',
        title: 'Upload Success',
        message: `Successfully uploaded ${file.name}`
      })
      this.fileChunkCookies.delete(file.upload.uuid)
    },
    onError(file, message) {
      EventBus.$emit('show-toast', {
        type: 'error',
        title: 'Upload Error',
        message: `Failed to upload ${file.name}: ${message}`
      })
      this.deleteItem(file.upload.uuid)
      this.fileChunkCookies.delete(file.upload.uuid)
    },
    onSending(file, xhr, formData) {
        // Add folder information to the upload request
        const [folderId, isRoot] = (this.selectedFolderValue || 'uncategorized').split('-');
        formData.append('workspace_id', this.getActiveWorkspace._id);
        // If MEDIASTORAGE value is already captured, pass it in the header
        if (this.mediaStorageValue) {
            xhr.setRequestHeader('mediastorage', this.mediaStorageValue);
        }
        console.log('Chunk upload Sending file:', xhr);
        // Capture MEDIASTORAGE value from the first chunk
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const responseHeaders = xhr.getAllResponseHeaders();
                const mediaStorageHeader = responseHeaders
                    .split('\r\n')
                    .find(header => header.toLowerCase().startsWith('mediastorage:'));
                if (mediaStorageHeader) {
                    this.mediaStorageValue = mediaStorageHeader.split(':')[1].trim();
                    console.log('Captured MEDIASTORAGE:', this.mediaStorageValue);
                }
            }
        };
    },
    onQueueComplete() {
      this.isUploading = false
      this.clearFiles()
      // Refresh all media data without closing modal
      EventBus.$emit('refetch-folders')
      EventBus.$emit('refetch-media')
      EventBus.$emit('refetch-media-limits')
      // Show success message
      EventBus.$emit('show-toast', {
        type: 'success',
        title: 'Upload Complete',
        message: 'All files have been uploaded successfully'
      })
    },
    clearFiles() {
      this.images = []
      if (this.dropzone) {
        this.dropzone.removeAllFiles()
      }
    },
    deleteItem(id) {
      this.images = this.images.filter((img) => img._id !== id)
    },
    beforeDestroy() {
      // Clean up all stored cookies when component is destroyed
      this.fileChunkCookies.clear()
    },
    uploadFiles() {
      if (this.images.length === 0) {
        EventBus.$emit('show-toast', {
          type: 'error',
          title: 'Upload Error',
          message: 'Please select files to upload'
        })
        return
      }
      this.isUploading = true
      try {
        if (this.dropzone) {
          // Ensure the dropzone queue has files
          if (this.dropzone.getQueuedFiles().length > 0) {
            // Process all files in the queue when upload button is clicked
            this.dropzone.processQueue()
          } else {
            EventBus.$emit('show-toast', {
              type: 'error',
              title: 'Upload Error',
              message: 'No files in queue to upload'
            })
            this.isUploading = false
          }
        }
      } catch (error) {
        console.error('Upload error:', error)
        EventBus.$emit('show-toast', {
          type: 'error',
          title: 'Upload Error',
          message: 'Failed to start upload process'
        })
        this.isUploading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dropzone {
  min-height: 150px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  background: white;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  &:hover {
    border-color: #666;
  }
  .dz-message {
    margin: 2em 0;
    font-size: 1.2em;
    color: #666;
    i {
      font-size: 2em;
      margin-bottom: 0.5em;
    }
  }
}
.upload-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  text-align: center;
  padding: 4px;
  font-size: 12px;
  .progress-bar {
    height: 4px;
    background: rgba(255, 255, 255, 0.3);
    margin-top: 4px;
    border-radius: 2px;
    overflow: hidden;
    &__fill {
      height: 100%;
      background: #fff;
      transition: width 0.2s ease;
    }
  }
}
</style>
