/**
 * useAIPostGeneration Composable
 * Provides reactive state and functions for:
 * - Saving and initializing post generation settings.
 * - Generating, retrieving, and paginating posts.
 * - Performing bulk actions (delete, regenerate, draft).
 * - Handling feedback and creating social posts.
 *
 * @module useAIPostGeneration
 */

import { computed, inject, ref } from 'vue'
import DummyTheme from '@src/assets/img/dummy-theme.jpeg'
import { debounce } from 'lodash'
import { useStore } from '@/src/store/base'
import proxy from '@/src/modules/common/lib/http-common'
import {
  savePostSettingsURL,
  updateStyleNameURL,
  updateBrandVoiceNameURL,
  generatePostsURL,
  getPostsURL,
  regeneratePostURL,
  deletePostURL,
  createSocialPostURL,
  bulkActionURL,
  feedbackURL,
} from '@/src/modules/publisher/config/api-utils'
import { EventBus } from '@/src/modules/common/lib/event-bus'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
// State
const AIPosts = ref([])
const postSettingsForm = ref({
  style: null,
  brandVoice: null,
  socialPlatform: '',
  language: 'english',
  themes: [],
  postType: 'image',
  postsCount: 10,
  captionLength: 5,
  emojiUsage: 'mid',
  hashtagUsage: 'mid',
  minCapLength: 1,
  maxCapLength: 10,
})

// Current view state (grid or list)
const currentView = ref('grid')

// Loaders
const postSettingLoader = ref(false)
const postGenerationLoader = ref(false)
const getPostsLoader = ref(false)
const loadMorePostsLoader = ref(false)
const regeneratingPostIds = ref(new Set())
const bulkActionLoader = ref(false)
const isLoading = ref(false)
// Pagination state
const currentPage = ref(1)
const perPage = ref(10)
const hasMorePosts = ref(true)


const searchQuery = ref('')

const selectedPosts = ref([])
const selectionMode = computed(() => selectedPosts.value.length > 0)
const allSelected = computed(() => selectedPosts.value.length === AIPosts.value.length)

// check if AIPosts is empty and no search query is present
const isAIPostsEmpty = computed(() => !AIPosts.value.length && !searchQuery.value)

// Options for dropdowns
const usageLevels = [
  { value: 'none', label: 'None' },
  { value: 'low', label: 'Low' },
  { value: 'mid', label: 'Mid' },
  { value: 'high', label: 'High' },
]

const platformOptions = [
  { icon: 'instagram-rounded.svg', value: 'instagram', label: 'Instagram' },
  { icon: 'facebook-rounded.svg', value: 'facebook', label: 'Facebook' },
  { icon: 'twitter-rounded.svg', value: 'twitter', label: 'Twitter(X)' },
  { icon: 'tiktok-rounded.svg', value: 'tiktok', label: 'TikTok' },
  { icon: 'gmb-rounded.svg', value: 'gmb', label: 'Google My Business' },
  { icon: 'threads-rounded.svg', value: 'threads', label: 'Threads' },
  { icon: 'bluesky-rounded.svg', value: 'bluesky', label: 'Bluesky' },
  { icon: 'linkedin-rounded.svg', value: 'linkedin', label: 'LinkedIn' },
  { icon: 'tumblr-rounded.svg', value: 'tumblr', label: 'Tumblr' },
]

const languageOptions = [
  { value: 'english', label: 'English', id: 'eng' },
  { value: 'spanish', label: 'Spanish', id: 'spa' },
  { value: 'french', label: 'French', id: 'fra' },
  { value: 'portuguese', label: 'Portuguese', id: 'por' },
  { value: 'german', label: 'German', id: 'deu' },
  { value: 'italian', label: 'Italian', id: 'ita' },
  { value: 'dutch', label: 'Dutch', id: 'nld' },
  { value: 'turkish', label: 'Turkish', id: 'tur' },
  { value: 'indonesian', label: 'Indonesian', id: 'ind' },
  { value: 'tagalog', label: 'Tagalog', id: 'tgl' },
  { value: 'swedish', label: 'Swedish', id: 'swe' },
  { value: 'danish', label: 'Danish', id: 'dan' },
  { value: 'norwegian', label: 'Norwegian', id: 'nor' },
  { value: 'romanian', label: 'Romanian', id: 'ron' },
  { value: 'polish', label: 'Polish', id: 'pol' },
  { value: 'finnish', label: 'Finnish', id: 'fin' },
  { value: 'hungarian', label: 'Hungarian', id: 'hun' },
  { value: 'greek', label: 'Greek', id: 'ell' },
  { value: 'czech', label: 'Czech', id: 'ces' },
  { value: 'malay', label: 'Malay', id: 'msa' },
]

const themeOptions = [
  { icon: DummyTheme, value: 'minimal', label: 'Minimal' },
  { icon: DummyTheme, value: 'elegant', label: 'Elegant' },
  { icon: DummyTheme, value: 'modern', label: 'Modern' },
]

const postTypeOptions = [
  { value: 'image', label: 'Text & Image', tooltip: 'This will consume your text and image credits' },
  { value: 'text', label: 'Text', tooltip: 'This will consume your text credits' },
]

/**
 * Composable for AI Post Generation.
 * @param {Ref} AIUserProfile - The AI user profile ref.
 * @returns {Object} - Methods and reactive state for post generation.
 */
export const useAIPostGeneration = (AIUserProfile) => {
  const root = inject('root')
  const { $bvModal } = root

  const { dispatch, getters } = useStore()

  const { openDraftComposer,isDraftComposer, draftPlanId } = useComposerHelper()


  /**
   * Save the current post settings.
   * @returns {Promise<boolean>} Status of the save operation.
   */
  const savePostSettings = async () => {
    postSettingLoader.value = true

    // Max num of posts is 10
    if (postSettingsForm.value.postsCount > 10) {
      return dispatch('toastNotification', {
        message: 'Number of posts cannot exceed 10',
        type: 'error',
      })
    }

    try {
      const workspaceId = getters.getActiveWorkspace._id
      const payload = {
        style: postSettingsForm.value.style.id,
        brand_voice: postSettingsForm.value.brandVoice.id,
        social_platform: postSettingsForm.value.socialPlatform,
        language: postSettingsForm.value.language.label,
        theme: 'idsaved',
        post_type: postSettingsForm.value.postType?.value,
        no_of_posts: postSettingsForm.value.postsCount,
        caption_length: {
          min: postSettingsForm.value.minCapLength,
          max: postSettingsForm.value.maxCapLength,
        },
        emoji_usage: postSettingsForm.value.emojiUsage,
        hashtag_usage: postSettingsForm.value.hashtagUsage,
      }

      const response = await proxy.post(savePostSettingsURL, {
        workspace_id: workspaceId,
        profile_id: AIUserProfile.value._id,
        ...payload,
      })

      if (!response.data.status) {
        throw new Error('Failed to save post settings')
      }

      console.debug('savePostSettings:response', response.data)

      dispatch('toastNotification', {
        message: 'Post settings saved successfully',
        type: 'success',
      })

      // Update AIUserProfile
      AIUserProfile.value = response.data.data

      return response.data.status
    } catch (error) {
      console.error('Error saving post settings:', error)
      dispatch('toastNotification', {
        message: 'Failed to save post settings',
        type: 'error',
      })
      return false
    } finally {
      postSettingLoader.value = false
    }
  }

  /**
   * Initialize the post settings form from the AIUserProfile.
   */
  const initializeFormFromProfile = () => {

    postSettingsForm.value.style = AIUserProfile.value?.styles?.find(
      (style) =>
        style.id === AIUserProfile.value?.post_generation_settings?.style
    )
    postSettingsForm.value.brandVoice = AIUserProfile.value?.brand_voices?.find(
      (voice) =>
        voice.id === AIUserProfile.value?.post_generation_settings?.brand_voice
    )
    postSettingsForm.value.socialPlatform =
      AIUserProfile.value?.post_generation_settings?.social_platform
    postSettingsForm.value.language = languageOptions.find(
      (lang) =>
        lang.label === AIUserProfile.value?.post_generation_settings?.language
    )

    postSettingsForm.value.postType = postTypeOptions.find(
      (type) =>
        type.value === AIUserProfile.value?.post_generation_settings?.post_type
    )
    postSettingsForm.value.postsCount =
      AIUserProfile.value?.post_generation_settings?.no_of_posts
    if (AIUserProfile.value?.post_generation_settings?.caption_length) {
      postSettingsForm.value.minCapLength =
        AIUserProfile.value?.post_generation_settings?.caption_length?.min
      postSettingsForm.value.maxCapLength =
        AIUserProfile.value?.post_generation_settings?.caption_length?.max
    }
    postSettingsForm.value.emojiUsage = usageLevels.find(
      (level) =>
        level.value ===
        AIUserProfile.value?.post_generation_settings?.emoji_usage
    ).value
    postSettingsForm.value.hashtagUsage = usageLevels.find(
      (level) =>
        level.value ===
        AIUserProfile.value?.post_generation_settings?.hashtag_usage
    ).value
  }

  /**
   * Update the name for a style or brand voice.
   * @param {string} type - 'styles' or 'brand_voices'.
   * @param {string} styleId - The ID of the style/brandVoice.
   * @param {string} name - The new name.
   * @returns {Promise<Object|undefined>} API response data.
   */
  const updateStyleName = async (type, styleId, name) => {
    try {
      const workspaceId = getters.getActiveWorkspace._id
      const payload = {
        id: styleId,
        name,
      }
      let api = ''
      if (type === 'styles') {
        api = updateStyleNameURL
      } else if (type === 'brand_voices') {
        api = updateBrandVoiceNameURL
      }

      if (!api) return

      const response = await proxy.post(api, {
        workspace_id: workspaceId,
        profile_id: AIUserProfile.value._id,
        ...payload,
      })
      if (!response.data.status) {
        throw new Error('Failed to update style name')
      }
      console.debug('updateStyleName:response', response.data)

      // dispatch('toastNotification', {
      //   message: response.data.message,
      //   type: 'success',
      // })
      return response.data
    } catch (error) {
      console.error('Error updating style name:', error)
      dispatch('toastNotification', {
        message: 'Failed to update name',
        type: 'error',
      })
    }
  }

  /**
   * Generate new posts.
   */
  const generatePosts = async () => {
    postGenerationLoader.value = true
    try {
      const workspaceId = getters.getActiveWorkspace._id
      const payload = {
        workspace_id: workspaceId,
        profile_id: AIUserProfile.value._id,
      }

      const response = await proxy.post(generatePostsURL, payload)

      // check if credits full
      if (response.data.image_credit_full) {
        dispatch('toastNotification', {
          message: 'Not enough credits to generate posts',
          type: 'error',
        })
        return
      }

      if (!response.data.status) {
        throw new Error('Failed to generate posts')
      }
      console.debug('generatePosts:response', response.data)

      // append the post in the start of the array
      AIPosts.value = [...response.data.posts, ...AIPosts.value]
      postGenerationLoader.value = false

      // update credits
      getters.getPlan.used_limits.caption_generation_credit = response.data.credit_usage.used_text_credits
      getters.getPlan.used_limits.image_generation_credit = response.data.credit_usage.used_image_credits

    } catch (error) {
      console.error('Error generating posts:', error)
      dispatch('toastNotification', {
        message: 'Failed to generate posts',
        type: 'error',
      })
      postGenerationLoader.value = false
    }
  }

  /**
   * Retrieve posts with pagination.
   * @param {number} [page=1] - The page number.
   * @param {boolean} [append=false] - Whether to append to current posts.
   */
  const getPosts = async (page = 1, append = false) => {

    // Make this api slow
    // await new Promise((resolve) => setTimeout(resolve, 15000))
    try {
      const workspaceId = getters.getActiveWorkspace._id
      const payload = {
        workspace_id: workspaceId,
        page,
        per_page: perPage.value,
      }

      if (searchQuery.value) {
        payload.search = searchQuery.value
      }

      const response = await proxy.post(getPostsURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to get posts')
      }
      console.debug('getPosts:response', response.data)

      // Update pagination state
      currentPage.value = page
      hasMorePosts.value = response.data.posts.length === perPage.value

      // Append or replace posts based on the append flag
      if (append) {
        AIPosts.value = [...AIPosts.value, ...response.data.posts]
      } else {
        AIPosts.value = response.data.posts
      }
      getPostsLoader.value = false
    } catch (error) {
      console.error('Error getting posts:', error)
      getPostsLoader.value = false
    }
  }

  /**
   * Load more posts for infinite scrolling.
   */
  const loadMorePosts = async () => {
    console.log('loadMorePosts:')

    loadMorePostsLoader.value = true

    if (!hasMorePosts.value) {
      loadMorePostsLoader.value = false
      return
    }

    await getPosts(currentPage.value + 1, true)
    loadMorePostsLoader.value = false
  }

  /**
   * Regenerate a specific post.
   * @param {string} postId - The ID of the post to regenerate.
   */
  const regeneratePost = async (postId) => {
    // Add this post ID to the set of regenerating posts
    if (regeneratingPostIds.value.has(postId)) return

    regeneratingPostIds.value.add(postId)
    try {
      const workspaceId = getters.getActiveWorkspace._id
      const payload = {
        workspace_id: workspaceId,
        profile_id: AIUserProfile.value._id,
        post_id: postId,
      }

      const response = await proxy.post(regeneratePostURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to regenerate post')
      }
      console.debug('regeneratePost:response', response.data)
      // update the post in the array
      AIPosts.value = AIPosts.value.map((post) => {
        if (post._id === postId) {
          return response.data.post
        }
        return post
      })
      dispatch('toastNotification', {
        message: 'Post regenerated successfully',
        type: 'success',
      })

      // update credits
      getters.getPlan.used_limits.caption_generation_credit = response.data.credit_usage.used_text_credits
      getters.getPlan.used_limits.image_generation_credit = response.data.credit_usage.used_image_credits
    } catch (error) {
      console.error('Error regenerating post:', error)
    } finally {
      // Remove this post ID from the set of regenerating posts
      regeneratingPostIds.value.delete(postId)
    }
  }

  /**
   * Delete a specific post.
   * @param {string} postId - The ID of the post to delete.
   * @returns {Promise<boolean>} Status of the delete operation.
   */
  const deletePost = async (postId) => {
    isLoading.value = true
    try {
      const workspaceId = getters.getActiveWorkspace._id
      const payload = {
        workspace_id: workspaceId,
        profile_id: AIUserProfile.value._id,
        post_id: postId,
      }

      const response = await proxy.post(deletePostURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to delete post')
      }
      console.debug('deletePost:response', response.data)
      // update the post in the array
      AIPosts.value = AIPosts.value.filter((post) => post._id !== postId)
      dispatch('toastNotification', {
        message: 'Post deleted successfully',
        type: 'success',
      })
      isLoading.value = false
      return response.data.status
    } catch (error) {
      console.error('Error deleting post:', error)
      isLoading.value = false
      return false
    }
  }

  /**
   * Create a social post from an AI generated post.
   * @param {string} postId - The post ID.
   * @param {boolean} [isDraft=false] - Whether this is a draft.
   */
  const createSocialPost = async (postId, isDraft = false) => {

    if (isLoading.value) return

    isLoading.value = true
    try {
      const workspaceId = getters.getActiveWorkspace._id
      const payload = {
        workspace_id: workspaceId,
        profile_id: AIUserProfile.value._id,
        post_id: postId,
      }

      const response = await proxy.post(createSocialPostURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to create social post')
      }
      if (isDraft) {
        isLoading.value = false
        return dispatch('toastNotification', {
          message: response.data.message,
          type: 'success',
        })
      }
      if (response.data.status && response.data.id) {
        EventBus.$emit('publication-composer-post', {
          mode: 'edit',
          id: response.data.id,
          duplicate: false,
          reopen: false,
          initialize: false,
          postType: null,
        })
        $bvModal.show('composer-modal')
      }
      console.debug('createSocialPost:response', response.data)
      isLoading.value = false
    } catch (error) {
      console.error('Error creating social post:', error)
      isLoading.value = false
    }
  }

  // Open New Social Composer
  const openNewSocialComposer = async () => {
    let res = false
    if (isDraftComposer.value) {
      res = await openDraftComposer(
        '⚠️ Unfinished Post is in the Composer!',
        'You have a post currently in the composer that is minimized. What would you like to do?',
        'Save & Create New',
        'Return to Composer'
      )

    }
    if (res === null) return
    else if (!res) {
      EventBus.$emit('publication-composer-post-loader', true)
      $bvModal.show('composer-modal')

      setTimeout(function () {
        EventBus.$emit('publication-composer-post', {
        mode: 'new',
        id: null,
        duplicate: false,
        reopen: false,
        initialize: true,
        postType: null,
      })
    }, 100)
    }
    else {
      const workspace = getters.getActiveWorkspace.slug
      history.pushState({}, null, `/${workspace}/composer/${draftPlanId.value}`)
      $bvModal.show('composer-modal')
    }

  }

  /**
   * Open the blog composer with proper initialization as a Composer Article
   * This function mimics the createPost('Composer Article') functionality from TopHeaderBar.vue
   */
  const openBlogComposer = () => {
    EventBus.$emit('open-new-blog-composer')
  }

  /**
   * Debounced search to get posts.
   */
  const debouncedSearch = debounce(() => {
      getPosts(1, false)
  }, 1000)

  /**
   * Toggle select/deselect all posts.
   */
  const selectAll = () => {
    if (selectedPosts.value.length === AIPosts.value.length) {
      selectedPosts.value = []
    } else {
      selectedPosts.value = AIPosts.value.map((post) => post._id)
    }
  }

  /**
   * Perform bulk actions on selected posts.
   * @param {string} action - The bulk action (e.g., 'delete', 'regenerate', 'draft').
   */
  const bulkAction = async (action) => {

    if (!selectedPosts.value.length || bulkActionLoader.value) {
      return
    }

    bulkActionLoader.value = true

    try {
      const workspaceId = getters.getActiveWorkspace._id
      const payload = {
        action,
        workspace_id: workspaceId,
        profile_id: AIUserProfile.value._id,
        post_ids: selectedPosts.value,
      }

      const response = await proxy.post(bulkActionURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to perform bulk action')
      }
      console.debug('bulkAction:response', response.data)

      const messages = {
        delete: 'Posts deleted successfully',
        regenerate: 'Posts regenerated successfully',
        draft: 'Posts drafted successfully',
      }

      dispatch('toastNotification', {
        message: messages[action],
        type: 'success',
      })
      // update the posts in the array
      if(action === 'delete') {

        AIPosts.value = AIPosts.value.filter((post) => !selectedPosts.value.includes(post._id))

      }else if(action === 'regenerate') {
        // Handle regenerate
      }
      else if(action === 'draft') {
        // Handle draft
      }

      selectedPosts.value = []
      bulkActionLoader.value = false
    } catch (error) {
      console.error('Error performing bulk action:', error)
      dispatch('toastNotification', {
        message: 'Failed to perform bulk action',
        type: 'error',
      })
      bulkActionLoader.value = false
    }
  }

  /**
   * Add feedback to a specific post.
   * @param {string} postId - The ID of the post.
   * @param {string} feedback - The feedback provided.
   */
  const addFeedback = async (postId, feedback) => {
    isLoading.value = true
    try {
      const workspaceId = getters.getActiveWorkspace._id
      const payload = {
        workspace_id: workspaceId,
        profile_id: AIUserProfile.value._id,
        post_id: postId,
        feedback,
      }

      const response = await proxy.post(feedbackURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to add feedback')
      }
      console.debug('addFeedback:response', response.data)
      // update the post in the array
      AIPosts.value = AIPosts.value.map((post) => {
        if (post._id === postId) {
          return { ...post, feedback }
        }
        return post
      })
      dispatch('toastNotification', {
        message: 'Feedback added successfully',
        type: 'success',
      })
      isLoading.value = false
    } catch (error) {
      console.error('Error adding feedback:', error)
      dispatch('toastNotification', {
        message: 'Failed to add feedback',
        type: 'error',
      })
      isLoading.value = false
    }
  }

  // Reset Selected Posts
  const resetSelectedPosts = () => {
    selectedPosts.value = []
    selectionMode.value = false
    allSelected.value = false
  }

  return {
    postSettingLoader,
    postSettingsForm,
    savePostSettings,
    initializeFormFromProfile,
    usageLevels,
    platformOptions,
    languageOptions,
    themeOptions,
    postTypeOptions,
    updateStyleName,
    generatePosts,
    getPosts,
    loadMorePosts,
    AIPosts,
    postGenerationLoader,
    getPostsLoader,
    loadMorePostsLoader,
    hasMorePosts,
    regeneratePost,
    regeneratingPostIds,
    deletePost,
    createSocialPost,
    searchQuery,
    debouncedSearch,
    selectedPosts,
    selectionMode,
    selectAll,
    bulkAction,
    allSelected,
    addFeedback,
    isAIPostsEmpty,
    resetSelectedPosts,
    bulkActionLoader,
    openNewSocialComposer,
    openBlogComposer,
    currentView
  }
}
