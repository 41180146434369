<script setup>
import { computed } from 'vue'
import SubscribersGraph from '@src/modules/analytics/views/youtube/components/graphs/SubscribersGraph.vue'
import EngagementsGraph from '@src/modules/analytics/views/youtube/components/graphs/EngagementsGraph.vue'
import VideoViewsGraph from '@src/modules/analytics/views/youtube/components/graphs/VideoViewsGraph.vue'
import WatchTimeGraph from '@src/modules/analytics/views/youtube/components/graphs/WatchTimeGraph.vue'
import PerformanceAndVideoScheduleGraph from '@src/modules/analytics/views/youtube/components/graphs/PerformanceAndVideoScheduleGraph.vue'
import VideoDiscoveryGraph from '@src/modules/analytics/views/youtube/components/graphs/VideoDiscoveryGraph.vue'
import VideoShareTypeGraph from '@src/modules/analytics/views/youtube/components/graphs/VideoShareTypeGraph.vue'
import PostsSection from '@src/modules/analytics/views/youtube/components/PostsSection.vue'
import CardsComponent from '@/src/modules/analytics/views/youtube/components/CardsComponent'
import TopAndLeastEngagingPosts from '@/src/modules/analytics/views/youtube/components/TopAndLeastEngagingPosts.vue'
import ReportFooter from '@/src/modules/analytics/components/reports/ReportFooter.vue'
import AiInsightsSection from '@src/modules/analytics/views/youtube/components/AIInsightsSection.vue'

import useYoutubeAnalytics from '@/src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'

const { topPosts, leastPosts, allTopPosts, selectedAccount } = useYoutubeAnalytics()

// max no. of posts per page
const chunkSize = 10

/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topEngagingPostsChunks = computed(() => {
  const startIndexes = []
  if (allTopPosts.value?.engagement) {
    for (let i = 0; i < allTopPosts.value.engagement.length; i += chunkSize) {
      startIndexes.push(i)
    }
  }
  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const TOTAL_SECTIONS = 8  // Updated to include AI insights sections
  return TOTAL_SECTIONS + topEngagingPostsChunks.value.length
})
</script>

<template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Overview</h2>
      <div class="flex flex-col">
        <!-- Cards -->
        <div class="grid grid-cols-8 gap-4 mb-14">
          <CardsComponent />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Cumulative Subscriber Trend</h2>
        <!-- Subscribers Graph -->
        <SubscribersGraph class="!mb-7" />
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Subscriber Trend</h2>
        <!-- Subscribers Graph -->
        <SubscribersGraph class="!mb-7" default-type="bar" />
      </div>
    </div>
    <ReportFooter :current-page="1" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Engagement Activity</h2>
      <!-- Engagements Graph -->
      <EngagementsGraph class="!mb-7" />
      <!-- Videos Graph -->
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Video Views</h2>
      <VideoViewsGraph />
    </div>
    <ReportFooter :current-page="2" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Engagement vs Daily Posting Pattern</h2>
      <!-- Videos and Engagements Graph -->
      <PerformanceAndVideoScheduleGraph class="!mb-7" />
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Video Views vs Daily Posting Pattern</h2>
      <PerformanceAndVideoScheduleGraph
        default-type="Video Views"
        class="!mb-7"
      />
    </div>
    <ReportFooter :current-page="3" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Watch Time</h2>
      <!-- Daily Watch Time Graph -->
      <WatchTimeGraph class="!mb-7" />

      <!-- Graph for how viewers find your videos -->
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">How Viewers Find Yout Videos</h2>
      <VideoDiscoveryGraph />
    </div>
    <ReportFooter :current-page="4" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Video Sharing Services</h2>
      <!-- Graph between % of videos and the services the video was shared through -->
      <VideoShareTypeGraph />
    </div>
    <ReportFooter :current-page="5" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top And Least Engaging Posts By Engagement</h2>
      <!-- Engaging Posts -->
      <TopAndLeastEngagingPosts
        :top-posts="topPosts"
        :least-posts="leastPosts"
        :selected-account="selectedAccount"
        class="!mb-7"
      />
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top And Least Engaging Posts By Views</h2>
      <TopAndLeastEngagingPosts
        :top-posts="topPosts"
        :least-posts="leastPosts"
        :selected-account="selectedAccount"
        default-option="Views"
      />
    </div>
    <ReportFooter :current-page="6" :total-pages="totalPageSize" />
  </div>

  <template v-for="(start, index) in topEngagingPostsChunks" :key="index">
    <!-- Iterate over each chunk of top engaging posts -->
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Youtube Top Posts</h2>
        <PostsSection
          :range-start-value="start"
          :range-end-value="Math.min(start + chunkSize, allTopPosts?.engagement?.length)"
        />
      </div>
      <ReportFooter :current-page="7 + index" :total-pages="totalPageSize" />
    </div>
  </template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">AI Insights</h2>
      <AiInsightsSection :indexes-to-show="[0, 1]" />
    </div>
    <ReportFooter :current-page="7 + topEngagingPostsChunks.length" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <AiInsightsSection :indexes-to-show="[2]" />
    </div>
    <ReportFooter :current-page="8 + topEngagingPostsChunks.length" :total-pages="totalPageSize" />
  </div>
</template>
