<script setup>
import { computed, inject, onMounted, ref } from 'vue'
import { paddle_ids as paddleIDs } from '@common/constants/pricing.js'
import useWorkspace from '@common/composables/useWorkspace'
import usePlatform from '@common/composables/usePlatform'
import CstButton from '@ui/Button/CstButton.vue'
import { usePaddle } from '@modules/billing/composables/usePaddle'
import { useStore } from '@state/base'
import { whiteLabelAddonURL } from '@modules/setting/config/api-utils'
import proxy from '@common/lib/http-common'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'
import usePermissions from '@common/composables/usePermissions'
import { userMavenMixin } from '@src/tracking/userMavenMixin'

const props = defineProps({
  showCloseButton: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: 'Your Title',
  },
  description: {
    type: String,
    default: 'Your Description',
  },
  features: {
    type: Array,
    default: () => [
      'Point A',
      'Point B',
      'Point C',
    ],
  },
  monthlyPrice: {
    type: Number,
    default: 50,
  },
  monthlyPriceLabel: {
    type: String,
    default: '/month',
  },
  yearlyPrice: {
    type: Number,
    default: 500,
  },
  yearlyPriceLabel: {
    type: String,
    default: '/year',
  },
  buttonText: {
    type: String,
    default: 'Purchase Now',
  },
  videoSrc: {
    type: String,
    default: '',
  },
  videoTitle: {
    type: String,
    default: '',
  },
  confirmationTitle: {
    type: String,
    default: 'Confirm Purchase',
  },
  confirmationMessage: {
    type: String,
    default: 'Are you sure you want to purchase this add-on?',
  },
  confirmationButtonText: {
    type: String,
    default: 'Proceed',
  },
  featureId: {
    type: String,
    default: '',
    required: true,
  },
})

defineEmits(['close'])

const root = inject('root')
const { $bvModal } = root
const { getters } = useStore()
const planType = ref('')
const isLoading = ref(false)
const { showToast } = usePlatform()
const { getSuperAdmin, getAdminsWithBillingPermissions } = useWorkspace()
const { openCheckout } = usePaddle()
const { hasPermission } = usePermissions()

const handlePurchaseNow = async () => {
  if (planType.value === '' || !hasPermission('can_see_subscription')) return
  const passThrough = {
    user_id: getSuperAdmin()?._id,
    user_email: getSuperAdmin()?.email,
  }

  if (getters?.getPlan?.subscription?.paddle_billing) {
    // paddle V1 billing
    $bvModal.show(`${props.featureId}-purchase-confirmation-modal`)
  } else {
    // paddle classic (old) billing
    await openCheckout({
      email: getSuperAdmin()?.email,
      title: props.title,
      passthrough: JSON.stringify(passThrough),
      product:
        planType.value === 'monthly'
          ? paddleIDs[process.env.VUE_APP_ENVIRONMENT].white_label_monthly
          : paddleIDs[process.env.VUE_APP_ENVIRONMENT].white_label_annual,
      successCallback: function () {
        showToast('Thanks for your purchase.', 'success')
        sendUserMavenEvent(false)
        setTimeout(async () => {
          // reload the page to update the limits
          window.location.reload()
        }, 2000)
      },
    })
  }
}

const onConfirmation = async () => {
  isLoading.value = true
  const payload = {
    workspace_id: getters.getActiveWorkspace?._id,
  }

  try {
    const { data } = await proxy.post(whiteLabelAddonURL, payload)

    if (data?.status) {
      showToast(data.message, 'success')
      sendUserMavenEvent(true)
      setTimeout(async () => {
        // reload the page to update the limits
        window.location.reload()
      }, 3000)
    } else {
      showToast(data.message, 'error')
    }
  } catch (error) {
    showToast(error.message, 'error')
  } finally {
    isLoading.value = false
    $bvModal.hide(`${props.featureId}-purchase-confirmation-modal`)
  }
}

// Clean computed property without side effects
const disabledPlans = computed(() => {
  if (getters?.getPlan?.subscription?.paddle_billing) {
    const currentPlan = getters?.getPlan?.subscription?.slug || ''
    return {
      monthly: currentPlan.includes('month'),
      annual: currentPlan.includes('annual'),
    }
  }
  return { monthly: false, annual: false }
})

// Set initial plan type based on current subscription
const setInitialPlanType = () => {
  if (getters?.getPlan?.subscription?.paddle_billing) {
    const currentPlan = getters?.getPlan?.subscription?.slug || ''
    if (currentPlan.includes('annual')) {
      planType.value = 'annual'
    } else if (currentPlan.includes('month')) {
      planType.value = 'monthly'
    }
  }
}

// Get the message to show when the user doesn't have access to billing
const getNoAccessMessage = () => {
  const adminEmails = getAdminsWithBillingPermissions.value.join(', ')
  if (adminEmails.length === 0) {
    return `You cannot purchase the ${props.title} add-on because you don't have billing access. Please contact your Super Admin (${
      getSuperAdmin()?.email
    }) for assistance`
  }
  return `You cannot purchase the ${props.title} add-on because you don't have billing access. Please contact your Super Admin (${
    getSuperAdmin()?.email
  }) or an Admin with billing permissions (${adminEmails}) for assistance`
}

const sendUserMavenEvent = (newBilling) => {
  userMavenMixin?.methods?.trackUserMaven(`${props.featureId}_purchased`, {
    billing_cycle: planType.value,
    new_billing: newBilling,
    current_plan: getters?.getPlan?.subscription?.slug,
  })
}

// Set initial value when component mounts
onMounted(() => {
  setInitialPlanType()
})
</script>

<template>
    <b-modal
    :id="`feature-add-on-modal-${featureId}`"
    hide-header
    hide-footer
    body-class="!p-0 !m-0 !w-[645px] lg:!w-[950px] max-h-[80vh]"
    content-class="!w-[645px] lg:!w-[950px]"
    dialog-class="flex w-screen h-[90vh] justify-center items-center"
  >
    <div class="w-full p-6 bg-white rounded-xl">
      <div class="flex w-full items-center justify-between mb-4">
        <p class="text-2xl font-medium leading-none">{{ props.title }}</p>
        <div
          v-if="props.showCloseButton"
          v-tooltip="'Close'"
          class="bg-gray-200 cursor-pointer flex items-center justify-center p-2.5 rounded-lg"
          @click="$bvModal.hide(`feature-add-on-modal-${featureId}`)"
        >
          <img
            alt="close icon"
            class="h-3 w-3"
            src="@assets/img/icons/close_cross.svg"
          />
        </div>
      </div>
      <p class="mb-6">{{ props.description }}</p>
      <div
        class="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-5 gap-y-6 gap-x-8 2xl:gap-x-10"
      >
        <div class="w-full 2xl:col-span-2" :class="{ '!col-span-full': !props.videoSrc }">
          <ul class="mb-8 space-y-2 p-3 rounded-lg bg-[#F8F8F8]">
            <li v-for="(feature, index) in props.features" :key="index" class="flex items-center">
              <img
                alt=""
                class="mr-2 h-4 w-4"
                src="@assets/img/icons/tick_grey.svg"
              />
              <p class="text-sm">{{ feature }}</p>
            </li>
          </ul>
          <div class="mb-8 flex space-x-4">
            <div
              :class="{
                '!border-blue-500 bg-[#F8FBFF] cursor-pointer hover:!border-blue-500':
                  planType === 'monthly',
                'opacity-50 cursor-not-allowed': disabledPlans.annual,
              }"
              class="border flex flex-1 py-3 px-4 rounded-lg"
              @click="!disabledPlans.annual && (planType = 'monthly')"
            >
              <div class="w-full">
                <p class="mb-1 font-medium">Monthly</p>
                <p class="text-2xl font-medium"
                  >${{ props.monthlyPrice }}<span class="text-sm font-normal">{{ props.monthlyPriceLabel }}</span></p
                >
              </div>
              <input
                v-model="planType"
                :disabled="disabledPlans.annual"
                class="scale-125 self-baseline"
                name="competitor_analytic_price"
                type="radio"
                value="monthly"
              />
            </div>
            <div
              :class="{
                '!border-blue-500 bg-[#F8FBFF] cursor-pointer hover:!border-blue-500':
                  planType === 'annual',
                'opacity-50 cursor-not-allowed': disabledPlans.monthly,
              }"
              class="border flex flex-1 py-3 px-4 rounded-lg"
              @click="!disabledPlans.monthly && (planType = 'annual')"
            >
              <div class="w-full">
                <p class="mb-1 font-medium">Yearly</p>
                <p class="text-2xl font-medium"
                  >${{ props.yearlyPrice }}<span class="text-sm font-normal">{{ props.yearlyPriceLabel }}</span></p
                >
              </div>
              <input
                v-model="planType"
                :disabled="disabledPlans.monthly"
                class="scale-125 self-baseline"
                name="competitor_analytic_price"
                type="radio"
                value="annual"
              />
            </div>
          </div>
          <div class="flex">
            <div
              v-tooltip.top="{
                content: !hasPermission('can_see_subscription')
                  ? getNoAccessMessage()
                  : planType === ''
                  ? `Kindly select a subscription plan prior to proceeding: $${props.monthlyPrice}${props.monthlyPriceLabel} OR $${props.yearlyPrice}${props.yearlyPriceLabel}`
                  : '',
                delay: 0,
              }"
              class="ml-auto"
            >
              <CstButton
                :disabled="
                  !hasPermission('can_see_subscription') || planType === ''
                "
                @click="handlePurchaseNow"
              >
                {{ props.buttonText }}
              </CstButton>
            </div>
          </div>
        </div>
        <div
          v-if="props.videoSrc"
          class="w-full flex justify-center items-center lg:col-span-1 2xl:col-span-3 aspect-w-16 aspect-h-9"
        >
          <div class="video-wrapper pr-2 lg:-mt-8">
            <div class="video-container">
              <iframe
                :src="props.videoSrc"
                :title="props.videoTitle"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CstConfirmationPopup
      :modal-name="`${props.featureId}-purchase`"
      :primary-button-text="props.confirmationButtonText"
      :loading="isLoading"
      @confirmed="onConfirmation"
    >
      <template v-slot:head>
        <p class="text-lg font-medium">{{ props.confirmationTitle }}</p>
      </template>
      <template v-slot:body>
        <p class="text-sm py-4">{{ props.confirmationMessage }}</p>
      </template>
    </CstConfirmationPopup>
  </b-modal>
</template>

<style>
/* Container styles */
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 0 auto;
}

/* Responsive iframe */
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0.75rem; /* rounded-xl equivalent */
}

/* Optional wrapper for grid layouts */
.video-wrapper {
  width: 100%;
}

/* Responsive breakpoints */
@media (min-width: 1024px) {
  .video-wrapper {
    grid-column: span 1;
  }
}

@media (min-width: 1536px) {
  .video-wrapper {
    grid-column: span 3;
  }
}
</style>
