<template>
  <div ref="mainComponentRef" class="analytics-main-container">
    <AnalyticsFilterBar
      :get-accounts="getAccounts"
      :get-date="getDate"
      :get-previous-date="getPreviousDate"
      :style="{
        // eslint-disable-next-line vue/camelcase
        cursor: fetching_data ? 'progress' : '',
        pointerEvents: fetching_data ? 'none' : '',
      }"
      :updating="fetching_data"
      type="tiktok"
    />

    <div
      v-if="getPlatformAccounts('tiktok')?.length <= 0 && !fetching_data"
      class="analytics-main-errors"
    >
      <img
        alt=""
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-tiktok-account.png"
      />
      <p v-if="getFetchSocialStatus"> Retrieving Data! Please Wait... </p>
      <p v-else>
        No Account Connected
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{ name: 'social' }"
          >Connect TikTok Account
        </router-link>
      </p>
    </div>

    <div
      v-else-if="getPlatformAccounts('tiktok')?.length > 0"
      class="analytics-content-wrapper"
    >
      <template v-if="selected_account?.state === 'Added'">
        <AnalyticsLoading :name="selected_account.name" />
      </template>
      <template v-else>
        <TabsComponent
          v-if="!getFetchSocialStatus"
          :tabs="['#overview', '#posts', '#AI-Insights']"
          type="tiktok"
        >
          <!-- overview tab -->
          <template v-if="selected_account" v-slot:overview>
            <OverviewSection />
          </template>

          <!-- posts tab -->
          <template v-slot:posts>
            <PostsSection />
          </template>

          <!-- AI Insights tab -->
          <template v-slot:AI-Insights>
            <AIInsightsSection />
          </template>
        </TabsComponent>
      </template>
    </div>

    <div v-else class="analytics-main-errors">
      <img
        alt=""
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p> Fetching Accounts! Please Wait... </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AnalyticsFilterBar from '@src/modules/analytics/views/overview/components/AnalyticsFilterBar'
import AnalyticsLoading from '@src/modules/analytics/components/common/AnalyticsLoading'
import analyticsUtilsMixin from '@src/modules/analytics/components/common/analyticsUtilsMixin'
import { tiktok } from '@src/modules/integration/store/states/mutation-types'
import { pusherAuthSocketAnalytics } from '@common/lib/pusher'
import { EventBus } from '@common/lib/event-bus'
import PostsSection from '@src/modules/analytics/views/tiktok/components/PostsSection.vue'
import OverviewSection from '@/src/modules/analytics/views/tiktok/components/OverviewSection.vue'
import TabsComponent from '@/src/modules/analytics/views/common/TabsComponent.vue'
import AIInsightsSection from '@/src/modules/analytics/views/tiktok/components/AIInsightsSection.vue'

export default {
  name: 'AnalyticsOverview',
  components: {
    AnalyticsLoading,
    AnalyticsFilterBar,
    OverviewSection,
    TabsComponent,
    PostsSection,
    AIInsightsSection,
  },
  mixins: [analyticsUtilsMixin],
  data() {
    return {
      tiktok_accounts: [],
      selected_account: [],
      date: '',
      previous_date: '',
      fetching_data: false,
      analytics_channel: '',
    }
  },
  computed: {
    ...mapGetters([
      'getSyncServiceStatus',
      'getFetchSocialStatus',
      'getProfile',
      'getTiktokAccounts',
    ]),
  },
  watch: {
    selected_account: async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        setTimeout(() => {
          this.$refs.mainComponentRef?.scrollIntoView({ behavior: 'smooth' })
        }, 0)
        this.removeSocketListners(oldVal)
        this.bindSocketListeners()
      }
    },
  },
  created() {
    // Listen for root event
    EventBus.$on('workspace-changed', () => {
      console.debug('workspace-changed')
      this.resetState()

      const pusher = pusherAuthSocketAnalytics()
      this.analytics_channel = pusher.subscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
      )
      if (this.selected_account?.platform_identifier) {
        this.bindSocketListeners()
      }
    })
  },
  mounted() {
    const pusher = pusherAuthSocketAnalytics()
    this.analytics_channel = pusher.subscribe(
      'analytics-channel-' + this.getActiveWorkspace._id
    )
    if (this.selected_account?.platform_identifier) {
      this.bindSocketListeners()
    }
  },
  beforeUnmount() {
    this.removeSocketListners(this.selected_account?.platform_identifier)
    if (this.analytics_channel) {
      this.analytics_channel.unbind(
        'syncing-' +
          this.getActiveWorkspace._id +
          '-' +
          this.selected_account?.platform_identifier
      )
      this.analytics_channel.unsubscribe(
        'analytics-channel-' + this.getActiveWorkspace._id
      )
    }
  },
  methods: {
    ...mapActions(['syncUpdateService']),
    resetState() {
      this.fetching_data = false
      this.tiktok_accounts = []
      this.selected_account = []
      this.date = ''
      this.previous_date = ''
      this.analytics_channel = ''
    },
    getAccounts(account) {
      this.selected_account = account
    },
    getDate(date) {
      this.date = date
    },
    getPreviousDate(date) {
      this.previous_date = date
    },
    getOverviewLoader(value) {
      this.fetching_data = value
    },
    getDemographicsLoader(value) {
      this.fetching_data = value
    },
    getPostsLoader(value) {
      this.fetching_data = value
    },
    removeSocketListners(value) {
      console.log('removing socket for tiktok ... ')
      const event = 'syncing-' + this.getActiveWorkspace._id + '-' + value
      this.analytics_channel.unbind(event)
    },
    bindSocketListeners() {
      const event =
        'syncing-' +
        this.getActiveWorkspace._id +
        '-' +
        this.selected_account?.platform_identifier
      console.log(event)
      this.analytics_channel.bind(event, (data) => {
        console.log('message data --> ', data)
        this.setTiktokState(
          data.state,
          data.account,
          data.last_analytics_updated_at
        )
      })
    },
    // eslint-disable-next-line camelcase
    setTiktokState(state, checkFor, last_analytics_updated_at) {
      const updatedItems = []
      for (const item in this.getTiktokAccounts) {
        const update = this.getTiktokAccounts[item]
        if (update.platform_identifier === checkFor) {
          update.state = state
          // eslint-disable-next-line camelcase
          update.last_analytics_updated_at = last_analytics_updated_at
        }
        updatedItems.push(update)
      }
      this.$store.dispatch('setPlatforms', {
        items: updatedItems,
        type: 'tiktok',
        all_item_setter: tiktok.SET_ALL_ACCOUNTS,
        item_setter: tiktok.SET_ACCOUNTS,
      })
    },
  },
}
</script>
