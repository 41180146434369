<template>
  <b-modal
    id="update-style-modal"
    title="Update Style"
    size="lg"
    hide-footer
    hide-header
    centered
    header-class="p-4 border-b"
    dialog-class="!w-[600px]"
  >
    <div class="modal_head">
      <div class="flex item-center justify-between w-full">
        <h4 class="text-xl font-semibold">Update Style</h4>
      </div>
      <button
        type="button"
        class="modal_head__close bg-transparent"
        data-dismiss="modal"
        aria-label="Close"
        @click.stop="handleClose"
      >&times;</button>
    </div>

    <div class="modal_body p-2">
      <CustomTab mode="style" />

      <!-- Footer -->
      <div class="flex items-center justify-end gap-3 px-2 py-4 mt-6">
        <Button
          variant="light" color="light" size="sm"
          @click="handleClose"
        >
          Cancel
        </Button>
        <Button
          size="sm"
          :loading="isLoading"
          @click="handleUpdateStyle"
        >
          Update
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { inject } from 'vue'
import { Button } from '@contentstudio/ui'
import CustomTab from '@modules/publisher/ai-content-library/components/form/tabs/CustomTab.vue'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { useBrandInfoSetting } from '@modules/publisher/ai-content-library/composables/useBrandInfoSetting'

const { isLoading } = useSetup()
const { updateStyle } = useBrandInfoSetting()

const root = inject('root')
const { $bvModal } = root

const handleUpdateStyle = async () => {
  await updateStyle()
  $bvModal.hide('update-style-modal')
}

const handleClose = () => {
  $bvModal.hide('update-style-modal')
}
</script>
