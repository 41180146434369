<template>
  <div>
    <Button
      v-tooltip="'Details'"
      variant="text"
      color="secondary"
      size="sm"
      radius="lg"
      class="!font-normal !cstu-text-gray-500 cstu-border cstu-border-gray-400/20 hover:cstu-bg-gray-100/50 hover:cstu-border-gray-400/25"
      @click="toggleFilterDrawer"
    >
      <i class="far fa-info-circle cstu-text-gray-500 text-sm leading-none"></i>
      <span class="hidden xl:inline-block">
        Details
      </span>
    </Button>
    <!-- Filter Drawer -->
    <CstDrawer
      :model-value="showFilterDrawer"
      title="Details"
      position="right"
      body-class="!overflow-visible"
      :width="290"
      @update:model-value="showFilterDrawer = $event"
    >
      <template v-slot:header="{ close }">
        <div class="h-[4.25rem] bottom-border-only flex items-center justify-between py-[16px] px-[14px]">
          <div class="flex items-center gap-[6px]" >
            <i class="far fa-info-circle cstu-text-gray-500 text-sm leading-none"></i>
            <span class="font-medium">Detailed Info</span>
          </div>
          <div class="cursor-pointer" @click="close">
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
              <path d="M10.5605 9.5L13.7803 6.28027C14.0732 5.9873 14.0732 5.5127 13.7803 5.21973C13.4873 4.92676 13.0127 4.92676 12.7197 5.21973L9.5 8.43945L6.28027 5.21973C5.9873 4.92676 5.5127 4.92676 5.21973 5.21973C4.92676 5.5127 4.92676 5.9873 5.21973 6.28027L8.43945 9.5L5.21973 12.7197C4.92676 13.0127 4.92676 13.4873 5.21973 13.7803C5.36621 13.9268 5.55811 14 5.75 14C5.94189 14 6.13379 13.9268 6.28027 13.7803L9.5 10.5605L12.7197 13.7803C12.8662 13.9268 13.0581 14 13.25 14C13.4419 14 13.6338 13.9268 13.7803 13.7803C14.0732 13.4873 14.0732 13.0127 13.7803 12.7197L10.5605 9.5Z" fill="#4A4A4A"/>
            </svg>
          </div>
        </div>
      </template>
      <template v-slot="{ close }">
        <div class="px-[13.5px] py-[11px] h-full overflow-y-auto">
          <div
            class="mb-4 py-4 px-3 rounded-xl space-y-5 transition-all duration-300 ease-in-out"
            :class="{ 'cstu-bg-blue-50 border-[1px] cstu-border-blue-500': isEditable, 'cstu-bg-gray-100 border-[1px] cstu-border-gray-100': !isEditable }"
            >
            <div class="flex items-center gap-3 bg-white p-4 rounded-xl border">
              <div class="relative">
                <img
                  :src="postBy?.image || defaultProfileImage"
                  :alt="postBy?.name"
                  class="w-10 h-10 rounded-full border"
                  @error="
                    (event) => {
                      event.target.src = defaultProfileImage
                    }
                  "
                />
                <div class="absolute -bottom-1 -right-1">
                  <img
                    :src="getSocialImageRounded(currentConversation?.platform)"
                    class="w-6 h-6 rounded-full"
                    :alt="currentConversation?.platform"
                  />
                </div>
              </div>
              <div class="flex-1 truncate">
                <p v-tooltip="postBy?.name" class="font-semibold truncate">{{ postBy?.name }}</p>
                <p class="text-sm cstu-text-gray-500 !text-opacity-50">Profile</p>
              </div>
              <Button
                v-tooltip="'Edit Profile'"
                variant="text"
                size="sm"
                :disabled="isUpdating"
                @click="handleEdit"
              >
                <img
                  :src="inboxEditProfile"
                  alt="edit-profile"
                />
              </Button>
            </div>
            <div class="space-y-3">
              <div
                v-for="(item, index) in detailsSection"
                :key="index"
                class="grid grid-cols-[auto,1fr] items-center gap-x-2"
              >
                <div class="flex items-center gap-3">
                  <img
                    :src="item.icon"
                    alt="icon"
                    class="w-5 h-5"
                  />
                  <span class="text-[#3A4557] font-bold min-w-[70px]">{{ item.title }}</span>
                </div>
                <template v-if="isEditable">
                  <div class="w-full">
                    <input
                      v-model="userInfo[item.key]"
                      type="text"
                      class="border-0 bg-transparent px-3 py-1.5 w-full outline-none"
                      :class="{ 'border-red-500 bg-red-50': validationErrors[item.key] }"
                      :placeholder="'Enter ' + item.title.toLowerCase()"
                      @input="validateField(item.key, userInfo[item.key])"
                    />
                    <p v-if="validationErrors[item.key]" class="text-xs text-red-500 px-3">
                      {{ validationErrors[item.key] }}
                    </p>
                  </div>
                </template>
                <template v-else>
                  <p class="px-3 py-1.5 w-full text-sm cstu-text-gray-500">
                    {{ userInfo[item.key] || '-' }}
                  </p>
                </template>
              </div>
              <template v-if="isEditable">
                <hr class="my-0" />
                <div class="flex items-center gap-3 justify-end">
                  <Button
                    variant="text"
                    size="sm"
                    :disabled="isUpdating"
                    @click="handleCancel"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="filled"
                    size="sm"
                    :disabled="Object.keys(validationErrors).length > 0 || isUpdating"
                    @click="handleUpdate"
                  >
                    {{ isUpdating ? 'Updating...' : 'Update' }}
                  </Button>
                </div>
              </template>
            </div>
          </div>
          <Collapsible button-class="!py-[8px]">
            <template v-slot:trigger="{ open }">
              <div class="flex items-center gap-3 group select-none">
                <span class="text-[#3A4557] font-semibold">Bookmarks</span>
                <div
                    class="text-[#3A4557] text-xs leading-none font-normal group-hover:font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#F7F7F7]"
                    :class="{
                    '!font-medium': open,
                  }"
                    >
                    {{ currentConversationBookmarks.length }}
                  </div>
              </div>
            </template>
            <div class="space-y-2">
              <template v-if="currentConversationBookmarks.length > 0">
                <template
                  v-for="(item, index) in currentConversationBookmarks"
                  :key="index"
                >
                  <ListItem
                    v-tooltip="'Click to jump to bookmark'"
                    class="text-sm flex items-center gap-[14px] group select-none"
                    @click="jumpToConversation(item),close()"
                  >
                    <img
                      v-tooltip="getBookmarkUserName(item)"
                      class="h-6 w-6 rounded-full object-cover"
                      :src="getBookmarkUserImage(item)"
                      alt="User image"
                      @error="
                        (event) => {
                          event.target.src = defaultProfileImage
                        }
                      "
                    />

                    <div
                      class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
                    >
                      {{ getBookmarkMessage(item) }}
                    </div>
                    <BookmarkButton :is-bookmarked="item?.is_starred" @click.stop="removeBookmark(item)" />
                  </ListItem>
                </template>
              </template>
              <template v-else>
                <p class="text-sm text-[#75797C] px-3">No bookmarks available</p>
              </template>
            </div>
          </Collapsible>
          <hr class="border-[#E0E0E0] my-3">
          <Collapsible button-class="!py-[8px]">
            <template v-slot:trigger="{ open }">
              <div class="flex items-center gap-3 group select-none">
                <span class="text-[#3A4557] font-semibold">Notes</span>
                <div
                    class="text-[#3A4557] text-xs leading-none font-normal group-hover:font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#F7F7F7]"
                    :class="{
                    '!font-medium': open,
                  }"
                    >
                    {{ currentConversationNotes.length }}
                  </div>
              </div>
            </template>
            <div class="space-y-2">
              <template v-if="currentConversationNotes.length > 0">
                <template
                  v-for="(item) in currentConversationNotes"
                  :key="item._id"
                >
                  <div
                    v-tooltip="'Click to jump to note'"
                    class="group select-none bg-[#FFFCF3] border !border-[#FFE48B] rounded-3xl p-3 space-y-1 cursor-pointer"
                    @click="jumpToConversation(item),close()"
                  >
                    <p class="text-sm">
                      {{ item.message }}
                    </p>
                    <p class="text-[#75797C] text-sm">
                      {{ formatDateTime(item.created_time) }}
                    </p>
                  </div>
                </template>
              </template>
              <template v-else>
                <p class="text-sm text-[#75797C] px-3">No notes available</p>
              </template>
            </div>
          </Collapsible>
        </div>
      </template>
    </CstDrawer>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import CstDrawer from '@ui/Drawer/CstDrawer.vue'
import { ListItem, Button, Collapsible } from '@contentstudio/ui'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox'
import { useStore } from '@state/base'
import BookmarkButton from '@src/modules/inbox-revamp/components/BookmarkButton.vue'
import { EventBus } from '@common/lib/event-bus'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const { dispatch, getters } = useStore()

const { getSocialImageRounded, getUTCFormattedTimeZoneDate } = useComposerHelper()
const {
  inboxEditProfile,
  inboxLocation,
  inboxJob,
  inboxInfo,
  inboxEmail,
  inboxCompany,
  inboxPhone,
 } = useIcons()

const {
  currentConversation,
  currentConversationNotes,
  currentConversationBookmarks,
} = useInbox()

const detailsSection = [
  {title: 'Email', icon: inboxEmail, key: 'email'},
  {title: 'Description', icon: inboxInfo, key: 'description'},
  {title: 'Location', icon: inboxLocation, key: 'location'},
  {title: 'Phone', icon: inboxPhone, key: 'phone'},
  {title: 'Job title', icon: inboxJob, key: 'job_title'},
  {title: 'Company', icon: inboxCompany, key: 'company'},
]

const defaultProfileImage = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

const isEditable = ref(false)
const isUpdating = ref(false)
const userInfo = ref({
  email: '',
  description: '',
  location: '',
  phone: '',
  job_title: '',
  company: '',
})
const userInfoBackup = ref({})
const validationErrors = ref({})

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const validatePhone = (phone) => {
  // Allow empty phone or valid format
  if (!phone) return true
  // Basic phone validation: allow +, spaces, (), - and numbers
  const phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/
  return phoneRegex.test(phone)
}

const validateField = (key, value) => {
  switch (key) {
    case 'email':
      if (value && !validateEmail(value)) {
        validationErrors.value[key] = 'Please enter a valid email address'
      } else {
        delete validationErrors.value[key]
      }
      break
    case 'phone':
      if (value && !validatePhone(value)) {
        validationErrors.value[key] = 'Please enter a valid phone number'
      } else {
        delete validationErrors.value[key]
      }
      break
    default:
      break
  }
}

const handleEdit = () => {
  userInfoBackup.value = JSON.parse(JSON.stringify(userInfo.value))
  validationErrors.value = {}
  isEditable.value = true
}

const handleCancel = () => {
  userInfo.value = JSON.parse(JSON.stringify(userInfoBackup.value))
  validationErrors.value = {}
  isEditable.value = false
}

const handleUpdate = () => {
  // Validate all fields
  Object.entries(userInfo.value).forEach(([key, value]) => {
    validateField(key, value)
  })

  // Check if there are any validation errors
  if (Object.keys(validationErrors.value).length > 0) {
    return
  }

  // Proceed with update
  updateUserDetails()
}

const updateUserDetails = async () => {
  isUpdating.value = true
  const payload = {
    workspace_id: getters?.getWorkspaces?.activeWorkspace?._id,
    platform_id: currentConversation.value?.platform_id,
    posted_by: {
      ...currentConversation.value?.inbox_details?.posted_by,
      ...userInfo.value,
    },
  }

  try {
    const res = await dispatch('updateUserDetails_v2', payload)
    if (res.isValid) {
      isEditable.value = false
      dispatch('toastNotification', {
        message: 'Profile updated successfully',
        type: 'success',
      })
    } else {
      dispatch('toastNotification', {
        message: res.message || 'Failed to update profile',
        type: 'error',
      })
    }
  } catch (error) {
    dispatch('toastNotification', {
      message: 'An error occurred while updating profile',
      type: 'error',
    })
  } finally {
    isUpdating.value = false
  }
}

const postBy = computed(() => {
  return currentConversation.value?.inbox_details?.posted_by
})

const toggleFilterDrawer = () => {
  showFilterDrawer.value = !showFilterDrawer.value
  console.log(showFilterDrawer.value)
}

async function getNotes() {
  console.log('getNotes')
  const payload = {
    workspace_id: getters?.getWorkspaces?.activeWorkspace?._id,
    conversation_id: currentConversation.value?.element_details?.element_id,
  }
  const res = await dispatch('getNotes_v2', payload)
  if (res.isValid) {
    currentConversationNotes.value = res.notes || []
  }
}

async function getBookmarks() {
  console.log('getBookmarks')
  const payload = {
    workspace_id: getters?.getWorkspaces?.activeWorkspace?._id,
    conversation_id: currentConversation.value?.element_details?.element_id,
  }
  const res = await dispatch('getBookmarks_v2', payload)
  if (res.isValid) {
    currentConversationBookmarks.value = res.bookmarks || []
  }
}

async function getUserDetails() {
  const payload = {
    workspace_id: getters?.getWorkspaces?.activeWorkspace?._id,
    element_id: currentConversation.value?.element_details?.element_id,
  }
  const res = await dispatch('getUserDetails_v2', payload)
  if (res.isValid) {
    console.log(res)
    mapUserDetails(res?.data)
  }
}

const formatDateTime = (date) => {
  return getUTCFormattedTimeZoneDate(date)?.formatDateTime();
}

const getBookmarkUserName = ( bookmarkItem ) => {
  const from = bookmarkItem?.from?.[0]
  return `${from?.first_name} ${from?.last_name}`
}

const getBookmarkUserImage = ( bookmarkItem ) =>{
  const from = bookmarkItem?.from?.[0]
  return from?.profile_pic || defaultProfileImage
}

const getBookmarkMessage = ( bookmarkItem ) =>{
  const isAttachment = bookmarkItem?.attachments?.length > 0
  if (isAttachment) {
    return 'Attachment included in this message';
  } else {
    return bookmarkItem?.message || 'Message not provided';
  }
}
const removeBookmark = ( bookmarkItem ) =>{
  // event bus emit
  EventBus.$emit('remove-conversation-bookmark', bookmarkItem)
}

const jumpToConversation = (conversation) => {
  EventBus.$emit('jump-to-conversation', conversation)
}

const mapUserDetails = (data) => {
  userInfo.value = {
    email: data?.email || '',
    description: data?.description || '',
    location: data?.location || '',
    phone: data?.phone || '',
    job_title: data?.job_title || '',
    company: data?.company || '',
  }
}

const showFilterDrawer = ref(false)

onMounted(() => {
  getNotes()
  getBookmarks()
  getUserDetails()
})

watch(
  () => currentConversation.value?.element_details?.element_id,
  (newVal, oldVal) => {
    if (newVal === oldVal) {
      return
    }
    getNotes()
    getBookmarks()
    getUserDetails()
  },
)

</script>
