<script setup>
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from '@state/base'
import FollowersGraph from '@src/modules/analytics/views/tiktok/components/graphs/FollowersGraph.vue'
import EngagementsGraph from '@src/modules/analytics/views/tiktok/components/graphs/EngagementsGraph.vue'
import VideosGraph from '@src/modules/analytics/views/tiktok/components/graphs/VideosGraph.vue'
import VideosPostingAndEngagements from '@src/modules/analytics/views/tiktok/components/graphs/VideosPostingAndEngagements.vue'
import debounce from 'lodash.debounce'
import CardsComponent from '@/src/modules/analytics/views/tiktok/components/CardsComponent.vue'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'
import TopAndLeastEngagingPosts from '@/src/modules/analytics/views/tiktok/components/TopAndLeastEngagingPosts.vue'
import TopPosts from '@src/modules/analytics/views/tiktok/components/TopPosts.vue'

// state
const route = useRoute()
const store = useStore()
const {
  routes,
  selectedAccount,
  dateRange,
  topEngagingPosts,
  leastEngagingPosts,

  fetchMedia,
} = useTiktokAnalytics()

watch(
  () => [dateRange, route.params.accountId],
  debounce(async () => {
    if (!route.name.includes('tiktok')) return
    selectedAccount.value = store.getters.getTiktokAccounts?.items.find(
      (i) => i.platform_identifier === route.params.accountId
    )

    fetchMedia(routes.PAGE_AND_POST_INSIGHTS)
    fetchMedia(routes.PAGE_FOLLOWERS_AND_VIEWS)
    fetchMedia(routes.POSTS_AND_ENGAGEMENTS)
    fetchMedia(routes.TOP_AND_LEAST_PERFORMING_POSTS)
    fetchMedia(routes.DAILY_ENGAGEMENTS_DATA)
  }, 300),
  { deep: true, immediate: true }
)
</script>

<template>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-8 gap-4 mb-7">
      <CardsComponent />
    </div>

    <div class="flex flex-col gap-y-[1.75rem]">
      <!-- Followers Graph -->
      <FollowersGraph />

      <!-- Engagements Graph -->
      <EngagementsGraph />

      <!-- Videos Graph -->
      <VideosGraph />

      <!-- Videos and Engagements Graph -->
      <VideosPostingAndEngagements />

      <!-- Engaging Posts -->
      <TopPosts />
    </div>
  </div>
</template>
