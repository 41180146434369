<template>
  <div
    class="transition-all duration-300 relative flex overflow-hidden"
    :style="mainDivStyle"
  >
    <!-- Sidebar Container -->
    <div class="relative z-50">
      <CstSidebar
        ref="sidebarRef"
        v-model="isMobileOpen"
        v-model:expanded="isExpanded"
      >
        <!-- Expanded Header -->
        <template v-slot:head-expanded>
          <div
            class="flex items-center justify-between h-[4.25rem] bottom-border-only"
          >
            <div class="flex items-center px-6 gap-1">
              <FilterDrawer />
              <SyncButton
                v-tooltip="'Sync your data across all of your social accounts'"
                :loading="isSyncing"
                size="sm"
                @click="handleSync"
              />
            </div>
            <SidebarToggleIconButton
              v-tooltip="'Collapse'"
              @click="sidebarRef?.toggleExpand()"
            />
          </div>
        </template>

        <!-- Shrunk Header -->
        <template v-slot:head-shrink>
          <div
            class="flex items-center justify-center h-[4.25rem] bottom-border-only"
          >
            <div class="rotate-180">
              <SidebarToggleIconButton
                v-tooltip="'Expand'"
                @click="sidebarRef?.toggleExpand()"
              />
            </div>
          </div>
        </template>

        <!-- Expanded Body -->
        <template v-slot:body-expanded>
          <div class="px-3 pt-3 space-y-2">
            <template v-for="(item, index) in sidebarItems" :key="index">
              <ListItem
                :selected="currentViewRoute === item.route"
                class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
                @click="router.push({ name: 'inbox-revamp', params: { filter: item.route } })"
              >
                <img
                  :src="item.icon"
                  alt="icon"
                  class="grayscale group-hover:grayscale-0"
                  :class="{
                    '!grayscale-0': currentViewRoute === item.route,
                  }"
                />
                <div
                  v-tooltip="item.title"
                  class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
                  :class="{
                    '!font-medium': currentViewRoute === item.route,
                  }"
                >
                  {{ item.title }}
                </div>

                <div
                  v-tooltip="`${conversationCount[item.countKey] || 0}`"
                  class="text-[#3A4557] text-xs leading-none font-normal group-hover:font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#F7F7F7] group-hover:bg-[#D8EFFF]"
                  :class="{
                    '!bg-[#D8EFFF] !font-medium': currentViewRoute === item.route,
                  }"
                >
                  {{ formatNumber(conversationCount[item.countKey] || 0) }}
                </div>

              </ListItem>
            </template>
            <hr />
            <Collapsible :default-open="true" :button-class="'!py-[8px]'" :hide-arrow="true">
              <template v-slot:trigger="{ open: isCollapsibleOpen }">
                <div class="flex items-center justify-between w-full">
                  <span class="text-[#3A4557] font-normal"> Others </span>
                  <div class="flex items-center justify-between w-[21px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                      :class="['cstu-transform cstu-transition-transform cstu-duration-200', { 'cstu-rotate-180': !isCollapsibleOpen }]"
                    >
                      <path d="M5.64844 0.919922L9.81641 5.08789C9.91667 5.18815 9.9668 5.3099 9.9668 5.45312C9.9668 5.59635 9.91667 5.7181 9.81641 5.81836L9.34375 6.29102C9.24349 6.39128 9.12174 6.44499 8.97852 6.45215C8.83529 6.45931 8.71354 6.41276 8.61328 6.3125L5.2832 2.98242L1.95312 6.3125C1.85286 6.41276 1.73112 6.45931 1.58789 6.45215C1.44466 6.44499 1.32292 6.39128 1.22266 6.29102L0.75 5.81836C0.649739 5.7181 0.599609 5.59635 0.599609 5.45312C0.599609 5.3099 0.649739 5.18815 0.75 5.08789L4.91797 0.919922C5.01823 0.805339 5.13997 0.748047 5.2832 0.748047C5.42643 0.748047 5.54818 0.805339 5.64844 0.919922Z" fill="#75797C"/>
                    </svg>
                  </div>
                </div>
              </template>
              <div class="space-y-2">
                <template
                  v-for="(item, index) in otherSidebarItems"
                  :key="index"
                >
                  <ListItem
                    :selected="currentViewRoute === item.route"
                    class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
                    @click="router.push({ name: 'inbox-revamp', params: { filter: item.route } })"
                  >
                    <img
                      :src="item.icon"
                      alt="icon"
                      class="grayscale group-hover:grayscale-0"
                      :class="{
                        '!grayscale-0': currentViewRoute === item.route,
                      }"
                    />
                    <div
                      v-tooltip="item.title"
                      class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
                      :class="{
                        '!font-medium': currentViewRoute === item.route,
                      }"
                    >
                      {{ item.title }}
                    </div>
                    <div
                      v-if="item?.countKey"
                      v-tooltip="`${conversationCount[item.countKey] || 0}`"
                      class="text-[#3A4557] text-xs leading-none font-normal group-hover:font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#F7F7F7] group-hover:bg-[#D8EFFF]"
                      :class="{
                        '!bg-[#D8EFFF] !font-medium': currentViewRoute === item.route,
                      }"
                    >
                      {{ formatNumber(conversationCount[item.countKey] || 0) }}
                    </div>
                  </ListItem>
                </template>
              </div>
            </Collapsible>
          </div>
        </template>

        <!-- Shrunk Body -->
        <template v-slot:body-shrink>
          <div class="px-3 pt-3 space-y-2">
            <template
              v-for="(item, index) in [...sidebarItems, ...otherSidebarItems]"
              :key="index"
            >
              <ListItem
                v-tooltip.right="item.title"
                :selected="currentViewRoute === item.route"
                class="text-sm flex items-center gap-[14px] group select-none"
                @click="router.push({ name: 'inbox-revamp', params: { filter: item.route } })"
              >
                <img
                  :src="item.icon"
                  :alt="item.title"
                  class="w-5 h-5 grayscale group-hover:grayscale-0"
                  :class="{
                    '!grayscale-0': currentViewRoute === item.route,
                  }"
                />
              </ListItem>
            </template>
          </div>
        </template>
      </CstSidebar>
    </div>

    <!-- Message List Container with Fixed Width -->
    <div
      class="transition-all duration-300 relative"
      :class="[
        'w-[30rem]',
        {
          'opacity-50': isMobileOpen && isMobileView,
        },
      ]"
    >
      <InboxListing />
    </div>

    <!-- Main Content -->
    <div
      class="flex-1 bg-gray-100 transition-all duration-300 overflow-auto"
      :class="{
        'opacity-50': isMobileOpen && isMobileView,
      }"
    >
    <template v-if="conversationList?.conversations?.length > 0">
      <router-view></router-view>
    </template>
    <template v-else>
      <div class="flex flex-col items-center justify-center h-full">
        <img :src="inboxNoResult" alt="No Result" class="w-44 h-44" />
        <p class="text-lg font-medium cstu-text-gray-500">No Results found</p>
        <p class="text-base cstu-text-gray-500">Your inbox is empty, check back later!</p>
      </div>
    </template>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import CstSidebar from '@ui/Sidebar/CstSidebar.vue'
import usePlatform from '@common/composables/usePlatform'
import SidebarToggleIconButton from '@src/modules/inbox-revamp/components/SidebarToggleIconButton.vue'
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox'
import {useIcons} from '@src/modules/inbox-revamp/composables/useIcons'
import useNumber from '@src/modules/common/composables/useNumber'
import { ListItem, Collapsible } from '@contentstudio/ui'
import InboxListing from '@src/modules/inbox-revamp/components/InboxListing.vue'
import FilterDrawer from '@src/modules/inbox-revamp/components/FilterDrawer.vue'
import { useRouter } from 'vue-router'
import { commonMethods } from '@common/store/common-methods'
import SyncButton from '@src/modules/inbox-revamp/components/SyncButton.vue'
import { EventBus } from '@common/lib/event-bus'
import { debounce } from 'lodash'
import { pusherInboxRevamp } from '@src/modules/common/lib/pusher.js'
import { useStore } from '@state/base'
import {useFeatures} from "@modules/billing/composables/useFeatures";

const { getters } = useStore()

const router = useRouter()
const { formatNumber } = useNumber()

const  { inboxNoResult } = useIcons()
const {
  sidebarItems,
  otherSidebarItems,
  conversationCount,
  selectedChannels,
  inboxTypes,
  selectedInboxType,
  conversationList,
  selectedTags,
  selectedAssingedTo,
  selectedAssingedBy,
  selectedMarkedDoneBy,
  selectedArchivedBy,
  pusherChannel,
  pusherInstance,
  currentConversation,
  targetConversationId,
  isFirstAPI,
  fetchDetails,
  fetchConversationList,
  triggerPlatformSyncJob,
  initializeSelectedChannels,
  updateLastMessage,
  updateLastComment,
  pushNewConversation,
  fetchTags,
} = useInbox()

const { topBannersHeight } = usePlatform()
const {canAccess} = useFeatures()

const inboxAccess = canAccess('social_inbox')
const sidebarRef = ref(null)
const isMobileOpen = ref(false)
const isExpanded = ref(true)
const isMobileView = ref(false)
const isSyncing = ref(false)
const isMounted = ref(true)

// Set up resize listener
onMounted(() => {
  if(!inboxAccess.value?.allowed){
    console.log('inbox access denied')
    router.push({
      name: 'dashboard',
      params: { workspace: getters?.getWorkspaces?.activeWorkspace?.slug }
    })
    return
  }
  checkMobileView()
  window.addEventListener('resize', checkMobileView)
  initializeSelectedChannels()
  fetchDetails(inboxTypes?.[selectedInboxType.value]?.filter)
  fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)
  setTimeout(() => {
    commonMethods.toggleWidgets(true)
  }, 500)

  connectPusher()

  EventBus.$on('fetched-social-accounts', async () => {
    console.log('fetched-social-accounts')
    initializeSelectedChannels()
    fetchDetails(inboxTypes?.[selectedInboxType.value]?.filter)
    fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)
  })

  EventBus.$on('workspace-changed', () => {
    fetchTags()
    disconnectPusher()
    connectPusher()
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', checkMobileView)
  EventBus.$off('fetched-social-accounts')
  EventBus.$off('workspace-changed')
  commonMethods.toggleWidgets()
  isMounted.value = false
  disconnectPusher()
})

const currentViewRoute = computed(() => {
  return router?.currentRoute?.value?.params?.filter || 'all'
})

const mainDivStyle = computed(() => {
  return {
    height: `calc(100vh - ${topBannersHeight.value}px)`,
  }
})

watch(
  () => [selectedChannels.value, selectedTags.value],
  debounce(async () => {
    fetchDetails(inboxTypes?.[selectedInboxType.value]?.filter)
    fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)
  }, 100),
  { deep: true }
)

watch(
  () => currentViewRoute.value,
  debounce(async (value, oldValue) => {
    console.log('currentViewRoute.value', value, oldValue)
    if (value === oldValue) {
      return
    }
    // resetting the actions filter:
    selectedAssingedTo.value = []
    selectedAssingedBy.value = []
    selectedMarkedDoneBy.value = []
    selectedArchivedBy.value = []
    fetchDetails(inboxTypes?.[selectedInboxType.value]?.filter)
    fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)
  }, 100),
)

watch(() => router?.currentRoute?.value?.params?.conversation,
  (value, oldValue) => {
    if(value === oldValue) {
      return
    }

    if(value && router?.currentRoute?.value?.query?.notification){
      targetConversationId.value = value

      if(!isFirstAPI.value){
        fetchDetails(inboxTypes?.[selectedInboxType.value]?.filter)
        fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, currentViewRoute.value)
      }
    }
    else if(isFirstAPI.value){
      targetConversationId.value = value
    }
  },
  { immediate: true }
)

// Check if mobile view
const checkMobileView = () => {
  isMobileView.value = window.innerWidth <= 1024 // lg breakpoint
}

const handleSync = async() => {
  isSyncing.value = true
  // This triggers all of the platforms
  await triggerPlatformSyncJob()
  isSyncing.value = false
}

const connectPusher = () => {
  if (!isMounted.value) return

  console.log('connectPusher')
  // Subscribe to the specific inbox events channel
  if (pusherInstance.value) {
    disconnectPusher()
  }

  pusherInstance.value = pusherInboxRevamp()

  // Handle connection state changes
  pusherInstance.value.connection.bind('state_change', (states) => {
    console.log('Pusher state changed:', states.previous, '->', states.current)
    if ((states.current === 'disconnected' || states.current === 'failed') && isMounted.value) {
      console.log('Attempting to reconnect in 3 seconds...')
      setTimeout(() => {
        if (isMounted.value) {
          console.log('Reconnecting to Pusher...')
          connectPusher()
        }
      }, 3000)
    }
  })

  pusherChannel.value = pusherInstance.value.subscribe(`inbox_events_${getters.getActiveWorkspace._id}`)

  // Handle subscription errors
  pusherChannel.value.bind('pusher:subscription_error', (error) => {
    console.error('Pusher subscription error:', error)
    if (isMounted.value) {
      setTimeout(() => {
        if (isMounted.value) {
          console.log('Retrying subscription...')
          connectPusher()
        }
      }, 3000)
    }
  })

  // Bind to various event types
  pusherChannel.value?.bind('new_message', (data) => {
    if (!isMounted.value) return
    console.log('PUSHER:: new_message',data)
    const isCurrentConversation = currentConversation.value?.platform_id === data?.platform_id &&
        currentConversation.value?.element_details?.element_id === data?.conversation_id

    if( isCurrentConversation ){
      console.log('PUSHER:: new_message -> conversation match')
      EventBus.$emit('push_new_message', data)
    }
    updateLastMessage(data, isCurrentConversation)
  })

  pusherChannel.value?.bind('new_comment', (data) => {
    if (!isMounted.value) return
    console.log('PUSHER:: new_comment',data)
    const isCurrentConversation = currentConversation.value?.platform_id === data?.platform_id &&
    currentConversation.value?.element_details?.element_id === data?.conversation_id

    if( isCurrentConversation ){
      EventBus.$emit('push_new_comment', data)
    }
    updateLastComment(data, isCurrentConversation)
  })

  pusherChannel.value?.bind('new_element', (data) => {
    if (!isMounted.value) return
    console.log('PUSHER:: new_element',data)
    pushNewConversation(data)
  })
}

const disconnectPusher = () => {
  console.log('disconnectPusher')
  pusherChannel.value?.unbind('new_message')
  pusherChannel.value?.unbind('new_comment')
  pusherChannel.value?.unbind('new_element')
  pusherChannel.value?.unbind('pusher:subscription_error')
  pusherInstance.value?.connection?.unbind('state_change')
  pusherInstance.value?.unsubscribe(`inbox_events_${getters.getActiveWorkspace._id}`)
  pusherInstance.value?.disconnect()
  pusherInstance.value = null
  pusherChannel.value = null
}
</script>

<style>
.bottom-border-only {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-x-only {
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}
</style>
