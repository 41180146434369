<script setup>
import { watch } from 'vue'
import useYoutubeAnalytics from '@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import SubscribersGraph from '@src/modules/analytics/views/youtube/components/graphs/SubscribersGraph.vue'
import EngagementsGraph from '@src/modules/analytics/views/youtube/components/graphs/EngagementsGraph.vue'
import VideoViewsGraph from '@src/modules/analytics/views/youtube/components/graphs/VideoViewsGraph.vue'
import WatchTimeGraph from '@src/modules/analytics/views/youtube/components/graphs/WatchTimeGraph.vue'
import PerformanceAndVideoScheduleGraph from '@src/modules/analytics/views/youtube/components/graphs/PerformanceAndVideoScheduleGraph.vue'
import VideoDiscoveryGraph from '@src/modules/analytics/views/youtube/components/graphs/VideoDiscoveryGraph.vue'
import VideoShareTypeGraph from '@src/modules/analytics/views/youtube/components/graphs/VideoShareTypeGraph.vue'
import debounce from 'lodash.debounce'
import CardsComponent from '@/src/modules/analytics/views/youtube/components/CardsComponent'
import TopAndLeastEngagingPosts from '@/src/modules/analytics/views/youtube/components/TopAndLeastEngagingPosts.vue'
import TopPosts from '@/src/modules/analytics/views/youtube/components/TopPosts.vue'

// state
const {
  routes,
  selectedAccount,
  dateRange,
  fetchMedia,
} = useYoutubeAnalytics()

watch(
  () => [dateRange, selectedAccount.value],
  debounce(async () => {
    fetchMedia(routes.CHANNEL_SUBSCRIBER_TREND)
    fetchMedia(routes.CHANNEL_SUMMARY)
    fetchMedia(routes.CHANNEL_ENGAGEMENT_TREND)
    fetchMedia(routes.CHANNEL_VIEWS_TREND)
    fetchMedia(routes.CHANNEL_WATCH_TIME_TREND)
    fetchMedia(routes.CHANNEL_FIND_VIDEO)
    fetchMedia(routes.CHANNEL_VIDEO_SHARING)
    fetchMedia(routes.TOP_PERFORMING_POSTS)
    fetchMedia(routes.LEAST_PERFORMING_POSTS)
    fetchMedia(routes.PERFORMANCE_AND_VIDEO_POSTING_SCHEDULE)
  }, 300),
  { deep: true, immediate: true }
)
</script>

<template>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-5 gap-4 mb-7">
      <CardsComponent />
    </div>

    <div class="flex flex-col gap-y-[1.75rem]">
      <!-- Subscribers Graph -->
      <SubscribersGraph />

      <!-- Engagements Graph (Likes/Dislikes/Shares/Comments) -->
      <EngagementsGraph />

      <!-- Videos Views Graph (Views by subscribers vs non-subscribers) -->
      <VideoViewsGraph />

      <PerformanceAndVideoScheduleGraph />

      <!-- Watch Time Graph -->
      <WatchTimeGraph />

      <div class="grid grid-cols-2 gap-4">
        <!-- Graph between % of videos and the sources from where the video was found -->
        <VideoDiscoveryGraph />

        <!-- Graph between % of videos and the services the video was shared through -->
        <VideoShareTypeGraph />
      </div>

      <!-- Top Posts -->
      <TopPosts type="top_posts" />

      <!-- Least Posts -->
      <TopPosts type="least_posts" />

    </div>
  </div>
</template>
