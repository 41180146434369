<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { EventBus } from '@common/lib/event-bus'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'
import AnalyticsTable from '@src/modules/analytics/views/common/AnalyticsPostsTable.vue'

const {
  topPostsData,
  isLoadingStates,
  routes,
  validPostsTableHeaders,
  getHeaderTitles,
  getHeaderTooltips,
  getBodyValues,
} = useLinkedinAnalytics('Top Posts')

const showModal = ref(false)
const date = ref('')
const posts = ref('')

// Event listeners for opening and closing the modal
const handleShowModal = ({ date: selectedDate, posts: postsData }) => {
  date.value = selectedDate
  showModal.value = true
  posts.value = postsData
}

const handleClose = (close) => {
  topPostsData.value = [] // Reset posts to an empty array
  close() // Call the original close function to close the modal
}

// Register event listeners on component mount
onMounted(() => {
  EventBus.$on('show-top-posts-modal', handleShowModal)
})

// Remove event listeners on component unmount
onBeforeUnmount(() => {
  EventBus.$off('show-top-posts-modal', handleShowModal)
})
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    content-class="!w-11/12"
    dialog-class="cst-modal !justify-center"
  >
    <template v-slot:modal-header="{ close }">
      <div class="flex items-center justify-between w-full px-4 py-2">
        <h3 class="text-lg font-medium">{{ posts }}{{ date }}</h3>
        <div
          v-tooltip="{
            content: 'Close',
            theme: 'light',
          }"
          class="cursor-pointer w-[1vw] h-[1vh] flex justify-center items-center rounded-full"
          @click="handleClose(close)"
        >
          <i class="fa fa-times fa-lg" aria-hidden="true"></i>
        </div>
      </div>
    </template>

    <div class="p-4">
      <AnalyticsTable
        :data-list="topPostsData"
        :valid-headers-list="validPostsTableHeaders"
        :non-sortable-items="['thumbnail']"
        :mutate-header-titles="getHeaderTitles"
        :mutate-body-values="getBodyValues"
        :mutate-header-tooltips="getHeaderTooltips"
        :is-loading="topPostsData.length === 0"
        custom-no-data-message="No posts found for this date"
      />
    </div>
  </b-modal>
</template>
