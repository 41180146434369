import PostIcon from '@modules/publisher/assets/posts-icon.svg'
import ProfileIcon from '@modules/publisher/assets/profile-icon.svg'
import BrandIcon from '@modules/publisher/assets/branding.svg'
import StrategyIcon from '@modules/publisher/assets/strategy.svg'
import TopicsIcon from '@modules/publisher/assets/topics.svg'
import DesignIcon from '@modules/publisher/assets/design.svg'
import VideoIcon from '@modules/publisher/assets/videos.svg'
import ArticleIcon from '@modules/publisher/assets/article.svg'
import BulkUploadIcon from '@modules/publisher/assets/bulk-upload.svg'
import EvergreenIcon from '@modules/publisher/assets/evergreen.svg'
import RSSIcon from '@modules/publisher/assets/rss.svg'
import SocialIcon from '@modules/publisher/assets/social.svg'
import PlannerIcon from '@modules/publisher/assets/planner-icon.svg'
import ContentCategoryIcon from '@modules/publisher/assets/content-categories.svg'

export const useIcons = () => {
  return {
    PostIcon,
    ProfileIcon,
    BrandIcon,
    StrategyIcon,
    TopicsIcon,
    DesignIcon,
    VideoIcon,
    ArticleIcon,
    BulkUploadIcon,
    EvergreenIcon,
    RSSIcon,
    SocialIcon,
    PlannerIcon,
    ContentCategoryIcon
  }
}
