<template>
  <b-modal
    id="planner-notes-modal"
    @hidden="closeModal"
    hide-header
    hide-footer
    centered
    dialog-class="xl:!max-w-[38rem]"
    no-close-on-backdrop
  >
    <div class="relative">
      <!--      modal close     -->
      <div class="w-full flex justify-between px-4 pt-5">
        <div>
          <!--        modal title     -->
          <div class="w-full flex items-center gap-x-[8px]">
            <p class="font-medium text-lg text-[#202324] select-none">{{ note.is_duplicate ? 'Duplicate Note' : (note.id ? 'Edit Note' : 'Add Note') }}</p>
          </div>
          <div class="w-full flex items-center gap-x-[8px]">
            <p class="text-sm text-[#757A8A] select-none">Notes added here will be visible in the Calendar View only for now. <a
                href="#"
                class="beacon"
                data-beacon-article-modal="6746c3068c083456b657df04">Learn more</a></p>
          </div>
        </div>
        <button v-tooltip="'Close'" @click="$bvModal.hide('planner-notes-modal')" class="btn h-max text-gray-400 hover:shadow px-2 py-0.5 bg-[#3A45570D]">
          <i class="font-light fas fa-times"></i>
        </button>
      </div>
      <!--      main modal body     -->
      <div class="px-4 w-full flex flex-col justify-center items-center">
        <div class="w-full space-y-6 py-5">
          <div class="w-full">
            <label for="title" class="block mb-2 font-medium text-gray-900">Title <span class="text-red-600">*</span></label>
            <input
                id="title"
                type="text"
                v-model="note.note_title"
                @input="(e) => updateNoteField('note_title', e.target.value)"
                placeholder="Add your title here"
                class="w-full border border-gray-300 rounded-lg !text-gray-900 px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-100 color-border cst-editor"
            />
            <p v-if="errors.note_title.error" class="mt-1 text-sm text-red-600">{{ errors.note_title.message }}</p>
          </div>
          <div>
            <label for="content" class="block text-sm font-medium text-gray-900 mb-2">Description</label>
            <textarea
                id="description"
                v-model="note.description"
                @input="(e) => updateNoteField('description', e.target.value)"
                placeholder="Add your note body here (optional)"
                rows="4"
                class="w-full border border-gray-300 rounded-lg !text-gray-900 px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-100 color-border cst-editor"
            ></textarea>
          </div>
          <div class="flex justify-between space-x-4">
            <div class="w-full">
              <label for="start_date" class="block text-sm font-medium text-gray-900 mb-2">Start Date <span class="text-red-600">*</span></label>
              <CstInputFields
                  :date-options="dateOptions"
                  :value="note.start_date"
                  class="planner-date-picker cursor-pointer w-full"
                  placeholder="Select specific date range"
                  type="date"
                  :range="false"
                  :date-picker-popup-classes="['planner-date-picker-popup']"
                  @change="(e) => updateNoteField('start_date', e)"
              />
              <p v-if="errors.start_date.error" class="mt-1 text-sm text-red-600">{{ errors.start_date.message }}</p>
            </div>
            <div class="w-full">
              <label for="end_date" class="block text-sm font-medium text-gray-900 mb-2">End Date</label>
              <CstInputFields
                  :date-options="dateOptions"
                  :value="note.end_date"
                  class="planner-date-picker cursor-pointer w-full"
                  placeholder="Select specific date range"
                  type="date"
                  :range="false"
                  :date-picker-popup-classes="['planner-date-picker-popup']"
                  @change="(e) => updateNoteField('end_date', e)"
              />
            </div>
          </div>
          <div>
            <label class="block text-sm font-medium text-gray-900 mb-2">Visibility</label>
            <div class="space-y-3 w-96">
                <CstDropdown class="w-64" size="small">
                  <template v-slot:selected>
                    <div class="flex flex-row space-x-2 py-1 items-center">
                      <div class="flex gap-x-3 items-center">
                        <p class="text-sm leading-none text-gray-900">{{ note.is_private ? 'Only me' : 'Public' }}</p>
                      </div>
                    </div>
                  </template>

                  <template v-slot>
                    <CstDropdownItem class="group point !pl-4 flex w-full justify-between items-center h-14" @click="updateNoteField('is_private', true)">
                      <div class="flex gap-x-3 items-center">
                        <div>
                          <p class="text-sm text-gray-900">Only me</p>
                          <p class="text-xs text-gray-700">Note will be shown to you only </p>
                        </div>
                      </div>
                    </CstDropdownItem>
                    <CstDropdownItem class="group point !pl-4 flex w-full justify-between items-center h-14" @click="updateNoteField('is_private', false)">
                      <div class="flex gap-x-3 items-center">
                        <div>
                          <p class="text-sm text-gray-900">Public</p>
                          <p class="text-xs text-gray-700">Note will be visible to all team members</p>
                        </div>
                      </div>
                    </CstDropdownItem>
                  </template>
                </CstDropdown>
              </div>
          </div>
          <div>
            <div class="flex items-center mb-4">
              <CstSimpleCheckbox v-model="note.is_recurring" reverse @change="(e) => handleRecurringChange(e,note.is_recurring) ">
                <template v-slot:label>
                  <span class="font-medium text-gray-900">Recurring</span>
                </template>
              </CstSimpleCheckbox>
            </div>
            <div class="mt-1">
              <div class="flex justify-between space-x-4">
                <div class="w-full">
                  <label for="recurring_start" class="block text-sm font-medium mb-2" :class="note.is_recurring ? 'text-gray-900' : 'text-gray-600'">Start Date</label>
                  <CstDropdown class="w-full" size="small" :disabled="!note.is_recurring">
                    <template v-slot:selected>
                      <div class="flex flex-row space-x-2 py-1 items-center">
                        <div class="flex gap-x-3 items-center">
                          <p class="text-sm leading-none" :class="note.is_recurring ? 'text-gray-900' : 'text-gray-600'">{{ note.recurring_start }}</p>
                        </div>
                      </div>
                    </template>

                    <template v-slot>
                      <div class="max-h-60 overflow-y-auto">
                        <CstDropdownItem
                          v-for="day in 31"
                          :key="day"
                          class="group point !pl-4 flex w-full justify-between items-center h-10"
                          @click="updateNoteField('recurring_start', day)"
                          :disabled="!note.is_recurring"
                        >
                          <div class="flex gap-x-3 items-center">
                            <p class="text-sm text-gray-900">{{ day }}</p>
                          </div>
                        </CstDropdownItem>
                      </div>
                    </template>
                  </CstDropdown>
                  <p v-if="errors?.recurring_start?.error" class="mt-1 text-sm text-red-600">{{ errors?.recurring_start?.message }}</p>
                </div>
                <div class="w-full">
                  <label for="recurring_end" class="block text-sm font-medium mb-2" :class="note.is_recurring ? 'text-gray-900' : 'text-gray-600'">End Date</label>
                  <CstDropdown class="w-full" size="small" :disabled="!note.is_recurring">
                    <template v-slot:selected>
                      <div class="flex flex-row space-x-2 py-1 items-center">
                        <div class="flex gap-x-3 items-center">
                          <p class="text-sm leading-none" :class="note.is_recurring ? 'text-gray-900' : 'text-gray-600'">{{ note.recurring_end }}</p>
                        </div>
                      </div>
                    </template>

                    <template v-slot>
                      <div class="max-h-60 overflow-y-auto">
                        <CstDropdownItem
                          v-for="day in 30"
                          :key="day"
                          class="group point !pl-4 flex w-full justify-between items-center h-10"
                          @click="updateNoteField('recurring_end', day)"
                          :disabled="!note.is_recurring"
                        >
                          <div class="flex gap-x-3 items-center">
                            <p class="text-sm text-gray-900">{{ day }}</p>
                          </div>
                        </CstDropdownItem>
                      </div>
                    </template>
                  </CstDropdown>
                  <p v-if="errors?.recurring_end?.error" class="mt-1 text-sm text-red-600">{{ errors?.recurring_end?.message }}</p>
                </div>
                <div class="w-full">
                  <label for="recurring_occurrences" class="block text-sm font-medium mb-2" :class="note.is_recurring ? 'text-gray-900' : 'text-gray-600'">Occurrence</label>
                  <div class="flex ring-1 ring-gray-300 h-10 rounded-md border-1">
                    <button
                      class="planner-notes-counter-btn planner-notes-counter-minus border-0"
                      @click="decrementOccurrences"
                      :disabled="!note.is_recurring"
                      :class="!note.is_recurring ? 'opacity-50 cursor-not-allowed' : ''"
                    >
                      <span class="text-lg font-bold">−</span>
                    </button>
                    <input
                      id="recurring_count"
                      v-model="note.recurring_count"
                      type="number"
                      min="1"
                      max="100"
                      class="planner-notes-counter-input !p-0 !m-0 border-0 flex items-center"
                      onkeydown="return false"
                      :disabled="!note.is_recurring"
                      :class="!note.is_recurring ? 'text-gray-600' : ''"
                    />
                    <button
                      class="planner-notes-counter-btn planner-notes-counter-plus border-0"
                      @click="incrementOccurrences"
                      :disabled="!note.is_recurring"
                      :class="!note.is_recurring ? 'opacity-50 cursor-not-allowed' : ''"
                    >
                      <span class="text-lg font-bold">+</span>
                    </button>
                  </div>
                  <p v-if="errors?.recurring_count?.error" class="mt-1 text-sm text-red-600">{{ errors?.recurring_count?.message }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">
            <label class="block text-sm font-medium text-gray-900 mb-2">Color</label>
            <div class="flex flex-wrap gap-2">
              <template v-for="(color, index) in colorOptions" :key="index">
                <div
                    @click="updateNoteField('note_color', color.hex)"
                    class="w-6 h-6 rounded-md cursor-pointer flex justify-center items-center transition-all duration-200 hover:scale-125 hover:shadow-md"
                    :style="{ backgroundColor: color.hex }"
                >
                  <i v-if="note.note_color === color.hex" class="fa fa-check text-white"></i>
                </div>
              </template>
            </div>
          </div>

          <div class="flex justify-between items-center pt-4" :class="{'!justify-end': !note.id}">
            <div v-tooltip="'Delete note'" v-if="note.id" @click.prevent="deleteNote" class="cursor-pointer flex items-center space-x-2">
              <i class="fas !text-red-500 fa-trash-alt"></i>
              <span class="leading-normal">Delete note</span>
            </div>
            <CstButton class="ml-auto block" @click.prevent="saveNote">
              <span>{{ note.is_duplicate ? 'Duplicate Note' : (note.id ? 'Edit Note' : 'Add Note') }}</span>
              <clip-loader
                  v-if="loader"
                  class="spinner ml-2"
                  :color="'#e0dfdf'"
                  :size="'15px'"
              ></clip-loader>
            </CstButton>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>


<script setup>
import { usePlannerNotes } from '@modules/planner_v2/composables/usePlannerNotes'
import CstButton from '@ui/Button/CstButton'
import CstInputFields from '@ui/Input/CstInputFields'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstSimpleCheckbox from '@ui/CheckBox/CstSimpleCheckbox'

const {
  note,
  colorOptions,
  loader,
  errors,
  closeModal,
  saveNote,
  deleteNote,
  updateNoteField,
  dateOptions,
  incrementOccurrences,
  decrementOccurrences,
  handleRecurringChange
} = usePlannerNotes()
</script>

<style lang="scss">
#planner-notes-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border: none !important;
        border-bottom: 0px !important;
        align-items: center !important;
        padding: 1rem 2.2rem;
        h5 {
          font-size: 1.25rem;
          font-weight: 500;
        }
      }
    }
  }
}

/* Counter UI specific styles */
.planner-notes-counter-btn {
  @apply w-[48px] flex items-center justify-center;
}

.planner-notes-counter-minus {
  @apply text-gray-700;
}

.planner-notes-counter-plus {
  @apply text-blue-500;
}

.planner-notes-counter-input {
  @apply flex-1 text-center text-gray-900 px-3 py-2.5;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
