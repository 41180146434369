<template>
  <div class="px-4 space-y-3">
    <!-- Logo Upload -->
    <div class="flex flex-col">
      <label class="text-sm font-medium text-[#3C4549]">Brand Logo
        <i
          v-tooltip="'Your brand\'s logo, which represents your visual identity. You can edit or replace it if needed.'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i>
      </label>
      <div
        class="w-32 h-32 relative rounded-xl border border-gray-200 transition-all hover:border-blue-500 cursor-pointer group"
        @click="triggerFileInput"
      >
        <!-- Loading State -->
        <div
          v-if="isLoading"
          class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-80 rounded-xl z-30"
        >
          <clip-loader :color="'#2294FF'" :size="'36px'" />
        </div>

        <!-- File Input -->
        <input
          ref="fileInput"
          type="file"
          accept="image/*"
          class="hidden"
          @change="handleLogoUpload"
        />

        <!-- Logo Preview -->
        <div
          class="h-full flex flex-col items-center justify-center p-2 relative"
        >
          <!-- Logo Image -->
          <div class="relative w-full h-full flex items-center justify-center group">
            <img
              :src="brandInfo?.branding?.logo || csIcon"
              alt="Brand Logo"
              class="max-w-full max-h-full object-contain"
            />
          </div>

          <!-- Overlay used for hover state -->
          <div class="absolute inset-0 hidden group-hover:block bg-preview rounded-xl z-10"> </div>

          <!-- Action Buttons -->
          <div
            class="absolute top-2 right-2 hidden group-hover:flex gap-2 z-20"
          >
            <button
              v-tooltip="'Change Logo'"
              class="w-6 h-6 rounded-lg bg-[#efefef] shadow-sm flex items-center justify-center"
              @click.stop="triggerFileInput"
            >
              <img src="@src/modules/publisher/assets/upload-logo.svg" alt="Change Logo" class="w-4 h-4" />
            </button>
            <button
              v-tooltip="'Remove Logo'"
              class="w-6 h-6 rounded-lg bg-[#efefef] shadow-sm flex items-center justify-center"
              @click.stop="removeLogo"
            >
              <img src="@src/assets/img/icons/delete-colored.svg" alt="Remove Logo" class="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Colors -->
    <div>
      <label class="block text-sm font-medium text-[#4A4A4A] mb-2"
        >Colors
        <i
          v-tooltip="'Your brand\'s primary colors, used to maintain a consistent visual style. Editable via the color picker'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i>
      </label>
      <div class="flex gap-6">
        <div
          class="flex items-center gap-2 mb-2 p-3 rounded-xl border border-[#86868673] hover:border-[#436AFF] cursor-pointer transition-colors"
          @click="triggerColorPicker('brand')"
        >
          <div
            class="w-6 h-6 rounded-lg border border-[#86868673]"
            :style="{
              backgroundColor: brandInfo?.branding?.brandColor || '#436AFF',
            }"
          ></div>
          <span class="text-sm text-[#4A4A4A]">Brand Color</span>
          <input
            ref="brandColorInput"
            type="color"
            class="absolute opacity-0 w-0 h-0"
            :value="brandInfo?.branding?.brandColor || '#436AFF'"
            @input="updateColor('brandColor', $event.target.value)"
          />
        </div>

        <div
          class="flex items-center gap-2 mb-2 p-3 rounded-xl border border-[#86868673] hover:border-[#436AFF] cursor-pointer transition-colors"
          @click="triggerColorPicker('background')"
        >
          <div
            class="w-6 h-6 rounded-lg border border-[#86868673]"
            :style="{
              backgroundColor:
                brandInfo?.branding?.backgroundColor || '#FFFFFF',
            }"
          ></div>
          <span class="text-sm text-[#4A4A4A]">Background Color</span>
          <input
            ref="backgroundColorInput"
            type="color"
            class="absolute opacity-0 w-0 h-0"
            :value="brandInfo?.branding?.backgroundColor || '#FFFFFF'"
            @input="updateColor('backgroundColor', $event.target.value)"
          />
        </div>

        <div
          class="flex items-center gap-2 mb-2 p-3 rounded-xl border border-[#86868673] hover:border-[#436AFF] cursor-pointer transition-colors"
          @click="triggerColorPicker('text')"
        >
          <div
            class="w-6 h-6 rounded-lg border border-[#86868673]"
            :style="{
              backgroundColor: brandInfo?.branding?.textColor || '#3A4557',
            }"
          ></div>
          <span class="text-sm text-[#4A4A4A]">Text Color</span>
          <input
            ref="textColorInput"
            type="color"
            class="absolute opacity-0 w-0 h-0"
            :value="brandInfo?.branding?.textColor || '#3A4557'"
            @input="updateColor('textColor', $event.target.value)"
          />
        </div>
      </div>
    </div>

    <!-- Business Name -->
    <div>
      <label class="block mb-2 font-medium text-[#3C4549]">
        Business Name
        <i
          v-tooltip="'The name of your brand or business. This will be used to personalize your content.'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i>
      </label>
      <input
        :value="brandInfo.branding.businessName"
        type="text"
        placeholder="Enter your business name"
        class="w-full border border-gray-300 rounded-xl px-4 py-2.5 focus:outline-none focus:ring-2 focus:ring-[#436AFF] cst-editor"
        @input="updateField('businessName', $event.target.value)"
      />
    </div>

    <!-- Fonts -->
    <div class="grid grid-cols-2 gap-4">
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Heading Font
          <i
            v-tooltip="'Select heading font'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <Dropdown
          radius="lg"
          container-classes="w-full"
          placement="bottom"
          match-width="true"
          button-classes="border rounded-xl hover:!border-[#2294FF]"
          dropdown-classes="!z-[1050]"
        >
          <template v-slot:selected>{{
            brandInfo.branding.headingFont || 'Select Font'
          }}</template>
          <DropdownItem
            v-for="font in headingFonts"
            :key="font.value"
            class="group flex justify-between items-center"
            :class="
              brandInfo.branding.headingFont === font.value
                ? 'bg-[#f0f7ff]'
                : ''
            "
            @click="updateField('headingFont', font.value)"
          >
            {{ font.label }}
            <i
              class="far fa-check"
              :class="
                brandInfo.branding.headingFont === font.value
                  ? 'visible'
                  : 'invisible'
              "
            ></i>
          </DropdownItem>
        </Dropdown>
      </div>
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Body Font
          <i
            v-tooltip="'Select body font'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <Dropdown
          radius="lg"
          container-classes="w-full"
          placement="bottom"
          match-width="true"
          button-classes="border rounded-xl hover:!border-[#2294FF]"
          dropdown-classes="!z-[1050]"
        >
          <template v-slot:selected>{{
            brandInfo.branding.bodyFont || 'Select Font'
          }}</template>
          <DropdownItem
            v-for="font in bodyFonts"
            :key="font.value"
            class="group flex justify-between items-center"
            :class="
              brandInfo.branding.bodyFont === font.value ? 'bg-[#f0f7ff]' : ''
            "
            @click="updateField('bodyFont', font.value)"
          >
            {{ font.label }}
            <i
              class="far fa-check"
              :class="
                brandInfo.branding.bodyFont === font.value
                  ? 'visible'
                  : 'invisible'
              "
            ></i>
          </DropdownItem>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import csIcon from '@src/assets/img/integration/cs_logo.svg'
import { ref } from 'vue'
import { Dropdown, DropdownItem } from '@contentstudio/ui'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'

const { brandInfo, uploadLogo, isLoading } = useSetup()

const fileInput = ref(null)
const brandColorInput = ref(null)
const backgroundColorInput = ref(null)
const textColorInput = ref(null)

const headingFonts = [
  { value: 'Poppins', label: 'Poppins' },
  { value: 'Roboto', label: 'Roboto' },
  { value: 'Inter', label: 'Inter' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Open Sans', label: 'Open Sans' },
  { value: 'Lato', label: 'Lato' },
]

const bodyFonts = [
  { value: 'Roboto', label: 'Roboto' },
  { value: 'Inter', label: 'Inter' },
  { value: 'Open Sans', label: 'Open Sans' },
  { value: 'Lato', label: 'Lato' },
  { value: 'Poppins', label: 'Poppins' },
  { value: 'Montserrat', label: 'Montserrat' },
]

const triggerFileInput = () => {
  fileInput.value?.click()
}

const handleLogoUpload = async (event) => {
  const file = event.target.files[0]
  if (file) {
    await uploadLogo(file)
  }
  // Clear the input value to allow uploading the same file again
  event.target.value = ''
}

const removeLogo = () => {
  brandInfo.branding.logo = null
}

const triggerColorPicker = (type) => {
  switch (type) {
    case 'brand':
      brandColorInput.value?.click()
      break
    case 'background':
      backgroundColorInput.value?.click()
      break
    case 'text':
      textColorInput.value?.click()
      break
  }
}

const updateField = (field, value) => {
  if (brandInfo?.branding) {
    brandInfo.branding[field] = value
  }
}

const updateColor = (field, value) => {
  if (brandInfo?.branding) {
    brandInfo.branding[field] = value
  }
}
</script>

<style scoped>
.bg-preview {
  background: linear-gradient(
    to bottom,
    rgba(38, 40, 44, 0.1) -26%,
    rgba(38, 40, 44, 0.3) 100%
  ) !important;
}
</style>
