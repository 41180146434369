<template>
  <div class="mt-6">
    <div>
      <label class="block mb-2 font-medium text-[#3C4549]">
        Website URL
        <i
          v-tooltip="'Enter your website URL to let AI analyze and extract key brand information such as styles, voice, and topics'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i>
      </label>
      <input
        v-model="setupInfo.website.url"
        type="text"
        placeholder="https://www.example.com"
        class="w-full border border-gray-300 rounded-xl px-3 py-2.5 focus:outline-none focus:ring-1 bg color-border hover:!border-[#2294FF]"
      />
    </div>
    <div class="mt-2">
      <h3 class="text-sm font-medium mb-2 py-2"
        >Examples of website to create a great brand voice</h3
      >
      <div class="grid grid-cols-4 gap-6">
        <div
          v-for="site in exampleSites"
          :key="site.url"
          class="flex flex-col items-center gap-y-3 py-6 border rounded-xl cursor-pointer"
          :class="site.bg"
        >
          <img :src="site.icon" :alt="site.name" class="w-8 h-8" />
          <a
            :href="site.url"
            target="_blank"
            class="text-sm font-medium text-[#3C4549]"
            >{{ site.name }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import UserMavenLogo from '@assets/img/integration/usermaven_icon.svg'
import ContentPenLogo from '@assets/img/contentpen.svg'
import ReplugLogo from '@assets/img/integration/replug.svg'
import PostnitroLogo from '@assets/img/postnitro.svg'

 defineProps({
  mode: {
    type: String,
    default: 'setup',
  }
})

const { setupInfo } = useSetup()


const exampleSites = [
  {
    url: 'https://www.usermaven.com',
    name: 'UserMaven',
    icon: UserMavenLogo,
    bg: 'hover:bg-[#F5F6FF]',
  },
  {
    url: 'https://www.contentpen.ai',
    name: 'ContentPen',
    icon: ContentPenLogo,
    bg: 'hover:bg-[#FFF9EE]',
  },
  {
    url: 'https://www.replug.io',
    name: 'Replug',
    icon: ReplugLogo,
    bg: 'hover:bg-[#E5F7FF]',
  },
  {
    url: 'https://www.postnitro.com',
    name: 'Postnitro',
    icon: PostnitroLogo,
    bg: 'hover:bg-[#EEF9F3]',
  },
]
</script>
