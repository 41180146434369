<template>
  <template v-if="isRegenerating">
    <AIPostCardSkeleton />
  </template>
  <article
    v-else
    class="flex flex-col items-center px-3 2xl:px-4 py-5 bg-white rounded-[8px] border border-gray-200 cst-editor cursor-pointer relative group"
    :data-item-selected="isSelected"
    @click="handleClick"
  >
    <!-- Selection overlay with checkbox -->
    <div
      class="absolute top-7 left-6 z-10 flex items-center justify-center checkbox-container"
    >
      <div v-tooltip="isSelected ? 'Unselect' : 'Select'" class="asset-action" @click.stop="toggleSelection">
        <img src="@src/assets/img/planner/checkbox-unselected.svg" class="w-6 h-6 unchecked" alt="unchecked" />
        <img src="@src/assets/img/planner/checkbox-hovered.svg" class="w-6 h-6 hovered" alt="hovered" />
        <img src="@src/assets/img/planner/checkbox-selected.svg" class="w-6 h-6 checked" alt="checked" />
      </div>
    </div>

    <!-- Preview icon -->
    <div
      v-tooltip="'View Preview'"
      class="absolute top-7 right-6 z-10 icon-preview cursor-pointer"
      @click.stop="$emit('preview', post._id)"
    >
      <img
        src="@src/modules/publisher/assets/preview-post.svg"
        class="w-5 h-5 p-1 bg-[#efefef] rounded-md hover:bg-white transition-colors duration-300"
        alt="preview post"
      />
    </div>

    <div class="flex flex-col w-full text-sm">
      <div class="relative w-full aspect-square rounded-lg overflow-hidden bg-gray-100">
        <!-- Loading skeleton -->
        <div v-if="imageLoading" class="absolute inset-0 w-full h-full skeleton-shimmer"></div>

        <!-- Actual image -->
        <img
          v-if="post.image"
          :src="post.image"
          class="object-contain w-full h-full rounded-lg"
          :alt="post.image_metadata?.alt || 'Post image'"
          @load="imageLoading = false"
          @error="imageError = true; imageLoading = false"
        />

        <!-- Placeholder for no image or error -->
        <img
          v-if="!post.image || imageError"
          src="@src/modules/publisher/assets/aipost-placeholder.svg"
          class="object-contain w-full h-full"
          alt="Post image"
          @load="imageLoading = false"
        />
      </div>
      <div class="flex flex-col mt-3.5 w-full">
        <p
          class="flex-1 shrink gap-2.5 self-stretch w-full leading-5 text-neutral-600 line-clamp-3 ellipsis"
        >
          {{ post.caption }}
        </p>
        <button
          class="mt-1.5 w-full text-[#2294FF] text-left"
        >
          {{ showMore ? 'Show less' : 'Show more' }}
        </button>
      </div>
    </div>
    <div class="flex items-center justify-between w-full mx-2 mt-6">
      <!-- Regenerate -->
      <div class="flex flex-col items-center gap-y-1">
        <button
          v-tooltip="'Regenerate this post using the saved settings. This will consume credits.'"
          class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#56c288] action-button border border-gray-200 hover:border-none"
          :disabled="isRegenerating"
          @click.stop="regeneratePost(post._id)"
        >
          <img
            class="w-4 h-4 default-icon"
            src="@src/assets/img/icons/regenerate.svg"
            alt="Regenerate"
          />
          <img
            class="w-4 h-4 hover-icon"
            src="@src/assets/img/icons/regenerate-white.svg"
            alt="Regenerate"
          />
        </button>
      </div>

      <!-- Compose -->
      <div class="flex flex-col items-center gap-y-1">
        <button
          v-tooltip="'Open this post in the Composer.'"
          class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#2a6ffb] action-button border border-gray-200 hover:border-none"
          :disabled="isLoading"
          @click.stop="createSocialPost(post._id)"
        >
          <img
            class="w-4 h-4 default-icon"
            src="@src/assets/img/icons/compose-gray.svg"
            alt="Compose"
          />
          <img
            class="w-4 h-4 hover-icon"
            src="@src/assets/img/icons/compose-white.svg"
            alt="Compose"
          />
        </button>
      </div>

      <!-- Draft -->
      <div class="flex flex-col items-center gap-y-1">
        <button
          v-tooltip="'Move this post to the drafts for later use.'"
          class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#ff922b] action-button border border-gray-200 hover:border-none"
          :disabled="isLoading"
          @click.stop="createSocialPost(post._id, true)"
        >
          <img
            class="w-4 h-4 default-icon"
            src="@src/assets/img/icons/file.svg"
            alt="Copy"
          />
          <img
            class="w-4 h-4 hover-icon"
            src="@src/assets/img/icons/file-white.svg"
            alt="Copy"
          />
        </button>
      </div>

      <!-- Delete -->
      <div class="flex flex-col items-center gap-y-1">
        <button
          v-tooltip="'Delete'"
          class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#f5144c] action-button border border-gray-200 hover:border-none"
          :disabled="isLoading"
          @click.stop="handleDeletePost(post._id)"
        >
          <img
            class="w-4 h-4 default-icon"
            src="@src/assets/img/icons/delete.svg"
            alt="Delete"
          />
          <img
            class="w-4 h-4 hover-icon"
            src="@src/assets/img/icons/delete-white.svg"
            alt="Delete"
          />
        </button>
      </div>
      <!-- More  -->
      <div class="flex flex-col items-center gap-y-1">
        <button
          v-tooltip="'More'"
          class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 group border border-gray-200"
          @click.stop="$emit('preview', post._id)"
        >
          <i class="fas fa-ellipsis-v text-gray-800" />
        </button>
      </div>
    </div>
  </article>

  <CstConfirmationPopup
    :modal-name="`delete-post-${post._id}`"
    primary-button-text="Delete"
    @confirmed="confirmDeletePost"
  >
    <template v-slot:head>
      <h4 class="text-xl !flex items-center gap-x-2"><i class="far fa-trash"></i> Delete Post</h4>
    </template>
    <template v-slot:body>
      <p class="pt-4">Are you sure you want to delete this post? This action cannot be undone.</p>
    </template>
    <template v-slot:footer="{ onCancel, onConfirm }">
      <Button
      variant="outline"
      size="sm"
      @click="onCancel"
    >
      Cancel
    </Button>
    <Button
      color="danger"
      size="sm"
      @click="onConfirm"
    >
      Delete
    </Button>
    </template>
  </CstConfirmationPopup>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { Button } from '@contentstudio/ui'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'
import AIPostCardSkeleton from './AIPostCardSkeleton.vue'

const root = inject('root')
const { $bvModal } = root

const { AIUserProfile } = useSetup()
const { regeneratePost, regeneratingPostIds, deletePost, createSocialPost, isLoading } =
  useAIPostGeneration(AIUserProfile)

const selectedPostId = ref(null)

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  selectedPosts: {
    type: Array,
    default: () => []
  },
  selectionMode: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'preview',
  'select',
  'deselect'
])

const showMore = ref(false)
const imageLoading = ref(true)
const imageError = ref(false)

// Check if this post is selected
const isSelected = computed(() => {
  return props.selectedPosts.includes(props.post._id)
})

// Check if this post is currently regenerating
const isRegenerating = computed(() => {
  return regeneratingPostIds.value.has(props.post._id)
})

// Toggle selection of this post
const toggleSelection = () => {
  if (isSelected.value) {
    emit('deselect', props.post._id)
  } else {
    emit('select', props.post._id)
  }
}

// Handle click on the card
const handleClick = () => {
  if (props.selectionMode) {
    toggleSelection()
  } else {
    emit('preview', props.post._id)
  }
}

const handleDeletePost = (postId) => {
  selectedPostId.value = postId
  $bvModal.show(`delete-post-${postId}-confirmation-modal`)
}

const confirmDeletePost = () => {
  deletePost(selectedPostId.value)
  $bvModal.hide(`delete-post-${selectedPostId.value}-confirmation-modal`)
  selectedPostId.value = null
}
</script>

<style lang="scss" scoped>
/* Hide checkbox and preview by default */
.checkbox-container,
.icon-preview {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

/* Show checkbox and preview on hover */
article:hover {
  .checkbox-container,
  .icon-preview {
    opacity: 1;
    visibility: visible;
  }
}

/* Styles for the checkbox */
.asset-action {
  .unchecked {
    display: block;
  }
  .hovered {
    display: none;
  }
  .checked {
    display: none;
  }
}

/* Styles for hovered checkbox */
.asset-action:hover {
  .unchecked {
    display: none;
  }
  .hovered {
    display: block;
  }
  .checked {
    display: none;
  }
}

/* Styles for selected post */
article[data-item-selected="true"] {
  .checkbox-container {
    opacity: 1;
    visibility: visible;
  }

  .asset-action {
    .unchecked, .hovered {
      display: none;
    }
    .checked {
      display: block;
    }
  }
}

/* Action button styles */
.action-button {
  .default-icon {
    display: block;
  }
  .hover-icon {
    display: none;
  }

  &:hover {
    .default-icon {
      display: none;
    }
    .hover-icon {
      display: block;
    }
  }
}

/* Skeleton shimmer animation */
.skeleton-shimmer {
  position: relative;
  overflow: hidden;
}

.skeleton-shimmer::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 1.5s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
</style>
