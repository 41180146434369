<script setup>
import { computed, watch } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'

import usePinterestAnalytics from '@/src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'

const props = defineProps({
  defaultType: {
    type: String,
    default: 'line',
    validator: (v) => {
      return ['line', 'bar'].includes(v)
    },
  },
  label: {
    type: String,
    default: 'Impressions',
  },
})

const {
  barChartOptions,
  impressionsData,
  isReportView,
  dataZoomOptions,
  isNoAnalyticsData,
} = usePinterestAnalytics(props.label)

const isNoData = computed(() => {
  return isNoAnalyticsData(impressionsData)
})

watch(
  () => impressionsData,
  async () => {
    const options = impressionsData.value
    // bar chart options
    barChartOptions.value.series[0].itemStyle = {}
    barChartOptions.value.series[0].data = options?.impressions_daily
    barChartOptions.value.xAxis.data = options?.buckets
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper
    type="Impressions-Graph"
    :enable-modal="true"
    :show-insights-button="!isReportView"
    :enable-insights="true"
    :platform="'pinterest'"
  >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Daily Impressions over time
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Analyze day-to-day impressions received during the selected
                  time period, regardless of the post publication date.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <MainGraph
          v-if="!isNoData"
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...barChartOptions,
                  ...dataZoomOptions,
                  grid: { ...barChartOptions.grid, bottom: 70 },
                }
              : barChartOptions
          "
          :is-modal="slotProps.isModal"
          :custom-modal-height="slotProps.isInsights ? '40vh' : '80vh'"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No Pins data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
