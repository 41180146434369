<template>
  <AnalyticsCardWrapper :custom-class="'overflow-x-auto'">
    <template v-slot:card-header>
      <div v-if="!isReportView" class="flex items-center gap-2">
        <h2 class="text-base font-weight-500 select-none">
          Platforms Engagement Breakdown
        </h2>
        <v-menu
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              Compares performance metrics (posts, engagement, reactions, comments, shares, reach, impressions) across
              different social media platforms to help optimize your content strategy.
            </p>
          </template>
        </v-menu>
      </div>
    </template>
    <template v-slot:card-body>
      <div
        v-if="!isLoading && platformDataList.length > 0"
        class="bg-white w-full shadow-sm select-none overflow-x-auto"
      >
        <table class="w-full rounded-lg">
          <thead class="gap-0.5 flex xl:w-full rounded-md sticky z-[2] top-0">
            <th
              v-for="(header, index) in headers"
              :key="`table_header_${index}`"
              scope="col"
              class="flex justify-between items-center px-4 py-3 w-full text-secondary-cs bg-gray-200"
            >
              <span class="text-gray-900 font-medium capitalize" :class="{ 'text-xs': isReportView, 'text-sm': !isReportView }">{{ formatHeader(header) }}</span>
            </th>
          </thead>
          <tbody>
            <tr v-if="isLoading">
              <td colspan="8">
                <div class="p-4">
                  <SkeletonBox width="100%" height="320px" />
                </div>
              </td>
            </tr>
            <tr
              v-for="(row, rowIndex) in platformDataList"
              v-else
              :key="`table_row_${rowIndex}`"
              class="flex border-b border-t-0 border-l-0 border-r-0 border-gray-300 border-solid bg-white min-h-[3rem] hover:bg-gray-100"
              :class="{
                '!bg-[#f5f5f5]': row.platform === 'total',
              }"
            >
              <td
                v-for="(header, colIndex) in headers"
                :key="`table_cell_${rowIndex}_${colIndex}`"
                class="flex flex-1 items-center bg-white px-2 py-3"
                :class="{
                  '!bg-[#f5f5f5]': row.platform === 'total',
                }"
              >
                <template v-if="colIndex === 0">
                  <div class="flex items-center">
                    <img
                      v-if="row.platform !== 'total'"
                      :src="getSocialImageRounded(row.platform)"
                      class="mr-2 rounded-full"
                      :class="{ 'w-5 h-5': isReportView, 'w-8 h-8': !isReportView }"
                      alt="Platform Icon"
                    />
                    <img
                      v-else
                      src="@/src/assets/img/icons/analytic/colored/total-colored-icon.svg"
                      class="w-8 h-8 mr-2"
                      alt="Total Icon"
                    />
                    <span class="font-medium text-secondary-cs" :class="{ 'text-xs': isReportView, 'text-sm': !isReportView }">{{
                      getPlatformName(row.platform)
                    }}</span>
                  </div>
                </template>
                <span
                  v-else
                  v-tooltip="{
                    content: row[header] === 'N/A' ? 'Data not available' : tooltipHtml(row[header]),
                    allowHTML: true,
                    theme: 'light',
                  }"
                  class="font-semibold text-secondary-cs"
                  :class="{
                    'font-normal': colIndex > 1 && row.platform !== 'total',
                  }"
                >
                  {{ row[header] === 'N/A' ? row[header] : formatNumber(row[header]) }}
                  <template v-if="row.platform === 'pinterest'">
                    <span v-if="header === 'reactions'" class="text-xs text-secondary-cs ml-1">(Pin Clicks)</span>
                    <span v-else-if="header === 'comments'" class="text-xs text-secondary-cs ml-1">(Outbound Clicks)</span>
                    <span v-else-if="header === 'shares'" class="text-xs text-secondary-cs ml-1">(Saves)</span>
                  </template>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else-if="isLoading" class="analytics-no-data-found">
        <SkeletonBox width="100%" height="320px" />
      </div>
      <div v-else class="flex justify-center items-center py-8">
        <div class="text-center">
          <p class="mb-2 text-lg font-medium text-gray-500">No platform data available</p>
          <p class="text-gray-400">Select an account or date range to view platform data</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>

<script setup>
import { computed } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import useNumber from '@common/composables/useNumber'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
import useOverviewAnalytics from '@/src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox'

const props = defineProps({
  platformData: {
    type: Object,
    default: () => ({}),
  },
  isLoading: {
    type: Boolean,
    default: false,
  }
})

const { isReportView } = useOverviewAnalytics()
const { formatNumber: baseFormatNumber } = useNumber()
const { tooltipHtml, getSocialImageRounded } = useComposerHelper()

// Enhanced number formatting with more user-friendly display
const formatNumber = (value) => {
  if (value === 'N/A' || value === undefined || value === null) {
    return 'N/A'
  }

  // For large numbers, use compact notation
  if (value >= 1000) {
    return new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1
    }).format(value)
  }

  // For small numbers, use regular formatting
  return baseFormatNumber(value)
}

const headers = [
  'platform',
  'posts',
  'engagement',
  'reactions',
  'comments',
  'shares',
  'reach',
  'impressions'
]

const platformDataList = computed(() => {
  if (!props.platformData?.grouped || !Array.isArray(props.platformData.grouped)) {
    return []
  }

  // Map platform items
  const result = props.platformData.grouped.map(item => {
    return {
      platform: item.platform_type || item.platform || 'unknown',
      posts: item.total_posts || 0,
      engagement: item.engagement || 0,
      reactions: item.reactions || 0,
      comments: item.comments || 0,
      shares: item.shares || 0,
      reach: item.reach === undefined ? 'N/A' : item.reach,
      impressions: item.impressions === undefined ? 'N/A' : item.impressions
    }
  })

  // Check if we have a total item - it might come as a separate item with 'total' as platform type
  const hasTotalItem = result.some(item => item.platform === 'total')

  // If we don't have a total item and we have at least one platform item, create a total
  if (!hasTotalItem && result.length > 0) {
    const totalItem = {
      platform: 'total',
      posts: 0,
      engagement: 0,
      reactions: 0,
      comments: 0,
      shares: 0,
      reach: 0,
      impressions: 0
    }

    // Sum up values for all platforms
    result.forEach(item => {
      Object.keys(totalItem).forEach(key => {
        if (key !== 'platform') {
          if (item[key] !== 'N/A' && !isNaN(item[key])) {
            totalItem[key] += Number(item[key])
          }
        }
      })
    })

    // Set reach and impressions to N/A if none of the platforms have these values
    if (result.every(item => item.reach === 'N/A')) {
      totalItem.reach = 'N/A'
    }
    if (result.every(item => item.impressions === 'N/A')) {
      totalItem.impressions = 'N/A'
    }

    result.push(totalItem)
  }

  // Sort platforms in a specific order, with total always at the bottom
  const platformOrder = {
    'facebook': 1,
    'instagram': 2,
    'linkedin': 3,
    'twitter': 4,
    'x': 4, // Same order as Twitter
    'youtube': 5,
    'tiktok': 6,
    'pinterest': 7,
    'total': 999 // Always at the bottom
  }

  result.sort((a, b) => {
    const orderA = platformOrder[a.platform.toLowerCase()] || 100
    const orderB = platformOrder[b.platform.toLowerCase()] || 100
    return orderA - orderB
  })

  return result
})

const formatHeader = (header) => {
  return header.charAt(0).toUpperCase() + header.slice(1)
}



const getPlatformName = (platform) => {
  const platformType = platform.toLowerCase()

  switch (platformType) {
    case 'facebook':
      return 'Facebook'
    case 'instagram':
      return 'Instagram'
    case 'linkedin':
      return 'LinkedIn'
    case 'twitter':
      return 'Twitter (X)'
    case 'youtube':
      return 'YouTube'
    case 'tiktok':
      return 'TikTok'
    case 'pinterest':
      return 'Pinterest'
    case 'total':
      return 'Total'
    default:
      return platform
  }
}
</script>

<style scoped>
.text-secondary-cs {
  color: #4a4a4a;
}
</style>
