<template>
  <!-- Full-height container -->
  <div class="flex flex-col ">
    <!-- Header -->
    <div class="flex items-center gap-2 p-4">
      <img
        :src="require('@assets/img/integration/' + platform + '-rounded.svg')"
        alt="Instagram"
        class="w-6 h-6"
      />
      <span class="text-sm font-medium">{{ capitalize(platform) }}</span>
    </div>

    <!-- Main Content -->
    <div
      class="flex flex-col flex-1 overflow-y-auto no-scrollbar relative"
    >
      <!-- Text Caption -->
      <div v-if="reverseLayout" class="flex-none px-4 py-3">
        <HtmlRenderer tag="p" class="text-sm"  :html-content="parseDescriptionHtml(post?.caption, post?.caption?.length, false)" />
      </div>

      <!-- Image Content -->
      <div
        class="relative flex items-center justify-center"
        :class="{ 'flex-1': reverseLayout }"
      >
        <img
          v-if="hasImage"
          :src="currentImage"
          alt="post-preview"
          class="h-full w-full object-contain"
          :class="{ 'rounded-b-xl': reverseLayout }"
        />
        <!-- Image Navigation -->
        <div
          v-if="hasMultipleImages"
          class="absolute inset-x-0 top-1/2 -translate-y-1/2 flex justify-between px-4"
        >
          <button
            class="w-8 h-8 rounded-full bg-white shadow-md flex items-center justify-center hover:bg-gray-50"
            :class="{
              'opacity-50 cursor-not-allowed': currentImageIndex === 0,
            }"
            @click="prevImage"
          >
            <i class="fas fa-chevron-left text-gray-600"></i>
          </button>
          <button
            class="w-8 h-8 rounded-full bg-white shadow-md flex items-center justify-center hover:bg-gray-50"
            :class="{
              'opacity-50 cursor-not-allowed':
                currentImageIndex === images.length - 1,
            }"
            @click="nextImage"
          >
            <i class="fas fa-chevron-right text-gray-600"></i>
          </button>
        </div>

        <!-- Image Counter -->
        <div
          v-if="hasMultipleImages"
          class="absolute bottom-4 left-1/2 -translate-x-1/2 bg-black/50 text-white  py-1 rounded-full text-xs"
        >
          {{ currentImageIndex + 1 }}/{{ images.length }}
        </div>
      </div>

      <!-- Text Caption -->
      <div v-if="!reverseLayout" class="flex-none px-4 py-3">
        <HtmlRenderer tag="p" class="text-sm"  :html-content="parseDescriptionHtml(post?.caption, post?.caption?.length, false)" />
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { parseDescriptionHtml } from '@/src/modules/common/lib/helper'
import HtmlRenderer from '@/src/modules/common/components/htmlRenderer.vue'

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  platform: {
    type: String,
    required: true,
    default: 'instagram',
  },
  reverseLayout: {
    type: Boolean,
    default: false,
  },
})

// Handle multiple images
const currentImageIndex = ref(0)

const images = computed(() => {
  if (Array.isArray(props.post?.images)) {
    return props.post.images
  }
  return props.post?.image ? [props.post.image] : []
})

const hasMultipleImages = computed(() => images.value.length > 1)

const currentImage = computed(() => images.value[currentImageIndex.value])

const hasImage = computed(() => images.value.length > 0)
const nextImage = () => {
  if (currentImageIndex.value < images.value.length - 1) {
    currentImageIndex.value++
  }
}

const prevImage = () => {
  if (currentImageIndex.value > 0) {
    currentImageIndex.value--
  }
}

const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
</script>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
