<template>
  <div
    class="flex items-center justify-between gap-10 px-7 py-3 bg-white bottom-border-only"
  >
    <div class="flex gap-4 items-center self-start text-sm">

        <!-- Search Input -->
        <div
          class="transition-all duration-300 ease-in-out flex items-center bg-gray-100/70 rounded-md px-3 py-1.5 overflow-hidden"
          :class="{ 'w-52': isSearchActive, 'w-32': !isSearchActive }"
        >
          <img
            src="@src/assets/img/chat_bot/search-icon.svg"
            class="h-5 w-5 flex-shrink-0"
            alt="search"
          />
          <input
            v-model="searchQuery"
            type="text"
            class="bg-transparent transition-all duration-300 ease-in-out ml-1 text-sm border-0 p-1 rounded"
            :class="{ 'w-36': isSearchActive, 'w-24': !isSearchActive }"
            :placeholder="isSearchActive ? 'Search Anything..' : 'Search'"
            @focus="activateSearch"
            @blur="deactivateSearch"
          />
          <img
            v-show="isSearchActive"
            src="@src/assets/img/common/circle-xmark.svg"
            alt="xmark"
            class="h-5 w-5 cursor-pointer flex-shrink-0 ml-auto"
            @click.stop="clearSearch"
          />
        </div>

        <!-- Bulk Actions Dropdown -->
        <Dropdown
          v-tooltip="disableBulkAction ? 'Select at least 2 posts to perform bulk actions' : bulkActionLoader ? 'Please wait, bulk action is in progress...' : ''"
          placement="bottom"
          dropdown-classes="w-48"
          size="xs"
          radius="xl"
          :disabled="disableBulkAction || bulkActionLoader"
        >
        <template v-slot:selected>
          <span class="text-[#595c5f] inline-flex justify-center items-center">
            <i class="far fa-layer-group mr-2"></i>
            <span class="pt-0.5 flex items-center gap-1">Bulk Actions

              <div v-if="bulkActionLoader">
                <clip-loader
                  color="#2294FF"
                  size="16px"
                ></clip-loader>
              </div>
            </span>

          </span>
        </template>
        <div class="space-y-1">
          <DropdownItem
            class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"

            @click="bulkAction('delete')"
          >
            <i class="fas !text-red-500 fa-trash-alt"></i>
            <span>Delete Posts</span>
          </DropdownItem>

          <!-- <DropdownItem
            class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
            @click="bulkAction('regenerate')"
          >
            <i class="fa fa-refresh text-[#56c288]" aria-hidden="true"></i>
            <span>
              Regenerate Posts
            </span>
          </DropdownItem> -->

          <DropdownItem
            class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
            @click="bulkAction('draft')"
          >
            <i class="fa fa-file text-[#ff922b]" aria-hidden="true"></i>
            <span>Draft Posts</span>
          </DropdownItem>
        </div>
        </Dropdown>

      <!-- Num of selected posts -->
      <span v-if="selectionMode" class="items-center rounded-xl font-normal px-4 py-2 text-sm flex justify-between bg-opacity-80 bg-orange-300 text-gray-900 text-opacity-70">
        {{ selectedPosts.length }} of {{ AIPosts.length }} selected
        <span class="cursor-pointer text-blue-600 ml-2" @click="selectAll">
          {{ allSelected ? 'Unselect All' : 'Select All' }}
        </span>
      </span>
    </div>


    <div class="flex gap-4">
      <!-- Credits -->
      <div class="relative flex items-center">
        <button
          v-tooltip="{
            content: 'View AI Text and Image Credits',
            placement: 'bottom',
          }"
          :class="{ '!bg-[#E3F2FF]': isCreditVisible }"
          class="border-none bg-transparent p-1.5 rounded-xl flex items-center "
          role="button"
          aria-label="View credit usage"
          aria-expanded="isCreditVisible"
          tabindex="0"
          @keydown.enter="toggleCreditVisibility"
          @keydown.space.prevent="toggleCreditVisibility"
          @click="toggleCreditVisibility"
        >
          <img
            src="@src/assets/img/chat_bot/credit-icon.svg"
            class="transition-transform duration-200"
            alt="chat-credit"
          />
        </button>

        <!-- Credit Banner -->
        <div
          v-if="isCreditVisible"
          v-click-away="toggleCreditVisibility"
          class="absolute -right-10 top-full mt-1.5 w-[400px] bg-white    flex flex-col px-5 py-4 rounded-xl shadow-lg z-[1500] border border-[#FFE5B2] gap-4 backdrop-blur-sm animate-in slide-in-from-top-2 fade-in duration-200"
          @keydown.esc="toggleCreditVisibility"
        >
          <!-- Text Credits -->
          <div class="flex flex-col w-full gap-2">
            <div class="flex items-center justify-between">
              <span class="whitespace-nowrap font-semibold text-sm text-[#3A4557] flex items-center gap-2">
                Text Generation
                <i
                  v-tooltip="{
                    content: creditsLimitsTooltip('text', getCreditUsedLimit, getCreditSubscribeLimit),
                    placement: 'top-start',
                    allowHTML: true,
                  }"
                  class="far fa-question-circle text-sm cursor-pointer text-[#2294FF] hover:text-[#1a75cc] active:text-[#1a75cc]/80 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#2294FF]/20 rounded-full"
                  role="button"
                  aria-label="Text generation credit information"
                  tabindex="0"
                  @keydown.enter.stop.prevent
                ></i>
              </span>
              <span class="w-max whitespace-nowrap font-medium text-sm text-[#3A4557]">
                {{ formatNumber(getCreditUsedLimit) }} of {{ formatNumber(getCreditSubscribeLimit) }}
              </span>
            </div>
            <progress
              class="credit-bar w-full"
              :class="{
                'credit-bar-danger': getCreditUsedLimit >= getCreditSubscribeLimit || getCreditUsedLimit > getCreditSubscribeLimit - 1000,
              }"
              :value="getCreditUsedLimit"
              :max="getCreditSubscribeLimit"
              role="progressbar"
              aria-label="Text generation credits used"
            ></progress>
          </div>

          <!-- Image Credits -->
          <div class="flex flex-col w-full gap-2">
            <div class="flex items-center justify-between">
              <span class="whitespace-nowrap font-semibold text-sm text-[#3A4557] flex items-center gap-2">
                Image Generation
                <i
                  v-tooltip="{
                    content: creditsLimitsTooltip('image', getImageCreditUsedLimit, getImageCreditLimit),
                    placement: 'top-start',
                    allowHTML: true,
                  }"
                  class="far fa-question-circle text-sm cursor-pointer text-[#2294FF] hover:text-[#1a75cc] active:text-[#1a75cc]/80 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#2294FF]/20 rounded-full"
                  role="button"
                  aria-label="Image generation credit information"
                  tabindex="0"
                  @keydown.enter.stop.prevent
                ></i>
              </span>
              <span class="w-max whitespace-nowrap font-medium text-sm text-[#3A4557]">
                {{ formatNumber(getImageCreditUsedLimit) }} of {{ formatNumber(getImageCreditLimit) }}
              </span>
            </div>
            <progress
              class="credit-bar w-full"
              :class="{
                'credit-bar-danger': getImageCreditUsedLimit >= getImageCreditLimit || getImageCreditUsedLimit > getImageCreditLimit - 10,
              }"
              :value="getImageCreditUsedLimit"
              :max="getImageCreditLimit"
              role="progressbar"
              aria-label="Image generation credits used"
            ></progress>
          </div>
        </div>
      </div>


      <div class="flex flex-auto gap-4 items-center">
        <!-- Generate Post Button -->
        <Button
          v-tooltip="'Generate new posts using your post settings'"
          :loading="postGenerationLoader"
          :disabled="postGenerationLoader"
          size="sm"
          @click="generatePosts"
        >
          <img
            src="@src/modules/publisher/assets/ai-icon.svg"
            class="h-4 w-4"
            alt=""
          />
          Generate Post
        </Button>
      <!-- Edit Post settings -->
        <Button
          v-tooltip="'Edit post settings'"
          class="!border-gray-600 hover:!border-[#2294FF] group"
          variant="bordered"
          color="secondary"
          size="sm"
          @click="openSettingsModal"
        >
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.5 8.005C12.8807 8.005 14 6.88569 14 5.505C14 4.12429 12.8807 3.005 11.5 3.005C10.3351 3.005 9.35631 3.80174 9.07875 4.88H2.125C1.77983 4.88 1.5 5.15983 1.5 5.505C1.5 5.85019 1.77983 6.13 2.125 6.13H9.07875C9.35631 7.20825 10.3351 8.005 11.5 8.005ZM10.25 5.505C10.25 6.19536 10.8096 6.755 11.5 6.755C12.1904 6.755 12.75 6.19536 12.75 5.505C12.75 4.81465 12.1904 4.255 11.5 4.255C10.8096 4.255 10.25 4.81465 10.25 5.505ZM1.5 10.505C1.5 9.12432 2.61929 8.005 4 8.005C5.1649 8.005 6.14371 8.80175 6.42124 9.88H13.375C13.7202 9.88 14 10.1598 14 10.505C14 10.8502 13.7202 11.13 13.375 11.13H6.42124C6.14371 12.2083 5.1649 13.005 4 13.005C2.61929 13.005 1.5 11.8857 1.5 10.505ZM5.25 10.505C5.25 9.81463 4.69036 9.255 4 9.255C3.30964 9.255 2.75 9.81463 2.75 10.505C2.75 11.1954 3.30964 11.755 4 11.755C4.69036 11.755 5.25 11.1954 5.25 10.505Z"
          fill="#4A4A4A"
          class="group-hover:fill-[#2294FF] transition-colors duration-200"
        />
      </svg>
        </Button>

        <!-- View Switcher -->
        <div class="cst-switch">
          <div
            class="inline-flex justify-between items-center cursor-pointer p-1 rounded-xl bg-gray-200"
          >
          <a
              v-tooltip="'Grid View'"
              class="flex items-center px-2"
              @click="switchView('grid')"
            >
              <div
                :class="{
                  'bg-white text-primary-variant-1':
                    currentView === 'grid',
                }"
                class="p-2 flex justify-center items-center rounded-md space-x-2"
              >
                <img
                  v-if="currentView === 'grid'"
                  src="@assets/img/icons/planner/planner_header/planner-calendar-view-active.svg"
                  class="w-5 h-5"
                  alt="Active Grid View Icon"
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-calendar-view.svg"
                  class="w-5 h-5"
                  alt="Inactive Grid View Icon"
                />
              </div>
            </a>
            <a
              v-tooltip="'List View'"
              class="flex items-center px-2"
              @click="switchView('list')"
            >
              <div
                :class="{
                  'bg-white text-primary-variant-1':
                    currentView === 'list',
                }"
                class="p-2 flex justify-center items-center rounded-md space-x-2"
              >
                <img
                  v-if="currentView === 'list'"
                  src="@assets/img/icons/planner/planner_header/planner-list-view-active.svg"
                  class="w-5 h-5"
                  alt="Active List View Icon"
                />
                <img
                  v-else
                  src="@assets/img/icons/planner/planner_header/planner-list-view.svg"
                  class="w-5 h-5"
                  alt="Inactive List View Icon"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted, watch, computed } from 'vue'
import { Button, Dropdown, DropdownItem } from '@contentstudio/ui'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { useStore } from '@/src/store/base'

const emit = defineEmits(['view-change'])

const { AIUserProfile } = useSetup()

const { generatePosts, postGenerationLoader, searchQuery, debouncedSearch, getPosts, selectedPosts, selectionMode, AIPosts, selectAll, allSelected, bulkAction, bulkActionLoader, currentView } =
  useAIPostGeneration(AIUserProfile)

const root = inject('root')
const { $bvModal } = root
const store = useStore()

const isSearchActive = ref(false)
const isCreditVisible = ref(false)

// disable bulk action button if less than 2 posts are selected
const disableBulkAction = computed(() => selectedPosts.value.length < 2)

// Credit related computeds and functions
const getCreditSubscribeLimit = computed(() => {
  return store.getters.getPlan?.subscription?.limits?.caption_generation_credit || 0
})

const getCreditUsedLimit = computed(() => {
  return store.getters.getPlan?.used_limits?.caption_generation_credit || 0
})

const getImageCreditLimit = computed(() => {
  return store.getters.getPlan?.subscription?.limits?.image_generation_credit || 0
})

const getImageCreditUsedLimit = computed(() => {
  return store.getters.getPlan?.used_limits?.image_generation_credit || 0
})

watch(searchQuery, debouncedSearch)

// Watch credits and show banner if low
watch(
  () => getCreditUsedLimit.value,
  (val) => {
    const textCreditsLow = val > getCreditSubscribeLimit.value - 1000 || val >= getCreditSubscribeLimit.value
  const imageCreditsLow = getImageCreditUsedLimit.value > getImageCreditLimit.value - 10 || getImageCreditUsedLimit.value >= getImageCreditLimit.value

  if (textCreditsLow || imageCreditsLow) {
    isCreditVisible.value = true
  }
  }
)

onMounted(() => {
  // Initialize search if there's a query value
  if (searchQuery.value) {
    isSearchActive.value = true
  }
})

// Animation for search activation
const activateSearch = () => {
  isSearchActive.value = true
}

const deactivateSearch = () => {
  if (!searchQuery.value) {
    isSearchActive.value = false
  }
}

const clearSearch = () => {
  searchQuery.value = ''
  isSearchActive.value = false

  // Call getPosts with false to reset the search
  getPosts(1, false)
}

const switchView = (view) => {
  currentView.value = view
  emit('view-change', view)
}

const openSettingsModal = () => {
  $bvModal.show('post-settings-modal')
}

const formatNumber = (num) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' })
  return formatter.format(num)
}

const toggleCreditVisibility = () => {
  isCreditVisible.value = !isCreditVisible.value
}

const getSuperAdminDetails = computed(() => {
  const teamMembers = store.getters.getWorkspaces.activeWorkspace.members
  if (teamMembers)
    return teamMembers.find((member) => member.role === 'super_admin').user
  return { full_name: '' }
})

const creditsLimitsTooltip = (type, usedLimit, subscribeLimit) => {

  return `
              <div class="text-left">
      <span class="font-medium block">AI ${type} Credits</span>
      <span class="block my-2">${usedLimit} of ${subscribeLimit} used</span>
      <span class="block">
        This limit is subscribed by ${getSuperAdminDetails.value?.full_name} and being consumed by all team members.
        And the credits get reset on the start of the month.
      </span>
    </div>
                `;
};


</script>

<style lang="scss" scoped>
.credit-bar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 6px;
  width: 100%;
  cursor: default;
  user-select: none;
  &::-webkit-progress-bar {
    border-radius: 8px;
    background: rgba(240, 134, 34, 0.08);
  }
  &::-webkit-progress-value {
    background: #2294FF;
    border-radius: 8px;
    transition: width 0.3s ease;
  }
}
.credit-bar-danger::-webkit-progress-value {
  background: #EE446E;
  border-radius: 8px;
}
</style>
