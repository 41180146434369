// Routes for Publisher Module
import PublisherMain from '@modules/publisher/views/PublisherMain.vue'
import AIContentMain from '@modules/publisher/ai-content-library/views/AIContentMain.vue'
import AIPosts from '@modules/publisher/ai-content-library/views/AIPosts.vue'
import AIProfile from '@modules/publisher/ai-content-library/views/AIProfile.vue'
import MainPlanner from '@src/modules/planner_v2/views/MainPlanner'
import CalendarView from '@src/modules/planner_v2/views/CalenderView'
import ListView from '@/src/modules/planner_v2/views/ListView.vue'
import SocialModal from '@/src/modules/composer_v2/views/SocialModal.vue'
import GridView from '@/src/modules/planner_v2/views/GridView.vue'
import { commonMethods } from '@/src/modules/common/store/common-methods'
import FeedView from '@/src/modules/planner_v2/views/FeedView.vue'
import { useStore } from '@/src/store/base'
const RssAutomationListing = () =>
  import(
    /* webpackChunkName: "rss-automation" */ '@modules/automation/components/rss/listing/RSSAutomationListing'
  )
const SaveRssAutomation = () =>
  import(
    /* webpackChunkName: "rss-automation" */ '@modules/automation/components/rss/SaveRSSAutomation'
  )
const SaveEvergreenAutomation = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '@modules/automation/components/evergreen/create/EvergreenMain'
  )
const AccountsEvergreenAutomation = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '@modules/automation/components/evergreen/create/EvergreenAccountSelection'
  )
const OptimizationEvergreenAutomation = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '@modules/automation/components/evergreen/create/EvergreenOptimization'
  )
const FinalizationEvergreenAutomation = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '@modules/automation/components/evergreen/create/EvergreenFinalization'
  )
const EvergreenAutomationListing = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '@modules/automation/components/evergreen/listing/EvergreenAutomationListing'
  )

const ArticleSocialAutomationListing = () =>
  import(
    /* webpackChunkName: "article-to-social-automation" */ '@modules/automation/components/article/listing/ArticleToSocialAutomationListing'
  )
const SaveArticleSocialAutomation = () =>
  import(
    /* webpackChunkName: "article-to-social-automation" */ '@modules/automation/components/article/save/ArticeToAutomationSaveSocial'
  )
const ArticleBlogAutomationListing = () =>
  import(
    /* webpackChunkName: "article-to-blog-automation" */ '@modules/automation/components/article/listing/ArticleToBlogAutomationListing'
  )
const SaveArticleBlogAutomation = () =>
  import(
    /* webpackChunkName: "article-to-blog-automation" */ '@modules/automation/components/article/save/ArticleToAutomationSaveBlog'
  )

const VideoSocialAutomationListing = () =>
  import(
    /* webpackChunkName: "video-to-social-automation" */ '@modules/automation/components/video/listing/VideoSocialListing'
  )
const SaveVideoSocialAutomation = () =>
  import(
    /* webpackChunkName: "video-to-social-automation" */ '@modules/automation/components/video/save/VideoAutomationSaveSocial'
  )

const SaveBulkCsvAutomation = () =>
  import(
    /* webpackChunkName: "bulk-automation" */ '@modules/automation/components/csv/BulkUploadAutomationSave'
  )

const PublishingQueue = () =>
  import(
    /* webpackChunkName: "settings" */ '@src/modules/setting/components/content-categories/ContentCategories.vue'
  )

const store = useStore()

// Get the default planner route based on user profile settings
const getDefaultPlannerRoute = () => {
  const profile = store.getters.getProfile

  switch (profile?.planner_default_view) {
    case 'calendar':
      return { name: 'planner_calendar_v2' }
    case 'feed':
      return { name: 'planner_feed_v2' }
    case 'compact_list':
      return { name: 'planner_list_compact_v2' }
    case 'grid_instagram':
      return { name: 'planner_instagram_grid_view' }
    case 'grid_tiktok':
      return { name: 'planner_tiktok_grid_view' }
    default:
      return { name: 'planner_list_v2' }
  }
}

export const publisherRoutes = {
  path: '/:workspace/publisher',
  name: 'publisher',
  component: PublisherMain,
  meta: {
    title: 'Publisher | ContentStudio',
  },
  redirect: () => {
    return { name: 'planner' }
  },
  children: [
    // Ai Content Library Route
    {
      path: 'ai-content-library',
      name: 'ai-content-library',
      component: AIContentMain,
      meta: {
        title: 'AI Content Library | ContentStudio',
      },
      redirect: { name: 'ai-content-library-posts' },
      children: [
        {
          path: 'posts',
          name: 'ai-content-library-posts',
          component: AIPosts,
          meta: {
            title: 'Posts | ContentStudio',
          },
        },
        {
          path: 'profile',
          name: 'ai-content-library-profile',
          component: AIProfile,
          meta: {
            title: 'Profile | ContentStudio',
          },
        },
      ],
    },

    // Planner
    {
      path: 'planner',
      name: 'planner',
      component: MainPlanner,
      meta: {
        title: 'Planner | ContentStudio',
      },
      redirect: () => getDefaultPlannerRoute(),
      children: [
        {
          path: 'list-view',
          name: 'planner_list_v2', // be careful when you change route name. This might break some code/feature
          component: ListView,
          meta: {
            title: 'Planner | List',
          },
          alias: ['/:workspace/composer'],
          children: [
            {
              path: 'composer:id?',
              name: 'social-modal', // be careful when you change route name. This might break some code/feature
              component: SocialModal,
              meta: {
                title: 'Social | Composer',
              },
            },
          ],
        },
        {
          path: 'compact-view',
          name: 'planner_list_compact_v2', // be careful when you change route name. This might break some code/feature
          component: ListView,
          meta: {
            title: 'Planner | Compact List',
          },
        },
        {
          path: 'instagram-grid-view',
          //! NAME IS IMPORTANT
          name: 'planner_instagram_grid_view', // be careful when you change route name. This might break some code/feature
          component: GridView,
          meta: {
            title: 'Planner | Instagram Grid View',
            isGridView: true,
          },
          beforeEnter(to, from, next) {
            // hiding chatWidget on grid view
            commonMethods?.toggleAiChat(true)
            next()
          },
        },
        {
          path: 'tiktok-grid-view',
          //! NAME IS IMPORTANT
          name: 'planner_tiktok_grid_view', // be careful when you change route name. This might break some code/feature
          component: GridView,
          meta: {
            title: 'Planner | Tiktok Grid View',
            isGridView: true,
          },
          beforeEnter(to, from, next) {
            // hiding chatWidget on grid view
            commonMethods?.toggleAiChat(true)
            next()
          },
        },
        {
          path: 'feed-view',
          name: 'planner_feed_v2', // be careful when you change route name. This might break some code/feature
          component: FeedView,
          meta: {
            title: 'Planner | Feed',
          },
        },
        {
          path: 'calendar-view',
          name: 'planner_calendar_v2', // be careful when you change route name. This might break some code/feature
          component: CalendarView,
          meta: {
            title: 'Planner | Calendar',
          },
        },
      ],
    },
    // RSS Automation Routes
    {
      path: 'automation/rss',
      name: 'rss-automation-listing',
      component: RssAutomationListing,
      meta: {
        title: 'RSS Feed Automation | Automation',
      },
    },
    {
      path: 'automation/rss/save',
      name: 'save-rss-automation',
      component: SaveRssAutomation,
      meta: {
        title: 'Save RSS Feed Automation | Automation',
      },
    },

    // Ever Green Routes
    {
      path: 'automation/evergreen',
      name: 'evergreen-automation-listing',
      component: EvergreenAutomationListing,
      meta: {
        title: 'Evergreen Automation | Automation',
      },
    },
    {
      path: 'automation/evergreen/save',
      name: 'save-evergreen-automation',
      // redirect: '/:workspace/automation/evergreen/save/accounts',
      redirect: { name: 'save-evergreen-automation-accounts' },
      component: SaveEvergreenAutomation,
      meta: {
        title: 'Save Evergreen Automation | Automation',
      },
      children: [
        {
          path: 'accounts/:evergreenId?',
          name: 'save-evergreen-automation-accounts',
          component: AccountsEvergreenAutomation,
          meta: {
            title: 'Evergreen Automation Accounts | Evergreen',
          },
        },
        {
          path: 'schedule/:evergreenId?',
          name: 'save-evergreen-automation-optimization',
          component: OptimizationEvergreenAutomation,
          meta: {
            title: 'Evergreen Automation Schedule | Evergreen',
          },
        },
        {
          path: 'content/:evergreenId?',
          name: 'save-evergreen-automation-finalize',
          component: FinalizationEvergreenAutomation,
          meta: {
            title: 'Evergreen Automation Content | Evergreen',
          },
        },
      ],
    },

    // Article to Social Routes
    {
      path: 'automation/social/articles',
      name: 'article-social-automation-listing',
      component: ArticleSocialAutomationListing,
      meta: {
        title: 'Articles to Social Media Automation | Automation',
      },
    },
    {
      path: 'automation/social/articles/create',
      name: 'save-article-social-automation',
      component: SaveArticleSocialAutomation,
      meta: {
        title: 'Save Articles to Social Media Automation | Automation',
      },
    },

    // Article to Blog Routes
    {
      path: 'automation/blog/articles',
      name: 'article-blog-automation-listing',
      component: ArticleBlogAutomationListing,
      meta: {
        title: 'Articles to Blog Automation',
      },
    },
    {
      path: 'automation/blog/articles/create',
      name: 'save-article-blog-automation',
      component: SaveArticleBlogAutomation,
      meta: {
        title: 'Save Articles to Blog Automation | Automation',
      },
    },

    // Video to Social Routes
    {
      path: 'automation/social/videos',
      name: 'video-social-automation-listing',
      component: VideoSocialAutomationListing,
      meta: {
        title: 'Videos to Social Media Automation | Automation',
      },
    },
    {
      path: 'automation/social/videos/create',
      name: 'save-video-social-automation',
      component: SaveVideoSocialAutomation,
      meta: {
        title: 'Save Videos to Social Media Automation | Automation',
      },
    },

    // Bulk CSV Routes
    {
      path: 'automation/csv/create/:csvId?',
      name: 'save-bulk-csv-automation',
      component: SaveBulkCsvAutomation,
      meta: {
        title: 'Save CSV Automation | Automation',
      },
    },

    // Content Categories Routes
    {
      path: 'content_categories-v2',
      name: 'content_categories-publisher',
      component: PublishingQueue,
      meta: {
        title: 'Content Categories | Publisher',
      },
    },
  ],
}
