<template>
  <div class="transition-all duration-300 relative flex overflow-hidden" :style="mainDivStyle">
    <SidebarMain />
    <router-view></router-view>
  </div>
</template>

<script setup>
import SidebarMain from '@src/modules/publisher/components/SidebarMain.vue'
import { computed } from 'vue'
import  usePlatform  from '@common/composables/usePlatform'
const { topBannersHeight } = usePlatform()

const mainDivStyle = computed(() => {
  return {
    height: `calc(100vh - ${topBannersHeight.value}px)`,
  }
})

</script>
