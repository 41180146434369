import http from '@common/lib/http-common'
import pick from 'lodash.pick'
import {
  changeRssAutomationStatusUrl,
  fetchRssAutomationsUrl,
  pullHistoryUrl,
  removeRssAutomationUrl,
  rssAutomationBulkOperationUrl,
  saveRssAutomationUrl,
} from '@src/modules/automation/config/api-utils'
import {
  AUTOMATION_CHANGE_STATUS_ERROR,
  AUTOMATION_REMOVAL_SUCCESS,
  UNKNOWN_ERROR,
  SAVE_AUTOMATION_ERROR,
} from '@common/constants/messages'

import Router from '@src/router'
import { planner } from '@src/modules/planner/store/mutation-types'
import { rssTypes } from './mutation-types'

const getDefaultRSSAutomation = () => {
  return {
    id: '',
    name: '',
    status: 0,
    feed_url: '',
    posting_type: 'Immediately',
    queue_slot_option: 'bottom',
    max_post: 1,
    review_content: false,
    post_variations: 'post_title',
    content_category_id: '',
    pull_frequency: '30m',
  }
}

const getDefaultRSSAutomationLoaders = () => {
  return {
    retrieve: false,
    store: false,
    pull_history_retrieve: false,
  }
}

const getDefaultRssAutomationTabStatus = () => {
  return {
    first: true,
    second: false,
    third: false,
  }
}

const getDefaultRssAutomationListing = () => {
  return {
    items: [],
    search: '',
    page: 1,
    limit: 20,
    nextPage: false,
    previousPage: false,
    fetchStatus: false,
    selected_all: false,
    selected: [],
    pull_history_items: [],
  }
}

export default {
  state: {
    selection: getDefaultRSSAutomation(),
    tabStatus: getDefaultRssAutomationTabStatus(),
    listing: getDefaultRssAutomationListing(),
    loaders: getDefaultRSSAutomationLoaders(),
  },
  getters: {
    getRssAutomationDetails: (state) => state.selection,
    getRssAutomationTabStatus: (state) => state.tabStatus,
    getRssAutomationListing: (state) => state.listing,
    getRSSAutomationLoaders: (state) => state.loaders,
    getRSSAutomationPullHistory: (state) => state.listing.pull_history_items,
  },
  actions: {
    async storeRSSAutomation({ commit, getters, dispatch }, activate = false) {
      const hashtag = getters.getNewWorkspaceHashtag
      const replug = getters.getPublishSelection.replug

      const payload = getters.getRssAutomationDetails
      delete payload.status
      if (activate) payload.activate = true
      payload.account_selection = getters.getAccountSelection

      // replug

      payload.replug = { status: replug.status }

      if (replug.status) {
        payload.replug.brand = replug.brand._id
        payload.replug.campaign = replug.campaign._id
        payload.replug.custom_domain = !!replug.campaign.domainId
      }
      // hashtags
      payload.hashtag = { status: getters.getPublishSelection.hashtag.status }
      if (payload.hashtag.status) {
        payload.hashtag = {
          ...payload.hashtag,
          id: getters.getPublishSelection.hashtag.selected,
          ...pick(hashtag, ['name', 'items', 'count', 'position']),
        }
        console.log(payload.hashtag)
      }
      payload.workspace_id = getters.getActiveWorkspace._id
      commit(rssTypes.SET_RSS_AUTOMATION_STORE_LOADER, true)
      const res = await http
        .post(saveRssAutomationUrl, payload)
        .then((res) => {
          if (res.data.status) {
            // updat existing campaign
            const message = res.data.message
              ? res.data.message
              : 'Your campaign has been saved!'
            if (
              // eslint-disable-next-line no-prototype-builtins
              payload.hasOwnProperty('id') &&
              payload.id &&
              payload.id.length > 3
            ) {
              const findIndex = getters.getRssAutomationListing.items.findIndex(
                (item) => item._id === res.data.data.id
              )
              getters.getRssAutomationListing.items.splice(
                findIndex,
                1,
                res.data.data
              )
              commit(planner.UPDATE_TO_ALL_RSS_AUTOMATIONS, res.data.data)
            } else {
              // add a new campaign
              getters.getRssAutomationListing.items.unshift(res.data.data)
              commit(planner.ADD_TO_ALL_RSS_AUTOMATIONS, res.data.data)
            }
            dispatch('toastNotification', { message })
            // fire event and push to the listing page.
            dispatch('trackEvent', { event: 'rss_recipe_created' })
            Router.push({
              name: 'rss-automation-listing',
              params: { workspace: getters.getActiveWorkspace.slug },
            })
          } else {
            const message = res.data.message
              ? res.data.message
              : SAVE_AUTOMATION_ERROR
            dispatch('toastNotification', { message, type: 'error' })
          }
          return res
        })
        .catch(() => {
          return null
        })
      commit(rssTypes.SET_RSS_AUTOMATION_STORE_LOADER, false)
      return res
    },
    async fetchRSSAutomationsList({ commit, getters, dispatch }, page = 1) {
      // pick the selected fields

      const payload = pick(getters.getRssAutomationListing, ['search', 'limit'])
      payload.page = page

      // set the loader
      commit(rssTypes.SET_RSS_AUTOMATION_RETRIEVE_LOADER, true)

      // send the async request

      await http
        .post(fetchRssAutomationsUrl, {
          ...payload,
          workspace_id: getters.getWorkspaces.activeWorkspace._id,
          platformSelection: getters.getPublishSelection.platforms.selection,
        })
        .then((res) => {
          // set the automations list

          const items = res.data.automations.data
            ? res.data.automations.data
            : []
          const nextPage = !!res.data.automations.next_page_url
          const previousPage = !!res.data.automations.prev_page_url
          commit(rssTypes.SET_RSS_AUTOMATION_ITEMS, items)
          commit(rssTypes.SET_RSS_AUTOMATION_LISTING_PAGINATION, {
            nextPage,
            previousPage,
            page,
          })
          return res
        })
        .catch(() => {
          return null
        })

      // remove the loader
      commit(rssTypes.SET_RSS_AUTOMATION_RETRIEVE_LOADER, false)
    },
    deleteRSSAutomation({ commit, getters, dispatch }, payload) {
      http
        .post(removeRssAutomationUrl, {
          automation_id: payload._id,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            getters.getRssAutomationListing.items.splice(payload.index, 1)
            const message = res.data.message
              ? res.data.message
              : AUTOMATION_REMOVAL_SUCCESS
            dispatch('toastNotification', {
              message,
            })
          } else {
            const message = res.data.message ? res.data.message : UNKNOWN_ERROR
            dispatch('toastNotification', {
              message,
              type: 'error',
            })
          }
        })
        .catch(() => {})
    },
    changeRSSAutomationStatus(
      { commit, getters, dispatch },
      { automation, index }
    ) {
      const status = automation.status ? 1 : 0
      http
        .post(changeRssAutomationStatusUrl, {
          id: automation._id,
          workspace_id: getters.getActiveWorkspace._id,
          status,
        })
        .then((res) => {
          if (res.data.status) {
            automation.status = status
            automation.last_execution = res.data.execution_time
            this.getRssAutomationListing.splice(index, 1, automation)
          } else {
            const message = res.data.message
              ? res.data.message
              : AUTOMATION_CHANGE_STATUS_ERROR
            dispatch('toastNotification', { message, type: 'error' })
          }
        })
        .catch(() => {})
    },
    bulkOperationRSS({ commit, getters, dispatch }, state) {
      const rssListing = getters.getRssAutomationListing
      http
        .post(rssAutomationBulkOperationUrl, {
          operation: state,
          automation_ids: rssListing.selected,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            // dispatch('toastNotification', { message: 'Operation is performed successfully.' })
            console.log(
              '[bulk operation for rss] for state',
              state.toLowerCase(),
              rssListing.selected
            )
            switch (state.toLowerCase()) {
              case 'active':
              case 'inactive':
                // eslint-disable-next-line no-case-declarations
                const status = state.toLowerCase() === 'active' ? 1 : 0
                rssListing.selected.forEach((item) => {
                  rssListing.items.map((rssItem) => {
                    if (item === rssItem._id) {
                      console.log('[rssitem status change] for item ', item)
                      rssItem.status = status
                    }
                  })
                })
                break
              case 'delete':
                dispatch('fetchRSSAutomationsList')
                /* // NOTE: let say user remove multiple items, what do we need to do in this case, brainstorm it...
              rssListing.selected.forEach(item => {
                getters.getRssAutomationListing.items.splice(rssListing.items.findIndex(rssItem => item === rssItem._id), 1)
              }) */
                break
            }
            // set the values to the null
            commit(rssTypes.SET_SELECTED_RSS_AUTOMATION_ITEMS, [])
            commit(rssTypes.SET_RSS_AUTOMATION_LISTING_SELECTED_ALL, false)
          }
        })
        .catch()
    },
    pullRSSHistory({ commit, getters, dispatch }, { id, page = 1 }) {
      http
        .post(pullHistoryUrl, {
          automation_id: id,
          workspace_id: getters.getActiveWorkspace._id,
          page,
        })
        .then((res) => {
          if (res.data.status) {
            console.log(res.data.data)
            commit(rssTypes.SET_RSS_PULL_HISTORY_ITEMS, res.data.data)
            /* if (page > 1) {
            commit(rssTypes.ADD_RSS_PULL_HISTORY_ITEMS, res.data.data)
          } else {
            commit(rssTypes.SET_RSS_PULL_HISTORY_ITEMS, res.data.data)
          } */
          }
        })
        .catch(() => {
          return null
        })
    },
  },
  mutations: {
    [rssTypes.RESET_ALL_RSS_AUTOMATION_STATES](state) {
      state.selection = getDefaultRSSAutomation()
      state.tabStatus = getDefaultRssAutomationTabStatus()
      state.listing = getDefaultRssAutomationListing()
    },
    [rssTypes.SET_RSS_AUTOMATION_CONTENT_CATEGORY_VALUE](state, value) {
      state.selection.content_category_id = value
    },
    [rssTypes.SET_RSS_AUTOMATION](state, automation) {
      if (automation) {
        state.selection = Object.assign(getDefaultRSSAutomation(), automation)
      } else {
        state.selection = getDefaultRSSAutomation()
      }
      state.tabStatus = getDefaultRssAutomationTabStatus()
    },
    [rssTypes.SET_RSS_AUTOMATION_RETRIEVE_LOADER](state, value) {
      state.loaders.retrieve = value
    },
    [rssTypes.SET_RSS_AUTOMATION_LISTING_PAGINATION](
      state,
      { nextPage = false, previousPage = false, page = 1 }
    ) {
      state.listing.nextPage = nextPage
      state.listing.previousPage = previousPage
      state.listing.page = page
    },
    [rssTypes.SET_RSS_AUTOMATION_ITEMS](state, value) {
      state.listing.items = value
    },
    [rssTypes.SET_SELECTED_RSS_AUTOMATION_ITEMS](state, value) {
      state.listing.selected = value
    },
    [rssTypes.SET_RSS_AUTOMATION_ACTIVE_TAB_STATUS](state, value) {
      if (value) {
        Object.keys(state.tabStatus).forEach((item, index) => {
          console.log(item, value === item)
          state.tabStatus[item] = value === item
        })
      }
    },
    [rssTypes.RESET_RSS_AUTOMATION](state) {
      state.selection = getDefaultRSSAutomation()
      state.tabStatus = getDefaultRssAutomationTabStatus()
    },
    [rssTypes.SET_RSS_AUTOMATION_STORE_LOADER](state, value) {
      state.loaders.store = value
    },
    [rssTypes.SET_RSS_AUTOMATION_LISTING_SELECTED_ALL](state, value) {
      state.listing.select_all = value
    },
    [rssTypes.SET_RSS_PULL_HISTORY_ITEMS](state, value) {
      state.listing.pull_history_items = value
    },
    [rssTypes.ADD_RSS_PULL_HISTORY_ITEMS](state, value) {
      state.listing.pull_history_items.concat(value)
    },

    [rssTypes.SET_RSS_FEED_URL_AUTOMATION](state, value) {
      state.selection.feed_url = value
    },
  },
}
