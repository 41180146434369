<template>
  <!-- Connected Accounts -->
  <div v-if="hasSocialAccounts" class="space-y-4">
    <!-- Search Bar -->
    <div class="relative">
      <input
        v-model="searchQuery"
        type="text"
        class="w-full px-4 py-2 pl-10 border border-gray-200 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#2294FF] focus:border-transparent"
        placeholder="Search account by name"
      />
      <i class="fas fa-search absolute left-3 top-3"></i>
      <button
        v-if="searchQuery"
        class="absolute right-3 top-3"
        @click="clearSearch"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>

    <!-- Select All -->
    <div class="flex items-center justify-between py-2 px-2">
      <div class="text-sm font-medium">Connected Accounts
        <i
          v-tooltip="'Select connected social accounts to let AI analyze existing content and extract brand attributes.'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i>
      </div>
      <div class="flex items-center space-x-2">
        <Checkbox
          label="Select All"
          label-position="left"
          :model-value="isAllSelected"
          size="lg"
          @update:model-value="toggleSelectAll"
        />
      </div>
    </div>

    <!-- Accounts List -->
    <div class="max-h-[320px] overflow-y-auto">
      <DynamicScroller
        ref="scroller"
        :items="virtualAccounts"
        :min-item-size="60"
        class="scroller"
        :page-mode="true"
        key-field="id"
        :buffer="200"
      >
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="index"
            :size-dependencies="[item.name]"
          >
            <div
              class="account-item flex items-center justify-between p-3 my-1 bg-white hover:!bg-[#F0F7FF] hover:rounded-lg"
            >
              <span class="flex items-center gap-4">
                <span class="relative">
                  <img
                    :src="item?.image || item?.platform_logo"
                    alt=""
                    class="rounded-full object-cover border h-[30px] w-[30px]"
                    data-fallback="0"
                    loading="lazy"
                    @error="handleImageError"
                  />
                  <img
                    :src="getSocialImageCached(item.channel_type)"
                    alt="social icon"
                    class="absolute -right-2 -bottom-1 h-[20px]"
                    loading="lazy"
                  />
                </span>
                <span class="flex flex-col flex-1">
                  <span
                    class="text-sm font-normal text-black-100 select-none truncate max-w-[200px]"
                  >
                    {{ item?.platform_name || item?.name }}
                  </span>
                  <span class="text-xs select-none text-gray-800">
                    {{ item?.account_type || item?.type }}
                  </span>
                </span>
              </span>
              <Checkbox
                :model-value="selectedAccountsMap[item.id] || false"
                size="lg"
                @update:model-value="toggleAccount(item)"
              />
            </div>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
  </div>

  <!-- NO Accounts Connected -->
  <div v-else class="flex items-center justify-center flex-col space-y-4 mb-4">
    <div class="flex items-center justify-center flex-col space-y-4 mb-4">
      <img
        class="h-60"
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/overview.png"
        alt="No accounts"
        loading="lazy"
      />
      <div class="mt-4 text-gray-700 font-normal text-base">
        No social media accounts connected
      </div>
      <Button @click="handleConnectSocial"> Connect Social Account </Button>
    </div>
  </div>
</template>

<script setup>
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller'
import { Button, Checkbox } from '@contentstudio/ui'
import { useStore } from '@state/base'
import { computed, ref, onMounted, watch, shallowRef } from 'vue'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import router from '@/src/router'

const { getters } = useStore()
const { setupInfo, updateSocialAccounts, getSocialAccountsByPlatform } = useSetup()
const scroller = ref(null)
const searchQuery = ref('')
const selectedAccounts = ref(new Set())
const selectedAccountsMap = ref({}) // Map for O(1) lookup
const virtualAccounts = shallowRef([]) // Using shallowRef for better performance with large lists

// Cache for social images to avoid repeated require calls
const socialImageCache = {};

// Initialize selected accounts from setupInfo
const initializeSelectedAccounts = () => {
  const allSelectedIds = new Set()
  const currentAccounts = getSocialAccountsByPlatform()
  const accountMap = {}

  Object.values(currentAccounts).forEach((platformAccounts) => {
    platformAccounts.forEach((id) => {
      allSelectedIds.add(id)
      accountMap[id] = true
    })
  })

  selectedAccounts.value = allSelectedIds
  selectedAccountsMap.value = accountMap
}

// Computed properties with memoization
const hasSocialAccounts = computed(() => getters.getSocialAccountsList.length > 0)
const getSocialAccountsList = computed(() => getters.getSocialAccountsList)

// Optimized filtering with debounce built in via v-model
const filteredAccounts = computed(() => {
  const accounts = getSocialAccountsList.value || []
  if (!searchQuery.value) return accounts

  const query = searchQuery.value.toLowerCase().trim()
  return accounts.filter((account) => {
    const name = (account.platform_name || account.name || '').toLowerCase()
    return name.includes(query)
  })
})

// Optimized lookup using map instead of Set.has() in render loop
const getAccountId = (account) => account[account.channel_identifier]

// Computed property for "select all" checkbox
const isAllSelected = computed(() => {
  const filtered = filteredAccounts.value
  return filtered.length > 0 &&
         filtered.every(account => selectedAccountsMap.value[getAccountId(account)])
})

// Clear search with a dedicated method
const clearSearch = () => {
  searchQuery.value = ''
}

// Toggle all accounts selection
const toggleSelectAll = () => {
  const filtered = filteredAccounts.value
  const newMap = { ...selectedAccountsMap.value }

  if (isAllSelected.value) {
    // Deselect all visible accounts
    filtered.forEach(account => {
      const id = getAccountId(account)
      delete newMap[id]
      selectedAccounts.value.delete(id)
    })
  } else {
    // Select all visible accounts
    filtered.forEach(account => {
      const id = getAccountId(account)
      newMap[id] = true
      selectedAccounts.value.add(id)
    })
  }

  selectedAccountsMap.value = newMap
  updateSelectedAccountsByPlatform()
}

// Toggle individual account selection
const toggleAccount = (account) => {
  const accountId = getAccountId(account)
  const newMap = { ...selectedAccountsMap.value }

  if (newMap[accountId]) {
    delete newMap[accountId]
    selectedAccounts.value.delete(accountId)
  } else {
    newMap[accountId] = true
    selectedAccounts.value.add(accountId)
  }

  selectedAccountsMap.value = newMap
  updateSelectedAccountsByPlatform()
}

// Update platform-specific account selections
const updateSelectedAccountsByPlatform = () => {
  const selectedAccountsByPlatform = {
    facebook: [],
    twitter: [],
    instagram: [],
    linkedin: [],
    pinterest: [],
    youtube: [],
    tiktok: [],
    tumblr: [],
    gmb: [],
    bluesky: [],
  }

  // Filter only once and use for both operations
  const selectedAccountsList = getSocialAccountsList.value.filter(
    account => selectedAccountsMap.value[getAccountId(account)]
  )

  // Group accounts by platform
  selectedAccountsList.forEach(account => {
    const platform = (account.channel_type || '').toLowerCase()
    if (platform in selectedAccountsByPlatform) {
      selectedAccountsByPlatform[platform].push(getAccountId(account))
    }
  })

  // Update the setupInfo with grouped accounts
  updateSocialAccounts(selectedAccountsByPlatform)
}

// Cached social image getter
const getSocialImageCached = (channelType) => {
  if (!channelType) return ''

  const type = channelType.toLowerCase()
  if (!socialImageCache[type]) {
    if (type === 'twitter') {
      socialImageCache[type] = require('@assets/img/integration/twitter-x-rounded.svg')
    } else {
      socialImageCache[type] = require(`@assets/img/integration/${type}-rounded.svg`)
    }
  }

  return socialImageCache[type]
}

// Image error handler
const handleImageError = (event) => {
  event.target.src = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
}

// Update virtual accounts list efficiently
const updateVirtualAccounts = (accounts = []) => {
  virtualAccounts.value = accounts.map(account => ({
    ...account,
    id: getAccountId(account),
  }))
}

// Handle navigation to connect social accounts
const handleConnectSocial = () => {
  router.push({
    name: 'social',
    params: {
      workspace: getters.getWorkspaces.activeWorkspace.slug,
    },
  })
}

// Watch for changes in filtered accounts
watch(filteredAccounts, updateVirtualAccounts, { immediate: true })

// Initialize on component mount
onMounted(() => {
  updateVirtualAccounts(getSocialAccountsList.value)
  initializeSelectedAccounts()
})
</script>

<style scoped>
.account-item {
  border-bottom: 1px solid #dee2e6 !important;
}

:deep(.vue-recycle-scroller__item-wrapper) {
  overflow: visible !important;
}

:deep(.vue-recycle-scroller__item-view) {
  overflow: visible !important;
}

/* Add will-change to improve rendering performance */
:deep(.scroller) {
  will-change: transform;
}

/* Optimize transitions */
.account-item {
  transition: background-color 0.15s ease-in-out;
  will-change: background-color;
}
</style>
