<template>
  <div class="flex items-center justify-center flex-col w-full h-full">
    <router-view></router-view>
    <div v-if="getProfileLoader">
      <!-- Loader  -->
      <!-- <clip-loader :color="'#2294FF'" :size="'36px'" /> -->
    </div>
    <template v-else-if="!isSetupComplete || (isSetupComplete && isAIPostsEmpty && !getPostsLoader && !postGenerationLoader)">
      <div class="fixed inset-0 bg-[#25262740] z-40"></div>
      <div
        class="z-100 fixed top-[50%] left-[50%] right-auto"
        style="transform: translate(-35%, -45%)"
      >
        <SetupPopupWrapper v-if="!isSetupComplete" />
        <GeneratePostPopup v-else />
      </div>
    </template>
  </div>
  <PostSettingsModal />
</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue'
import SetupPopupWrapper from '@modules/publisher/ai-content-library/components/SetupPopupWrapper.vue'
import GeneratePostPopup from '@modules/publisher/ai-content-library/components/modals/GeneratePostPopup.vue'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import PostSettingsModal from '@modules/publisher/ai-content-library/components/modals/PostSettingsModal.vue'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'
import { EventBus } from '@/src/modules/common/lib/event-bus'
const {
  fetchBrandInfo,
  isSetupComplete,
  getProfileLoader,
  resetSetupInfo,
  resetBrandInfo,
  AIUserProfile,
  currentView
} = useSetup()
const { getPosts, getPostsLoader, isAIPostsEmpty, resetSelectedPosts,postGenerationLoader } = useAIPostGeneration(AIUserProfile)

onMounted(async () => {
  getPostsLoader.value = true
  await fetchBrandInfo()
  await getPosts()

  // Load posts when workspace changes
  EventBus.$on('workspace-changed', refetchAIContentLibraryData)
})

onBeforeUnmount(() => {
  EventBus.$off('workspace-changed')
  resetAIContentLibraryData()
})

const refetchAIContentLibraryData = async () => {
  resetAIContentLibraryData()
  getPostsLoader.value = true
  await fetchBrandInfo()
  await getPosts()

}

const resetAIContentLibraryData = () => {
  resetSetupInfo()
  resetBrandInfo()
  resetSelectedPosts()
  currentView.value = 'grid'
}
</script>
