<template>
  <AnalyticsCardWrapper
    :type="type"
    :enable-modal="!isModal"
    :enable-insights="showInsights"
    :show-insights-button="!isReportView"
    :platform="'overview'"
    >
    <template v-slot:card-header>
      <div class="flex items-center gap-2">
        <slot name="header-left"></slot>
        <h2 class="text-base font-weight-500 select-none">{{ title }}</h2>
        <v-menu
          v-if="!isReportView"
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              {{getComponentInfoTooltip('performanceComparison')}}
            </p>
          </template>
        </v-menu>
      </div>
      <div class="flex gap-2 items-center ml-auto">
        <slot name="header-right"></slot>
        <div
          v-if="isModal"
          v-tooltip="{
            content: 'Minimize',
            theme: 'light',
          }"
          class="
            cursor-pointer
            w-10
            h-10
            flex
            justify-center
            items-center
            bg-gray-50
            hover:bg-gray-100
            active:bg-gray-300
            rounded-full
          "
          @click="$bvModal.hide(`performance-${type}-modal`)"
        >
          <i class="fa fa-times fa-lg" aria-hidden="true"></i>
        </div>
      </div>
    </template>
    <template v-slot:card-body>
      <div v-if="isLoading">
        <SkeletonBox
          class="!w-full"
          :style="{ height: chartHeight }"
        ></SkeletonBox>
      </div>
      <div
        v-else-if="isAllDataFetched"
        class="analytics-no-data-found h-5/6 relative right-0 left-0 z-10 bg-white"
      >
        <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
        <p>Data is being fetched.</p>
      </div>

      <div v-else class="flex">
      <!-- Custom y axis label -->
      <div
        class="min-w-0 flex justify-center items-center relative"
        :style="{ height: isModal ? '85vh' : chartHeight, flex: '0 0 50px' }"
      >
        <div
          class="
            flex flex-col
            items-center
            justify-between
            whitespace-nowrap
            h-full
            py-14
            before:content-['']
            before:block
            before:absolute
            before:h-[300px]
            before:w-px
            before:bg-[#ECEFF1]
            overflow-hidden
          "
        >
          <span
            class="
              text-sm
              px-2.5
              py-1
              rounded-full
              bg-[#EEF9F3]
              relative
              pl-2
              whitespace-nowrap
              -rotate-90
            "
            >Highest</span
          >
          <span
            class="
              text-[#979CA0
              ]
              bg-white
              text-sm
              px-4
              whitespace-nowrap
              -rotate-90
            "
            >{{ yAxisLabel }}</span
          >
          <span
            class="
              text-sm
              px-2.5
              py-1
              rounded-full
              bg-[#FFECF0]
              pr-2
              whitespace-nowrap
              -rotate-90
            "
          >
            Lowest</span
          >
        </div>
      </div>
      <div class="flex-1">
        <div
          ref="chartRef"
          :style="{ height: isModal ? '85vh' : chartHeight }"
        ></div>
        <!-- Custom X axis label -->
        <div
          class="
            flex
            items-center
            justify-center
            mb-6
            text-center
            relative
            overflow-hidden
            mx-14
          "
        >
          <div
            class="
              flex
              items-center
              w-full
              justify-between
              before:left-0
              before:content-['']
              before:block
              before:absolute
              before:w-full
              before:h-px
              before:bg-[#ECEFF1]
            "
          >
            <span
              class="
                text-sm
                px-2.5
                py-1
                rounded-full
                bg-[#FFECF0]
                relative
                pr-2
              "
              >Lowest</span
            >
            <span class="text-[#979CA0 ] bg-white text-sm relative px-4">{{
              xAxisLabel
            }}</span>
            <span
              class="
                text-sm
                px-2.5
                py-1
                rounded-full
                bg-[#EEF9F3]
                relative
                pl-2
              "
              >Highest</span
            >
          </div>
        </div>
      </div>
    </div>
    </template>
  </AnalyticsCardWrapper>
</template>


<script setup>
import { computed, watch, onMounted, ref, defineProps, defineExpose } from 'vue'
import { commonMethods } from '@common/store/common-methods'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'
import useCompetitorHelper from '@src/modules/analytics_v3/composables/useCompetitorHelper'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  xAxisLabel: {
    type: String,
    default: 'Engagement',
  },
  yAxisLabel: {
    type: String,
    default: 'Followers',
  },
  data: {
    type: [Array, Object],
    default: () => [],
  },
  isAllDataFetched: {
    type: Boolean,
    default: false,
  },
  chartTypes: {
    type: Array,
    default: () => ['scatter', 'custom'],
  },
  type: {
    type: String,
    default: 'comparison',
  },
  legend: {
    type: Object,
    default: () => ({
      show: true,
      data: [],
    }),
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  chartHeight: {
    type: String,
    default: '500px',
  },
  isModal: {
    type: Boolean,
    default: false,
  },
  allowDataZoom: {
    type: Boolean,
    default: true,
  },
  xyAxisKeys: {
    type: Object,
    default: () => ({
      x: 'followers',
      y: 'engagementByFollowers',
    }),
  },
  isLocalImage: {
    type: Boolean,
    default: false,
  },
  customTooltipFormatter: {
    type: Function,
    default: null,
  },
})

const { getComponentInfoTooltip } = useCompetitorHelper()
const { selectedMetric1, selectedMetric2, isReportView } = useOverviewAnalytics()
const xLabel = ref(selectedMetric1.value.label)
const yLabel = ref(selectedMetric2.value.label)

// mounted
onMounted(() => {
  if (props.isModal && chartRef.value) {
    setup(chartOptions.value)
  }
})

watch(
  () => [selectedMetric1.value, selectedMetric2.value],
  () => {
    xLabel.value = selectedMetric1.value.label
    yLabel.value = selectedMetric2.value.label
  }
)

const renderItem = (params, api) => {
  const group = {
    type: 'group',
    children: [],
  }

  const encode = params.encode

  let x = api.value(encode.x)
  let y = api.value(encode.y)
  const image = api.value(encode.image)
  const color = api.value(encode.color)

  // Adding jitter to x and y coordinates
  const jitterAmount = 0.5
  x += (Math.random() - 0.5) * jitterAmount
  y += (Math.random() - 0.5) * jitterAmount

  const point = api.coord([x, y])

  // size of the image 45x45
  const size = 45

  // style for the circular border
  const borderStyle = {
    stroke: color,
    lineWidth: 1,
    fill: null,
  }

  // add circular border to the group
  group.children.push({
    type: 'circle',
    shape: {
      cx: point[0],
      cy: point[1],
      r: size / 2 + borderStyle.lineWidth / 2 + 1.5,
    },
    style: borderStyle,
  })

  // image style
  const imageStyle = {
    image: props?.isLocalImage ? image : `https://microlinks.cstuinternal.com/format_image/?link=${encodeURIComponent(image)}`,
    x: -size / 2,
    y: -size / 2,
    width: size,
    height: size,
  }

  // add image to the group
  group.children.push({
    type: 'image',
    style: imageStyle,
    position: [point[0], point[1]],
  })

  return group
}

const tooltipFormatter = (params) => {
  const { data } = params
  const [name, engagementByFollowers, followers, image] = data

  return `
    <div class="flex flex-col">
      <div class="flex items-center">
        <img src="${image}" class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover" alt=""
            onerror="this.onerror=null;this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
        />
        <span class="text-[#000D21] text-base font-weight-500">${name}</span>
      </div>
      <div class="flex justify-between items-center mt-4">
        <div class="flex items-end">
          <span class="text-[979CA0] text-sm mr-1.5">${selectedMetric1.value.label}</span>
          <span class="text-[#69788E] text-sm font-bold">
              ${followers.toLocaleString('en-US', {
                notation: 'compact',
                compactDisplay: 'short',
              })}
          </span>
        </div>
      </div>
       <div class="flex justify-between items-center mt-2">
        <div class="flex items-end">
          <span class="text-[979CA0] text-sm mr-1.5">${selectedMetric2.value.label}</span>
          <span class="text-[#69788E] text-sm font-bold">${engagementByFollowers}</span>
        </div>
      </div>
    </div>
  `
}

const getSeriesData = () => {
  return props.data?.map((item) => ({
    name: item.name ? item.name : item.slug,
    type: 'custom',
    dimensions: ['name', 'followers', 'engagementByFollowers'],
    animation: true,
    renderItem,
    itemStyle: {
      borderWidth: 2,
    },
    encode: {
      x: [1],
      y: [2],
      name: 0,
      image: 3,
      color: 5,
    },
    color: item.color,
    data: [
      [
        item.name ? item.name : item.slug,
        item[props.xyAxisKeys.x],
        item[props.xyAxisKeys.y],
        item.image,
        item.averagePostsPerDay,
        item.color,
      ],
    ],
    label: {
      position: 'right',
      show: true,
      formatter: params => params.name
    },
    labelLayout:{
      hideOverlap: true,
    }
  }))
}

    const chartOptions = computed(() => {
      if (props.isAllDataFetched) return {}
      return {
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: [0],
            disabled: !props.allowDataZoom,
          },
          {
            type: 'slider',
            xAxisIndex: [0],
            bottom: 5,
            show: props.allowDataZoom,
          },
          {
            type: 'inside',
            yAxisIndex: [0],
            disabled: !props.allowDataZoom,
          },
          {
            type: 'slider',
            yAxisIndex: [0],
            bottom: 5,
            show: props.allowDataZoom,
          },
        ],
        legend: {
          type: 'scroll',
          show: props.legend.show,
          data: props.legend.data,
          width: '100%',
          top: 0,
          left: 'center',
          icon: 'circle',
          formatter: (value) => {
            return value.length > 10 ? value.substring(0, 16) + '...' : value
          },
        },
        grid: {
          left: 80,
          top: 50,
          right: 50,
          bottom: 50,
          width: 'auto',
          borderColor: '#E9EFF6',
        },
        xAxis: {
          type: 'value',
          name: props.xAxisLabel,
          boundaryGap: 0.1,
          nameLocation: 'middle',
          nameTextStyle: {
            color: 'white',
        padding: [0, 0, 10, 0],
        fontSize: 12,
        fontWeight: 400,
          },
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
            formatter: (value) => {
              return value < 0 ? '' : value
            },
          },
          axisLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          axisPointer: {
            type: 'line',
            snap: true,
            label: {
              precision: '2',
            },
          },
        },
        yAxis: {
          type: 'value',
          name: props.yAxisLabel,
          boundaryGap: 0.1,
          nameLocation: 'middle',
          nameTextStyle: {
            color: 'white',
          },
          axisLabel: {
            color: '#979CA0',
            fontSize: 12,
            fontWeight: 400,
            formatter: (value) => {
              return value < 0
                ? ''
                : value.toLocaleString('en-US', {
                    notation: 'compact',
                    compactDisplay: 'short',
                  })
            },
          },
          axisLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          splitLine: {
            lineStyle: {
              color: '#F2F4F6',
            },
          },
          axisPointer: {
            type: 'line',
            snap: true,
            label: {
              formatter: (dataPoint) => {
                return dataPoint.value.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })
              },
            },
          },
        },
        tooltip: {
          trigger: 'item',
          enterable: true,
          axisPointer: {
            type: 'cross',
            label: {
              color: '#61BAE4',
              backgroundColor: '#E6F7FF',
            },
            crossStyle: {
              color: '#61BAE4',
              width: 1,
            },
          },
          formatter: props?.customTooltipFormatter ? props.customTooltipFormatter : tooltipFormatter,
        },
        series: [...getSeriesData()],
      }
    })

    // setting up the eChart
    const { setup, chartRef } = useEcharts(chartOptions.value, props.chartTypes)

    watch(
      () => [chartRef.value, chartOptions.value],
      () => {
        if (!chartRef.value) return
        setup(chartOptions.value)
      }
    )

// Expose necessary variables and methods
defineExpose({
  chartRef,
  chartOptions,
  commonMethods,
  getComponentInfoTooltip,
})
</script>
