<template>
  <div class="h-full overflow-hidden relative">

    <template v-if="currentView === 'grid'">
    <!-- Gradient overlay -->
    <div
      class="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-white z-100 to-transparent pointer-events-none"
    ></div>

    <!-- Floating buttons container -->
    <div
      v-if="hasPostGenerationSettings"
      class="absolute bottom-6 left-1/2 transform -translate-x-1/2 z-100 flex gap-2"
    >
      <div class="relative inline-flex group">
        <div
          class="absolute -inset-0.5 bg-gradient-to-r from-[#0285ED] via-[#0BD2DD] to-[#FC756C] rounded-lg blur-md opacity-25 group-hover:opacity-75 transition duration-500 group-hover:duration-300 animate-gradient"
        ></div>
        <Button
          v-tooltip="'Generate new posts using your post settings'"
          color="dark"
          size="md"
          class="relative transition-all duration-200 hover:-translate-y-0.5 z-10"
          :disabled="postGenerationLoader"
          :loading="postGenerationLoader"
          @click="handleGeneratePost"
        >
          <img
            src="@src/modules/publisher/assets/ai-icon.svg"
            class="h-4 w-4"
            alt=""
          />
          Generate Post
        </Button>
      </div>
      <!-- Edit Post settings -->
      <Button
        v-tooltip="'Edit post settings'"
        class="!border-gray-600 hover:!border-[#2294FF] group"
        variant="bordered"
        color="secondary"
        size="md"
        @click="openSettingsModal"
      >
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.5 8.005C12.8807 8.005 14 6.88569 14 5.505C14 4.12429 12.8807 3.005 11.5 3.005C10.3351 3.005 9.35631 3.80174 9.07875 4.88H2.125C1.77983 4.88 1.5 5.15983 1.5 5.505C1.5 5.85019 1.77983 6.13 2.125 6.13H9.07875C9.35631 7.20825 10.3351 8.005 11.5 8.005ZM10.25 5.505C10.25 6.19536 10.8096 6.755 11.5 6.755C12.1904 6.755 12.75 6.19536 12.75 5.505C12.75 4.81465 12.1904 4.255 11.5 4.255C10.8096 4.255 10.25 4.81465 10.25 5.505ZM1.5 10.505C1.5 9.12432 2.61929 8.005 4 8.005C5.1649 8.005 6.14371 8.80175 6.42124 9.88H13.375C13.7202 9.88 14 10.1598 14 10.505C14 10.8502 13.7202 11.13 13.375 11.13H6.42124C6.14371 12.2083 5.1649 13.005 4 13.005C2.61929 13.005 1.5 11.8857 1.5 10.505ZM5.25 10.505C5.25 9.81463 4.69036 9.255 4 9.255C3.30964 9.255 2.75 9.81463 2.75 10.505C2.75 11.1954 3.30964 11.755 4 11.755C4.69036 11.755 5.25 11.1954 5.25 10.505Z"
        fill="#4A4A4A"
        class="group-hover:fill-[#2294FF] transition-colors duration-200"
      />
    </svg>
      </Button>
    </div>
  </template>
    <!-- Grid View -->
    <div
      v-if="currentView === 'grid'"
      class="h-full p-4 3xl:p-6 overflow-y-auto scroll-smooth !pb-16"
      @scroll="handleScroll"
    >
      <div class="grid grid-cols-4 3xl:grid-cols-5 gap-6">
        <template v-if="getPostsLoader">
          <AIPostCardSkeleton v-for="i in 10" :key="'get-skeleton-' + i" />
        </template>
        <template v-else>
          <template v-if="postGenerationLoader">
            <AIPostCardSkeleton
              v-for="i in parseInt(postSettingsForm.postsCount)"
              :key="'gen-skeleton-' + i"
            />
          </template>
          <AIPostCard
            v-for="post in AIPosts"
            :key="post._id"
            :post="post"
            :selected-posts="selectedPosts"
            :selection-mode="selectionMode"
            @preview="openPreview"
            @select="selectPost"
            @deselect="deselectPost"
          />
          <div
            v-if="loadMorePostsLoader"
          >
            <AIPostCardSkeleton :key="'load-skeleton'" />
          </div>
        </template>
      </div>
    </div>

    <!-- List View -->
    <AIPostListView
      v-else
      :posts="AIPosts"
      :selected-posts="selectedPosts"
      :selection-mode="selectionMode"
      :get-posts-loader="getPostsLoader"
      :post-generation-loader="postGenerationLoader"
      :post-settings-form="postSettingsForm"
      :load-more-posts-loader="loadMorePostsLoader"
      :all-selected="allSelected"
      @preview="openPreview"
      @select="selectPost"
      @deselect="deselectPost"
      @select-all="toggleSelectAll"
      @scroll="handleScroll"
    />
  </div>
  <PostPreviewModal
    :post="selectedPost"
    :has-prev="hasPreviewPrev"
    :has-next="hasPreviewNext"
    @prev="navigatePreview('prev')"
    @next="navigatePreview('next')"
  />
</template>

<script setup>
import { ref, inject, computed, onMounted } from 'vue'
import { Button } from '@contentstudio/ui'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'
import AIPostCard from './AIPostCard.vue'
import AIPostCardSkeleton from './AIPostCardSkeleton.vue'
import AIPostListView from './AIPostListView.vue'
import PostPreviewModal from './modals/PostPreviewModal.vue'

const { AIUserProfile, hasPostGenerationSettings } = useSetup()
const {
  generatePosts,
  getPostsLoader,
  AIPosts,
  postGenerationLoader,
  postSettingsForm,
  loadMorePosts,
  hasMorePosts,
  loadMorePostsLoader,
  getPosts,
  selectedPosts,
  selectionMode,
  selectAll,
  allSelected,
  currentView
} = useAIPostGeneration(AIUserProfile)



const root = inject('root')
const { $bvModal } = root

const selectedPostId = ref(null)

const selectedPost = computed(() => {
  if (!selectedPostId.value) return null
  return AIPosts.value.find((post) => post._id === selectedPostId.value)
})

const currentPostIndex = computed(() => {
  if (!selectedPostId.value) return -1
  return AIPosts.value.findIndex((post) => post._id === selectedPostId.value)
})

const hasPreviewPrev = computed(() => currentPostIndex.value > 0)
const hasPreviewNext = computed(
  () => currentPostIndex.value < AIPosts.value.length - 1
)

onMounted(async () => {
  getPostsLoader.value = true
  await getPosts()
  getPostsLoader.value = false
})

const openSettingsModal = () => {
  $bvModal.show('post-settings-modal')
}

const openPreview = (postId) => {
  selectedPostId.value = postId
  $bvModal.show('post-preview-modal')
}

const navigatePreview = (direction) => {
  const newIndex =
    direction === 'prev'
      ? currentPostIndex.value - 1
      : currentPostIndex.value + 1
  if (newIndex >= 0 && newIndex < AIPosts.value.length) {
    selectedPostId.value = AIPosts.value[newIndex]._id
  }
}

const handleGeneratePost = () => {
  generatePosts()
}

const handleScroll = (event) => {
  // If the event is coming from the list view, the scroll logic is already handled there
  // and we just need to call loadMorePosts
  if (currentView.value === 'list') {
    console.log('handleScroll from list view: Loading more posts...')
    loadMorePosts()
    return
  }

  // For grid view, we need to check if we're near the bottom
  const { scrollHeight, scrollTop, clientHeight } = event.target

  // Load more posts when user scrolls near bottom (within 100px)
  if (
    scrollHeight - scrollTop - clientHeight < 100 &&
    hasMorePosts.value &&
    !getPostsLoader.value
  ) {
    console.log('handleScroll from grid view: Loading more posts...')
    loadMorePosts()
  }
}

// Selection functionality
const selectPost = (postId) => {
  if (!selectedPosts.value.includes(postId)) {
    selectedPosts.value.push(postId)
  }
}

const deselectPost = (postId) => {
  selectedPosts.value = selectedPosts.value.filter(id => id !== postId)
}

const handleMore = (postId) => {
  console.log('More options for post:', postId)
}

// Toggle between selecting all posts and deselecting all
const toggleSelectAll = () => {
  selectAll()
}
</script>
