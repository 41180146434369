import { ref, computed, nextTick } from 'vue'
import { useStore } from '@state/base'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import { useRouter } from 'vue-router'
import useDateFormat from '@common/composables/useDateFormat'
import _ from 'underscore'


const { momentWrapper } = useDateFormat()
const store = useStore()

const {
  inboxAll,
  inboxAssigned,
  inboxMine,
  inboxUnassigned,
  inboxArchived,
  inboxMarkedDone,
} = useIcons()

// Constants
const sidebarItems = [
  { id: 1, title: 'All', countKey: 'ALL', icon: inboxAll, route: 'all' },
  { id: 2, title: 'Unassigned', countKey: 'UNASSIGNED', icon: inboxUnassigned, route: 'unassigned' },
  { id: 3, title: 'Assigned', countKey: 'ASSIGNED', icon: inboxAssigned, route: 'assigned' },
  { id: 4, title: 'Mine', countKey: 'MINE', icon: inboxMine, route: 'mine' },
]
const otherSidebarItems = [
  { id: 1, title: 'Marked As Done', countKey: 'MARKED_AS_DONE', icon: inboxMarkedDone, route: 'marked_as_done' },
  { id: 2, title: 'Archived', countKey: 'ARCHIVED', icon: inboxArchived, route: 'archived' },
]

const routeBasedFiltersKeys = {
  segmentedControl: 'read_unread',
  assignedBy: 'assigned_by',
  assignedTo: 'assigned_to',
  title: 'title',
  markedDoneBy: 'marked_done_by',
  archivedBy: 'archived_by',
}
const routeBasedBulkActionsKeys = {
  assign: 'assign',
  archive: 'archive',
  markAsDone: 'mark_as_done',
  markAsUndone: 'mark_as_undone',
  unarchive: 'unarchive',
}

const routeBasedBulkActions = {
  all: [
    routeBasedBulkActionsKeys.assign,
    routeBasedBulkActionsKeys.archive,
    routeBasedBulkActionsKeys.markAsDone,
  ],
  unassigned: [
    routeBasedBulkActionsKeys.assign,
    routeBasedBulkActionsKeys.archive,
    routeBasedBulkActionsKeys.markAsDone
  ],
  assigned: [
    routeBasedBulkActionsKeys.assign,
    routeBasedBulkActionsKeys.archive,
    routeBasedBulkActionsKeys.markAsDone
  ],
  mine: [
    routeBasedBulkActionsKeys.assign,
    routeBasedBulkActionsKeys.archive,
    routeBasedBulkActionsKeys.markAsDone
  ],
  marked_as_done: [routeBasedBulkActionsKeys.markAsUndone],
  archived: [routeBasedBulkActionsKeys.unarchive],
}

const routeBasedFilters = {
  all: [routeBasedFiltersKeys.segmentedControl],
  unassigned: [routeBasedFiltersKeys.segmentedControl],
  assigned: [routeBasedFiltersKeys.assignedBy, routeBasedFiltersKeys.assignedTo],
  mine: [routeBasedFiltersKeys.title],
  marked_as_done: [routeBasedFiltersKeys.markedDoneBy],
  archived: [routeBasedFiltersKeys.archivedBy],
}

const socialChannelsArray = [
  {
    name: 'facebook',
    key: 'facebook_id',
    getter: 'getFacebookAccounts',
  },
  {
    name: 'instagram',
    key: 'instagram_id',
    getter: 'getInstagramAccounts',
  },
  {
    name: 'twitter',
    key: 'twitter_id',
    getter: 'getTwitterAccounts',
  },
  {
    name: 'linkedin',
    key: 'linkedin_id',
    getter: 'getLinkedinAccounts',
  },
  {
    name: 'gmb',
    key: 'name',
    getter: 'getGmbAccounts',
  }
]

const inboxTypes = {
  all: { label: 'All', value: 'all', filter: ['conversation', 'post', 'review'] },
  private: { label: 'Private Chats', value: 'private', filter: ['conversation'] },
  post: { label: 'Post Comments', value: 'post', filter: ['post'] },
  review: { label: 'Reviews', value: 'review', filter: ['review'] },
}

const convoSortingOptions = [
  {
    label: 'Newest First',
    value: 'desc',
  },
  {
    label: 'Oldest First',
    value: 'asc',
  },
]

// Refs
const selectedChannels = ref({
  facebook: [],
  instagram: [],
  linkedin: [],
  gmb: [],
})

const tags = ref([])
const selectedTags = ref([])
const selectedInboxType = ref('all')
const conversationCount = ref({})
const conversationList = ref({})
const selectedConversationSort = ref(convoSortingOptions[0].value)
const isConversationFetching = ref(false)
const pageNumber = ref(1)
const selectedConversationListItems = ref([])
const searchText = ref('')
const isAssigning = ref(false)
const isArchiving = ref(false)
const isMarkingAsDone = ref(false)
const currentConversation = ref(null)
const selectedAssingedTo = ref([])
const selectedAssingedBy = ref([])
const selectedMarkedDoneBy = ref([])
const selectedArchivedBy = ref([])
const currentConversationNotes = ref([])
const currentConversationBookmarks = ref([])
const pusherInstance = ref(null)
const pusherChannel = ref(null)
const targetConversationId = ref(null)
const isFirstAPI = ref(true)
// Initialize with all channels selected
const initializeSelectedChannels = () => {
  const channels = filteredChannels.value
  Object.entries(channels).forEach(([platform, platformChannels]) => {
    selectedChannels.value[platform] = platformChannels.map(channel => channel.platform_id)
  })
}
// Methods
  /**
   * Toggle the selection of a channel in a given platform.
   *
   * @param {string} platform - The platform of the channel to toggle.
   * @param {string} channelId - The ID of the channel to toggle.
   */
const toggleChannelSelection = (platform, channelId) => {
  const platformChannels = selectedChannels.value[platform]
  const index = platformChannels.indexOf(channelId)

  if (index === -1) {
    platformChannels.push(channelId)
  } else {
    platformChannels.splice(index, 1)
  }
}
  /**
   * Select all channels for all platforms.
   * This function iterates over each platform and selected all channels,
   * effectively setting the selection for each platform to a state where all channels are selected.
   */
const selectAllChannels = () => {
  Object.entries(filteredChannels.value).forEach(([platform, channels]) => {
    selectedChannels.value[platform] = channels.map(channel => channel.platform_id)
  })
}
/**
 * Deselect all channels for all platforms.
 * This function iterates over each platform and clears the selected channels,
 * effectively resetting the selection for each platform to an empty state.
 */
const deselectAllChannels = () => {
  Object.keys(selectedChannels.value).forEach(platform => {
    selectedChannels.value[platform] = []
  })
}
/**
 * Selects all channels for a given platform.
 *
 * @param {string} platform - The platform whose channels are to be selected.
 */
const selectPlatformChannels = (platform) => {
  selectedChannels.value[platform] = filteredChannels.value[platform].map(channel => channel.platform_id)
}
/**
 * Deselects all channels for a given platform.
 *
 * @param {string} platform - The platform whose channels are to be deselected.
 */
const deselectPlatformChannels = (platform) => {
  selectedChannels.value[platform] = []
}
/**
 * Check if a channel is selected.
 * @param {string} platform - The platform the channel belongs to.
 * @param {string} channelId - The ID of the channel to check.
 * @returns {boolean} True if the channel is selected, false otherwise.
 */
const isChannelSelected = (platform, channelId) => {
  return selectedChannels.value[platform].includes(channelId)
}
  /**
   * Check if all channels for a given platform are selected.
   * @param {string} platform - The platform to check.
   * @returns {boolean} True if all channels for the platform are selected, false otherwise.
   */
const isPlatformAllSelected = (platform) => {
  const platformChannels = filteredChannels.value[platform] || []
  const selectedPlatformChannels = selectedChannels.value[platform] || []
  return platformChannels.length > 0 && platformChannels.length === selectedPlatformChannels.length
}
  /**
   * Check if the selection state of channels for a given platform is indeterminate.
   * A selection state is indeterminate if at least one channel is selected, but not all of them are.
   * @param {string} platform - The platform to check.
   * @returns {boolean} True if the selection state is indeterminate, false otherwise.
   */
const isPlatformIndeterminate = (platform) => {
  const selectedCount = selectedChannels.value[platform]?.length || 0
  const totalCount = filteredChannels.value[platform]?.length || 0
  return selectedCount > 0 && selectedCount < totalCount
}
/**
 * Toggle the selection of a tag.
 *
 * @param {string} tagId - The ID of the tag to toggle.
 */
const toggleTagSelection = (tagId) => {
  const index = selectedTags.value.indexOf(tagId)
  if (index === -1) {
    selectedTags.value.push(tagId)
  } else {
    selectedTags.value.splice(index, 1)
  }
}
/**
 * Check if a tag is selected.
 * @param {string} tagId - The ID of the tag to check.
 * @returns {boolean} True if the tag is selected, false otherwise.
 */
const isTagSelected = (tagId) => {
  return selectedTags.value.includes(tagId)
}
/**
 * Select all tags.
 */
const selectAllTags = () => {
  selectedTags.value = tags.value.map(tag => tag._id)
}
/**
 * Deselect all tags.
 */
const deselectAllTags = () => {
  selectedTags.value = []
}
/**
 * Fetch workspace tags from the API and sort them by tag name
 */
const fetchTags = async () => {
  try {
    const response = await store.dispatch('fetchWorkspaceTags', {
      workspace_id: store?.getters?.getActiveWorkspace?._id,
    })

    if (response?.isValid) {
      tags.value = _.sortBy(response.tags, 'tag_name')
    } else {
      tags.value = []
    }
  } catch (err) {
    tags.value = []
  }
}
const createWorkspaceTag = async ({ name, color }) => {
  try {
    const response = await store.dispatch('createTagDetails', {
      workspace_id: store?.getters?.getActiveWorkspace?._id,
      tag_name: name,
      tag_color: color,
    })

    if (response?.isValid) {
      const newTag = response.tagDetails
      tags.value.unshift(newTag)
      await store.dispatch('toastNotification', {
        message: 'Tag created successfully',
        type: 'success',
      })
    }
  } catch (err) {
    await store.dispatch('toastNotification', {
      message: err.response?.data?.message || 'Something went wrong',
      type: 'error',
    })
  }
}
const triggerPlatformSyncJob = async (filteredOnly = false) => {
  try {
    const workspaceId = store?.getters?.getActiveWorkspace?._id
    let platformIds = {}
    if(filteredOnly) {
      platformIds = selectedChannels.value
    }
    else{
      platformIds = Object.keys(filteredChannels.value).reduce((acc, platform) => {
        acc[platform] = filteredChannels.value[platform].map(channel => channel.platform_id);
        return acc;
      }, {});
    }
    // Construct the payload
    const payload = {
      platform_ids: platformIds,
      workspace_id: workspaceId,
      job_types: [
        "post",
        "conversation"
      ],
      limit_params: {
        conversation: 50,
        message: 50,
        post: 50,
        comment: 50
      }
    }

    const response = await store.dispatch('syncAllData_v2', payload)
    if (response?.isValid) {
      await store.dispatch('toastNotification', {
        message: 'Platform Data Sync Job Triggered Successfully',
        type: 'success',
      })
    }
    else{
      await store.dispatch('toastNotification', {
        message: 'Something went wrong',
        type: 'error',
      })
    }
  } catch (err) {
    await store.dispatch('toastNotification', {
      message: err.response?.data?.message || 'Something went wrong',
      type: 'error',
    })
  }
}

const fetchDetails = async (typeFilters = ['conversation', 'post', 'review'], isDefault = true) => {
  const payload = {
    workspace_id: store.getters.getActiveWorkspace._id,
    conversation_counts: conversationCount.value,
    channels: [],
    all_channels: selectedChannels.value,
    tags: selectedTags.value,
    inbox_types: typeFilters,
    search_term: searchText.value,
    default: isDefault,
  }

  const resp = await store.dispatch('fetchSideBarDetails_v2', payload)
  if (resp?.isValid) {
    conversationCount.value = resp.conversation_counts
  }
  return resp
}

const assignInboxToUser = async (assignStatus = true, assignedTo = { user_id: '', user_name: '' }, elementIds = []) => {
  try {
    isAssigning.value = true
    const payload = {
      workspace_id: store.getters.getActiveWorkspace._id,
      element_ids: elementIds.length > 0 ? elementIds : selectedConversationListItems.value,
      assigned: assignStatus,
      assigned_to: assignedTo
    }
    const response = await store.dispatch('assignInboxToUser_v2', payload)
    isAssigning.value = false
    if (response.status) {
      await store.dispatch('toastNotification', {
        message: 'Conversations assigned successfully',
        type: 'success',
      })
      selectedConversationListItems.value = []
      return true
    } else {
      throw new Error(response.message || 'Failed to assign conversations')
    }
  } catch (error) {
    isAssigning.value = false
    await store.dispatch('toastNotification', {
      message: error.message || 'Something went wrong while assigning conversations',
      type: 'error',
    })
    return false
  }
}

const archiveInboxItems = async (archiveStatus = true, elementIds = []) => {
  try {
    isArchiving.value = true
    const payload = {
      workspace_id: store.getters.getActiveWorkspace._id,
      element_ids: elementIds.length > 0 ? elementIds : selectedConversationListItems.value,
      archived: archiveStatus,
    }
    const response = await store.dispatch('archiveInboxItems_v2', payload)
    isArchiving.value = false
    if (response.status) {
      await store.dispatch('toastNotification', {
        message: `Conversations ${archiveStatus ? 'archived' : 'unarchived'} successfully`,
        type: 'success',
      })
      selectedConversationListItems.value = []
      return true
    } else {
      throw new Error(response.message || 'Failed to archive conversations')
    }
  } catch (error) {
    isArchiving.value = false
    await store.dispatch('toastNotification', {
      message: error.message || 'Something went wrong while archiving conversations',
      type: 'error',
    })
    return false
  }
}

const markAsDoneInboxItems = async (doneStatus = true, elementIds = []) => {
  try {
    isMarkingAsDone.value = true
    const payload = {
      workspace_id: store.getters.getActiveWorkspace._id,
      element_ids: elementIds.length > 0 ? elementIds : selectedConversationListItems.value,
      marked_done: doneStatus,
    }
    const response = await store.dispatch('markAsDoneInboxItems_v2', payload)
    isMarkingAsDone.value = false
    if (response.status) {
      await store.dispatch('toastNotification', {
        message: `Conversations marked as ${doneStatus ? 'done' : 'undone'} successfully`,
        type: 'success',
      })
      selectedConversationListItems.value = []
      return true
    } else {
      throw new Error(response.message || 'Failed to mark as done conversations')
    }
  } catch (error) {
    isMarkingAsDone.value = false
    await store.dispatch('toastNotification', {
      message: error.message || 'Something went wrong while marking as done conversations',
      type: 'error',
    })
    return false
  }
}

const assignTagToInboxItem = async (tagId , currentStatus = false) => {

  const action = currentStatus ? 'remove_tag' : 'add_tag'
  try {
    const payload = {
      workspace_id: store.getters.getActiveWorkspace._id,
      platform_id: currentConversation.value.platform_id,
      element_id: currentConversation.value?.element_details?.element_id,
      inbox_type: currentConversation.value.inbox_type,
      tags: [tagId],
      action
    }
    const response = await store.dispatch('updateInboxTags_v2', payload)
    if (response?.isValid) {
      if(action === 'add_tag') {
        const tagToAdd = tags.value.find(tag => tag._id === tagId);
        if (tagToAdd) {
          currentConversation.value.inbox_details?.tags.push(tagToAdd);
          updateCurrentConversation(currentConversation.value);
        }
        console.log('Tag added:', tagToAdd);
      }
      else if(action === 'remove_tag') {
        currentConversation.value.inbox_details.tags = currentConversation.value.inbox_details?.tags.filter(tag => tag._id !== tagId);
        updateCurrentConversation(currentConversation.value);
      }

    } else {
      throw new Error(response.message || 'Failed to tag conversations')
    }
  } catch (error) {
    await store.dispatch('toastNotification', {
      message: error?.message || 'Something went wrong while adding tag.',
      type: 'error',
    })
    return false
  }
}

const updateCurrentConversation = (updatedConversation) => {
  const index = conversationList.value.conversations.findIndex(
    conv => conv.element_details?.element_id === updatedConversation.element_details?.element_id
  );
  if (index !== -1) {
    conversationList.value.conversations[index] = {
      ...conversationList.value.conversations[index],
      ...updatedConversation
    };
  }
};

const markAsRead = async (conversation) => {
  try {
    const payload = {
      workspace_id: store.getters.getActiveWorkspace._id,
      element_id: conversation?.element_details?.element_id,
      element_type: conversation?.inbox_type,
    }

    const response = await store.dispatch('markAsRead_v2', payload)
    if (response.status) {

      const conversationToUpdate = conversation;
      if (conversationToUpdate.inbox_type === 'conversation') {
        conversationToUpdate.last_message.message_status = 'read';
      } else if (conversationToUpdate.inbox_type === 'post') {
        conversationToUpdate.last_comment.comment_status = 'read';
      }
      updateCurrentConversation(conversationToUpdate);

    } else {
      throw new Error(response.message || 'Failed to mark as read conversations')
    }
  } catch (error) {
    // await store.dispatch('toastNotification', {
    //   message: error.message || 'Something went wrong while marking as read conversations',
    //   type: 'error',
    // })
    return false
  }
}

const getReadStatus = (conversation) => {
  switch(conversation?.inbox_type) {
    case 'conversation':
      return conversation?.last_message?.message_status || 'read'
    case 'post':
      return conversation?.last_comment?.comment_status || 'read'
    default:
      return 'read'
  }
}

const scrollMessageIntoView = (messageId) => {
  document.getElementById(`message-item-${messageId}`)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center'
  })
}

const refreshReviewObject = ( newReview , pushToTop = true ) => {
  // find review in conversation
  let oldReview = null

  const reviewIndex = conversationList?.value?.conversations?.findIndex(review => {
    if(review._id === newReview?._id){
      oldReview = review
      return true
    }
    return false
  })
  if (reviewIndex !== -1) {
    // move to top as well
    if (pushToTop) {
      conversationList.value.conversations.splice(reviewIndex, 1)
      conversationList.value.conversations.unshift(newReview)
      // if it is selected, update the currentConversation
      if (currentConversation.value?._id === oldReview?._id) {
        currentConversation.value = conversationList.value.conversations[0]
        scrollMessageIntoView(newReview._id)
      }
    }else{
      // replace
      conversationList.value.conversations[reviewIndex] = newReview
      currentConversation.value = newReview
    }
  }
}

const isMessageNewer = (oldTime, newTime) => {
  if (!oldTime || !newTime) return false
  return momentWrapper(oldTime).isBefore(momentWrapper(newTime))
}

const pushNewConversation = (conversation) => {
  conversationList.value.conversations.unshift(conversation)
}
// Computed

const currentConversationPlatform = computed(() => {
  let currentPlatform = currentConversation.value?.platform;
  if (currentPlatform === 'google_my_business') {
    currentPlatform = 'gmb';
  }
  const currentPlatformId = currentConversation.value?.platform_id;
  return filteredChannels.value[currentPlatform]?.find(channel => channel.platform_id === currentPlatformId);
});


  /**
   * Compute the filtered channels by excluding Twitter and
   * filtering out channels without inbox permission.
   * @returns {Object} An object with the filtered channels grouped by platform.
   */
const filteredChannels = computed(() => {
  const result = {};

  socialChannelsArray.forEach((item) => {
    if(item.name === 'twitter') return;
    const getter = store.getters[item.getter];
    console.log('getter', getter,item.getter);
    // eslint-disable-next-line no-prototype-builtins
    const channelItems = getter?.items ? getter.items : getter;

    result[item.name] = [];

    channelItems?.forEach((channel) => {
      const channelObj = {};
      if (item.name === 'facebook') {
        if (channel.type !== 'Page') return;
        const inboxPermission = _.findWhere(channel.permissions, {
          permission: 'pages_messaging',
        });
        channelObj.inbox_permission = inboxPermission
          ? inboxPermission.status
          : 'not_granted';
      }
      if (
        item.name === 'instagram' &&
        // eslint-disable-next-line no-prototype-builtins
        !(!!channel?.is_inbox && channel.is_inbox)
      ) {
        return;
      }
      if (item.name === 'linkedin') {
        if (channel.type !== 'Page') return;
      }
      channelObj.platform_id = channel[item.key];
      channelObj.platform_image = channel.image;
      channelObj.platform_type = item.name;
      channelObj.type = channel.type ? channel.type : 'Profile';
      if (item.name === 'gmb') {
        channelObj.platform_name = channel.location_name;
        channelObj.full_name = channel.platform_name;
        channelObj.platform_image = channel.account_image
          ? channel.account_image
          : 'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png';
      } else {
        channelObj.platform_name = channel.name;
      }
      channelObj.validity = channel.validity;
        result[item.name].push(channelObj);
    });
  });

  return result;
})

  /**
   * Returns the total number of channels across all platforms.
   * @returns {number} The total number of channels.
   */
const getTotalChannelsCount = computed(() => {
  return Object.values(filteredChannels.value).reduce((total, channels) => total + channels.length, 0)
})

  /**
   * Returns the total number of selected channels across all platforms.
   * @returns {number} The total number of selected channels.
   */
const getSelectedChannelsCount = computed(() => {
  return Object.values(selectedChannels.value).reduce((total, channelIds) => total + channelIds.length, 0)
})

  /**
   * Check if all channels for all platforms are selected.
   * @returns {boolean} True if all channels for all platforms are selected, false otherwise.
   */
const isAllSelected = computed(() => {
  return getTotalChannelsCount.value > 0 && getTotalChannelsCount.value === getSelectedChannelsCount.value
})

/**
 * Check if there are any selected channels.
 * @returns {boolean} True if there are any selected channels, false otherwise.
 */
const isIndeterminate = computed(() => {
  const selectedCount = getSelectedChannelsCount.value
  return selectedCount > 0 && selectedCount < getTotalChannelsCount.value
})

/**
 * Check if all tags are selected.
 * @returns {boolean} True if all tags are selected, false otherwise.
 */
const isAllTagsSelected = computed(() => {
  return tags.value.length > 0 && selectedTags.value.length === tags.value.length
})

/**
 * Check if the selection state of tags is indeterminate.
 * A selection state is indeterminate if at least one tag is selected, but not all of them are.
 * @returns {boolean} True if the selection state is indeterminate, false otherwise.
 */
const isTagsIndeterminate = computed(() => {
  return selectedTags.value.length > 0 && selectedTags.value.length < tags.value.length
})

/**
 * Check if any filters are selected.
 * @returns {boolean} True if any filters are selected, false otherwise.
 */
const hasFilterSelected = computed(() => {
  // Check if any accounts are deselected (default is all selected)
  const hasAccountFilters = !isAllSelected.value

  // Check if any tags are selected (default is none selected)
  const hasTagFilters = selectedTags.value.length > 0

  return hasAccountFilters || hasTagFilters
})

export function useInbox() {
  const router = useRouter()
  const loading = ref(false)

  const fetchConversationList = async (typeFilters = ['conversation', 'post', 'review'], action = 'ALL', page = 1, append = false) => {
    isConversationFetching.value = true

    // Reset the page number when fetching new conversations
    if (!append && pageNumber.value > 1) {
      pageNumber.value = 1
    }
    if (!append) {
      selectedConversationListItems.value = []
    }
    const payload = {
      action: action.toUpperCase(),
      workspace_id: store.getters.getActiveWorkspace._id,
      all_channels: selectedChannels.value,
      tags: selectedTags.value,
      inbox_types: typeFilters,
      order_by: selectedConversationSort.value,
      count: 50,
      channels: [],
      search_term: searchText.value,
      team_member: null,
      assigned_by: selectedAssingedBy.value,
      assigned_to: selectedAssingedTo.value,
      marked_done_by: selectedMarkedDoneBy.value,
      archived_by: selectedArchivedBy.value,
      targeted_element: targetConversationId.value,
      limit: 20,
      page
    }

    const resp = await store.dispatch('fetchFilteredInboxDetails_v2', payload)
    if (resp?.isValid) {
      if (append) {
        const oldConversations = conversationList.value?.conversations || []
        resp.conversations = [...oldConversations, ...resp.conversations]
      }
      conversationList.value = resp
      // if we are not appending, we need to set the current conversation by default and reroute
      const currentPath = router.currentRoute.value.path.split('/')
      if (!append &&
        conversationList.value?.conversations?.length > 0 &&
        currentPath.includes('inbox')
      ) {
        if(targetConversationId.value){
          const conversation = conversationList.value?.conversations?.find(convo => convo.element_details?.element_id === targetConversationId.value)
          if(conversation){
            currentConversation.value = conversation
            nextTick(() => {
              scrollMessageIntoView(currentConversation.value?._id)
            })
            targetConversationId.value = null
          }
        }else if(currentPath.includes(action.toLowerCase())){
          currentConversation.value = conversationList.value?.conversations[0]
          router.push({ name: 'inbox-revamp-conversation', params: { conversation: currentConversation.value?.element_details?.element_id, filter: action } })
        }
        isFirstAPI.value = false
      }
    }
    // else {
    //   await store.dispatch('toastNotification', {
    //     message: resp?.message || 'Something went wrong while fetching conversation list',
    //     type: 'error',
    //   })
    // }
    isConversationFetching.value = false
    return resp
  }

  const fetchTargetedConversation = async (targetId, typeFilters = ['conversation', 'post', 'review']) => {
    const payload = {
      action: 'ALL',
      workspace_id: store.getters.getActiveWorkspace._id,
      all_channels: selectedChannels.value,
      tags: selectedTags.value,
      inbox_types: typeFilters,
      order_by: selectedConversationSort.value,
      count: 1,
      channels: [],
      search_term: '',
      team_member: null,
      assigned_by: selectedAssingedBy.value,
      assigned_to: selectedAssingedTo.value,
      marked_done_by: selectedMarkedDoneBy.value,
      archived_by: selectedArchivedBy.value,
      targeted_element: targetId,
      limit: 1,
      page: 1
    }

    const resp = await store.dispatch('fetchFilteredInboxDetails_v2', payload)
    return resp?.conversations?.find(convo => convo.element_details?.element_id === targetId)
  }

  /**
 * Updates the last message of a conversation in the conversation list.
 * If it's the current conversation, it also updates the current conversation and scrolls to view.
 *
 * @param {Object} message - The new message to be set as the last message.
 * @param {boolean} isCurrentConversation - Indicates if this is the currently active conversation.
 */
const updateLastMessage = async (message, isCurrentConversation) => {
  // Find the index of the conversation to update
  const conversationIndex = conversationList.value.conversations.findIndex(conversation => conversation?.element_details?.element_id === message?.conversation_id);
  if (conversationIndex !== -1) {
    const conversationToUpdate = conversationList.value.conversations[conversationIndex];

    // Update the last message if the new message is newer
    if (isMessageNewer(conversationToUpdate?.last_message?.created_time, message?.created_time)) {
      conversationToUpdate.last_message = message;
      conversationToUpdate.updated_at = momentWrapper().utc();
      conversationToUpdate.unread_message_count++;
    }

    // Update current conversation and scroll to view if it's the active conversation
    if (isCurrentConversation) {
      currentConversation.value = conversationList.value.conversations[conversationIndex];
      scrollMessageIntoView(currentConversation.value._id);
    }
  }
  else{
    console.log('Conversation not found')
    const conversation = await fetchTargetedConversation(message?.conversation_id)
    if(conversation){
      pushNewConversation(conversation)
    }
  }
}

/**
 * Updates the last comment of a conversation in the conversation list.
 * If it's the current conversation, it also updates the current conversation and scrolls to view.
 *
 * @param {Object} comment - The new comment to be set as the last comment.
 * @param {boolean} isCurrentConversation - Indicates if this is the currently active conversation.
 */
const updateLastComment = async (comment, isCurrentConversation) => {
  // Find the conversation to update
  const conversationIndex = conversationList.value.conversations.findIndex(conversation => conversation?.element_details?.element_id === comment?.post_id);
  if (conversationIndex !== -1) {
    const conversationToUpdate = conversationList.value.conversations[conversationIndex];

    // Update the last comment if the new comment is newer
    if (isMessageNewer(conversationToUpdate?.last_comment?.created_time, comment?.created_time)) {
      conversationToUpdate.last_comment = comment;
      conversationToUpdate.updated_at = momentWrapper().utc();
      conversationToUpdate.unread_comment_count++;
    }

    // Update current conversation and scroll to view if it's the active conversation
    if (isCurrentConversation) {
      currentConversation.value = conversationList.value.conversations[conversationIndex];
      scrollMessageIntoView(currentConversation.value._id);
    }
  }
  else{
    console.log('Conversation not found')
    const conversation = await fetchTargetedConversation(comment?.post_id)
    if(conversation){
      pushNewConversation(conversation)
    }
  }
}


  return {
    pusherInstance,
    pusherChannel,
    selectedChannels,
    filteredChannels,
    loading,
    sidebarItems,
    otherSidebarItems,
    inboxTypes,
    selectedInboxType,
    isAllSelected,
    isIndeterminate,
    toggleChannelSelection,
    selectAllChannels,
    deselectAllChannels,
    selectPlatformChannels,
    deselectPlatformChannels,
    isChannelSelected,
    isPlatformAllSelected,
    isPlatformIndeterminate,
    tags,
    fetchTags,
    selectedTags,
    toggleTagSelection,
    isTagSelected,
    isAllTagsSelected,
    isTagsIndeterminate,
    selectAllTags,
    deselectAllTags,
    createWorkspaceTag,
    triggerPlatformSyncJob,
    fetchDetails,
    conversationCount,
    initializeSelectedChannels,
    fetchConversationList,
    conversationList,
    currentConversation,
    isConversationFetching,
    pageNumber,
    selectedConversationListItems,
    convoSortingOptions,
    selectedConversationSort,
    searchText,
    isAssigning,
    hasFilterSelected,
    assignInboxToUser,
    isArchiving,
    archiveInboxItems,
    isMarkingAsDone,
    markAsDoneInboxItems,
    routeBasedBulkActions,
    routeBasedBulkActionsKeys,
    routeBasedFilters,
    routeBasedFiltersKeys,
    assignTagToInboxItem,
    selectedAssingedTo,
    selectedAssingedBy,
    selectedMarkedDoneBy,
    selectedArchivedBy,
    currentConversationPlatform,
    currentConversationNotes,
    currentConversationBookmarks,
    targetConversationId,
    isFirstAPI,
    markAsRead,
    getReadStatus,
    refreshReviewObject,
    updateCurrentConversation,
    updateLastMessage,
    isMessageNewer,
    pushNewConversation,
    updateLastComment,
    fetchTargetedConversation,
    scrollMessageIntoView,
  }
}
