<template>
  <b-modal
    id="update-brand-voice-modal"
    title="Update Brand Voice"
    size="lg"
    hide-footer
    hide-header
    centered
    header-class="p-4 border-b"
    dialog-class="!w-[600px]"
    @shown="handleShown"
  >
    <div class="modal_head">
      <div class="flex item-center justify-between w-full">
        <h4 class="text-xl font-semibold">Update Brand Voice</h4>
      </div>
      <button
        type="button"
        class="modal_head__close bg-transparent"
        data-dismiss="modal"
        aria-label="Close"
        @click.stop="handleClose"
      >&times;</button>
    </div>

    <div class="modal_body py-4 px-2">
      <!-- Step Content -->
      <div ref="stepContainer" class="min-h-[400px]">
        <component :is="steps[currentStep].component" v-model="currentStepData" />
      </div>

      <!-- Navigation Buttons -->
      <div class="flex justify-between mt-6 px-4">
        <Button
          v-if="currentStep > 0"
          class="px-4 py-2 text-[#436AFF] hover:bg-[#F5F7FF] rounded-lg"
          size="sm"
          variant="light"
          color="secondary"
          @click="prevStep"
        >
          Back
        </Button>
        <div class="ml-auto">
          <Button size="sm" :loading="isLoading" @click="handleNextStep">
            {{ currentStep === steps.length - 1 ? 'Update' : 'Next' }}
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import { Button } from '@contentstudio/ui'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { useBrandInfoSetting } from '@modules/publisher/ai-content-library/composables/useBrandInfoSetting'
import { useIcons } from '@modules/publisher/composables/useIcons'
import StrategyStep from '@modules/publisher/ai-content-library/components/form/steps/StrategyStep.vue'
import TopicsStep from '@modules/publisher/ai-content-library/components/form/steps/TopicsStep.vue'

const { isLoading, brandInfo } = useSetup()
const { handleUpdateBrandVoice } = useBrandInfoSetting()
const { StrategyIcon, TopicsIcon } = useIcons()

const currentStepData = computed(() => {
  switch (currentStep.value) {
    case 0:
      return { strategy: brandInfo.strategy }
    case 1:
      return { topics: brandInfo.topics }
    default:
      return {}
  }
})

const root = inject('root')
const { $bvModal } = root

const currentStep = ref(0)
const completedSteps = ref([])
const stepContainer = ref(null)

const steps = [
  {
    name: 'Strategy',
    component: StrategyStep,
    icon: StrategyIcon,
    textColor: 'text-[#4CCE88]',
    borderColor: '!border-[#4CCE88]',
    color: '#4CCE88',
  },
  {
    name: 'Topics',
    component: TopicsStep,
    icon: TopicsIcon,
    textColor: 'text-[#FF9472]',
    borderColor: '!border-[#FF9472]',
    color: '#FF9472',
  },
]

const handleNextStep = async () => {
  if (currentStep.value < steps.length - 1) {
    completedSteps.value.push(currentStep.value)
    currentStep.value++
  } else {
    // On final step, save and close
    await handleUpdateBrandVoice()
    $bvModal.hide('update-brand-voice-modal')
  }
}

const prevStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--
  }
}

const handleClose = () => {
  $bvModal.hide('update-brand-voice-modal')
}

const handleShown = () => {
// Reset step state
  currentStep.value = 0
  completedSteps.value = []
}
</script>
