<template>
  <Dropdown
    :unstyled="true"
    :open="isOpen"
    :show-arrow="false"
    :placement="positionClasses"
    container-classes="h-0 !absolute -top-3 -left-3"
    button-classes="!h-0 !w-0"
    dropdown-classes="!p-0 !min-w-[18rem] !max-h-[28rem] h-full !w-max rounded-lg dropdown-shadow bg-white focus:outline-none !z-[1060]"
    @close="handleClickAway"
  >
    <template v-slot:selected>
      <span></span>
    </template>
    <Picker
      class="emoji-mart-wrap z-[1049]"
      :data="index"
      :emoji-size="18"
      :native="false"
      :emoji-tooltip="true"
      title="Pick your emoji..."
      emoji="point_up"
      @select="emojiUnicodeAdded"
    ></Picker>
  </Dropdown>
</template>

<script>
import { Dropdown } from '@contentstudio/ui';
import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from "emoji-mart-vue-fast/src";
const index = new EmojiIndex(data)

export default {
  name: 'CstEmojiPicker',
  components: {
    Picker,
    Dropdown
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String,
      default: 'bottom-end',
    },
  },
  data() {
    return {
      loaded: false,
      index,
    }
  },
  computed: {
    positionClasses() {
      switch (this.position) {
        case 'bottom-end':
          return 'bottom'
        case 'bottom-start':
          return 'bottom'
        case 'top-end':
          return 'top'
        case 'top-start':
          return 'top'
        case 'left':
          return 'left'
        case 'right':
          return 'right'
        default:
          return 'bottom'
      }
    },
    // emojiDataAll() {
    //   return [EmojiDatSmileys, EmojiDataComponent,EmojiDataAnimalsNature, EmojiDataFoodDrink, EmojiDataFlags, EmojiDataSymbols];
    // },
    // emojiGroups() {
    //   return [
    //     { "group": 0, "description": "😀" },
    //     { "group": 1, "description": "👍️" },
    //     { "group": 2, "description": "🏿" },
    //     { "group": 3, "description": "🐶" },
    //     { "group": 4, "description": "🍉" },
    //     { "group": 6, "description": "🎁" },
    //     { "group": 7, "description": "🎶" },
    //     { "group": 8, "description": "⏺️" },
    //     { "group": 9, "description": "🏁" }
    //   ];
    // }
  },
  mounted() {
    console.debug('Emoji Dropdown mounted')
  },
  beforeUnmount() {
    this.$off('on-select')
  },
  methods: {
    handleClickAway() {
      setTimeout(() => {
        this.$emit('handle-click', this.isOpen, 'emoji')
      }, 100)
    },
    emojiUnicodeAdded(emojiUnicode) {
      const Unicode = emojiUnicode.native
      this.$emit('on-select', Unicode)
    },
  },
}
</script>

<style></style>
