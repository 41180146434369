<template>
  <div class="flex flex-col w-full h-full">
    <AIPostHeader />
    <div class="flex-1 min-h-0">
      <AIPostSection />
    </div>
  </div>
</template>

<script setup>
import AIPostHeader from '@modules/publisher/ai-content-library/components/AIPostHeader.vue'
import AIPostSection from '@modules/publisher/ai-content-library/components/AIPostSection.vue'

</script>
