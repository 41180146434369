import { computed, ref } from 'vue'
import proxy from '@common/lib/http-common'
import moment from 'moment'
import { useStore } from '@state/base'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@common/composables/useNumber'
import AudienceGrowthIcon from '@/src/assets/img/icons/analytic/colored/fb-audience-growth-ai-insights.svg'
import EngagementIcon from '@/src/assets/img/icons/analytic/colored/network-engagement-ai-insights.svg'
import ContentTypeIcon from '@/src/assets/img/icons/analytic/colored/content-type-ai-insights.svg'
import PostingTypeIcon from '@/src/assets/img/icons/analytic/colored/post-type-ai-insights.svg'
import LikesIcon from '@/src/assets/img/icons/analytic/colored/outlined/like-color-outlined-icon.svg'
import CommentsIcon from '@/src/assets/img/icons/analytic/colored/outlined/comment-green-color-outlined-icon.svg'
import SharesIcon from '@/src/assets/img/icons/analytic/colored/outlined/shares-red-color-outlined-icon.svg'
import SavesIcon from '@/src/assets/img/icons/analytic/colored/outlined/save-color-outlined-icon.svg'
import MediaTypeIcon from '@/src/assets/img/icons/analytic/colored/outlined/media-type-color-outlined-icon.svg'
import ClicksIcon from '@/src/assets/img/icons/analytic/colored/outlined/post-color-outlined-icon.svg'
import ReachIcon from '@/src/assets/img/icons/analytic/colored/outlined/reach-color-outlined-icon.svg'
import ImpressionsIcon from '@/src/assets/img/icons/analytic/colored/outlined/impressions-pink-color-outlined-icon.svg'
import useAnalyticsUtils from '@/src/modules/analytics/components/common/composables/useAnalyticsUtils'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';
import useString from '@/src/modules/common/composables/useString'
import OverallReachPerformanceIcon from '@/src/assets/img/icons/analytic/colored/reach-performance-ai-insights.svg'
import OverallPageEngagementPerformanceIcon from '@/src/assets/img/icons/analytic/colored/engagement-performance-ai-insights.svg'
import OverallPageImpressionsIcon from '@/src/assets/img/icons/analytic/colored/page-impressions-ai-insights.svg'
import PlatformPerformanceIcon from '@src/assets/img/icons/analytic/colored/engagement-rate-colored-icon.svg'
import OverallPostPerformanceIcon from '@/src/assets/img/icons/analytic/colored/content-type-ai-insights.svg'

const { stringToHexColor } = useString()

const { channelImage, getSocialImageRounded, handleImageError, tooltipHtml } = useComposerHelper()

const { getAccountId, getAccountById } = useAnalyticsUtils()
const { roundNumber } = useNumber()

const selectedAccount = ref(null)
const isAllAccountsSelected = ref(false)

// Account Statistics Tab Management
const selectedTab = ref('overall')

const isLoadingStates = ref({
  getSummary: false,
  getTopPerformingGraph: false,
  getTopPerformingPlatform: false,
  ai_insights: false,
})

const routes = {
  SUMMARY: 'getSummary',
  TOP_PERFORMING_GRAPH : 'getTopPerformingGraph',
  GET_PLATFORM_DATA :'getPlatformData',
  GET_PLATFORM_DATA_DETAILED: 'getPlatformDataDetailed',
  GET_PLATFORM_DATA_GRAPHS: 'getPlatformDataGraphs',
  GET_TOP_POSTS: 'getTopPosts',
  AI_INSIGHTS: 'ai_insights',
}
const PLATFORM_TITLE_AND_COLOR = {
  facebook: {
    title: 'Facebook',
    color: '#02B2FF',
  },
  instagram: {
    title: 'Instagram',
    color: '#EE446E',
  },
  linkedin: {
    title: 'LinkedIn',
    color: '#3FB2FF',
  },
  tiktok: {
    title: 'TikTok',
    color: '#010101',
  },
  youtube: {
    title: 'YouTube',
    color: '#FF0000',
  },
  pinterest: {
    title: 'Pinterest',
    color: '#E60032',
  },
}

// state
const { DEFAULT_DATE_RANGE } = useAnalytics()
const dateRange = ref(DEFAULT_DATE_RANGE)
const selectedGraphType = ref('reach')

const cardsData = ref({})
const topPerformingGraphData = ref({})
const platformData = ref({
  grouped: [],
  individual: []
})

const platformDataDetailed = ref([])
const platformDataGraphs = ref([])
const topPostsData = ref([])
const aiInsightsData = ref({})

const performanceRadio = [
  {
    label: 'Platforms Performance Comparison',
    chartType: 'grouped',
  },
  { label: 'Accounts Performance Comparison', chartType: 'individual' },
]

const selectedPerformanceType = ref(
  performanceRadio.find((i) => i.chartType ===  performanceRadio[0]?.chartType)
)

const comparisonType = [
  {
    label: 'Accounts Performance Comparison',
    chartType: 'accounts_performance',
    key: 'individual',
    xAxisKey: 'followers',
    yAxisKey: 'total_engagement',
  },
  {
    label: 'Platforms Performance Comparison',
    chartType: 'platforms_performance',
    key: 'grouped',
    xAxisKey: 'total_engagement',
    yAxisKey: 'total_posts',
  },
]

const metricOptions = [
  {
    label: 'Engagement',
    value: 'engagement',
  },
  {
    label: 'Impressions',
    value: 'impressions',
  },
  {
    label: 'Total posts',
    value: 'total_posts',
  },
  {
    label: 'Reach',
    value: 'reach',
  },
  {
    label: 'Followers',
    value: 'followers',
  }
]

const selectedMetric1 = ref(metricOptions[0])
const selectedMetric2 = ref(metricOptions[3])

const selectedComparisonType = ref(
  comparisonType.find((i) => i.chartType ===  comparisonType[0]?.chartType)
)

const cards = ref([
  {
    title: 'Followers',
    key: 'followers',
    tooltip: `The total number of posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts.`,
  },
  {
    title: 'Posts',
    key: 'posts',
    tooltip: `The total number of impressions received by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Impressions are based on the lifetime performance of the posts.`,
  },
  {
    title: 'Engagements',
    key: 'engagement',
    tooltip:
      'The total number of engagement actions generated by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Engagement includes actions such as likes, comments, shares, and other interactions with the posts, based on their lifetime performance.',
  },
  {
    title: 'Impressions',
    key: 'impressions',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
  },
])

const getFormattedDateRange = () => {
  return dateRange.value
    .map((date) => moment(date).format('YYYY-MM-DD'))
    .join(' - ')
}

const getIconMapping = (title) => {
  const iconMapper = {
    'Followers': AudienceGrowthIcon,
    'Posts': PostingTypeIcon,
    'Engagements': EngagementIcon,
    'Impressions': ContentTypeIcon,
  }
  return iconMapper[title]
}

const getCardData = (card) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    tooltip: 'Default tooltip',
  }

  if (!cardsData.value || !Object.keys(cardsData.value)?.length) {
    return defaultValue
  }

  const { summary } = cardsData.value

  return {
    title: card.title,
    total: summary?.[card.key] || defaultValue.total,
    growth: summary?.[card.key + '_change_pct'] || defaultValue.growth,
    tooltip: card.tooltip,
    icon: getIconMapping(card.title),
  }
}

/**
 * @description as the header data is created by the keys of tableData, this method returns proper names on basis of the key names.
 * @param value
 * @returns {string}
 */
const getHeaderTitles = (value) => {
  const titleMappings = {
    totalEngagement: 'Engagements',
    rateOfDistribution: 'Distribution Rate',
    default: 'Analytics'
  };

  return titleMappings[value] || titleMappings.default;
}
const getComponentInfoTooltip = (name = '', platform = '', postType = '') => {
  switch (name) {
    case 'breakdown':
      return `Provides insights into how posts are distributed across campaigns and labels, ensuring comprehensive coverage across all categories and areas of focus.`
    case 'impressions':
      return `Evaluate the impressions received on posts categorized by their associated campaigns and labels.`
    case 'engagements':
      return `Evaluate the engagements received on posts categorized by their associated campaigns and labels.`
    default:
      return ``
  }
}

const getDetailedPulseInfoTooltip = (name = '') => {
  switch (name) {
    case 'posts':
      return `Provides insights into how posts are distributed across campaigns and labels, ensuring comprehensive coverage across all categories and areas of focus.`
    case 'impressions':
      return `Evaluate the impressions received on posts categorized by their associated campaigns and labels.`
    case 'engagements':
      return `Evaluate the engagements received on posts categorized by their associated campaigns and labels.`
    default:
      return ``
  }
}

const getPulseTooltipTitle = (name = '') => {
  switch (name) {
    case 'posts':
      return `Posts Published`
    case 'impressions':
      return `Impressions`
    case 'engagements':
      return `Engagements`
    default:
      return name
  }
}

const getDetailedPulseChartTitle = (name = '') => {
  switch (name) {
    case 'totalEngagement':
      return `Posting pattern over time`
    case 'rateOfDistribution':
      return `Impressions trend over time`
    default:
      return ``
  }
}

const getTableHeaderTooltips = (value) => {
  switch (value) {
    case 'totalEngagement':
      return `The total number of posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts.`
    case 'rateOfDistribution':
      return `The percentage change in the number of Posts Published compared to the previous time period.`
    default:
      return ``
  }
}

// AI Insights section mapping for different chart types
const AIInsightsSectionMapping = {
  'Engagement Across Platforms': 'engagement_across_platforms',
  'Reach Across Platforms': 'reach_across_platforms',
  'Impressions Across Platforms': 'impressions_across_platforms',
  'performanceComparison': 'platform_performance_comparison',
  'platform-performance-comparison': 'platform_performance_comparison',
  'Account-Statistics': 'accounts_statistics',
  'overview-top-posts': 'top_posts',
}

const canFetchMedia = computed(() => {
  return !!(
    selectedAccount.value !== undefined &&
    selectedAccount.value !== null &&
    dateRange.value.length > 0
  )
})

const getTopPerformingPlatform = computed(() => {
  const currentData = platformData.value?.grouped || []
  if (!currentData.length) return currentData;

  const totalEngagement = currentData.reduce((acc, item) => acc + item?.total_engagement, 0)

  return currentData.map((item) => ({
    name: item?.platform_type || '',
    totalEngagement : item?.total_engagement || 0,
    rateOfDistribution : roundNumber((item?.total_engagement / totalEngagement) * 100) || 0,
   }))
})

const getAccountStatistics = computed(() => {
  if(!platformData.value?.individual.length ){
    return []
  }

  console.log(platformData.value?.individual)
  return platformData.value?.individual.map((item) => {
    const account = getAccountById(item?.account_id)
    return {
      name: account?.platform_name || account?.name || account?.username || item?.platform_type,
      image: channelImage(account, item?.platform_type),
      platformImage: getSocialImageRounded(item?.platform_type),
      ...item
    }
  })
})
const getGroupedGraphData = computed(() => {
  const data = {}
  const currentData = platformData.value?.grouped || []
  if (!currentData.length) {
    comparisonType?.forEach((type) => {
      data[type.chartType] = {
        legends: [],
        data: [],
      };
    });
    return data;
  }

  comparisonType?.forEach((type) => {
    data[type.chartType] = {
      legends : currentData.map((item) => PLATFORM_TITLE_AND_COLOR[item?.platform_type]?.title || ''),
      data : currentData.map((item) => ({
        "image": getSocialImageRounded(item?.platform_type || ''),
        "name": PLATFORM_TITLE_AND_COLOR[item?.platform_type]?.title,
        "xAxis": item?.[selectedMetric1.value.value] || 0,
        "yAxis": item?.[selectedMetric2.value.value] || 0,
        "color": PLATFORM_TITLE_AND_COLOR[item?.platform_type]?.color,
     }))
    }
  })
  return data
})

const getIndividualGraphData = computed(() => {
  const data = {}
  const currentData = platformData.value?.individual || []
  if (!currentData.length) {
    comparisonType?.forEach((type) => {
      data[type.chartType] = {
        legends: [],
        data: [],
      };
    });
    return data;
  }
  comparisonType?.forEach((type) => {
    data[type.chartType] = {
      legends : currentData.map((item) => {
        const account = getAccountById(item?.account_id)
        return account?.platform_name || account?.name || account?.username || item?.platform_type
      }),
      data : currentData.map((item, index) => {
        const account = getAccountById(item?.account_id)
        const name = account?.platform_name || account?.name || account?.username || item?.platform_type
        const image = channelImage(account, item?.platform_type)
        return {
          image,
          name,
          "xAxis": item?.[selectedMetric1.value.value] || 0,
          "yAxis": item?.[selectedMetric2.value.value] || 0,
          "color": stringToHexColor(name),
        }
    })
    }
  })
  return data
})

const getSelectedPerformanceData = computed(() => {
  if(selectedPerformanceType.value.chartType === 'grouped'){
    return getGroupedGraphData.value
  }
  else if(selectedPerformanceType.value.chartType === 'individual'){
    return getIndividualGraphData.value
  }
  return {}
})

const setIsloading = (type, value) => {
  isLoadingStates.value[type] = value
}

// Account Statistics functionality (merged from useAccountStatistics.js)
const getAccountStatisticsTabs = () => {
  return [
    { label: 'Overall', value: 'overall', icon: null },
    { label: 'Facebook', value: 'facebook', icon: getSocialImageRounded('facebook') },
    { label: 'Instagram', value: 'instagram', icon: getSocialImageRounded('instagram') },
    { label: 'LinkedIn', value: 'linkedin', icon: getSocialImageRounded('linkedin') },
    { label: 'Pinterest', value: 'pinterest', icon: getSocialImageRounded('pinterest') },
    { label: 'TikTok', value: 'tiktok', icon: getSocialImageRounded('tiktok') },
    { label: 'YouTube', value: 'youtube', icon: getSocialImageRounded('youtube') }
  ]
}

// Function to get account count for platform tabs
const getAccountCount = (accountStatistics, platform) => {
  if (!accountStatistics || !Array.isArray(accountStatistics)) return 0
  if (platform === 'overall') return accountStatistics.length
  return accountStatistics.filter(account => account.platform_type === platform).length
}

// Function to filter accounts by selected platform tab
const getFilteredAccounts = (accountStatistics, tabValue) => {
  if (!accountStatistics || !Array.isArray(accountStatistics)) return []
  if (tabValue === 'overall') {
    return accountStatistics
  }
  return accountStatistics.filter(account => account.platform_type === tabValue)
}

// Get AI Insights Icons mapping
const getAIInsightsIcons = (category) => {
  const iconMapper = {
    'Overall Audience Growth Performance': AudienceGrowthIcon,
    'Overall Reach Performance': OverallReachPerformanceIcon,
    'Overall Engagement Performance': OverallPageEngagementPerformanceIcon,
    'Overall Impressions Performance': OverallPageImpressionsIcon,
    'Platform Performance Comparison': PlatformPerformanceIcon,
    'Accounts Performance Comparison': PlatformPerformanceIcon,
    'Top Posts Performance': OverallPostPerformanceIcon,
    'Overall Post Performance': OverallPostPerformanceIcon,
  }
  return iconMapper[category] ?? AudienceGrowthIcon
}

export default function useOverviewAnalytics(defaultLabel = 'Data') {
  const { getters, dispatch } = useStore()

  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    lineChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
    tooltipFormatterScroll,
    ANALYTICS_DELIMITER,
  } = useAnalytics(defaultLabel)

  const fetchMedia = async (type, extraPayload = {}, append = false) => {
    if (canFetchMedia.value) {
      try {
        setIsloading(type, true)
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          date: getFormattedDateRange(),
          timezone: getters.getActiveWorkspace.timezone,
          ...extraPayload,
        }

          Object.assign(payload, {
            facebook_accounts: [],
            instagram_accounts: [],
            linkedin_accounts: [],
            tiktok_accounts: [],
            youtube_accounts: [],
            pinterest_accounts: [],
            twitter_accounts: [],
            gmb_accounts: [],
            tumblr_accounts: [],
          })

          // Override payload for AI insights
        if (type === routes.AI_INSIGHTS) {
          payload.workspace_id = getters.getActiveWorkspace._id
          payload.date = getFormattedDateRange()
          payload.type = extraPayload.type
          payload.timezone = getters.getActiveWorkspace.timezone
          payload.limit = 5
        }

          selectedAccount.value?.forEach((account) => {
            payload[`${account.account_type}_accounts`].push(
              getAccountId(account)
            )
          })

          // Add metric parameters for GET_PLATFORM_DATA
          if (type === routes.GET_PLATFORM_DATA) {
            if (extraPayload.metric1) {
              payload.metric1 = extraPayload.metric1;
            }
            if (extraPayload.metric2) {
              payload.metric2 = extraPayload.metric2;
            }
            if (extraPayload.comparisonType) {
              payload.comparisonType = extraPayload.comparisonType;
            }
          }

          const { data } = await proxy.post(
            BASE_ENDPOINT + type,
            payload
          )

        // let leastPosts
        // let topPosts
        switch (type) {
          case routes.SUMMARY:
            cardsData.value = data
            break
          case routes.TOP_PERFORMING_GRAPH:
            if (extraPayload?.type === 'reach'){
              topPerformingGraphData.value.reach = data
            }
            else if (extraPayload?.type === 'engagement'){
              topPerformingGraphData.value.engagement = data
            }
            else if (extraPayload?.type === 'impressions'){
              topPerformingGraphData.value.impressions = data
            }
            break
          case routes.GET_PLATFORM_DATA:
            if (extraPayload?.type === 'grouped'){
              platformData.value.grouped = data
            }
            else if (extraPayload?.type === 'individual'){
              platformData.value.individual = data
            }
            break
          case routes.GET_PLATFORM_DATA_DETAILED:
            platformDataDetailed.value = data
            break
          case routes.GET_PLATFORM_DATA_GRAPHS:
            platformDataGraphs.value = data
            break
          case routes.GET_TOP_POSTS:
            topPostsData.value = data || []
            break
          case routes.AI_INSIGHTS:
            aiInsightsData.value = data || {}
            break
          default:
            break
        }
      } catch (e) {
        console.error('FETCH DATA:::', e)
      } finally {
        setIsloading(type, false)
      }
    }
  }

  const comparisonGraphTooltipFormatter = (params) => {
    const { data } = params
    console.log('data in tooltip fomatter : ', data)
    const [name, dataMetric1, dataMetric2, image] = data

    return `
      <div class="flex flex-col">
        <div class="flex items-center">
          <img src="${image}" class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover"  alt=""
              onerror="this.onerror=null;this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
          />
          <span class="text-[#000D21] text-base font-weight-500">${name}</span>
        </div>
        <div class="flex justify-between items-center mt-4">
          <div class="flex items-end">
            <span class="text-[979CA0] text-sm mr-1.5">${
              selectedMetric1.value.label
            }</span>
            <span class="text-[#69788E] text-sm font-bold">
                ${dataMetric1.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })}
            </span>
          </div>

        </div>
          <div class="flex justify-between items-center mt-2">
          <div class="flex items-end">
            <span class="text-[979CA0] text-sm mr-1.5">${selectedMetric2.value.label}</span>
            <span class="text-[#69788E] text-sm font-bold">${dataMetric2.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })}</span>
          </div>
        </div>
      </div>
    `
  }

  return {
    // constants
    PLATFORM_TITLE_AND_COLOR,
    routes,
    analyticsDesignSystem,
    legendOptions,
    dataZoomOptions,
    defaultChartOptions,

    // state
    selectedAccount,
    isAllAccountsSelected,
    cards,
    dateRange,
    barChartOptions,
    lineChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    isReportView,
    screenWidth,
    isLoadingStates,
    getTopPerformingPlatform,
    getGroupedGraphData,
    getIndividualGraphData,
    selectedPerformanceType,
    performanceRadio,
    comparisonType,
    selectedComparisonType,
    metricOptions,
    selectedMetric1,
    selectedMetric2,
    getSelectedPerformanceData,
    getAccountStatistics,
    selectedGraphType,

    cardsData,
    topPerformingGraphData,
    platformData,
    platformDataDetailed,
    platformDataGraphs,
    topPostsData,
    aiInsightsData,
    // methods
    fetchMedia,
    getCardData,
    isNoAnalyticsData,
    axisLabelFormatter,
    getHeaderTitles,
    getComponentInfoTooltip,
    getTableHeaderTooltips,
    tooltipFormatterScroll,
    setIsloading,
    getDetailedPulseInfoTooltip,
    getDetailedPulseChartTitle,
    getPulseTooltipTitle,
    comparisonGraphTooltipFormatter,
    AIInsightsSectionMapping,
    getAIInsightsIcons,
    ANALYTICS_DELIMITER,

    // Account Statistics Functions (from useAccountStatistics)
    selectedTab,
    getAccountStatisticsTabs,
    getAccountCount,
    getFilteredAccounts,

    // Refactored utility functions from TopPostsComponent
    formatNumber,
    formatDate,
    formatMediaType,
    getPlatformImage,
    getPlaceholderImage,
    getPlatformName,
    getAccountImage,
    getAccountName,
    getDefaultMediaType,
    getPlatformMetrics,
    platformPlaceholders,
    getAvailableTabs,
  }
}

// Platform placeholder images for posts without media
const platformPlaceholders = {
  facebook: [
    'https://images.unsplash.com/photo-1522075469751-3a6694fb2f61',
    'https://images.unsplash.com/photo-1517841905240-472988babdf9',
    'https://images.unsplash.com/photo-1539571696357-5a69c17a67c6'
  ],
  instagram: [
    'https://images.unsplash.com/photo-1534528741775-53994a69daeb',
    'https://images.unsplash.com/photo-1524504388940-b1c1722653e1',
    'https://images.unsplash.com/photo-1521119989659-a83eee488004'
  ],
  linkedin: [
    'https://images.unsplash.com/photo-1560250097-0b93528c311a',
    'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2',
    'https://images.unsplash.com/photo-1551836022-deb4988cc6c0'
  ],
  tiktok: [
    'https://images.unsplash.com/photo-1531746020798-e6953c6e8e04',
    'https://images.unsplash.com/photo-1601288496920-b6154fe3626a',
    'https://images.unsplash.com/photo-1542764165169-ac3a260b2b33'
  ],
  youtube: [
    'https://images.unsplash.com/photo-1526047932273-341f2a7631f9',
    'https://images.unsplash.com/photo-1611162617213-7d7a39e9b1d7',
    'https://images.unsplash.com/photo-1548612570-2a5a381e1e9f'
  ],
  pinterest: [
    'https://images.unsplash.com/photo-1581467655410-0c2bf55d9d6c',
    'https://images.unsplash.com/photo-1579751626657-72bc17010498',
    'https://images.unsplash.com/photo-1508166785545-c2dd4c113c66'
  ]
}

// Define available tabs for platforms
const getAvailableTabs = () => {
  return [
    { label: 'Overall', value: 'overall' },
    { label: 'Instagram', value: 'instagram' },
    { label: 'Facebook', value: 'facebook' },
    { label: 'LinkedIn', value: 'linkedin' },
    { label: 'Pinterest', value: 'pinterest' },
    { label: 'TikTok', value: 'tiktok' },
    { label: 'YouTube', value: 'youtube' }
  ]
}

// Format numbers with K, M suffix
const formatNumber = (num) => {
  if (num === undefined || num === null) return '0'
  if (num === 0) return '0'

  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M'
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K'
  }

  return num.toString()
}

// Format date
const formatDate = (date) => {
  return moment(date).format('MMM DD, YYYY h:mm A')
}

// Format media type to sentence case
const formatMediaType = (mediaType) => {
  if (!mediaType) return ''

  // Convert to lowercase first, then capitalize first letter
  return mediaType.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())
}

// Get platform image
const getPlatformImage = (platform) => {
  return getSocialImageRounded(platform) || ''
}

// Get placeholder image for post
const getPlaceholderImage = (platform, index) => {
  const platformImages = platformPlaceholders[platform] || platformPlaceholders.facebook
  return platformImages[index % platformImages.length]
}

// Get platform name
const getPlatformName = (platform) => {
  const platforms = {
    facebook: 'Facebook',
    instagram: 'Instagram',
    linkedin: 'LinkedIn',
    pinterest: 'Pinterest',
    tiktok: 'TikTok',
    youtube: 'YouTube',
    overall: 'Overall'
  }

  return platforms[platform] || platform
}

// Function to get account image based on account_id
const getAccountImage = (accountId, platformType) => {
  if (!accountId) return getPlatformImage(platformType)

  // Get account from analytics utils
  const account = getAccountById(accountId)

  // Use channelImage function from useComposerHelper
  return channelImage(account, platformType)
}

// Function to get account name based on account_id
const getAccountName = (accountId, platformType) => {
  // Default to platform name if no account is found
  const defaultName = getPlatformName(platformType)

  if (!accountId) return defaultName

  // Get account from analytics utils
  const account = getAccountById(accountId)

  // Return name, username, or default platform name
  return account?.platform_name || account?.name || account?.username || defaultName
}

// Get default media type based on platform if not provided
const getDefaultMediaType = (platform) => {
  const defaultTypes = {
    facebook: 'Post',
    instagram: 'Photo',
    linkedin: 'Article',
    pinterest: 'Pin',
    tiktok: 'Video',
    youtube: 'Video'
  }

  return defaultTypes[platform] || 'Post'
}

// Get platform-specific metrics for each post
const getPlatformMetrics = (post) => {
  // Get media type to show in stats
  const mediaType = post.media_type || getDefaultMediaType(post.platform_type)
  const formattedMediaType = formatMediaType(mediaType)

  // Default metrics shown on all platforms
  const defaultMetrics = [
    { title: 'Media Type', value: formattedMediaType, isText: true, icon: MediaTypeIcon },
    { title: 'Total Engagement', value: post.total_engagement, icon: EngagementIcon },
    { title: 'Post Impressions', value: post.total_impressions, icon: ImpressionsIcon },
    { title: 'Reach', value: post.reach, icon: ReachIcon }
  ]

  // Platform-specific metrics
  const platformMetrics = {
    pinterest: [
      { title: 'Pin Clicks', value: post.pin_clicks, icon: ClicksIcon },
      { title: 'Outbound Clicks', value: post.outbound_clicks, icon: ContentTypeIcon },
      { title: 'Saves', value: post.saves, icon: SavesIcon }
    ],
    instagram: [
      { title: 'Likes', value: post.likes, icon: LikesIcon },
      { title: 'Comments', value: post.comments, icon: CommentsIcon },
      { title: 'Saves', value: post.saves, icon: SavesIcon }
    ],
    default: [
      { title: 'Likes', value: post.likes, icon: LikesIcon },
      { title: 'Comments', value: post.comments, icon: CommentsIcon },
      { title: 'Shares', value: post.shares, icon: SharesIcon }
    ]
  }

  // Determine which platform-specific metrics to use
  let specificMetrics
  if (post.platform_type === 'pinterest') {
    specificMetrics = platformMetrics.pinterest
  } else if (post.platform_type === 'instagram') {
    specificMetrics = platformMetrics.instagram
  } else {
    specificMetrics = platformMetrics.default
  }

  // Combine default and platform-specific metrics
  return [...specificMetrics, ...defaultMetrics]
}
