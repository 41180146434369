const ArticleSocialAutomationListing = () =>
  import(
    /* webpackChunkName: "article-to-social-automation" */ '../../components/article/listing/ArticleToSocialAutomationListing'
  )
const SaveArticleSocialAutomation = () =>
  import(
    /* webpackChunkName: "article-to-social-automation" */ '../../components/article/save/ArticeToAutomationSaveSocial'
  )
const ArticleBlogAutomationListing = () =>
  import(
    /* webpackChunkName: "article-to-blog-automation" */ '../../components/article/listing/ArticleToBlogAutomationListing'
  )
const SaveArticleBlogAutomation = () =>
  import(
    /* webpackChunkName: "article-to-blog-automation" */ '../../components/article/save/ArticleToAutomationSaveBlog'
  )
const VideoSocialAutomationListing = () =>
  import(
    /* webpackChunkName: "video-to-social-automation" */ '../../components/video/listing/VideoSocialListing'
  )
const SaveVideoSocialAutomation = () =>
  import(
    /* webpackChunkName: "video-to-social-automation" */ '../../components/video/save/VideoAutomationSaveSocial'
  )
const SaveBulkCsvAutomation = () =>
  import(
    /* webpackChunkName: "bulk-automation" */ '../../components/csv/BulkUploadAutomationSave'
  )
const EvergreenAutomationListing = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '../../components/evergreen/listing/EvergreenAutomationListing'
  )
const BulkAutomationListing = () =>
  import(
    /* webpackChunkName: "bulk-automation" */ '../../components/csv/BulkUploadListing'
  )
const SaveEvergreenAutomation = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '../../components/evergreen/create/EvergreenMain'
  )
const AccountsEvergreenAutomation = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '../../components/evergreen/create/EvergreenAccountSelection'
  )
const OptimizationEvergreenAutomation = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '../../components/evergreen/create/EvergreenOptimization'
  )
const FinalizationEvergreenAutomation = () =>
  import(
    /* webpackChunkName: "evergreen-automation" */ '../../components/evergreen/create/EvergreenFinalization'
  )
const RssAutomationListing = () =>
  import(
    /* webpackChunkName: "rss-automation" */ '../../components/rss/listing/RSSAutomationListing'
  )
const SaveRssAutomation = () =>
  import(
    /* webpackChunkName: "rss-automation" */ '../../components/rss/SaveRSSAutomation'
  )

export const automationRoutes = {
  articleSocialAutomationListing: {
    path: '/:workspace/automation/social/articles',
    name: 'articleSocialAutomationListing',
    component: ArticleSocialAutomationListing,
    meta: {
      title: 'Articles to Social Media Automation | Automation'
    }
  },
  saveArticleSocialAutomation: {
    path: '/:workspace/automation/social/articles/create',
    name: 'saveArticleSocialAutomation',
    component: SaveArticleSocialAutomation,
    meta: {
      title: 'Save Articles to Social Media Automation | Automation'
    }
  },
  articleBlogAutomationListing: {
    path: '/:workspace/automation/blog/articles',
    name: 'articleBlogAutomationListing',
    component: ArticleBlogAutomationListing,
    meta: {
      title: 'Articles to Blog Automation'
    }
  },
  saveArticleBlogAutomation: {
    path: '/:workspace/automation/blog/articles/create',
    name: 'saveArticleBlogAutomation',
    component: SaveArticleBlogAutomation,
    meta: {
      title: 'Save Articles to Blog Automation | Automation'
    }
  },
  videoSocialAutomationListing: {
    path: '/:workspace/automation/social/videos',
    name: 'videoSocialAutomationListing',
    component: VideoSocialAutomationListing,
    meta: {
      title: 'Videos to Social Media Automation | Automation'
    }
  },
  saveVideoSocialAutomation: {
    path: '/:workspace/automation/social/videos/create',
    name: 'saveVideoSocialAutomation',
    component: SaveVideoSocialAutomation,
    meta: {
      title: 'Save Videos to Social Media Automation | Automation'
    }
  },
  saveBulkCsvAutomation: {
    path: '/:workspace/automation/csv/create/:csvId?',
    name: 'saveBulkCsvAutomation',
    component: SaveBulkCsvAutomation,
    meta: {
      title: 'Save CSV Automation | Automation'
    }
  },
  bulkSocialAutomationListing: {
    path: '/:workspace/automation/csv',
    name: 'bulkAutomationListing',
    component: BulkAutomationListing,
    meta: {
      title: 'Bulk CSV Automation | Automation'
    }
  },
  evergreenAutomationListing: {
    path: '/:workspace/automation/evergreen',
    name: 'evergreenAutomationListing',
    component: EvergreenAutomationListing,
    meta: {
      title: 'Evergreen Automation | Automation'
    }
  },
  rssAutomationListing: {
    path: '/:workspace/automation/rss',
    name: 'rssAutomationListing',
    component: RssAutomationListing,
    meta: {
      title: 'RSS Feed Automation | Automation'
    }
  },
  saveRssAutomation: {
    path: '/:workspace/automation/rss/save',
    name: 'saveRssAutomation',
    component: SaveRssAutomation,
    meta: {
      title: 'Save RSS Feed Automation | Automation'
    }
  },

  saveEvergreenAutomation: {
    path: '/:workspace/automation/evergreen/save',
    name: 'saveEvergreenAutomation',
    // redirect: '/:workspace/automation/evergreen/save/accounts',
    redirect: {name: 'saveEvergreenAutomationAccounts'},
    component: SaveEvergreenAutomation,
    meta: {
      title: 'Save Evergreen Automation | Automation'
    },
    children: [
      {
        path: 'accounts/:evergreenId?',
        name: 'saveEvergreenAutomationAccounts',
        component: AccountsEvergreenAutomation,
        meta: {
          title: 'Evergreen Automation Accounts | Evergreen'
        }
      },
      {
        path: 'schedule/:evergreenId?',
        name: 'saveEvergreenAutomationOptimization',
        component: OptimizationEvergreenAutomation,
        meta: {
          title: 'Evergreen Automation Schedule | Evergreen'
        }
      },
      {
        path: 'content/:evergreenId?',
        name: 'saveEvergreenAutomationFinalize',
        component: FinalizationEvergreenAutomation,
        meta: {
          title: 'Evergreen Automation Content | Evergreen'
        }
      }
    ]
  }
}
