<template>
  <AnalyticsCardWrapper
    type="account-statistics"
    :enable-modal="false"
    :loading="isLoading"
    class="hover:shadow-sm transition-all duration-300 w-full"
  >
    <template v-slot:card-header>
      <div class="flex flex-col mb-3 space-y-2">
        <div class="flex items-center gap-2 sm:gap-3 mb-2">
          <div class="relative">
            <img
              :src="accountImage"
              alt="account"
              class="h-8 w-8 sm:h-10 sm:w-10 rounded-full object-cover border shadow-sm"
              @error="handleImageError"
            />
            <img
              :src="require(`@/src/assets/img/integration/${platformType}-icon.svg`)"
              class="absolute -bottom-1 -right-1 w-4 h-4 sm:w-5 sm:h-5 bg-white rounded-full border border-gray-200 shadow-sm"
              alt="Platform"
              @error="$event.target.src = require(`@/src/assets/img/integration/${platformType}-rounded.svg`)"
            />
          </div>
          <div class="flex flex-col min-w-0">
            <span
              v-tooltip="{
                content: tooltipHtml(name),
                theme: 'light',
                allowHTML: true,
              }"
              class="font-semibold text-[#000D21] text-sm truncate"
            >
              {{ name }}
            </span>
            <span class="text-xs text-gray-500">{{ platformTypeFormatted }}</span>
          </div>
        </div>
        <div class="mt-2 w-full">
          <CstDropdown
            size="small"
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            dropdown-classes="!rounded-md !border-[#70707029] !shadow-sm"
            button-classes="flex !px-3 !py-1.5 !w-[160px] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A] hover:!bg-gray-100 transition-colors"
          >
            <template v-slot:selected>
              <p class="text-[14px] font-weight-500 capitalize truncate">
                {{ selectedMetric }}
              </p>
            </template>
            <template v-slot>
              <CstDropdownItem
                v-for="metric in metrics"
                :key="metric"
                @click="selectedMetric = metric"
              >
                <p class="text-gray-900 text-sm capitalize">{{ metric }}</p>
              </CstDropdownItem>
            </template>
          </CstDropdown>
        </div>
      </div>
    </template>
    <template v-slot:card-body>
      <div class="mt-1">
        <div v-if="graphData && hasData" class="h-32 sm:h-40 w-full animate-fadeIn">
          <MainGraph
            :chart-options="chartOptions"
            type="line"
            custom-height="100%"
          />
        </div>
        <div v-else class="h-32 sm:h-40 w-full flex items-center justify-center text-gray-600 text-sm">
          No data available
        </div>
      </div>
      <!-- External Link Icon -->
      <div class="absolute top-2 right-2 sm:top-3 sm:right-3">
        <i class="fas fa-external-link-alt text-gray-400 text-sm sm:text-base"></i>
      </div>

      <!-- Stats Grid -->
      <div class="flex flex-col mt-4 animate-fadeIn space-y-1">
        <div v-for="(stat, index) in statistics" :key="index" class="flex items-center py-1">
          <div class="flex items-center min-w-0 flex-1">
            <img :src="getStatIcon(stat.label)" alt="stat icon" class="text-[#757A8A] w-4 sm:w-5 mr-1 sm:mr-2 flex-shrink-0">
            <span class="text-sm text-[#757A8A] font-normal tracking-tight truncate">{{ stat.label }}</span>
          </div>
          <div class="flex items-center justify-end flex-shrink-0">
            <span
              v-tooltip="{
                content: tooltipHtml(stat.value),
                theme: 'light',
                allowHTML: true,
              }"
              class="font-semibold text-[#000D21] tracking-tight text-sm mr-2 w-10 text-right"
            >
              {{ formatNumber(stat.value) }}
            </span>
            <div
              v-if="stat.trend !== null && stat.trend !== undefined"
              v-tooltip="{
                content: tooltipHtml(stat.changeText),
                theme: 'light',
                allowHTML: true,
              }"
              class="flex items-center text-[10px] font-medium px-1 py-0.5 rounded-md cursor-pointer w-14 justify-center"
              :class="getTrendColorClass(stat.trend)"
            >
              {{ Math.abs(stat.trend) }}%
              <i
                class="fas ml-0.5 text-[10px]"
                :class="stat.trend > 0 ? 'fa-arrow-up' : 'fa-arrow-down'"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import useNumber from '@common/composables/useNumber'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import MainGraph from '@src/modules/analytics/views/overviewV2/components/graphs/MainGraph.vue'
import {graphic} from 'echarts/core'
import PostingTypeIcon from '@src/assets/img/icons/analytic/colored/post-type-ai-insights.svg'
import AudienceGrowthIcon from '@src/assets/img/icons/analytic/colored/fb-audience-growth-ai-insights.svg'
import ImpressionsIcon from '@/src/assets/img/icons/analytic/colored/page-impressions-ai-insights.svg'
import EngagementIcon from '@/src/assets/img/icons/analytic/colored/engagement-performance-ai-insights.svg'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'


const { handleImageError, tooltipHtml } = useComposerHelper()

const props = defineProps({
  platformImage: {
    type: String,
    required: true
  },
  accountImage: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  },
  platformType: {
    type: String,
    required: true
  },
  accountId: {
    type: String,
    required: true
  },
  platformDataDetailed: {
    type: Array,
    default: () => []
  },
  platformDataGraphs: {
    type: Array,
    default: () => []
  },
  isLoading: {
    type: Boolean,
    default: false
  }
})

const { formatNumber } = useNumber()

const metrics = ['engagement', 'reach', 'impressions', 'posts']
const selectedMetric = ref('engagement')

const accountData = computed(() => {
  if (!props.platformDataDetailed || props.platformDataDetailed.length === 0) {
    return null
  }

  return props.platformDataDetailed.find(item =>
    item.account_id === props.accountId &&
    item.platform_type === props.platformType
  )
})

const platformTypeFormatted = computed(() => {
  const platformTypes = {
    'facebook': 'Facebook',
    'instagram': 'Instagram',
    'linkedin': 'LinkedIn',
    'tiktok': 'TikTok',
    'youtube': 'YouTube',
    'pinterest': 'Pinterest'
  }

  return platformTypes[props.platformType] || props.platformType
})

const graphData = computed(() => {
  if (!props.platformDataGraphs || props.platformDataGraphs.length === 0) {
    return null
  }

  return props.platformDataGraphs.find(item => item.account_id === props.accountId)
})

const hasData = computed(() => {
  if (!graphData.value) return false
  const metricData = graphData.value[selectedMetric.value] || []
  return metricData.length > 0 && metricData.some(value => value !== null && value !== 0)
})



const chartOptions = computed(() => {
  if (!graphData.value) return {}

  const metricData = graphData.value[selectedMetric.value] || []
  const dates = graphData.value.buckets || []

  return {
    color: getMetricColor(selectedMetric.value),
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'line'
      },
      formatter: (params) => {
        const param = params[0]
        return `<div style="display: flex; flex-direction: column; gap: 8px;">
          <div style="font-weight: bold;">${param.name}</div>
          <div>${selectedMetric.value.charAt(0).toUpperCase() + selectedMetric.value.slice(1)}: ${param.value}</div>
        </div>`
      }
    },
    grid: {
      top: '10%',
      left: '5%',
      right: '5%',
      bottom: '5%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: dates,
      axisLine: { show: false },
      axisTick: { show: false },
      boundaryGap: '0%',
      axisLabel: { show: false }
    },
    yAxis: {
      min: 'dataMin',
      type: 'value',
      splitLine: {
        show: true,
        lineStyle: {
          type: 'dashed',
          opacity: 0.3
        }
      },
      alignTicks: true,
      axisLabel: { show: false },
      axisTick: {
        show: false,
        splitNumber: 4
      }
    },
    series: [
      {
        name: selectedMetric.value,
        type: 'line',
        smooth: true,
        data: metricData,
        showSymbol: false,
        emphasis: { focus: 'series' },
        animation: true,
        lineStyle: {
          width: 2,
          opacity: 0.6
        },
        areaStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: getMetricColor(selectedMetric.value)  // Start color (top)
            },
            {
              offset: 1,
              color: '#FFFFFF',  // End color (bottom)
              opacity: 0.1
            }
          ])
        }
      }
    ]
  }
})

const statistics = computed(() => {
  if (!accountData.value) {
    return [
      { label: 'Followers', value: 0, trend: null, changeText: '' },
      { label: 'Posts', value: 0, trend: null, changeText: '' },
      { label: 'Engagement', value: 0, trend: null, changeText: '' },
      { label: 'Impressions', value: 0, trend: null, changeText: '' }
    ]
  }

  return [
    {
      label: 'Followers',
      value: accountData.value.current_followers || 0,
      trend: accountData.value.followers_change_pct,
      changeText: `${accountData.value.old_followers} in last period`
    },
    {
      label: 'Posts',
      value: accountData.value.current_posts || 0,
      trend: accountData.value.posts_change_pct,
      changeText: `${accountData.value.old_posts} in last period`
    },
    {
      label: 'Engagement',
      value: accountData.value.current_engagement || 0,
      trend: accountData.value.engagement_change_pct,
      changeText: `${accountData.value.old_engagement} in last period`
    },
    {
      label: 'Impressions',
      value: accountData.value.current_impressions || 0,
      trend: accountData.value.impressions_change_pct,
      changeText: `${accountData.value.old_impressions} in last period`
    }
  ]
})

const getTrendColorClass = (trend) => {
  if (trend > 0) return 'text-green-600 bg-green-50'
  if (trend < 0) return 'text-red-600 bg-red-50'
  return 'text-gray-600 bg-gray-50'
}

const getStatIcon = (label) => {
  switch (label.toLowerCase()) {
    case 'followers':
      return AudienceGrowthIcon
    case 'posts':
      return PostingTypeIcon
    case 'engagement':
      return EngagementIcon
    case 'impressions':
      return ImpressionsIcon
    default:
      return AudienceGrowthIcon
  }
}

const getMetricColor = (metric, alpha = 1) => {
  // Using the new color palette
  const colorMapping = {
    'engagement': alpha === 1 ? '#82CBA3' : `rgba(130, 203, 163, ${alpha})`,  // green
    'reach': alpha === 1 ? '#2294FF' : `rgba(34, 148, 255, ${alpha})`,        // blue
    'impressions': alpha === 1 ? '#FFC555' : `rgba(255, 197, 85, ${alpha})`,  // yellow
    'posts': alpha === 1 ? '#9FA6FF' : `rgba(159, 166, 255, ${alpha})`  // purple
  }

  return colorMapping[metric] || (alpha === 1 ? '#82CBA3' : `rgba(130, 203, 163, ${alpha})`)
}

// Watch for changes in the selected metric to update the graph
watch(selectedMetric, () => {
  // The chart will automatically update because chartData is a computed property
  // that depends on selectedMetric
})
</script>

<style scoped>
.rounded-lg {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.bg-green-50 {
  background-color: rgba(240, 253, 244, 1);
}

.bg-red-50 {
  background-color: rgba(254, 242, 242, 1);
}

.text-green-600 {
  color: rgba(22, 163, 74, 1);
}

.text-red-600 {
  color: rgba(220, 38, 38, 1);
}
</style>
