<script setup>
import { computed, watch, ref } from 'vue'

import debounce from 'lodash.debounce'
import CardsComponent from '@src/modules/analytics/views/overviewV2/components/CardsComponent.vue'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import EngagementsGraph from '@src/modules/analytics/views/overviewV2/components/graphs/EngagementsGraph.vue'
import PerformanceComparison from '@src/modules/analytics_v3/components/PerformanceComparison'
import AccountStatisticsWrapper from '@src/modules/analytics_v3/components/AccountStatisticsWrapper.vue'
import PlatformsEngagementBreakdownTable from '@src/modules/analytics/views/overviewV2/components/PlatformsEngagementBreakdownTable.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import ChevronDownIcon from '@/src/assets/img/chevron-down.svg'
import TopPostsComponent from '@src/modules/analytics/views/overviewV2/components/TopPostsComponent.vue'

const {
  routes,
  dateRange,
  isLoadingStates,
  selectedAccount,
  selectedPerformanceType,
  performanceRadio,
  getSelectedPerformanceData,
  selectedComparisonType,
  getAccountStatistics,
  selectedGraphType,
  fetchMedia,
  comparisonGraphTooltipFormatter,
  metricOptions,
  selectedMetric1,
  selectedMetric2,
  platformData,
  platformDataDetailed,
  platformDataGraphs,
  topPostsData
} = useOverviewAnalytics()

watch(
  () => [dateRange, selectedAccount],
  debounce(async () => {
    fetchMedia(routes.SUMMARY)
    fetchMedia(routes.TOP_PERFORMING_GRAPH, { type: selectedGraphType.value })
    fetchMedia(routes.GET_PLATFORM_DATA, { type: 'grouped' })
    fetchMedia(routes.GET_PLATFORM_DATA, { type: 'individual' })
    fetchMedia(routes.GET_PLATFORM_DATA_DETAILED)
    fetchMedia(routes.GET_PLATFORM_DATA_GRAPHS)
    fetchMedia(routes.GET_TOP_POSTS, {type: 'total_engagement'})
  }, 300),
  { deep: true, immediate: true }
)

watch(
  () => [selectedGraphType.value],
  debounce(async () => {
    fetchMedia(routes.TOP_PERFORMING_GRAPH, {type: selectedGraphType.value})
  }, 100),
  { deep: true, immediate: true }
)

const isGrouped = computed(() => {
  return selectedPerformanceType.value?.chartType === performanceRadio[0].chartType
})
</script>

<template>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-4 gap-4 mb-7">
      <CardsComponent />
    </div>
    <div class="grid mb-7">
      <EngagementsGraph
        label="Engagements"
        :default-type="selectedGraphType"
      />
    </div>

    <div class="grid grid-cols-1 gap-4 mb-7">
      <PlatformsEngagementBreakdownTable
        :platform-data="platformData"
        :is-loading="isLoadingStates[routes?.GET_PLATFORM_DATA]"
      />
    </div>

    <div class="grid grid-cols-1 gap-4 mb-7">
      <PerformanceComparison
        :title="'Performance Comparison'"
        :legend="{
          show: true,
          data: getSelectedPerformanceData[selectedComparisonType.chartType]?.legends,
        }"
        :x-axis-label="selectedMetric1.label"
        :y-axis-label="selectedMetric2.label"
        :is-all-data-fetched="false"
        :data="getSelectedPerformanceData[selectedComparisonType.chartType]?.data"
        :is-loading="isLoadingStates[routes?.GET_PLATFORM_DATA]"
        type="performanceComparison"
        :is-local-image="isGrouped"
        :custom-tooltip-formatter="comparisonGraphTooltipFormatter"
        :xy-axis-keys="{ x: 'xAxis', y: 'yAxis' }"
        :allow-data-zoom="false"
      >
      <template v-slot:header-left>
        <div class="flex space-x-4">
          <!-- First dropdown: Comparison Type -->
          <CstDropdown
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            dropdown-classes="!rounded-md !border-[#70707029]"
            button-classes="flex !px-5 !w-[22rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
          >
            <template v-slot:arrow>
              <img :src="ChevronDownIcon" alt="chevron-down" />
            </template>
            <template v-slot:selected>
              <p class="text-[14px] font-weight-500 capitalize">
                {{ selectedPerformanceType.label }}
              </p>
            </template>
            <template v-slot>
              <template
                v-for="(item, index) in performanceRadio"
                :key="`comparison_type_${index}`"
              >
                <CstDropdownItem @click="selectedPerformanceType = item">
                  <p class="text-gray-900 text-sm">{{ item.label }}</p>
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>

          <!-- Second dropdown: First Metric -->
           <CstDropdown
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            dropdown-classes="!rounded-md !border-[#70707029]"
            button-classes="flex !px-5 !w-[12rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
          >
            <template v-slot:arrow>
              <img :src="ChevronDownIcon" alt="chevron-down" />
            </template>
            <template v-slot:selected>
              <p class="text-[14px] font-weight-500 capitalize">
                {{ selectedMetric2.label }}
              </p>
            </template>
            <template v-slot>
              <template
                v-for="(item, index) in metricOptions"
                :key="`metric2_${index}`"
              >
                <CstDropdownItem @click="selectedMetric2 = item">
                  <p class="text-gray-900 text-sm">{{ item.label }}</p>
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>
          <span class="text-[14px] font-weight-500 text-[#4A4A4A] self-center mx-2">Vs</span>
          <!-- Third dropdown: Second Metric -->
          <CstDropdown
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            dropdown-classes="!rounded-md !border-[#70707029]"
            button-classes="flex !px-5 !w-[12rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
          >
            <template v-slot:arrow>
              <img :src="ChevronDownIcon" alt="chevron-down" />
            </template>
            <template v-slot:selected>
              <p class="text-[14px] font-weight-500 capitalize">
                {{ selectedMetric1.label }}
              </p>
            </template>
            <template v-slot>
              <template
                v-for="(item, index) in metricOptions"
                :key="`metric1_${index}`"
              >
                <CstDropdownItem @click="selectedMetric1 = item">
                  <p class="text-gray-900 text-sm">{{ item.label }}</p>
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>
        </div>
      </template>
      </PerformanceComparison>
    </div>
    <div class="grid grid-cols-1 gap-4 mb-7">
      <AccountStatisticsWrapper
        :account-statistics="getAccountStatistics"
        :platform-data-detailed="platformDataDetailed"
        :platform-data-graphs="platformDataGraphs"
        :is-loading-states="isLoadingStates[routes?.GET_PLATFORM_DATA_GRAPHS] || isLoadingStates[routes?.GET_PLATFORM_DATA_DETAILED]"
      />
    </div>

    <div class="grid grid-cols-1 gap-4 mb-7">
      <TopPostsComponent
        :top-posts-data="topPostsData"
        :is-loading="isLoadingStates[routes?.GET_TOP_POSTS]"
      />
    </div>

  </div>
</template>

<style lang="scss">
.account-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    gap: 16px;
}
</style>
