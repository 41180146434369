<template>
  <b-modal
    id="planner-post-preview"
    :no-enforce-focus="true"
    body-class="relative h-5/6"
    content-class="bg-transparent"
    dialog-class="flex fixed mw-100 m-0 top-0 bottom-0 left-0 right-0"
    hide-footer
    hide-header
    @hidden="onModalHidden"
    @hide="unsubscribeAndUnbindPusher"
    @shown="onModalShown"
  >
    <div class="h-100 flex items-center justify-between space-x-5">
      <!-- Left button -->
      <div class="flex-shrink-0 min-w-[4.219rem]">
        <div
          v-if="!disableLeft"
          v-tooltip="'Previous Post'"
          class="
            flex
            items-center
            justify-start
            space-x-2
            text-center text-white
            cursor-pointer
            px-2.5
            py-2
            rounded-xl
          "
          @click="previousPlan"
        >
          <i class="fa fa-chevron-left text-2xl"></i>
          <span class="font-medium text-sm">Previous</span>
        </div>
      </div>

      <!-- Content -->
      <div
        class="
          h-[90vh]
          max-h-[90vh] max-w-[1650px]
          min-w-[80%]
          flex flex-col flex-grow-1
          bg-white
          rounded-xl
        "
      >
        <!--loader-->
        <div
          v-if="planLoader"
          class="min-h-full p-3 flex flex-col items-center justify-center"
        >
          <img
            :src="
              (shouldShowWhiteLabelData && appData.brandLogo) || loadingImage
            "
            :class="{
              'animate-bounce': shouldShowWhiteLabelData && appData?.brandLogo,
            }"
            width="55"
            alt="logo-animation"
          />
          <span class="mt-2.5 text-gray-800">Loading Post Details</span>
        </div>
        <!-- Post  -->
        <template v-else>
          <!-- Header -->
          <div
            class="
              flex
              justify-between
              py-[0.7rem]
              pl-[1.5rem]
              pr-[1rem]
              align_center
            "
          >
            <div class="flex align-items-center">
              <span class="font-semibold text-base">Post Details</span>
              <span
                :class="postStatusBadgeClasses(plan)"
                class="
                  text-sm
                  font-medium
                  mr-2
                  px-3
                  py-[0.4rem]
                  rounded-[6.25rem]
                  ml-3
                  capitalize
                  inline-flex
                  items-center
                  justify-center
                "
              >
                <span
                  :class="postStatusBackgroundClasses"
                  class="w-1 h-1 rounded-full mr-1.5 bg-opacity-95 block"
                ></span>
                {{ planStatus }}
              </span>
            </div>

            <div class="flex align-items-center">
              <span class="block font-weight-500 text-[xs] text-[#202324]">
                <span v-if="!externalPostPreview"
                  >{{
                    getWorkspaceTimeZoneTime(
                      plan.execution_time?.date,
                      getAccountDateTimeFormat,
                    )
                  }}
                  <span class="font-weight-400 text-[#757A8A]"
                    >(Publish Date)</span
                  >
                </span>
                <span v-else>
                  {{
                    $filters.publishedOnlyDateWithWeekdayAndMonth(
                      plan.workspaceTimeZone
                        ? convertToWorkspaceTimeZone(
                            plan.execution_time?.date,
                            plan.workspaceTimeZone,
                          )
                        : getWorkspaceTimeZoneTime(
                            plan.execution_time?.date,
                            'YYYY-MM-DD HH:mm:ss',
                          ),
                    )
                  }}
                  <span class="font-weight-400 text-[#757A8A]"
                    >(Publish Date)</span
                  >
                </span>
              </span>
            </div>

            <div class="flex align-items-center">
              <!--Labels Button-->
              <LabelAttachment
                :external-preview="externalPostPreview"
                :plan-labels="plan?.labels"
                :attached-labels="attachedLabels"
                :plan-id="plan?._id"
                :external-labels="plan?.labels_info"
                @update-attached-labels="UpdateAttachedLabels"
              />

              <!-- Campaigns Button-->
              <CampaignAttachment
                :external-preview="externalPostPreview"
                :external-campaign="plan?.content_category"
                :attached-campaign="getAttachedCampaign"
                :plan-id="plan?._id"
                @update-attached-campaign="UpdateAttachedCampaign"
              />
              <button
                v-if="canEdit"
                class="
                  mr-[0.5rem]
                  text-[#757A8A]
                  align-items-center
                  bg-white
                  inline-flex
                  gap-[0.5rem]
                  text-[0.875rem]
                  leading-4
                  !border
                  border-solid
                  font-weight-400
                  py-[0.67rem]
                  px-[1rem]
                  !border-[#D2D5DF]
                  rounded-[0.3rem]
                "
                @click="editPlan"
              >
                <img
                  :src="require('@assets/img/icons/planner/edit_icon.svg')"
                  alt=""
                  class="h-[1rem] w-[1rem]"
                />
                Edit
              </button>
              <button
                v-if="canDuplicate"
                class="
                  mr-[0.5rem]
                  text-[#757A8A]
                  align-items-center
                  bg-white
                  inline-flex
                  gap-[0.5rem]
                  text-[0.875rem]
                  leading-4
                  !border
                  border-solid
                  font-weight-400
                  py-[0.67rem]
                  px-[1rem]
                  !border-[#D2D5DF]
                  rounded-[0.3rem]
                "
                @click="duplicatePlan"
              >
                <img
                  :src="require('@assets/img/icons/duplicateicon.svg')"
                  alt=""
                  class="h-[1rem] w-[1rem"
                />
                Duplicate
              </button>
              <!--More option -->
              <CstDropdown
                v-if="canEdit || canDuplicate || canDelete"
                :show-arrow="false"
                button-classes="!border-0 !px-0 !py-0"
                container-classes="!w-max"
                size="small"
              >
                <template v-slot:selected>
                  <button
                    class="
                      align-items-center
                      text-[#757A8A]
                      bg-white
                      inline-flex
                      gap-[0.5rem]
                      text-sm
                      !border
                      border-solid
                      font-weight-400
                      py-[0.836rem]
                      px-[1rem]
                      !border-[#D2D5DF]
                      rounded-[0.3rem]
                    "
                  >
                    <img
                      :src="require('@assets/img/planner/more.svg')"
                      alt=""
                      class="h-[90%]"
                    />
                  </button>
                </template>
                <CstDropdownItem
                  v-tooltip="
                    !shareAbleLinkAccess.allowed
                      ? shareAbleLinkAccess.error?.message
                      : ''
                  "
                  class="
                    text-gray-800
                    block
                    cursor-pointer
                    hover:bg-gray-100
                    active:bg-gray-100
                    focus:outline-none
                    focus:ring-1
                    focus:ring-offset-1
                    focus:ring-offset-gray-100
                    focus:ring-gray-500
                    py-0.5
                    text-sm
                  "
                  @click="
                    !shareAbleLinkAccess.allowed
                      ? showUpgradeModal()
                      : sharePlan(plan?._id)
                  "
                >
                  <div class="flex items-center justify-between space-x-2">
                    <div class="flex items-center space-x-2">
                      <div class="flex items-center justify-center w-6 h-6">
                        <i
                          class="
                            far
                            fa-link
                            text-gray-700
                            !bg-transparent
                            !shadow-none
                          "
                        ></i>
                      </div>
                      <div>Share via Link</div>
                    </div>
                    <img
                      v-if="!shareAbleLinkAccess.allowed"
                      src="@assets/img/icons/lock_black.svg"
                      class="w-4 h-4"
                      alt="Locked"
                    />
                  </div>
                </CstDropdownItem>
                <b-dropdown-item
                  v-if="
                    !clientProfile &&
                    plan.publish_time_options &&
                    ((plan.status === 'draft' &&
                      plan.publish_time_options.hide_client) ||
                      (plan.status === 'draft' &&
                        !plan.publish_time_options.hide_client))
                  "
                  class="
                    text-gray-800
                    block
                    cursor-pointer
                    hover:bg-gray-100
                    active:bg-gray-100
                    focus:outline-none
                    focus:ring-1
                    focus:ring-offset-1
                    focus:ring-offset-gray-100
                    focus:ring-gray-500
                    py-0.5
                    text-sm
                  "
                  link-class="px-2"
                >
                  <div
                    v-if="
                      plan.status === 'draft' &&
                      plan.publish_time_options.hide_client
                    "
                    @click.prevent="hideFromClientAction(plan, false)"
                  >
                    <div class="flex items-center space-x-2">
                      <div class="flex items-center justify-center w-6 h-6">
                        <img
                          alt="approval_publish"
                          src="@assets/img/icons/planner/unhide_from_client.svg"
                        />
                      </div>
                      <div>UnHide from clients</div>
                    </div>
                  </div>
                  <div
                    v-else-if="
                      plan.status === 'draft' &&
                      !plan.publish_time_options.hide_client
                    "
                    @click.prevent="hideFromClientAction(plan, true)"
                  >
                    <div class="flex items-center space-x-2">
                      <div class="flex items-center justify-center w-6 h-6">
                        <img
                          alt="approval_publish"
                          src="@assets/img/icons/planner/hide_from_client.svg"
                        />
                      </div>
                      <div>Hide from clients</div>
                    </div>
                  </div>
                </b-dropdown-item>
                <CstDropdownItem
                  v-if="canDelete"
                  class="
                    text-gray-800
                    block
                    cursor-pointer
                    hover:bg-gray-100
                    active:bg-gray-100
                    focus:outline-none
                    focus:ring-1
                    focus:ring-offset-1
                    focus:ring-offset-gray-100
                    focus:ring-gray-500
                    py-0.5
                    text-sm
                  "
                  @click="deletePlan"
                >
                  <div class="flex items-center space-x-2">
                    <div class="flex items-center justify-center w-6 h-6">
                      <i
                        class="far fa-trash-alt fa-lg text-red-500 !shadow-none"
                      ></i>
                    </div>
                    <div class="text-red-500">Delete Post</div>
                  </div>
                </CstDropdownItem>
              </CstDropdown>

              <div
                v-if="!externalPostPreview"
                class="ml-[1rem] h-[25px] w-px bg-[#D2D5DF]"
              ></div>

              <div
                v-tooltip="'Close'"
                class="p-2.5 cursor-pointer"
                @click="$bvModal.hide('planner-post-preview')"
              >
                <img
                  alt="close icon"
                  class="h-[0.88rem] w-[0.88rem]"
                  src="@assets/img/icons/close_cross.svg"
                />
              </div>
            </div>
          </div>
          <hr class="m-0 hr-color" />
          <!-- Main Content -->
          <div
            class="
              flex-grow
              bg-white
              flex
              justify-between
              rounded-xl
              h-[calc(100%-60.5px-77px)]
            "
          >
            <LeftSIdePostStatus
              :active-account="accountInPreview"
              :external-post-preview="externalPostPreview"
              :plan="plan"
              class="bottom-left-border"
              @select-social-account="selectSocialAccount"
            />
            <!-- Post Details -->
            <div class="flex flex-col w-[44.25%] h-full">
              <div class="px-6 py-[1.1rem] bg-[#F4F6FA]">
                <div class="flex items-center relative">
                  <span class="ml-1 text-sm font-semibold mr-[0.4rem]">
                    Post Preview
                  </span>
                  <span class="text-xs text-[#757A8A]"
                    >(Not an exact depiction, there will be some minor
                    difference)</span
                  >
                  <div v-if="isDeleted">
                    <span
                      class="
                        text-[#B52D4A]
                        absolute
                        right-0
                        top-0
                        rounded-[0.3rem]
                        font-medium
                        text-sm
                      "
                      >Deleted</span
                    >
                  </div>
                  <div
                    v-else-if="
                      plan.post_state === 'published' ||
                      plan.post_state === 'failed'
                    "
                    class="flex flex-grow justify-end"
                  >
                    <img
                      :src="getStatusImgUrl()"
                      alt="status img"
                      class="w-[1.75rem] h-[1.5625]"
                    />
                  </div>
                </div>
              </div>

              <!--            <hr class="m-0 hr-color" />-->

              <!-- Post Preview -->
              <div
                v-if="hasPlan && isSocialPost"
                :class="[
                  ((showInstagramPreview ||
                    showThreadsPreview ||
                    showFacebookPreview ||
                    showBlueskyPreview) &&
                    'flex justify-center') ||
                    (showFacebookPreview && '!p-2 no-scrollbar'),
                ]"
                class="p-3 overflow-y-auto h-full overflow-x-hidden"
              >
                <template v-if="!hasAccountSelection">
                  <div class="flex flex-col items-center justify-center">
                    <img
                      alt=""
                      class="mt-4 w-52 opacity-70"
                      src="@assets/img/composer/no-preview.png"
                    />
                    <span class="mt-6 block text-md text-gray-800 leading-5">
                      At least one account should be selected for post preview.
                    </span>
                  </div>
                </template>
                <template v-else>
                  <template v-if="showFacebookPreview">
                    <FacebookPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :carousel="
                        plan.carousel_options ? plan.carousel_options : null
                      "
                      :carousel-account="findFacebookCarouselAccount(plan)"
                      :comment="
                        plan?.first_comment_accounts?.includes(
                          accountInPreview?.facebook_id,
                        ) && plan?.has_first_comment
                          ? plan?.first_comment_message
                          : ''
                      "
                      :comment-details="
                        plan?.posting?.find(
                          (item) =>
                            item.platform_id === accountInPreview.facebook_id,
                        )
                      "
                      :common-box-status="plan.common_box_status"
                      :detail="getFeedSharingDetails"
                      :execution-time="plan.execution_time"
                      :external-preview="externalPostPreview"
                      :facebook-background-id="
                        plan?.facebook_options?.facebook_background_id ?? ''
                      "
                      :facebook-share-to-story="
                        plan?.facebook_options?.facebook_share_to_story ?? false
                      "
                      :facebook-post-type="findFacebookPostType"
                      :publish-as="getFacebookPublishAs"
                      type="planner"
                      :toggle-preview="true"
                      custom-class="w-[29.25rem]"
                      :locations="plan?.locations"
                    />
                  </template>
                  <template v-else-if="showInstagramPreview">
                    <InstagramPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :comment="
                        plan?.first_comment_accounts?.includes(
                          accountInPreview?.instagram_id,
                        ) && plan?.has_first_comment
                          ? plan?.first_comment_message
                          : ''
                      "
                      :comment-details="
                        plan?.posting?.find(
                          (item) =>
                            item.platform_id === accountInPreview.instagram_id,
                        )
                      "
                      :common-box-status="plan.common_box_status"
                      :detail="getFeedSharingDetails"
                      :hide-footer="true"
                      :instagram-collaborators="
                        plan?.instagram_collaborators ?? []
                      "
                      :instagram-post-type="plan.instagram_post_type"
                      :instagram-share-to-story="
                        plan?.instagram_share_to_story ?? false
                      "
                      :toggle-preview="true"
                      :user-tags="getFeedSharingDetails.image_tagging_details"
                      :locations="plan?.locations"
                      custom-class="w-[29.25rem]"
                      type="planner"
                    />
                  </template>
                  <template v-else-if="showThreadsPreview">
                    <ThreadsPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :detail="getFeedSharingDetails"
                      :hide-footer="true"
                      :multi-threads="getMultiThreads"
                      :multi-threads-details="
                        plan?.posting?.find(
                          (item) =>
                            item.platform_id ===
                            accountInPreview.platform_identifier,
                        )?.multi_threads
                      "
                      custom-class="w-[29.25rem]"
                    />
                  </template>
                  <template v-else-if="showTwitterPreview">
                    <TwitterPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :common-box-status="plan.common_box_status"
                      :detail="getFeedSharingDetails"
                      :execution-time="plan.execution_time"
                      :thread="fetchTwitterThreads"
                      :threaded-tweet-details="
                        plan?.posting?.find(
                          (item) =>
                            item.platform_id === accountInPreview.twitter_id,
                        )?.threaded_tweets
                      "
                      :user-tags="getFeedSharingDetails.image_tagging_details"
                    />
                  </template>
                  <template v-else-if="showLinkedinPreview">
                    <LinkedinPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :comment="
                        plan?.first_comment_accounts?.includes(
                          accountInPreview?.linkedin_id,
                        ) && plan?.has_first_comment
                          ? plan?.first_comment_message
                          : ''
                      "
                      :comment-details="
                        plan?.posting?.find(
                          (item) =>
                            item.platform_id === accountInPreview.linkedin_id,
                        )
                      "
                      :detail="getFeedSharingDetails"
                      :execution-time="plan.execution_time"
                      :linkedin-options="plan?.linkedin_options"
                    />
                  </template>
                  <template v-else-if="showPinterestPreview">
                    <PinterestPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :detail="getFeedSharingDetails"
                      :followers-count="180"
                      source-url=""
                    />
                  </template>
                  <template v-else-if="showTumblrPreview">
                    <TumblrPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :detail="getFeedSharingDetails"
                    />
                  </template>
                  <template v-else-if="showInstagramPreview">
                    <InstagramPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :comment="
                        plan?.first_comment_accounts?.includes(
                          accountInPreview?.instagram_id,
                        ) && plan?.has_first_comment
                          ? plan?.first_comment_message
                          : ''
                      "
                      :comment-details="
                        plan?.posting?.find(
                          (item) =>
                            item.platform_id === accountInPreview.instagram_id,
                        )
                      "
                      :common-box-status="plan.common_box_status"
                      :detail="getFeedSharingDetails"
                      :hide-footer="true"
                      :instagram-collaborators="
                        plan?.instagram_collaborators ?? []
                      "
                      :instagram-post-type="plan.instagram_post_type"
                      :instagram-share-to-story="
                        plan?.instagram_share_to_story ?? false
                      "
                      :toggle-preview="true"
                      :user-tags="getFeedSharingDetails.image_tagging_details"
                      custom-class="min-w-[29.25rem]"
                      type="planner"
                    />
                  </template>
                  <template v-else-if="showYoutubePreview">
                    <YoutubePreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :comment="
                        plan?.first_comment_accounts?.includes(
                          accountInPreview.platform_identifier,
                        ) && plan?.has_first_comment
                          ? plan?.first_comment_message
                          : ''
                      "
                      :comment-details="
                        plan?.posting?.find(
                          (item) =>
                            item.platform_id ===
                            accountInPreview.platform_identifier,
                        )
                      "
                      :detail="getFeedSharingDetails"
                      :video-title="plan.youtube_options?.title"
                      :youtube-post-type="plan.youtube_options?.post_type"
                      type="planner"
                    />
                  </template>
                  <template v-else-if="showTiktokPreview">
                    <TikTokPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :detail="getFeedSharingDetails"
                      :tiktok-options="plan.tiktok_options"
                      type="planner"
                    />
                  </template>
                  <template v-else-if="showGmbPreview">
                    <GmbPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :cta="getGMBOptions('cta')"
                      :detail="getFeedSharingDetails"
                      :event-date="
                        getDateTimeFormat(
                          getGMBOptions('start_date'),
                          'MMM DD',
                        ) +
                        ' - ' +
                        getDateTimeFormat(getGMBOptions('end_date'), 'MMM DD')
                      "
                      :event-title="getGMBOptions('title')"
                      :execution-time="plan.execution_time"
                    />
                  </template>
                  <template v-if="showBlueskyPreview">
                    <BlueskyPreview
                      :account="
                        getAccount(selectedChannelType, accountInPreview, store)
                      "
                      :common-box-status="plan.common_box_status"
                      :detail="getFeedSharingDetails"
                      :execution-time="plan.execution_time"
                      :thread="fetchBlueskyTweets"
                      :threaded-tweet-details="
                        plan?.posting?.find(
                          (item) =>
                            item.platform_id ===
                            accountInPreview.platform_identifier,
                        )?.multi_bluesky
                      "
                      custom-class="w-[29.25rem]"
                    />
                  </template>
                </template>
              </div>
              <div v-if="hasPlan && isBlogPost" class="p-3 overflow-y-auto">
                <div
                  class="
                    flex flex-col
                    space-y-4
                    bg-white
                    cst-drop-shadow
                    overflow-auto
                    p-3
                    rounded-xl
                    w-full
                  "
                >
                  <div class="flex flex-col space-y-3">
                    <img
                      v-if="plan.post.image.link"
                      :src="plan.post.image.link"
                      alt=""
                      class="max-w-full"
                      @error="
                        (event) => {
                          event.target.src = `https://storage.googleapis.com/lumotive-web-storage/no-image-available-small.png`
                        }
                      "
                    />
                    <span
                      v-if="plan.post.title"
                      class="text-xl px-3"
                      v-html="plan.post.title"
                    ></span>
                    <span
                      v-if="plan.post.description"
                      class="px-3"
                      v-html="plan.post.description"
                    ></span>
                  </div>
                </div>
              </div>

              <template v-if="!hasAccountSelection && (!isSocialPost && !isBlogPost)">
                  <div class="flex flex-col items-center justify-center">
                    <img
                      alt=""
                      class="mt-4 w-52 opacity-70"
                      src="@assets/img/composer/no-preview.png"
                    />
                    <span class="mt-6 block text-md text-gray-800 leading-5">
                      At least one account should be selected for post preview.
                    </span>
                  </div>
                </template>
            </div>
            <!-- Comments/Internal Notes -->
            <div
              class="comments-list flex flex-col w-[27.87%] bg-cs-light-gray"
            >
              <div
                v-if="externalPostPreview"
                class="overflow-y-auto py-3 px-2 h-full"
              >
                <CommentsAndNotes
                  v-if="segValue === 'comments'"
                  :plan="plan"
                  :token="token"
                  comment-type="external"
                />
              </div>
              <div v-else class="h-full flex flex-col">
                <!-- Comments -->
                <div
                  class="
                    flex
                    items-center
                    h-14
                    p-2
                    m-4
                    bg-gray-100
                    rounded-lg
                    relative
                  "
                >
                <img src="@src/assets/img/common/new_tag.svg" alt="Icon" class="absolute -top-2 -right-2" />
                  <span
                    class="
                      flex-1 flex
                      justify-center
                      items-center
                      h-full
                      cursor-pointer
                      relative
                      rounded-md
                      transition-colors
                      duration-200
                    "
                    :class="{
                      'bg-white shadow-sm segmented-control text-gray-900':
                        segValue === 'comments',
                      'text-gray-900 hover:segmented-control':
                        segValue !== 'comments',
                    }"
                    @click="
                      fetchPlanPostAnalytics(accountInPreview);
                      segValue = 'comments';
                    "
                  >
                    <div class="flex items-center gap-2">
                      <img
                        :src="PostDiscussionIcon"
                        class="w-5 h-5"
                        alt="Post Discussion Icon"
                      />
                      Post Discussion
                    </div>
                  </span>

                  <span
                    class="
                      flex-1 flex
                      justify-center
                      items-center
                      h-full
                      cursor-pointer
                      relative
                      rounded-md
                      transition-colors
                      duration-200
                    "
                    :class="{
                      'bg-white shadow-sm segmented-control text-gray-900':
                        segValue === 'stats',
                      'text-gray-900 hover:segmented-control':
                        segValue !== 'stats',
                    }"
                    @click="
                      fetchPlanPostAnalytics(accountInPreview);
                    segValue = 'stats';
                    "
                  >
                    <div class="flex items-center gap-1">
                      <img
                        :src="PostAnalyticsIcon"
                        class="w-5 h-5"
                        alt="Post Analytics Icon"
                      />
                      Post Analytics
                      <i
                        v-tooltip="'Analytics update every 24 hours. Click to view more'"
                        class="far fa-question-circle p-0 cursor-pointer"
                        @click="openDocs"
                      ></i>
                    </div>
                  </span>
                </div>
                <CommentsAndNotes
                  v-if="segValue === 'comments'"
                  :is-client="clientProfile"
                  :plan="plan"
                />
                <div v-if="segValue === 'stats'">
                  <div
                    class="
                      overflow-y-auto
                      flex-1 flex flex-col
                      items-center
                      px-4
                      bg-white
                      max-h-[45vh]
                    "
                  >
                    <template
                      v-if="
                        postAnalytics && Object.keys(postAnalytics).length > 0
                      "
                    >
                      <div
                        v-for="(value, key) in analyticsMetrics"
                        :key="key"
                        class="
                          flex
                          justify-between
                          py-5
                          px-3
                          bottom-border
                          w-11/12
                        "
                      >
                        <span
                          v-tooltip="getAnalyticsTooltip(key)"
                          class="text-gray-900"
                        >
                          <img
                            :src="getAnalyticsIcons(key)"
                            class="w-5 h-5 mr-2"
                            alt="Post Analytics Icon"
                          />
                          {{ formatKey(key) }}
                        </span>
                        <span class="text-lg font-bold text-gray-900">{{
                          value
                        }}</span>
                      </div>
                    </template>
                    <template
                      v-else-if="
                        postAnalytics && Object.keys(postAnalytics).length === 0
                      "
                    >
                      <div class="py-3 px-3 text-gray-800">
                        {{ errorMessage }}
                      </div>
                    </template>
                    <template v-else>
                      <ClipLoader />
                    </template>
                  </div>
                  <div
                    v-if="
                      !(
                        showThreadsPreview ||
                        showTwitterPreview ||
                        showTumblrPreview ||
                        showGmbPreview ||
                        showBlueskyPreview ||
                        getAccountName(accountInPreview) === '' ||
                        (selectedChannelType === 'facebook' &&
                        accountInPreview.type === 'Group') ||
                        (selectedChannelType === 'linkedin' &&
                        accountInPreview.type === 'Profile')
                      )
                    "
                    v-tooltip="
                      'You will be redirected to the analytics section for this account'
                    "
                    class="
                      bg-white
                      border border-gray-200
                      m-4
                      rounded-lg
                      shadow-sm
                      hover:shadow-md
                      transition-all
                      duration-300
                      cursor-pointer
                      w-11/12
                    "
                    @click="navigateToPageAnalytics"
                  >
                    <div class="flex justify-between items-center py-4 px-4">
                      <span class="text-gray-900 text-sm">
                        View
                        <span class="font-bold">{{
                          getAccountName(accountInPreview)
                        }}</span>
                        stats.
                      </span>
                      <span class="text-gray-900">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>

                <!-- Approval Status -->
                <template v-if="segValue === 'comments' && (showApprovalStatus || showExternalActions)">
                  <PlanApprovalStatus
                    :external-actions="plan.external_actions"
                    :plan="plan"
                  />
                </template>
              </div>
            </div>
          </div>
          <!-- Approve and reject footer -->
          <div v-if="hasPlan && (isSocialPost || isBlogPost)">
            <div
              v-if="automationApproval && !externalPostPreview"
              class="p-4 bg-[#ECEEF5] flex justify-center gap-3 rounded-b-lg"
            >
              <button
                class="
                  bg-[#B52D4A]
                  py-3
                  px-5
                  text-base
                  !border
                  font-weight-500
                  leading-4
                  text-white
                  rounded-lg
                "
                @click="automationApprovalAction('rejected')"
              >
                <span class="inline-block w-[6.25rem]">Reject</span>
              </button>
              <button
                class="
                  bg-[#4EB17C]
                  py-3
                  px-5
                  text-base
                  !border
                  font-weight-500
                  leading-4
                  text-white
                  rounded-lg
                "
                @click="automationApprovalAction('scheduled')"
              >
                <span class="inline-block w-[6.25rem]">Approve</span>
              </button>
            </div>
            <div
              v-else-if="
                canApprove ||
                (externalPostPreview &&
                  (plan.post_state === 'scheduled' ||
                    plan.post_state === 'draft' ||
                    plan.post_state === 'reviewed' ||
                    plan.post_state === 'missedReview'))
              "
              class="p-4 bg-[#ECEEF5] flex justify-center gap-3 rounded-b-lg"
            >
              <button
                class="
                  bg-[#B52D4A]
                  py-3
                  px-5
                  text-base
                  !border
                  font-weight-500
                  leading-4
                  text-white
                  rounded-lg
                "
                @click="rejectWithComment"
              >
                <span class="inline-block w-[3.125rem]">Reject</span>
              </button>
              <button
                class="
                  bg-[#4EB17C]
                  py-3
                  px-5
                  text-base
                  !border
                  font-weight-500
                  leading-4
                  text-white
                  rounded-lg
                "
                @click="approveWithComment"
              >
                <span class="inline-block w-[4.3125rem]">Approve</span>
              </button>
            </div>
          </div>
        </template>
      </div>
      <!-- Right button -->
      <div class="flex-shrink-0">
        <div
          v-if="!disableRight"
          v-tooltip="'Next Post'"
          class="
            flex
            items-center
            justify-end
            space-x-2
            text-center text-white
            cursor-pointer
            px-2.5
            py-2
            rounded-xl
          "
          @click="nextPlan"
        >
          <span class="font-medium text-sm">Next</span>
          <i class="fa fa-chevron-right text-2xl"></i>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  defineComponent,
  computed,
  nextTick,
  inject,
  watch,
  onMounted,
} from 'vue'
import { useStore } from '@state/base'
import Helpers from '@src/modules/planner_v2/mixins/Helpers'
import FacebookPreview from '@src/modules/composer_v2/components/SocialPreviews/FacebookPreview'
import TwitterPreview from '@src/modules/composer_v2/components/SocialPreviews/TwitterPreview'
import InstagramPreview from '@src/modules/composer_v2/components/SocialPreviews/InstagramPreview'
import YoutubePreview from '@src/modules/composer_v2/components/SocialPreviews/YoutubePreview'
import LinkedinPreview from '@src/modules/composer_v2/components/SocialPreviews/LinkedinPreview'
import PinterestPreview from '@src/modules/composer_v2/components/SocialPreviews/PinterestPreview'
import TumblrPreview from '@src/modules/composer_v2/components/SocialPreviews/TumblrPreview'
import GmbPreview from '@src/modules/composer_v2/components/SocialPreviews/GmbPreview'
import TikTokPreview from '@src/modules/composer_v2/components/SocialPreviews/TikTokPreview'
import ThreadsPreview from '@src/modules/composer_v2/components/SocialPreviews/ThreadsPreview'
import { commonMethods } from '@common/store/common-methods'
import CommentsAndNotes from '@src/modules/planner_v2/components/CommentsAndNotes'
import { getAccount } from '@common/composables/useSocialChannels'
import useApproval from '@common/composables/useApproval'
import isEmpty from 'lodash/isEmpty'
import PlanApprovalStatus from '@src/modules/planner_v2/components/PlanApprovalStatus'
import { EventBus } from '@common/lib/event-bus'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import { mapGetters } from 'vuex'
import LeftSIdePostStatus from '@src/modules/planner_v2/components/LeftSIdePostStatus'
import { getPlatformIdentifierValue } from '@common/lib/integrations'
import usePlannerHelper from '@src/modules/planner_v2/composables/usePlannerHelper'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
import useDateFormat from '@common/composables/useDateFormat'
import { convertToWorkspaceTimeZone } from '@common/lib/helper'
import LabelAttachment from '@src/components/common/LabelAttachment.vue'
import CampaignAttachment from '@src/components/common/CampaignAttachment.vue'
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'
import { getAccountName } from '@src/modules/common/composables/useSocialChannels.js'
import { socketMixin } from '@common/mixins/watchers/socketMixin'
import { listener } from '@common/mixins/watchers/listener'
import BlueskyPreview from '@modules/composer_v2/components/SocialPreviews/BlueskyPreview.vue'
import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'
import PostDiscussionIcon from '@src/assets/img/icons/analytic/colored/outlined/post-discussion-outline-icon.svg'
import PostAnalyticsIcon from '@src/assets/img/icons/analytic/colored/outlined/post-analysis-outlined-icon.svg'
import usePostPreview from '@src/modules/planner_v2/composables/usePostPreview.js'
import ClipLoader from '@src/modules/common/components/spinner/ClipLoader'
import usePlannerActions from '@/src/modules/planner_v2/composables/usePlannerActions'

export default defineComponent({
  components: {
    ClipLoader,
    BlueskyPreview,
    LeftSIdePostStatus,
    CstDropdownItem,
    CstDropdown,
    PlanApprovalStatus,
    CommentsAndNotes,
    FacebookPreview,
    TwitterPreview,
    InstagramPreview,
    YoutubePreview,
    LinkedinPreview,
    PinterestPreview,
    TumblrPreview,
    GmbPreview,
    ThreadsPreview,
    TikTokPreview,
    LabelAttachment,
    CampaignAttachment,
  },
  mixins: [Helpers, socketMixin, listener],
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    disableLeft: {
      type: Boolean,
      default: false,
    },
    disableRight: {
      type: Boolean,
      default: false,
    },
    externalPostPreview: {
      type: Boolean,
      default: false,
    },
    token: {
      type: String,
      default: '',
    },
    planLoader: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'next-plan',
    'previous-plan',
    'close-preview',
    'approve-with-comment',
    'edit-plan',
    'duplicate-plan',
    'delete-plan',
    'reject-with-comment',
  ], // can't be used in setup() but it can be used in Vue.js templates
  setup(props, { emit }) {
    const { getAccountDateTimeFormat } = useDateFormat()

    const { checkApprovalStatus } = useApproval()
    const { renderClass, fetchPostAnalytics, postAnalytics, postStatusBadgeClasses } = usePlannerHelper()
    const { getAccountId, getAccountIdKey } = useAnalyticsUtils()
    const { openDraftComposer, draftPlanId } = useComposerHelper()
    const { appData, shouldShowWhiteLabelData } = useWhiteLabelApplication()

    const {
      loadingImage,
      selectedChannelType,
      accountInPreview,
      segValue,
      previewedPost,
      errorMessage,
      comment,
      loader,
      storySelected,
      sharePlan,
      showUpgradeModal,
      findFacebookAccount,
      findFacebookCarouselAccount,
      shareAbleLinkAccess,
      analyticsMetrics,
      formatKey,
      getAnalyticsTooltip,
      getAnalyticsIcons,
      navigateToPageAnalytics,
    } = usePostPreview()

    const store = {
      getters: useStore().getters,
      dispatch: useStore().dispatch,
    }
    const root = inject('root')

    const { hideFromClientAction, clientProfile } = usePlannerActions(
      props.plan
    )

    // watch for plan _id change
    watch(
      () => props.plan._id,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          handleAccountSelection()
          // Subscribe to new plan socket
          subscribeAndBindPusher()
        }
      }
    )

    onMounted(() => {
      EventBus.$on('is-story-selected', (value) => {
        storySelected.value = value
      })
    })

    // this function gets status image url based on account posting status
    const getStatusImgUrl = () => {
      const platformId = getPlatformIdentifierValue(
        accountInPreview.value,
        selectedChannelType.value
      )
      let postedObj = props.plan?.posting?.find(
        (item) => item.platform_id === platformId
      )
      if (isBlogPost.value) {
        postedObj = props.plan?.posting[0]
        if (postedObj?.error) {
          return require('@assets/img/icons/planner/failed_icon.svg')
        } else {
          return require('@assets/img/icons/planner/published_icon.svg')
        }
      }
      if (!postedObj) {
        return require('@assets/img/icons/planner/failed_icon.svg')
      } else if (postedObj?.first_comment?.status === false) {
        return require('@assets/img/icons/planner/partially_failed_icon.svg')
      } else if (postedObj?.error) {
        return require('@assets/img/icons/planner/failed_icon.svg')
      } else if (
        postedObj.device_notification === false &&
        postedObj.notification_processed === false
      ) {
        return require('@assets/img/icons/planner/failed_icon.svg')
      } else if (
        postedObj.device_notification === false &&
        postedObj.notification_processed === false
      ) {
        return require('@assets/img/icons/planner/failed_icon.svg')
      } else {
        return require('@assets/img/icons/planner/published_icon.svg')
      }
    }

    const fetchPlanPostAnalytics = (accountInPreview) => {
      // Define the unsupported platforms mapping
      const unsupportedPlatforms = {
        threads:
          'Post analytics for Threads are not yet available. We’re evaluating support for this platform.',
        twitter:
          'Post analytics for Twitter are currently unavailable due to API limitations.',
        tumblr:
          'Post analytics for Tumblr are not yet available. We’re evaluating support for this platform.',
        gmb: 'Post analytics for Google My Business are not yet available. We’re evaluating support for this platform.',
        bluesky:
          'Post analytics for Bluesky are not yet available. We’re evaluating support for this platform.',
      }

      const unsupported =
        unsupportedPlatforms[selectedChannelType.value] ?? false
      if (unsupported) {
        errorMessage.value = unsupported
        postAnalytics.value = []
        return
      }
      if (notShowAnalytics.value) {
        errorMessage.value =
          'Analytics for this post are not available due to platform limitations'
        postAnalytics.value = []
        return
      }

      previewedPost.value = props.plan?.posting.find((item) => {
        return (
          item.platform_id?.toString() ===
          accountInPreview[getAccountIdKey(selectedChannelType.value)].toString()
        )
      })

      if (
        previewedPost.value?.error ||
        props?.plan?.post_state !== 'published'
      ) {
        errorMessage.value =
          'Analytics will be available 24 hours after the post is published. Data is synced every 24 hours.'
        postAnalytics.value = []
        return
      }
      fetchPostAnalytics(
        previewedPost.value?.posted_id,
        selectedChannelType.value
      )
      if (!postAnalytics.value || postAnalytics.value.length === 0) {
        if (previewedPost.value?.deleted) {
          errorMessage.value = 'Analytics are not available for deleted posts.'
          postAnalytics.value = []
          return
        }
        errorMessage.value =
          'Analytics will be available 24 hours after the post is published. Data is synced every 24 hours.'
        postAnalytics.value = []
      }
    }

    const openDocs = () => {
        window.open('https://docs.contentstudio.io/article/1086-how-to-view-posts-analytics-in-planner', '_blank')
      }

    const notShowAnalytics = computed(() => {
      return (
        !isSocialPost.value ||
        (selectedChannelType.value === 'facebook' &&
        accountInPreview.value.type === 'Group') ||
        (selectedChannelType.value === 'linkedin' &&
        accountInPreview.value.type === 'Profile') ||
        (storySelected.value || props?.plan?.instagram_post_type === 'story') ||
        props?.plan?.tiktok_options?.privacy_level === 'SELF_ONLY' ||
        props?.plan?.youtube_options?.privacy_status === 'private'
      )
    })

    // Get Attached labels
    const attachedLabels = computed(() => {
      // return the ids of the labels attached to the plan
      const labels = store.getters.getLabels?.filter((label) => {
        return props.plan?.labels?.includes(label._id)
      })

      return labels.map((label) => label._id)
    })

    // Get Attached Campaign
    const getAttachedCampaign = computed(() => {
      return props.plan?.folderId
    })

    const isDeleted = computed(() => {
      const platformId = getPlatformIdentifierValue(
        accountInPreview.value,
        selectedChannelType.value
      )
      const postedObj = props.plan?.posting?.find(
        (item) => item.platform_id === platformId
      )
      return !!postedObj?.deleted
    })

    const postStatusBackgroundClasses = computed(() => {
      // get the post status
      const status = props.plan.status

      if (props.plan.partially_failed) {
        return 'bg-[#B52D4A]'
      }

      if (props.plan.post_state === 'reviewed') {
        return 'bg-[#5FB6F9]'
      }

      // create the classes
      switch (status) {
        case 'processing':
          return 'bg-[#9299F8]'
        case 'scheduled':
          return 'bg-[#F0BB52]'
        case 'published':
          return 'bg-[#5EBC7E]'
        case 'partially_failed':
          return 'bg-[#B52D4A]'
        case 'failed':
          return 'bg-[#EB554D]'
        case 'rejected':
          return 'bg-[#EB516B]'
        case 'review':
          if (props.plan.render_class?.tooltip === 'In review') {
            return 'bg-[#5FB6F9]'
          } else {
            return 'bg-[#081F4B]'
          }
        case 'draft':
          return 'bg-[#76797C]'
        case 'missed_review':
          return 'bg-[#081F4B]'
        default:
          return 'bg-[#9299F8]'
      }
    })

    /**
     * Computes and checks if facebook preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showFacebookPreview = computed(() => {
      return selectedChannelType.value === 'facebook'
    })

    /**
     * Computes and checks if linkedin preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showLinkedinPreview = computed(() => {
      return selectedChannelType.value === 'linkedin'
    })

    /**
     * Computes and checks if twitter preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showTwitterPreview = computed(() => {
      return selectedChannelType.value === 'twitter'
    })

    /**
     * Computes and checks if pinterest preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showPinterestPreview = computed(() => {
      return selectedChannelType.value === 'pinterest'
    })

    /**
     * Computes and checks if tumblr preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showTumblrPreview = computed(() => {
      return selectedChannelType.value === 'tumblr'
    })

    /**
     * Computes and checks if instagram preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showInstagramPreview = computed(() => {
      return selectedChannelType.value === 'instagram'
    })

    /**
     * Computes and checks if youtube preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showYoutubePreview = computed(() => {
      return selectedChannelType.value === 'youtube'
    })

    /**
     * Computes and checks if tiktok preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showTiktokPreview = computed(() => {
      return selectedChannelType.value === 'tiktok'
    })

    /**
     * Computes and checks if gmb preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showGmbPreview = computed(() => {
      return selectedChannelType.value === 'gmb'
    })

    /**
     * Computes and checks if threads preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showThreadsPreview = computed(() => {
      return selectedChannelType.value === 'threads'
    })

    /**
     * Computes and checks if bluesky preview should be shown
     * @type {ComputedRef<boolean>}
     */
    const showBlueskyPreview = computed(() => {
      return selectedChannelType.value === 'bluesky'
    })

    /**
     * Fetches the sharing details of active social channel feed
     * @type {ComputedRef<*>}
     */
    const getFeedSharingDetails = computed(() => {
      return props.plan?.common_box_status
        ? props.plan?.common_sharing_details
        : props.plan[`${selectedChannelType.value}_sharing_details`]
    })

    /**
     * If facebook preview is active and group channel is selected, returns the profile/account used to publish as
     * @type {ComputedRef<unknown>}
     */
    const getFacebookPublishAs = computed(() => {
      if (props.plan?.feed_preview?.account?.type === 'Group') {
        return commonMethods.getFBGroupPostedAsAccount(
          findFacebookAccount(
            getAccount(
              props.plan?.feed_preview?.channel,
              props.plan?.feed_preview?.account,
              store
            )?.facebook_id
          ),
          props.plan?.facebook_options?.posted_as
            ? props.plan?.facebook_options?.posted_as
            : false
        )
      }
      return null
    })

    /**
     * if threads has reply then will compute the threads media
     * @type {ComputedRef<[{image: [], image_suggestions: [], multimedia: [], website: string, description: string, video: {duration: string, thumbnails_suggestions: [], converted_link: string, thumbnail: string, size: number, converted_size: number, mime_type: string, converted_mime_type: string, name: string, link: string, converted_video: string, title: string}, message: string, title: string, url: string}]|*[]>}
     */
    const getMultiThreads = computed(() => {
      return props.plan?.threads_options?.has_multi_threads
        ? props.plan?.threads_options.multi_threads
        : []
    })

    /**
     * Checks if the user has the permission/access to approve plan
     * @type {ComputedRef<unknown>}
     */
    const canApprove = computed(() => {
      return (
        props.plan.approval?.status === 'pending_approval' &&
        checkApprovalStatus(props.plan?.approval, false, store)
      )
    })

    /**
     * Checks if the user has the permission/access to delete plan
     * @type {ComputedRef<unknown>}
     */
    const canDelete = computed(() => {
      return props.plan.can_perform?.delete && !planInProgress.value
    })

    /**
     * Checks if the user has the permission/access to duplicate plan
     * @type {ComputedRef<*>}
     */
    const canDuplicate = computed(() => {
      return props.plan.can_perform?.duplicate
    })

    /**
     * Checks if the user has the permission/access to edit plan
     * @type {ComputedRef<unknown>}
     */
    const canEdit = computed(() => {
      return root.canEditPost(props.plan) && !planInProgress.value
    })

    /**
     * Checks if the user has the permission/access to replace plan
     * @type {ComputedRef<*>}
     */
    const canReplace = computed(() => {
      return props.plan.can_perform?.replace
    })

    /**
     * Verifies if the props has an active plan
     * @type {ComputedRef<boolean>}
     */
    const hasPlan = computed(() => {
      return !isEmpty(props.plan)
    })

    /**
     * Checks if the plan status is currently in progress.
     * @type {ComputedRef<unknown>}
     */
    const planInProgress = computed(() => {
      return (
        props.plan.status === 'queued' || props.plan.status === 'processing'
      )
    })

    /**
     * Checks if the plan has any active account selection
     * @type {ComputedRef<unknown>}
     */
    const hasAccountSelection = computed(() => {
      let hasAccSelection = false
      if (!hasPlan.value) return false
      if (isSocialPost.value) {
        const accountsArr = Object.values(props.plan?.account_selection)
        for (let i = 0; i < accountsArr.length; i++) {
          if (accountsArr[i].length > 0) {
            hasAccSelection = true
            return hasAccSelection
          }
        }
      } else if (isBlogPost.value) {
        const accountsArr = Object.values(props.plan?.blog_selection)
        for (let i = 0; i < accountsArr.length; i++) {
          if (accountsArr[i].website) {
            hasAccSelection = true
            return hasAccSelection
          }
        }
      }

      return hasAccSelection
    })

    /**
     * Returns true/false based on the approval status if exists in this.item.
     * @returns {[]|*|boolean}
     */
    const showApprovalStatus = computed(() => {
      return props.plan?.approval?.approvers?.length > 0
    })

    /**
     * Returns boolean value true if the plan is of social type
     * @type {ComputedRef<boolean>}
     */
    const isSocialPost = computed(() => {
      if (!('account_selection' in props.plan)) {
        return false
      }

      const accountSelection = props.plan.account_selection

      return Object.keys(accountSelection).some(
        (key) =>
          Array.isArray(accountSelection[key]) &&
          accountSelection[key].length > 0
      )
    })

    /**
     * eturns boolean value false if the plan is of blog type.
     * @type {ComputedRef<boolean>}
     */
    const isBlogPost = computed(() => {
      return 'blog_selection' in props.plan
    })

    /**
     * Permission checker for automation approval buttons.
     * @type {ComputedRef<unknown>}
     */
    const automationApproval = computed(() => {
      return props.plan.status === 'review' && !props.plan.approval
    })

    /**
     * Fetches threaded tweets for twitter previews if any.
     * @type {ComputedRef<[{image: [], image_suggestions: [], website: string, progressBar: {image: number, numberOfImages: number, video: number}, upload_thread_tweets_sharing_video: boolean, location: {name: string, id: string}, video: {thumbnail: string, facebook_video: string, link: string, facebook_converted_size: number, title: string, facebook_converted_mimetype: string, duration: string, thumbnails_suggestions: [], converted_link: string, size: number, converted_size: number, mime_type: string, converted_mime_type: string, name: string, converted_video: string}, message: string, fetchThreadedTweetsShortLinks: boolean, cancelledLink: string, url: string, uploadThreadedTweetsSharingImage: {position: number, status: boolean}}]|[]|[{image: [], image_suggestions: [], website: string, mentions: [], description: string, location: {name: string, id: string}, video: {thumbnail: string, facebook_video: string, link: string, facebook_converted_size: number, title: string, facebook_converted_mimetype: string, duration: string, thumbnails_suggestions: [], converted_link: string, size: number, converted_size: number, mime_type: string, converted_mime_type: string, name: string, converted_video: string}, message: string, title: string, url: string}]|*>}
     */
    const fetchTwitterThreads = computed(() => {
      return props.plan.twitter_options?.threaded_tweets
    })

    /**
     * Fetches bluesky tweets for twitter previews if any.
     * @type {ComputedRef<[{image: [], image_suggestions: [], website: string, progressBar: {image: number, numberOfImages: number, video: number}, upload_thread_tweets_sharing_video: boolean, location: {name: string, id: string}, video: {thumbnail: string, facebook_video: string, link: string, facebook_converted_size: number, title: string, facebook_converted_mimetype: string, duration: string, thumbnails_suggestions: [], converted_link: string, size: number, converted_size: number, mime_type: string, converted_mime_type: string, name: string, converted_video: string}, message: string, fetchThreadedTweetsShortLinks: boolean, cancelledLink: string, url: string, uploadThreadedTweetsSharingImage: {position: number, status: boolean}}]|[]|[{image: [], image_suggestions: [], website: string, mentions: [], description: string, location: {name: string, id: string}, video: {thumbnail: string, facebook_video: string, link: string, facebook_converted_size: number, title: string, facebook_converted_mimetype: string, duration: string, thumbnails_suggestions: [], converted_link: string, size: number, converted_size: number, mime_type: string, converted_mime_type: string, name: string, converted_video: string}, message: string, title: string, url: string}]|*>}
     */
    const fetchBlueskyTweets = computed(() => {
      return props.plan?.bluesky_options?.has_multi_bluesky
        ? props.plan.bluesky_options?.multi_bluesky
        : []
    })

    const findFacebookPostType = computed(() => {
      return props.plan.facebook_options?.post_type
    })

    /**
     * Fetches planner plan status
     * @type {ComputedRef<string|*>}
     */
    const planStatus = computed(() => {
      if (props.externalPostPreview) {
        return renderClass(props.plan)?.tooltip
      }
      return props.plan.partially_failed
        ? 'Partially Failed'
        : props.plan.render_class?.tooltip
    })

    /**
     * Setter for social account
     * @param data
     */
    const selectSocialAccount = (data) => {
      selectedChannelType.value = data.channelKey
      accountInPreview.value = data.account
      accountInPreview.value.account_type = selectedChannelType.value
      previewedPost.value = props.plan?.posting.find((item) => {
        return (
          item.platform_id?.toString() ===
          getAccountId(accountInPreview.value)?.toString()
        )
      })
      segValue.value = 'comments'
      postAnalytics.value = null
    }

    /**
     *  When the modal is first shown, checks for available account selection.
     */
    const handleAccountSelection = () => {
      if (isSocialPost.value) {
        const accountSelection = Object.entries(props.plan.account_selection)
        for (let i = 0; i < accountSelection.length; i++) {
          if (accountSelection[i][1].length) {
            selectSocialAccount({
              account: accountSelection[i][1][0],
              channelKey: accountSelection[i][0],
            })
            break
          } else {
            selectedChannelType.value = 'facebook'
          }
        }
      }
    }

    /**
     * Event fired when the modal is shown
     */
    const onModalShown = () => {
      handleAccountSelection()
      subscribeAndBindPusher()
      window.addEventListener('keydown', handleKeyDown, null)
    }

    /**
     * Event fired when the modal is hidden
     */
    const onModalHidden = () => {
      window.removeEventListener('keydown', handleKeyDown)
      selectedChannelType.value = ''
      accountInPreview.value = null
      segValue.value = 'comments'
      emit('close-preview')
      unsubscribeAndUnbindPusher()
    }

    /**
     * Handles arrow keys left/right operation to navigated
     * @param e
     */
    const handleKeyDown = (e) => {
      root.$bvModal.hide('post-status-modal')
      if (
        e.target?.id === 'comment-box-top' ||
        e.target?.id === 'comment-box-external' ||
        e.target?.id === 'internal-note-box-top' ||
        e.target?.id === 'full-name-preview' ||
        e.target?.id === 'email-preview'
      )
        return
      switch (e.keyCode) {
        case 37:
          previousPlan()
          break
        case 39:
          nextPlan()
          break
      }
    }

    /**
     * onClick event for fetching the next available plan
     * @returns {Promise<void>}
     */
    const nextPlan = async () => {
      // Unsubscribe from current plan socket
      unsubscribeAndUnbindPusher()
      emit('next-plan')
      await nextTick()
    }

    /**
     * onClick event for fetching the previous available plan
     * @returns {Promise<void>}
     */
    const previousPlan = async () => {
      // Unsubscribe from current plan socket
      unsubscribeAndUnbindPusher()
      emit('previous-plan')
      await nextTick()
    }

    /**
     * onClick event for approving a plan with comment.
     */
    const approveWithComment = () => {
      emit('approve-with-comment', props.plan)
    }

    /**
     * onClick event for rejecting a plan with comment.
     */
    const rejectWithComment = () => {
      emit('reject-with-comment', props.plan)
    }

    /**
     * onClick event for editing a plan
     */
    const editPlan = async () => {
      const res = await openDraftComposer(
        '⚠️ Unfinished Post is in the Composer!',
        'You have a post currently in the composer that is minimized. What would you like to do?',
        'Save & Edit Selected',
        'Return to Composer'
      )
      if (res === null) {
      } else if (!res) {
        EventBus.$emit('reset-composer-data')
        emit('edit-plan', props.plan)
        root.$bvModal.hide('planner-post-preview')
      } else {
        const workspace = root.$route.params.workspace
        history.pushState(
          {},
          null,
          `/${workspace}/composer/${draftPlanId.value}`
        )
        root.$bvModal.show('composer-modal')
      }
    }

    /**
     * onClick event for deleting a plan.
     */
    const deletePlan = () => {
      emit(
        'delete-plan',
        props.plan?._id,
        props.plan.post_state,
        props.plan?.blog_reference && !props.plan?.parent_reference
          ? props.plan?.blog_reference
          : null
      )
    }

    /**
     * onClick event for duplicating a plan
     */
    const duplicatePlan = async () => {
      const res = await openDraftComposer(
        '⚠️ Unfinished Post is in the Composer!',
        'You have a post currently in the composer that is minimized. What would you like to do?',
        'Save & Create Duplicate',
        'Return to Composer'
      )
      if (res === null) {
      } else if (!res) {
        EventBus.$emit('reset-composer-data')
        emit('duplicate-plan', props.plan)
        root.$bvModal.hide('planner-post-preview')
      } else {
        const workspace = root.$route.params.workspace
        history.pushState(
          {},
          null,
          `/${workspace}/composer/${draftPlanId.value}`
        )
        root.$bvModal.show('composer-modal')
      }
    }

    /**
     * Returns relevant data required to show the gmb post preview
     * @param type
     * @returns {string|*|string}
     */
    const getGMBOptions = (type) => {
      switch (type) {
        case 'cta':
          return props.plan.gmb_options?.action_type
            ? props.plan.gmb_options.action_type
            : ''

        case 'title':
          return props.plan.gmb_options?.title
            ? props.plan.gmb_options.title
            : ''

        case 'start_date':
          return props.plan.gmb_options?.start_date
            ? props.plan.gmb_options.start_date
            : ''

        case 'end_date':
          return props.plan.gmb_options?.end_date
            ? props.plan.gmb_options.end_date
            : ''
      }
      return ''
    }

    // Approve automation post
    const automationApprovalAction = (action) => {
      root.changePlanStatusMethod(action, props.plan, true)
      // nextPlan()
      root.$bvModal.hide('planner-post-preview')
    }

    /**
     * Chhecks if the plan has external actions
     * @type {ComputedRef<unknown>}
     */
    const showExternalActions = computed(() => {
      return props.plan?.external_actions?.length
    })

    const subscribeAndBindPusher = () => {
      const channelName = `retry_posting:${props.plan._id}`

      // Bind socket event handlers first
      window.socket.on(
        channelName,
        function ({ status, errorMessage, posting }) {
          const postingIndex = props.plan.posting.findIndex(
            (item) => item._id === posting._id
          )

          if (postingIndex < 0) return

          switch (status) {
            case 'in_progress':
              props.plan.posting[postingIndex].retrying = true
              break

            case 'complete':
              props.plan.posting[postingIndex] = posting
              if (!posting?.error) {
                props.plan.partially_failed = false
                props.plan.status = 'published'
                props.plan.post_state = 'published'
                props.plan.render_class.tooltip = 'Published'
              }
              break

            case 'failed':
              props.plan.posting[postingIndex].retrying = false
              store.dispatch('toastNotification', {
                errorMessage,
                error: 'error',
              })
              break
          }
        }
      )

      // Then emit the subscribe event
      window.socket.emit('subscribe', { channel: channelName })
    }

    const unsubscribeAndUnbindPusher = () => {
      const channelName = `retry_posting:${props.plan._id}`

      // First emit unsubscribe
      window.socket.emit('unsubscribe', { channel: channelName })

      // Then remove the event listener
      window.socket.removeAllListeners(channelName)
    }

    // Update the Attached Labels
    const UpdateAttachedLabels = (newLabels) => {
      props.plan.labels = newLabels
    }

    // Update the Attached Campaign
    const UpdateAttachedCampaign = (newCampaign) => {
      props.plan.folderId = newCampaign
    }

    return {
      openDocs,
      analyticsMetrics,
      getAnalyticsTooltip,
      getAccountName,
      previewedPost,
      errorMessage,
      navigateToPageAnalytics,
      PostDiscussionIcon,
      PostAnalyticsIcon,
      segValue,
      postAnalytics,
      getAnalyticsIcons,
      fetchPlanPostAnalytics,
      formatKey,
      showExternalActions,
      store,
      selectedChannelType,
      loader,
      loadingImage,
      comment,
      accountInPreview,
      showFacebookPreview,
      showLinkedinPreview,
      showTwitterPreview,
      showPinterestPreview,
      showTumblrPreview,
      showInstagramPreview,
      showYoutubePreview,
      showTiktokPreview,
      showGmbPreview,
      showThreadsPreview,
      getFeedSharingDetails,
      getFacebookPublishAs,
      canApprove,
      getMultiThreads,
      canDelete,
      canDuplicate,
      canEdit,
      canReplace,
      hasPlan,
      planInProgress,
      hasAccountSelection,
      showApprovalStatus,
      isSocialPost,
      isBlogPost,
      automationApproval,
      fetchTwitterThreads,
      postStatusBadgeClasses,
      planStatus,
      draftPlanId,
      selectSocialAccount,
      onModalShown,
      onModalHidden,
      nextPlan,
      previousPlan,
      handleAccountSelection,
      findFacebookAccount,
      findFacebookCarouselAccount,
      approveWithComment,
      rejectWithComment,
      editPlan,
      deletePlan,
      getAccount,
      duplicatePlan,
      getGMBOptions,
      automationApprovalAction,
      findFacebookPostType,
      getStatusImgUrl,
      sharePlan,
      postStatusBackgroundClasses,
      hideFromClientAction,
      clientProfile,
      subscribeAndBindPusher,
      unsubscribeAndUnbindPusher,
      isDeleted,
      openDraftComposer,
      getAccountDateTimeFormat,
      convertToWorkspaceTimeZone,
      UpdateAttachedLabels,
      attachedLabels,
      UpdateAttachedCampaign,
      getAttachedCampaign,
      appData,
      shouldShowWhiteLabelData,
      shareAbleLinkAccess,
      showUpgradeModal,
      fetchBlueskyTweets,
      showBlueskyPreview,
    }
  },
  computed: {
    ...mapGetters(['getSocialIntegrationsNames']),
  },
})
</script>

<style scoped lang="scss">
.accounts-list {
  border-right: 1px solid #eff0f1;
  border-radius: 20px 0 0 20px;
}

.comments-list {
  border-left: 1px solid #eff0f1;
  border-radius: 0 20px 20px 0;
}

.segmented-control {
  border: 1px solid rgba(74, 74, 74, 0.16);
  border-radius: 8px;
}

.post-preview-content {
  height: 90vh;
  width: 100%;
  max-width: 81rem;
}

.bottom-left-border {
  border-bottom-left-radius: 0.7rem;
}

.bottom-border {
  border-bottom: 1px solid #eceef5;
}
</style>
