<script setup>
import { computed } from 'vue'
import TopAndLeastEngagingPosts from '@src/modules/analytics/views/tiktok/components/TopAndLeastEngagingPosts.vue'
import FollowersGraph from '@src/modules/analytics/views/tiktok/components/graphs/FollowersGraph.vue'
import EngagementsGraph from '@src/modules/analytics/views/tiktok/components/graphs/EngagementsGraph.vue'
import VideosGraph from '@src/modules/analytics/views/tiktok/components/graphs/VideosGraph.vue'
import VideosPostingAndEngagements from '@src/modules/analytics/views/tiktok/components/graphs/VideosPostingAndEngagements.vue'
import CardsComponent from '@/src/modules/analytics/views/tiktok/components/CardsComponent.vue'
import PostTable from '@/src/modules/analytics/views/tiktok/components/PostTable.vue'
import ReportFooter from '@/src/modules/analytics/components/reports/ReportFooter.vue'
import AiInsightsSection from '@src/modules/analytics/views/tiktok/components/AIInsightsSection.vue'

import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'

const { topEngagingPosts, leastEngagingPosts, allPosts, selectedAccount } =
  useTiktokAnalytics()

const chunkSize = 10
/**
 * Computes the array of start indexes for chunks of top engaging posts.
 * @returns {Array<number>} An array containing the start indexes for each chunk.
 */
const topPostsChunks = computed(() => {
  const startIndexes = []

  let i = 0
  while (i < allPosts.value.length) {
    startIndexes.push(i)
    i += chunkSize
  }

  return startIndexes
})

/**
 * Computes the total page size based on the number of hard-coded sections and the number of chunks of top engaging posts.
 * @returns {number} The total page size.
 */
const totalPageSize = computed(() => {
  const hardCodedSections = 6  // Updated to include AI insights sections
  return hardCodedSections + topPostsChunks.value.length
})
</script>

<template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Overview</h2>
      <div class="flex flex-col">
        <!-- Cards -->
        <div class="grid grid-cols-8 gap-4 mb-14">
          <CardsComponent />
        </div>
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Followers</h2>
        <FollowersGraph />
      </div>
    </div>
    <ReportFooter :current-page="1" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Cumulative Engagement Activity</h2>
        <EngagementsGraph />
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Engagement Activity</h2>
        <EngagementsGraph default-type="bar" />
      </div>
    </div>
    <ReportFooter :current-page="2" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <div class="flex flex-col">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Cumulative Video Views</h2>
        <VideosGraph />
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Daily Video Views</h2>
        <VideosGraph default-type="bar" />
      </div>
    </div>
    <ReportFooter :current-page="3" :total-pages="totalPageSize" />
  </div>

  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Engagement vs Daily Posting Pattern</h2>
      <VideosPostingAndEngagements />
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">Top Performing Videos</h2>
      <TopAndLeastEngagingPosts
        :top-posts="topEngagingPosts"
        :least-posts="leastEngagingPosts"
        :selected-account="selectedAccount"
      />
    </div>
    <ReportFooter :current-page="4" :total-pages="totalPageSize" />
  </div>

  <template v-for="(start, index) in topPostsChunks" :key="index">
    <!-- Iterate over each chunk of top engaging posts -->
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">TikTok Top Posts</h2>
        <PostTable
          :range-start-value="start"
          :range-end-value="Math.min(start + chunkSize, allPosts?.length)"
        />
      </div>
      <ReportFooter :current-page="5 + index" :total-pages="totalPageSize" />
    </div>
  </template>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white my-2 text-base font-weight-500">AI Insights</h2>
      <AiInsightsSection :indexes-to-show="[0, 1]" />
    </div>
    <ReportFooter :current-page="5 + topPostsChunks.length" :total-pages="totalPageSize" />
  </div>
  <div class="page_view flex flex-col justify-between">
    <div class="subpage">
      <AiInsightsSection :indexes-to-show="[2]" />
    </div>
    <ReportFooter :current-page="6 + topPostsChunks.length" :total-pages="totalPageSize" />
  </div>
</template>
