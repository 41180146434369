<template>
  <div class="space-y-4 px-4 max-h-[400px] overflow-y-auto scrollable-area">
    <!-- Brand Voice -->
    <div>
      <label class="text-sm font-medium text-[#3A4557]"
        >Brand Voice
        <i
          v-tooltip="'Defines the tone and style of communication your brand uses to connect with its audience (e.g., professional, casual).'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i
      ></label>

      <textarea
        v-model="brandInfo.strategy.brandVoice"
        rows="3"
        placeholder="Enter your brand voice"
        class="w-full border border-gray-300 rounded px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
      />
    </div>

    <!-- Niche -->
    <div>
      <label class="text-sm font-medium text-[#3A4557]"
        >Niche
        <i
          v-tooltip="'Your brand\'s industry or area of expertise. Helps create relevant content for your market.'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i
      ></label>
      <textarea
        v-model="brandInfo.strategy.niche"
        rows="3"
        placeholder="Describe your niche"
        class="w-full border border-gray-300 rounded px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
      />
    </div>

    <!-- Audience -->
    <div>
      <label class="text-sm font-medium text-[#3A4557]"
        >Audience
        <i
          v-tooltip="'The group of people you aim to reach with your content. Understanding your audience helps tailor messaging'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
    ></i
      ></label>
      <textarea
        v-model="brandInfo.strategy.audience"
        rows="3"
        placeholder="Describe your audience"
        class="w-full border border-gray-300 rounded px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
      />
    </div>

    <!-- Tone -->
    <div>
      <label class="text-sm font-medium text-[#3A4557]"
        >Tone
        <i
          v-tooltip="'Describes the personality conveyed in your content (e.g., conversational, professional).'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i
      ></label>
      <div class="relative">
        <div
          class="flex flex-wrap gap-2 p-2 min-h-[42px] rounded-lg border border-gray-300 bg-white"
        >
          <span
            v-for="tone in brandInfo.strategy.tone"
            :key="tone"
            class="px-2 py-1 bg-gray-100 text-gray-900 rounded-md text-sm"
          >
            {{ tone }}
            <i
              class="fas fa-times ml-1 cursor-pointer"
              @click.stop="removeTone(tone)"
            ></i>
          </span>
          <input
            v-model="newTone"
            type="text"
            placeholder="Enter tone and press Enter"
            class="flex-1 min-w-[120px] border-none outline-none focus:ring-0 text-sm"
            @keydown.enter.prevent="addTone"
            @blur="addTone"
          />
        </div>
      </div>
    </div>

    <!--  Language -->
    <div>
      <label class="text-sm font-medium text-[#3A4557]"
        >Language
        <i
          v-tooltip="'Preferred language style for your audience (e.g., simple, formal).'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i
      ></label>

      <div class="relative">
        <div
          class="flex flex-wrap gap-2 p-2 min-h-[42px] rounded-lg border border-gray-300 bg-white"
        >
          <span
            v-for="language in brandInfo.strategy.language"
            :key="language"
            class="px-2 py-1 bg-gray-100 text-gray-900 rounded-md text-sm"
          >
            {{ language }}
            <i
              class="fas fa-times ml-1 cursor-pointer"
              @click.stop="removeLanguage(language)"
            ></i>
          </span>
          <input
            v-model="newLanguage"
            type="text"
            placeholder="Enter language and press Enter"
            class="flex-1 min-w-[120px] border-none outline-none focus:ring-0 text-sm"
            @keydown.enter.prevent="addLanguage"
            @blur="addLanguage"
          />
        </div>
      </div>
    </div>

    <!-- Emotion -->
    <div>
      <label class="text-sm font-medium text-[#3A4557]"
        >Emotion
        <i
          v-tooltip="'Emotion your brand should evoke (e.g., positive, welcoming).'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i
      ></label>
      <div class="relative">
        <div
          class="flex flex-wrap gap-2 p-2 min-h-[42px] rounded-lg border border-gray-300 bg-white"
        >
          <span
            v-for="emotion in brandInfo.strategy.emotion"
            :key="emotion"
            class="px-2 py-1 bg-gray-100 text-gray-900 rounded-md text-sm"
          >
            {{ emotion }}
            <i
              class="fas fa-times ml-1 cursor-pointer"
              @click.stop="removeEmotion(emotion)"
            ></i>
          </span>
          <input
            v-model="newEmotion"
            type="text"
            placeholder="Enter emotion and press Enter"
            class="flex-1 min-w-[120px] border-none outline-none focus:ring-0 text-sm"
            @keydown.enter.prevent="addEmotion"
            @blur="addEmotion"
          />
        </div>
      </div>
    </div>

    <!-- Competitors -->
    <div>
      <label class="text-sm font-medium text-[#3A4557]">Competitors</label>
      <i
        v-tooltip="'Brands you compete with or draw inspiration from.'"
        class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
      ></i>
      <div class="relative">
        <div
          class="flex flex-wrap gap-2 p-2 min-h-[42px] rounded-lg border border-gray-300 bg-white"
        >
          <span
            v-for="competitor in brandInfo.strategy.competitors"
            :key="competitor"
            class="px-2 py-1 bg-gray-100 text-gray-900 rounded-md text-sm"
          >
            {{ competitor }}
            <i
              class="fas fa-times ml-1 cursor-pointer"
              @click.stop="removeCompetitor(competitor)"
            ></i>
          </span>
          <input
            v-model="newCompetitor"
            type="text"
            placeholder="Enter competitor and press Enter"
            class="flex-1 min-w-[120px] border-none outline-none focus:ring-0 text-sm"
            @keydown.enter.prevent="addCompetitor"
            @blur="addCompetitor"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'

const { brandInfo } = useSetup()

const newTone = ref('')
const newLanguage = ref('')
const newCompetitor = ref('')
const newEmotion = ref('')

const addTone = () => {
  const tone = newTone.value.trim()
  if (tone && !brandInfo.strategy.tone.includes(tone)) {
    brandInfo.strategy.tone.push(tone)
  }
  newTone.value = ''
}

const removeTone = (tone) => {
  brandInfo.strategy.tone = brandInfo.strategy.tone.filter((t) => t !== tone)
}

const addLanguage = () => {
  const language = newLanguage.value.trim()
  if (language && !brandInfo.strategy.language.includes(language)) {
    brandInfo.strategy.language.push(language)
  }
  newLanguage.value = ''
}

const removeLanguage = (language) => {
  brandInfo.strategy.language = brandInfo.strategy.language.filter(
    (l) => l !== language
  )
}

const addCompetitor = () => {
  const competitor = newCompetitor.value.trim()
  if (competitor && !brandInfo.strategy.competitors.includes(competitor)) {
    brandInfo.strategy.competitors.push(competitor)
  }
  newCompetitor.value = ''
}

const removeCompetitor = (competitor) => {
  brandInfo.strategy.competitors = brandInfo.strategy.competitors.filter(
    (c) => c !== competitor
  )
}

const addEmotion = () => {
  const emotion = newEmotion.value.trim()
  if (emotion && !brandInfo.strategy.emotion.includes(emotion)) {
    brandInfo.strategy.emotion.push(emotion)
  }
  newEmotion.value = ''
}

const removeEmotion = (emotion) => {
  brandInfo.strategy.emotion = brandInfo.strategy.emotion.filter(
    (e) => e !== emotion
  )
}
</script>

<style scoped>
.scrollable-area::-webkit-scrollbar-track {
  background-color: white;
}

.scrollable-area::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
}

.scrollable-area::-webkit-scrollbar {
  width: 10px;
}
</style>
