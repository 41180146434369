<template>
  <!-- Sidebar Container -->
  <div class="relative z-50">
    <CstSidebar ref="sidebarRef" v-model:expanded="isExpanded" class="border-right-only">
      <!-- Expanded Header -->
      <template v-slot:head-expanded>
        <div
          class="flex items-center justify-between h-[4.4rem] bottom-border-only"
        >
          <div class="flex justify-between items-center w-full pl-4 ">
            <div class="flex items-center relative">
              <button
                class="bg-cs-primary rounded-l-md focus:outline-none text-cs-white border-0 px-3 py-2"
                @click="openNewSocialComposer"
              >
                <i class="fa fa-plus mr-1 font-thin"></i>
                Compose Post
              </button>
              <button
                class="bg-cs-primary rounded-r-md focus:outline-none text-cs-white border-0 ml-px p-2"
                @click="showDropdown = !showDropdown"
              >
                <i
                  class="fa fa-chevron-down"
                  :class="{ 'fa-rotate-180': showDropdown }"
                ></i>
              </button>
              <div
                v-show="showDropdown"
                v-click-away="() => (showDropdown = false)"
                class="absolute top-10 right-0 flex flex-col w-max max-w-sm rounded-xl bg-white p-2 z-100"
                style="box-shadow: rgb(184 189 209 / 30%) 1px 1px 10px 0"
              >
                <div class="w-full p-1 text-white">
                  <div
                    class="w-full px-2 py-2.5 cursor-pointer hover:bg-cs-foggray rounded-lg"
                    @click="openBlogComposer"
                  >
                    <span class="text-sm text-gray-900 font-medium"
                      >Compose Blog Post</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <!-- Header Content -->
            <CstButton
              v-tooltip="'Collapse'"
              variant="text"
              size="small"
              class="group"
              @click="sidebarRef?.toggleExpand()"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.9998 9.99996C15.9998 10.3681 15.7013 10.6666 15.3332 10.6666H6.27598L9.80457 14.1952C10.0649 14.4556 10.0649 14.8777 9.80457 15.1381C9.54422 15.3984 9.12212 15.3984 8.86177 15.1381L4.1951 10.4714C4.06509 10.3413 4 10.171 3.99984 10.0006V16.6666C3.99984 17.0348 3.70136 17.3333 3.33317 17.3333C2.96498 17.3333 2.6665 17.0348 2.6665 16.6666V3.33329C2.6665 2.96511 2.96498 2.66663 3.33317 2.66663C3.70136 2.66663 3.99984 2.96511 3.99984 3.33329V10.0006C4 9.83021 4.06509 9.65856 4.1951 9.52855L8.86177 4.86189C9.12212 4.60153 9.54422 4.60153 9.80457 4.86189C10.0649 5.12224 10.0649 5.54435 9.80457 5.80469L6.27598 9.33329H15.3332C15.7013 9.33329 15.9998 9.63177 15.9998 9.99996Z"
                  class="fill-[#979CA0] group-hover:fill-[#4A4A4A]"
                />
              </svg>
            </CstButton>
          </div>
        </div>
      </template>

      <!-- Shrunk Header -->
      <template v-slot:head-shrink>
        <div
          class="flex items-center justify-center h-[4.40rem] bottom-border-only"
        >
          <div class="rotate-180">
            <!-- Header Content Shrink -->
            <CstButton
              v-tooltip="'Collapse'"
              variant="text"
              size="small"
              class="group"
              @click="sidebarRef?.toggleExpand()"
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.9998 9.99996C15.9998 10.3681 15.7013 10.6666 15.3332 10.6666H6.27598L9.80457 14.1952C10.0649 14.4556 10.0649 14.8777 9.80457 15.1381C9.54422 15.3984 9.12212 15.3984 8.86177 15.1381L4.1951 10.4714C4.06509 10.3413 4 10.171 3.99984 10.0006V16.6666C3.99984 17.0348 3.70136 17.3333 3.33317 17.3333C2.96498 17.3333 2.6665 17.0348 2.6665 16.6666V3.33329C2.6665 2.96511 2.96498 2.66663 3.33317 2.66663C3.70136 2.66663 3.99984 2.96511 3.99984 3.33329V10.0006C4 9.83021 4.06509 9.65856 4.1951 9.52855L8.86177 4.86189C9.12212 4.60153 9.54422 4.60153 9.80457 4.86189C10.0649 5.12224 10.0649 5.54435 9.80457 5.80469L6.27598 9.33329H15.3332C15.7013 9.33329 15.9998 9.63177 15.9998 9.99996Z"
                  class="fill-[#979CA0] group-hover:fill-[#4A4A4A]"
                />
              </svg>
            </CstButton>
          </div>
        </div>
      </template>

      <!-- Expanded Body -->
      <template v-slot:body-expanded>
        <div class="px-3 pt-3 space-y-2">
          <Collapsible :default-open="true" selected-class="!bg-white">
            <template v-slot:trigger>
              <span>AI Content Library</span>
            </template>
            <!-- Body -->
            <div class="pt-3 space-y-2">
              <ListItem
                v-for="item in contentLibraryItems"
                :key="item.id"
                :selected="currentRoute === item.route"
                class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
                @click="router.push({ name: item.route })"
              >
                <img
                  :src="item.icon"
                  alt="icon"
                  class="grayscale group-hover:grayscale-0 h-4 w-4"
                  :class="{
                    '!grayscale-0': currentRoute === item.route,
                  }"
                />
                <div
                  v-tooltip="item.name"
                  class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
                  :class="{
                    '!font-medium': currentRoute === item.route,
                  }"
                >
                  {{ item.name }}
                </div>

                <div
                  v-if="item?.count"
                  class="text-[#3A4557] text-xs leading-none font-normal group-hover:font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#F7F7F7] group-hover:bg-[#D8EFFF]"
                  :class="{
                    '!bg-[#D8EFFF] !font-medium': currentRoute === item.route,
                  }"
                >
                  {{ item?.count }}
                </div>
              </ListItem>
            </div>
          </Collapsible>

          <hr />
          <!-- Planner -->

          <ListItem
            :selected="isPlannerRoute"
            class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
            @click="router.push({ name: plannerItem.route })"
          >
            <img
              :src="plannerItem.icon"
              alt="icon"
              class="grayscale group-hover:grayscale-0 h-4 w-4"
              :class="{
                '!grayscale-0': isPlannerRoute,
              }"
            />
            <div
              v-tooltip="'Planner'"
              class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
              :class="{
                '!font-medium': isPlannerRoute,
              }"
            >
              {{ plannerItem.name }}
            </div>
          </ListItem>

          <hr />

          <Collapsible :default-open="true" selected-class="!bg-white">
            <template v-slot:trigger>
              <span>Automations</span>
            </template>
            <!-- Body -->
            <div class="pt-3 space-y-2">
              <ListItem
                v-for="item in automationItems"
                :key="item.id"
                :selected="currentRoute === item.route || isAutomationRoute(item.route)"
                class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
                @click="router.push({ name: item.route })"
              >
                <img
                  :src="item.icon"
                  alt="icon"
                  class="grayscale group-hover:grayscale-0 h-4 w-4"
                  :class="{
                    '!grayscale-0': currentRoute === item.route || isAutomationRoute(item.route),
                  }"
                />
                <div
                  v-tooltip="item.name"
                  class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
                  :class="{
                    '!font-medium': currentRoute === item.route || isAutomationRoute(item.route),
                  }"
                >
                  {{ item.name }}
                </div>

                <div
                  v-if="item?.count"
                  class="text-[#3A4557] text-xs leading-none font-normal group-hover:font-medium flex min-w-[35px] px-[10px] py-1 justify-center items-center gap-[10px] flex-shrink-0 rounded-[20px] bg-[#F7F7F7] group-hover:bg-[#D8EFFF]"
                  :class="{
                    '!bg-[#D8EFFF] !font-medium': currentRoute === item.route || isAutomationRoute(item.route),
                  }"
                >
                  {{ item?.count }}
                </div>

                <i v-if="item.locked" class="far fa-lock text-gray-800 text-xs"></i>
              </ListItem>
            </div>
          </Collapsible>

          <hr />

          <!-- Publishing Queue -->
           <Collapsible :default-open="true" selected-class="!bg-white">
            <template v-slot:trigger>
              <span>Planner Settings</span>
            </template>
            <!-- Body -->
            <div class="pt-3 space-y-2">
              <ListItem
                v-for="item in plannerSettingItems"
                :key="item.id"
                :selected="currentRoute === item.route"
                class="text-sm flex items-center gap-[14px] group select-none !py-[8px]"
                @click="router.push(item.slug ? { name: item.route, params: { workspace: item.slug } } : { name: item.route })"
              >
                <img
                  :src="item.icon"
                  alt="icon"
                  class="grayscale group-hover:grayscale-0 h-4 w-4"
                  :class="{
                    '!grayscale-0': currentRoute === item.route,
                  }"
                />
                <div
                  v-tooltip="item.name"
                  class="text-[#3A4557] font-normal group-hover:font-medium flex-1 truncate"
                  :class="{
                    '!font-medium': currentRoute === item.route,
                  }"
                >
                  {{ item.name }}
                </div>
              </ListItem>
            </div>
          </Collapsible>
        </div>
      </template>

      <!-- Shrunk Body -->
      <template v-slot:body-shrink>
        <div class="px-3 pt-3 space-y-4">
          <template v-for="(item, index) in [...contentLibraryItems, plannerItem, ...automationItems, ...plannerSettingItems]" :key="index">
            <ListItem
              v-tooltip.right="item.name"
              :selected="currentRoute === item.route"
              class="text-sm flex items-center gap-[14px] group select-none"
              @click="router.push({ name: item.route })"
            >
              <img
                :src="item.icon"
                :alt="item.title"
                class="grayscale group-hover:grayscale-0"
                :class="{
                  '!grayscale-0': currentRoute === item.route,
                }"
              />
            </ListItem>
          </template>
        </div>
      </template>
    </CstSidebar>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import CstSidebar from '@src/components/UI/Sidebar/CstSidebar.vue'
import CstButton from '@src/components/UI/Button/CstButton.vue'
import { Collapsible, ListItem } from '@contentstudio/ui'
import { useRouter } from 'vue-router'
import { useIcons } from '@modules/publisher/composables/useIcons'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'
import { useStore } from '@/src/store/base'

const { PostIcon, ProfileIcon, BulkUploadIcon, EvergreenIcon, RSSIcon, ArticleIcon, VideoIcon, PlannerIcon, SocialIcon, ContentCategoryIcon } = useIcons()
const { AIUserProfile } = useSetup()
const { openNewSocialComposer, openBlogComposer } = useAIPostGeneration(AIUserProfile)
const router = useRouter()
const { getters } = useStore()

const sidebarRef = ref(null)
const isExpanded = ref(true)
const showDropdown = ref(false)

// list of sidebar items for AI Content Library
const contentLibraryItems = ref([
  {
    id: 1,
    name: 'Posts',
    icon: PostIcon,
    route: 'ai-content-library-posts',
  },
  {
    id: 2,
    name: 'Profile',
    icon: ProfileIcon,
    route: 'ai-content-library-profile',
  },
])

// Planner
const plannerItem = ref(
  {
    id: 1,
    name: 'Planner',
    icon: PlannerIcon,
    route: 'planner'
  }
)

// list of sidebar items for Automations
const automationItems = ref([
  {
    id: 1,
    name: 'Bulk Schedule',
    icon: BulkUploadIcon,
    route: 'save-bulk-csv-automation'
  },
  {
    id: 2,
    name: 'Evergreen',
    icon: EvergreenIcon,
    route: 'evergreen-automation-listing',
    locked: true
  },
  {
    id: 3,
    name: 'RSS',
    icon: RSSIcon,
    route: 'rss-automation-listing'
  },
  {
    id: 4,
    name: 'Articles to Social Media',
    icon: ArticleIcon,
    route: 'article-social-automation-listing'
  },
  {
    id: 5,
    name: 'Videos to Social Media',
    icon: VideoIcon,
    route: 'video-social-automation-listing',
    locked: true
  }
])

// list of sidebar items for Content Categories
const plannerSettingItems = ref([
  {
    id: 1,
    name: 'Content Categories',
    icon: ContentCategoryIcon,
    route: 'content_categories-publisher'
  },
  {
    id: 2,
    name: 'Social Accounts',
    icon: SocialIcon,
    route: 'social',
    slug: getters.getActiveWorkspace.slug,
  }
])

const currentRoute = computed(() => {
  return router.currentRoute?.value?.name
})

// Check if the current route is related to planner
const isPlannerRoute = computed(() => {
  // Check if the current route name is 'planner' or contains 'planner'
  return currentRoute.value === 'planner' ||
         (typeof currentRoute.value === 'string' && currentRoute.value.includes('planner'))
})

// Check if the current route is related to automation
const isAutomationRoute = (itemRoute) => {
  // Map main routes to their related save/edit routes
  const routeMapping = {
    'evergreen-automation-listing': ['save-evergreen-automation', 'save-evergreen-automation-accounts', 'save-evergreen-automation-optimization', 'save-evergreen-automation-finalize'],
    'rss-automation-listing': ['save-rss-automation'],
    'article-social-automation-listing': ['save-article-social-automation'],
    'video-social-automation-listing': ['save-video-social-automation'],
    'save-bulk-csv-automation': []
  }

  // Check if current route exactly matches the menu item route
  if (currentRoute.value === itemRoute) {
    return true
  }

  // Check if current route is one of the related routes for this menu item
  if (routeMapping[itemRoute] && routeMapping[itemRoute].includes(currentRoute.value)) {
    return true
  }

  return false
}

</script>

<style>
.bottom-border-only {
  border-bottom: 1px solid #dee2e6 !important;
}
.border-x-only {
  border-left: 1px solid #dee2e6 !important;
  border-right: 1px solid #dee2e6 !important;
}
.border-right-only {
  border-right: 1px solid #dee2e6 !important;
}
</style>
