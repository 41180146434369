<template>
  <b-modal
    id="add-style-modal"
    title="Add Style"
    size="lg"
    hide-footer
    hide-header
    centered
    header-class="p-4 border-b"
    dialog-class="!w-[600px]"
    @shown="handleShown"
  >
  <div class="modal_head">
    <div class="flex item-center justify-between w-full">
      <h4 class="text-xl font-semibold">Add Style</h4>
    </div>
    <button
      type="button"
      class="modal_head__close bg-transparent"
      data-dismiss="modal"
      aria-label="Close"
      @click.stop="handleClose"
      >&times;</button
    >
  </div>
  <div class="modal_body p-4">

    <div v-if="showLoader" class="h-[560px]">
      <AiGenerationLoader
        text="Our AI Bot is analyzing your business profile!"
        :loading-completed="loadingCompleted"
        :loading-steps="loadingSteps"
        :is-content-library="true"
      />
    </div>

    <template v-if="styleAnalyzed">
      <CustomTab mode="style" />
    </template>


    <template v-else-if="!showLoader && !styleAnalyzed">
    <!-- Tabs -->
    <div class="flex items-center gap-x-4">
      <!-- Tab Headers -->
      <div
        v-for="tab in tabs"
        :key="tab.id"
        :class="[
          selectedTab === tab.id ? 'cstu-text-blue-500 cstu-bg-blue-50' : '',
          'select-none text-sm rounded-full py-2 hover:cstu-bg-blue-50 px-3 flex items-center hover:cstu-text-blue-500 cursor-pointer gap-x-2',
        ]"
        @click="selectedTab = tab.id"
      >
        <i :class="[tab.icon]" aria-hidden="true"></i>
        <span class="text-base">{{ tab.name }}</span>
      </div>
    </div>

    <!-- Tab Content -->
    <div class="mt-4">
      <WebsiteTab v-if="selectedTab === 'website'" mode="style"  />
      <SocialTab v-else-if="selectedTab === 'social'" mode="style" />
      <CustomTab v-else-if="selectedTab === 'custom'" mode="style" />
    </div>
  </template>

    <!-- Footer -->
    <div
      v-if="!showFooter && !showLoader"
      class="flex items-center justify-between px-2 py-4 mt-6"
    >
      <a
        href="www.contentstudio.com"
        target="_blank"
        class="text-sm text-blue-500"
        >About AI Library</a
      >

      <Button v-if="selectedTab === 'custom' || styleAnalyzed" size="sm" @click="handleAddCustomStyle" >Save</Button>

      <Button v-else size="sm" :loading="isLoading" @click="handleAnalyze">
        Analyze Your Brand
      </Button>
    </div>

  </div>
</b-modal>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import { Button } from '@contentstudio/ui'
import CustomTab from '@modules/publisher/ai-content-library/components/form/tabs/CustomTab.vue'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { useBrandInfoSetting } from '@modules/publisher/ai-content-library/composables/useBrandInfoSetting'
import WebsiteTab from '@/src/modules/publisher/ai-content-library/components/form/tabs/WebsiteTab.vue'
import SocialTab from '@/src/modules/publisher/ai-content-library/components/form/tabs/SocialTab.vue'
import { useStore } from '@/src/store/base'
import AiGenerationLoader from '@/src/components/common/AiGenerationLoader.vue'

const { isLoading, setupInfo, resetSetupInfo } = useSetup()
const { addStyleCustom, handleAddStyle, showLoader ,styleAnalyzed} = useBrandInfoSetting()
const { dispatch, getters } = useStore()

const root = inject('root')
const { $bvModal } = root

const hasSocialSetup = computed(() => {
  // Check if any social platform has selected accounts
  return getters.getSocialAccountsList?.length > 0
})

const selectedTab = ref('website')
const tabs = [
  {
    id: 'website',
    name: 'Website',
    icon: 'far fa-link',
  },
  {
    id: 'social',
    name: 'Social Accounts',
    icon: 'icon-social-accounts-cs',
  },
  {
    id: 'custom',
    name: 'Custom',
    icon: 'fa fa-paint-brush',
  },
]
const loadingSteps = ref([
  { message: 'Initializing', status: false },
  { message: 'Extracting data', status: false },
  { message: `Analyzing brand info`, status: false },
  { message: `Generating!`, status: false },
])
const loadingCompleted = ref(false)

const showFooter = computed(() => {
  return selectedTab.value === 'social' && !hasSocialSetup.value
})


const validateWebsiteUrl = () => {

  if (!setupInfo.website.url) {
    dispatch('toastNotification', {
      message: 'Please enter a website URL',
      type: 'error',
    })
    return false
  }

  let url = setupInfo.website.url.trim()

  // Add https:// if no protocol is specified
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'https://' + url
    setupInfo.website.url = url
  }

  try {
    const urlObj = new URL(url)
    if (!['http:', 'https:'].includes(urlObj.protocol)) {
      dispatch('toastNotification', {
        message: 'Please enter a valid http or https URL',
        type: 'error',
      })
      return false
    }
    return true
  } catch {
    dispatch('toastNotification', {
      message: 'Please enter a valid URL',
      type: 'error',
    })
    return false
  }
}

const handleAnalyze = async () => {
  if (showFooter.value) {
    selectedTab.value = 'social'
    return
  }

  // Validate website URL
  if (!validateWebsiteUrl()) {
    return
  }

  await handleAddStyle()
}

const handleAddCustomStyle = async () => {
  addStyleCustom()
  $bvModal.hide('add-style-modal')
}

const handleClose = () => {
  $bvModal.hide('add-style-modal')
}

const handleShown = () => {
  console.log('handleShown')
  resetSetupInfo()
}

const emit = defineEmits(['setup-complete'])
</script>
