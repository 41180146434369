<script setup>
import { computed, watch, ref } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'
import { EventBus } from '@common/lib/event-bus'
import TopPostsModal from '@src/modules/analytics/views/tiktok/components/TopPostsModal.vue'

// state
const {
  postsAndEngagements,
  videosAndEngagementCharts,
  dataZoomOptions,
  videosAndEngagementChartOptions: chartOptions,
  isReportView,
  analyticsDesignSystem,
  isNoAnalyticsData,
  fetchMedia,
  routes,
  allPosts,
} = useTiktokAnalytics()

const isNoData = computed(() => {
  return isNoAnalyticsData(postsAndEngagements)
})

watch(
  () => postsAndEngagements,
  () => {
    chartOptions.value.series = []

    const options = postsAndEngagements.value[0] || {}
    videosAndEngagementCharts.value?.forEach((chart, i) => {
      const series = {
        name: chart.name,
        type: 'bar',
        data: options[chart && chart?.api_response_key] || [],
        color: chart.color,
        colorBy: 'series',
        itemStyle: {
          borderRadius: [5, 5, 0, 0],
        },
        yAxisIndex: i,
        barMaxWidth: analyticsDesignSystem?.graphs?.dualBarMaxWidth,
        areaStyle: { opacity: 0.4, cursor: 'pointer' },
        cursor: 'pointer',
      }

      chartOptions.value.series?.push(series)
    })

    chartOptions.value.xAxis.data = options?.days_bucket
  },
  {
    deep: true,
    immediate: true,
  }
)

const openedModal = ref(false)

const customClickHandler = async (params) => {
  console.log(params)
  if (params?.name) {
    const selectedDate = params?.name
    const dateRange = `${selectedDate} - ${selectedDate}`

    // Fetch posts for the selected date
    fetchMedia(routes.POSTS_DATA, { date: dateRange, limit: 200 })

    if (allPosts?.value) {
      EventBus.$emit('show-top-posts-modal', {
        date: selectedDate,
        posts: 'Videos published on '
      })
    }
  }
}
</script>

<template>
  <AnalyticsCardWrapper
    type="specific-type-chart"
    :enable-modal="true"
    :is-modal="openedModal"
    @toggle-is-modal="(value) => (openedModal = value)"
    :show-insights-button="!isReportView"
    :enable-insights="true"
    :selected-dropdown-option="'Engagement vs Daily Posting Pattern'"
    platform="tiktok"
  >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Engagement vs Daily Posting Pattern
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Assess the relationship between your daily tweet posting
                  frequency and subsequent engagement metrics, over the selected
                  time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <MainGraph
          v-if="!isNoData"
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
          :custom-click-handler="customClickHandler"
          :custom-modal-height="slotProps.isInsights ? '40vh' : '80vh'"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
  <TopPostsModal/>
</template>
