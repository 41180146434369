<script>
import 'slick-carousel/slick/slick.css'
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'
import { parseDescription } from '@common/lib/helper'
import {
  instagramPostingOption,
  social,
} from '@src/modules/publish/store/states/mutation-types.js'
import { evergreen } from '@src/modules/automation/store/automation-mutation-type.js'
import { EventBus } from '@common/lib/event-bus'
import Slick from 'vue-slick'
import EvergreenBulk from './EvergreenBulk'

Slick.compatConfig = { MODE: 3, RENDER_FUNCTION: false }
draggable.compatConfig = {RENDER_FUNCTION: false, MODE: 3}
export default {
  components: {
    // Slick: () => import('vue-slick'),
    EvergreenBulk,
    draggable,
  },

  data() {
    return {
      slickOptions: {
        infinite: false,
        draggable: false,
        slidesToShow: 1,
        asNavFor: this.$refs.slick,
        speed: 700,
        autoplay: false,
        dots: false,
        nextArrow: false,
        prevArrow: false,
      },
    }
  },

  computed: {
    ...mapGetters([
      'getEvergreenSelection',
      'getEvergreenVariationData',
      'getAccountSelection',
      'getEvergreenAutomationBulk',
    ]),

    /*
     * check file is uploading , to show progress bar
     */
    isProcessing() {
      return (
        this.getEvergreenAutomationBulk.csvFileUpload &&
        this.getEvergreenAutomationBulk.total > 0
      )
    },

    firstCommentAccountSelection() {
      const facebookAccounts = this.getAccountSelection.facebook
      const instagramAccounts = this.getAccountSelection.instagram
      const youtubeAccounts = this.getAccountSelection.youtube
      const linkedinAccounts = this.getAccountSelection.linkedin

      return [
        ...facebookAccounts,
        ...instagramAccounts,
        ...youtubeAccounts,
        ...linkedinAccounts,
      ]
    },
  },
  watch: {
    /*
     * reload slick on change post (delete, edit, add)
     */
    'getEvergreenSelection.posts'(value) {
      const self = this
      value.forEach(function (value, index) {
        self.slickReload(index)
      })
    },
  },
  created() {
    this.$store.commit(evergreen.SET_EVERGREEN_AUTOMATION_TAB_STATUS, 'second')
    this.$store.commit(
      instagramPostingOption.SET_INSTAGRAM_POSTING_OPTION,
      'api'
    ) // for IG first comment
    /*
     * if url is hit directly we check social accounts selected and name exist
     * if not we redirect back to step 1 social accounts selection
     */
    if (this.$parent.isValidStep) {
      this.$parent.redirectEvergreen('saveEvergreenAutomationAccounts')
      return false
    }
  },

  methods: {
    ...mapActions([
      'setCommonSharingDetails',
      'saveEvergreenAutomation',
      'setSocialSharingTagsSuggestionsItems',
    ]),

    processDescription(description, limit) {
      return parseDescription(description, limit)
    },

    // slick slider methods
    slickReload(index) {
      if (this.$refs.slick && this.$refs.slick[index]) {
        const currIndex = this.$refs.slick[index].currentSlide()
        this.$refs.slick[index].destroy()
        this.$nextTick(() => {
          this.$refs.slick[index].create()
          this.$refs.slick[index].goTo(currIndex, true)
        })
      }
    },

    // event trigger when any changes in slider
    handleAfterChange(index) {
      console.log('handleAfterChange', index)

      if (
        parseInt(this.$refs.currentSliderNumber[index].innerHTML) ===
          this.getEvergreenSelection.posts[index].length &&
        this.getEvergreenSelection.posts[index].length > 1
      ) {
        this.$refs.variationArrowNext[index].classList.add('lastIndex')
        this.$refs.variationArrowPrev[index].classList.remove('lastIndex')
      } else if (
        parseInt(this.$refs.currentSliderNumber[index].innerHTML) === 1
      ) {
        this.$refs.variationArrowNext[index].classList.remove('lastIndex')
        this.$refs.variationArrowPrev[index].classList.add('lastIndex')
      } else {
        this.$refs.variationArrowNext[index].classList.remove('lastIndex')
        this.$refs.variationArrowPrev[index].classList.remove('lastIndex')
      }
      this.$refs.currentSliderNumber[index].innerHTML =
        this.$refs.slick[index].currentSlide() + 1
    },

    // next slide method
    next(index) {
      console.debug('Method:next', this.$refs, this.$refs.slick)
      this.$refs.slick[index].next()
      this.$refs.currentSliderNumber[index].innerHTML =
        this.$refs.slick[index].currentSlide() + 1
    },

    // previous slide method
    prev(index) {
      this.$refs.slick[index].prev()
      this.$refs.currentSliderNumber[index].innerHTML =
        this.$refs.slick[index].currentSlide() + 1
    },

    /*
     * get post video thumbnail or post image
     */
    getEvergreenPostImage(post) {
      if (post.video && post.video.link) {
        return post.video.thumbnail
      } else if (post.image && post.image.length) {
        return post.image[0]
      }
      return false
    },

    /*
     * get post video thumbnail or post image count
     */
    getEvergreenPostTotalImages(post) {
      if (post.video && post.video.link) {
        return 1
      } else if (post.image && post.image.length) {
        return post.image.length
      }
      return 0
    },

    /*
     * edit post variation
     * set single box data
     * set evergreen states for edit variation
     * show edit modal
     */
    editEvergreenVariation(variation, variationIndex, postIndex) {
      console.debug('Method:editEvergreenVariation', variation)
      variation = JSON.parse(JSON.stringify(variation))
      this.setCommonSharingDetails(variation)
      if (variation.gmb_options) {
        this.$store.commit(social.SET_GMB_OPTIONS, variation.gmb_options)
      } else this.$store.commit(social.SET_GMB_OPTIONS, null)

      this.setSharingMessage('Common', variation.message)
      this.setSocialSharingTagsSuggestionsItems([])
      this.$store.commit(evergreen.SET_EVERGREEN_ACTIVE_POST, variation.id)
      this.$store.commit(evergreen.SET_EVERGREEN_ADD_STATE, 'variation')
      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, postIndex)
      this.$store.commit(
        evergreen.SET_EVERGREEN_VARIATION_INDEX,
        variationIndex
      )

      EventBus.$emit('evergreenFetchedFirstComment', {
        has_first_comment: variation?.has_first_comment || false,
        first_comment_message: variation?.first_comment_message || '',
        first_comment_accounts: variation?.first_comment_accounts || [],
      })

      // eslint-disable-next-line no-undef
      $('#addEvergreenPost').modal('show')
    },

    /*
     * reset states for adding new Posts
     * show Add Post Modal
     */
    openAddPostModal() {
      // reset social box
      this.setCommonSharingDetails(null)
      this.$store.commit(social.SET_GMB_OPTIONS, null)
      document.getElementById('common_sharing_message').value = ''
      document.getElementById('common_sharing_message').style.height = '63px'
      this.setSocialSharingTagsSuggestionsItems([])
      // reset add post stats
      this.$store.commit(evergreen.SET_EVERGREEN_ADD_STATE, 'post')
      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, 0)
      this.$store.commit(evergreen.SET_EVERGREEN_ACTIVE_POST, '')

      EventBus.$emit('evergreenFetchedFirstComment', {
        has_first_comment: false,
        first_comment_message: '',
        first_comment_accounts: this.firstCommentAccountSelection,
      })

      // show add post modal
      // eslint-disable-next-line no-undef
      $('#addEvergreenPost').modal('show')
    },

    /*
     * add new Variation in post
     * set states and show add post modal
     */
    addVariation(index) {
      // set 1st variation in social box
      this.setCommonSharingDetails(
        JSON.parse(JSON.stringify(this.getEvergreenSelection.posts[index][0]))
      )
      this.setSharingMessage(
        'Common',
        this.getEvergreenSelection.posts[index][0].message
      )
      this.setSocialSharingTagsSuggestionsItems([])
      if (this.getAccountSelection.gmb.length) {
        if (this.getEvergreenSelection.posts[index][0].gmb_options) {
          this.$store.commit(
            social.SET_GMB_OPTIONS,
            JSON.parse(
              JSON.stringify(
                this.getEvergreenSelection.posts[index][0].gmb_options
              )
            )
          )
        } else this.$store.commit(social.SET_GMB_OPTIONS, null)
      }
      // set stats for add variations
      this.$store.commit(evergreen.SET_EVERGREEN_ADD_STATE, 'variation')
      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, index)
      this.$store.commit(evergreen.SET_EVERGREEN_ACTIVE_POST, '')
      // show add modal
      // eslint-disable-next-line no-undef
      $('#addEvergreenPost').modal('show')
    },

    /*
     * fetch post url or url from text
     * and show generate variation modal
     */
    addURLVariation(index) {
      let link = this.getEvergreenSelection.posts[index][0].url
      if (link === '') {
        const urls = this.getLinksFromText(
          this.getEvergreenSelection.posts[index][0].message
        )
        if (urls) link = urls[0]
      }

      if (link === '') {
        this.alertMessage(
          'Auto-generate variation feature works only with link posts.',
          'error'
        )
        return false
      }

      this.$store.commit(evergreen.SET_EVERGREEN_POST_INDEX, index)
      this.setCommonSharingDetails(null)
      this.$store.commit(social.SET_GMB_OPTIONS, null)
      document.getElementById('common_sharing_message').value = ''
      this.$store.commit(evergreen.SET_VARIATION_URL, link)
      this.$store.commit(evergreen.SET_EVERGREEN_VARIATION_PREVIEW_POSTS, [])
      // eslint-disable-next-line no-undef
      $('#autoVariationGenerate').modal('show')
    },

    /*
     * generate post all warning messages
     */
    singleBoxWarningMessagesEvergreen(posts) {
      const messages = []
      const socialPlatforms = this.getAccountSelection

      posts.forEach(function (details, index) {
        let images = []
        if (
          Object.prototype.toString.call(details.image) === '[object Array]'
        ) {
          images = details.image
        } else {
          images = [details.image]
        }

        if (socialPlatforms.twitter.length) {
          if (images.length > 4) {
            messages.push(
              'X (Twitter) doesn’t allow publishing more than 4 images. So, we’ll use the first 4 images for the X (Twitter) post. [Variation ' +
                (index + 1) +
                ']'
            )
          }
        }

        if (socialPlatforms.pinterest.length && images.length > 1) {
          messages.push(
            'Pinterest doesn’t allow publishing more than 1 image. So, we’ll use the first image for the Pinterest post. [Variation ' +
              (index + 1) +
              ']'
          )
        }

        if (socialPlatforms.linkedin.length) {
          if (images.length > 9) {
            messages.push(
              'Linkedin doesn’t allow publishing more than 9 images. So, we’ll use the first 9 images for the Linkedin post. [Variation ' +
                (index + 1) +
                ']'
            )
          }
        }

        if (socialPlatforms.tumblr.length && images.length > 1) {
          messages.push(
            'Tumblr doesn’t allow publishing more than 1 image. So, we’ll use the first image for the Tumblr post. [Variation ' +
              (index + 1) +
              ']'
          )
        }

        // showing warning message if images are more than 10
        if (socialPlatforms.instagram.length) {
          if (images.length > 10) {
            messages.push(
              'Instagram doesn’t allow publishing more than 10 images. So, we’ll use the first 10 images for the Instagram post. [Variation ' +
                (index + 1) +
                ']'
            )
          }
        }

        if (details.mentions.length > 0) {
          messages.push(
            'You can only tag Facebook pages and X (Twitter) handles. [Variation ' +
              (index + 1) +
              ']'
          )
        }
      })

      return messages
    },

    /*
     * calculate bulk add posts progress bar
     */
    EvergreenProcessBar(percentage = false) {
      if (percentage) {
        return {
          width:
            (this.getEvergreenAutomationBulk.totalProcess /
              this.getEvergreenAutomationBulk.total) *
              100 +
            '%',
        }
      }
      return (
        Math.round(
          (this.getEvergreenAutomationBulk.totalProcess /
            this.getEvergreenAutomationBulk.total) *
            100
        ) + '%'
      )
    },

    /*
     * calculate total expected post can b generated from one cycle of evergreen
     */
    getTotalExpectedPosts() {
      let maxVariation = 0
      this.getEvergreenSelection.posts.forEach(function (value, index) {
        if (value && value.length > maxVariation) maxVariation = value.length
      })
      return maxVariation * this.getEvergreenSelection.posts.length
    },
  },
}
</script>

<template>
  <div class="page_3 schedule_campaign">
    <div class="automation_page width_medium">
      <div class="page_inner basic_form">
        <div class="step_heading">
          <h3>Step 2 - Content</h3>
        </div>

        <div class="evergreen_option">
          <div class="box_align">
            <div class="c_box">
              <div class="box_inner" @click.prevent="openAddPostModal">
                <div class="img_icon">
                  <img
                    class="selected_img"
                    src="../../../../../assets/img/evergreen/social_active.png"
                    alt=""
                  />
                  <img
                    class="normal_img"
                    src="../../../../../assets/img/evergreen/social_inactive.png"
                    alt=""
                  />
                </div>
                <h3 class="icon_text" data-cy="c-post">Add Post</h3>
              </div>
            </div>
            <div class="or_sep">
              <span class="sep_text">OR</span>
            </div>
            <EvergreenBulk></EvergreenBulk>
          </div>
        </div>

        <template v-if="isProcessing">
          <p
            v-if="EvergreenProcessBar() === '0%'"
            style="font-size: 1.125rem"
            class="mb-3 mt-3"
            >Transforming file (it may take few seconds depending on the size of
            file)</p
          >
          <p v-else style="font-size: 1.125rem" class="mb-3 mt-3"
            >Processing {{ EvergreenProcessBar() }}</p
          >
          <div class="process_bar_automation">
            <div class="limit_add" :style="EvergreenProcessBar(true)"></div>
            <div class="limit_exceed"></div>
          </div>
        </template>

        <p
          v-if="
            getEvergreenSelection.posts &&
            getEvergreenSelection.posts.length > 0
          "
          class="expected_count mt-2 mb-3"
        >
          Total expected posts in 1 cycle :
          <strong>{{ getTotalExpectedPosts() }}</strong>
        </p>

        <draggable
          v-model="getEvergreenSelection.posts"
          ghost-class="ghost"
          handle=".drag_tool_item"
          class="evergreen_list"
          group="variations"
        >
          <template v-slot:item="{ element, index }">
            <div class="evergreen_list_item input_field">
              <div class="list_inner">
                <button
                  v-tooltip.top="'Drag'"
                  class="drag_tool_item btn border_btn drag_btn"
                >
                  <i class="icon_left fa fa-arrows-alt mr-0"></i>
                </button>
                <div class="top_area d-flex align-items-center">
                  <div class="top_left">
                    <p
                      >Post <span>{{ index + 1 }}</span></p
                    >
                  </div>
                  <div class="top_right ml-auto">
                    <button
                      class="btn border_btn"
                      @click.prevent="
                        confirmAction('removeEvergreenPost', { index: index })
                      "
                    >
                      <i class="icon_left icon-delete-cs"></i>
                      <span>Remove post</span>
                    </button>
                  </div>
                </div>
                <Slick
                  ref="slick"
                  :options="slickOptions"
                  @after-change="handleAfterChange(index)"
                >
                  <div
                    v-for="(variation, variationIndex) in element"
                    :key="variationIndex"
                    class="center_content d-flex align-items-start"
                  >
                    <div class="content">
                      <div
                        class="content_inner"
                        v-html="processDescription(variation.message, 320)"
                      ></div>

                      <!--........Don't remove this 'display:none' button as because it's using indirectly...........-->
                      <div class="btn_content_block">
                        <button
                          :ref="'insideEdit' + index"
                          class="btn border_btn"
                          @click.prevent="
                            editEvergreenVariation(
                              variation,
                              variationIndex,
                              index,
                            )
                          "
                        >
                          <i class="icon_left fa fa-edit"></i> <span>Edit</span>
                        </button>
                        <!--........Don't remove this 'display:none' button as because it's using indirectly...........-->
                        <button
                          :ref="'insideRemove' + index"
                          class="ml-2 btn border_btn"
                          @click.prevent="
                            confirmAction('removeEvergreenVariation', {
                              variationIndex: variationIndex,
                              postIndex: index,
                            })
                          "
                        >
                          <i class="icon_left icon-delete-cs"></i>
                          <span>Remove</span>
                        </button>
                      </div>
                    </div>
                    <div
                      v-if="getEvergreenPostImage(variation)"
                      class="img_block d-flex align-items-center justify-content-center"
                    >
                      <img
                        :src="getEvergreenPostImage(variation)"
                        class="img"
                        alt=""
                      />

                      <div
                        v-if="getEvergreenPostTotalImages(variation) > 1"
                        class="count_number"
                      >
                        <span
                          >+{{
                            getEvergreenPostTotalImages(variation) - 1
                          }}
                          more</span
                        >
                      </div>

                      <div
                        v-if="variation.video && variation.video.link"
                        class="play_icon"
                        @click.prevent="
                          previewVideo(
                            '',
                            '',
                            variation.video.converted_video,
                            'External',
                          )
                        "
                      >
                        <i class="fa fa-play" aria-hidden="true"></i>
                      </div>
                    </div>
                  </div>
                </Slick>
                <div class="variation_area d-flex align-items-center">
                  <div class="v_left">
                    <i
                      ref="variationArrowPrev"
                      class="fa fa-caret-left"
                      @click="prev(index)"
                    ></i>
                    <i
                      ref="variationArrowNext"
                      class="fa fa-caret-right"
                      @click="next(index)"
                    ></i>
                    <span
                      >Variation <span ref="currentSliderNumber">1</span> of
                      {{ element.length }}</span
                    >
                  </div>
                  <div class="v_right ml-auto">
                    <button
                      class="btn border_btn"
                      @click.prevent="addVariation(index)"
                    >
                      <span>Add variations</span>
                    </button>
                    <button
                      class="btn border_btn ml-2"
                      :disabled="getEvergreenVariationData.fetchLoader"
                      :class="{
                        disabled: getEvergreenVariationData.fetchLoader,
                      }"
                      @click.prevent="addURLVariation(index)"
                    >
                      <span>Auto-generate variations</span>
                    </button>
                  </div>
                </div>

                <div
                  v-if="
                    $parent.$parent.singleBoxErrorMessageEvergreen(element)
                      .length > 0
                  "
                  class="error_block"
                >
                  <div class="warning_block_content">
                    <p
                      >Error ({{
                        $parent.$parent.singleBoxErrorMessageEvergreen(element)
                          .length
                      }})</p
                    >
                    <ul>
                      <li
                        v-for="(
                          message, i
                        ) in $parent.$parent.singleBoxErrorMessageEvergreen(
                          element,
                        )"
                        :key="i"
                        >{{ message }}</li
                      >
                    </ul>
                  </div>
                </div>
                <div
                  v-else-if="
                    singleBoxWarningMessagesEvergreen(element).length > 0
                  "
                  class="warning_block"
                >
                  <div class="warning_block_content">
                    <p
                      >Warning ({{
                        singleBoxWarningMessagesEvergreen(element).length
                      }})</p
                    >
                    <ul>
                      <li
                        v-for="(
                          message, j
                        ) in singleBoxWarningMessagesEvergreen(element)"
                        :key="j"
                        >{{ message }}</li
                      >
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </draggable>

        <div
          v-if="getEvergreenSelection.posts.length > 0"
          class="post_create_link"
        >
          <p data-cy="add-another" @click.prevent="openAddPostModal"
            ><i class="cs-plus"></i> Add another post</p
          >
        </div>

        <div class="field_group text_center">
          <button
            class="btn btn-studio-theme-transparent-grey mr-1"
            :disabled="getEvergreenSelection.saveEvergreenContent"
            @click.prevent="
              $parent.$parent.redirectEvergreen(
                'save-evergreen-automation-accounts',
              )
            "
          >
            <i class="icon_left cs-angle-left"></i>
            <span>Prev</span>
          </button>

          <button
            class="btn btn-studio-theme-space"
            data-cy="step2-button"
            @click.prevent="$parent.$parent.processStepSecond(true)"
          >
            <span>Next</span>
            <i class="icon_right arrow_right_active cs-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
@import '../../../../../assets/less/third-party/slick.less';
</style>
