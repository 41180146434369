<template>
  <AnalyticsCardWrapper
    :type="'Account-Statistics'"
    :enable-modal="true"
    :enable-insights="showInsights"
    :show-insights-button="!isReportView"
    :platform="'overview'"
    >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">Accounts Statistics</h2>
            <v-menu
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">Watch and compare different statistics of your accounts</p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:card-body>
      <div class="flex flex-col gap-2">
        <!-- Tabs -->
        <div class="px-1">
          <b-tabs
            v-model="tabIndex"
            nav-class="analytics-tabs analytics-main-tabs border-t-0 border-r-0 border-l-0 border-b border-gray-200"
            content-class="analytics-tabs-content"
          >
            <b-tab
              v-for="tab in availableTabs"
              :key="tab.value"
              class="p-5"
              :active="selectedTab === tab.value"
              @click="selectedTab = tab.value"
            >
              <template v-slot:title>
                <div class="flex items-center justify-center">
                  {{ tab.label }}
                </div>
              </template>

              <!-- Tab Content -->
              <div class="pt-4 border-t-0 border-b-0">
                <div v-if="tab.value === selectedTab" class="animate-fadeIn">
                  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                    <template v-if="tab.value === 'overall'">
                      <AccountStatistics
                        v-for="item in visibleOverallAccounts"
                        :key="item.id"
                        class="account-statistics-item"
                        :platform-image="item.platformImage"
                        :account-image="item.image"
                        :name="item.name"
                        :platform-type="item.platform_type"
                        :account-id="item.account_id"
                        :platform-data-detailed="platformDataDetailed"
                        :platform-data-graphs="platformDataGraphs"
                        :is-loading="isLoadingStates"
                      />
                    </template>
                    <template v-else>
                      <AccountStatistics
                        v-for="item in visibleFilteredAccounts"
                        :key="item.id"
                        class="account-statistics-item"
                        :platform-image="item.platformImage"
                        :account-image="item.image"
                        :name="item.name"
                        :platform-type="item.platform_type"
                        :account-id="item.account_id"
                        :platform-data-detailed="platformDataDetailed"
                        :platform-data-graphs="platformDataGraphs"
                        :is-loading="isLoadingStates"
                      />
                    </template>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>

        <!-- Load More Button -->
        <div v-if="hasMoreAccounts && !isReportView" class="flex justify-center mt-4 mb-2">
          <button
            class="px-6 py-2 text-sm text-gray-600 bg-gray-100 border border-gray-200 rounded-md hover:bg-gray-200 transition-all duration-300 shadow-sm font-medium"
            @click="loadMore"
          >
            Load More
          </button>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>

<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import AccountStatistics from '@src/modules/analytics_v3/components/AccountStatistics.vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics'
// Icons are now handled directly with Font Awesome

const props = defineProps({
  accountStatistics: {
    type: Array,
    default: () => []
  },
  platformDataDetailed: {
    type: Array,
    default: () => []
  },
  platformDataGraphs: {
    type: Array,
    default: () => []
  },
  isLoadingStates: {
    type: Boolean,
    default: false
  }
})

// Tab management using useOverviewAnalytics
const {
  selectedTab,
  getAccountStatisticsTabs,
  getFilteredAccounts,
  isReportView
} = useOverviewAnalytics()

const tabIndex = ref(0)

// Only include tabs that have data available for them
const availableTabs = computed(() => {
  // Get all possible tabs from the composable
  return getAccountStatisticsTabs()

  // TODO: Filter tabs to only include those with data
})

// Watch for tab index changes to update selectedTab
watch(tabIndex, (newIndex) => {
  selectedTab.value = availableTabs.value[newIndex].value
})

// Watch for selectedTab changes to update tabIndex
watch(selectedTab, (newValue) => {
  const index = availableTabs.value.findIndex(tab => tab.value === newValue)
  if (index !== -1) {
    tabIndex.value = index
  }
})

// Pagination implementation with responsive count based on screen size
const visibleAccountsCount = ref(3)

// Function to update visible accounts count based on screen width
const updateVisibleAccountsCount = () => {
  const width = window.innerWidth
  if (width >= 1536) { // 2xl breakpoint
    visibleAccountsCount.value = 8
  } else if (width >= 1280) { // xl breakpoint
    visibleAccountsCount.value = 4
  } else if (width >= 1024) { // lg breakpoint
    visibleAccountsCount.value = 3
  } else if (width >= 640) { // sm breakpoint
    visibleAccountsCount.value = 2
  } else {
    visibleAccountsCount.value = 1
  }
}

// Initialize count and set up resize listener
onMounted(() => {
  updateVisibleAccountsCount()
  window.addEventListener('resize', updateVisibleAccountsCount)
})

// Clean up event listener
onUnmounted(() => {
  window.removeEventListener('resize', updateVisibleAccountsCount)
})

// Computed properties for visible accounts
const visibleOverallAccounts = computed(() => {
  return props.accountStatistics.slice(0, visibleAccountsCount.value)
})

const visibleFilteredAccounts = computed(() => {
  return filteredAccounts.value.slice(0, visibleAccountsCount.value)
})

// Determine if there are more accounts to show
const hasMoreAccounts = computed(() => {
  if (selectedTab.value === 'overall') {
    return props.accountStatistics.length > visibleAccountsCount.value
  } else {
    return filteredAccounts.value.length > visibleAccountsCount.value
  }
})

// Filter accounts using the imported function
const filteredAccounts = computed(() => {
  return getFilteredAccounts(props.accountStatistics, selectedTab.value)
})

// Load more function for pagination
const loadMore = () => {
  // Show all remaining accounts at once
  if (selectedTab.value === 'overall') {
    visibleAccountsCount.value = props.accountStatistics.length;
  } else {
    visibleAccountsCount.value = filteredAccounts.value.length;
  }

  // Add a small animation delay for smoother experience
  setTimeout(() => {
    const newItems = document.querySelectorAll('.account-statistics-item');
    newItems.forEach(item => {
      if (!item.classList.contains('animate-fadeIn')) {
        item.classList.add('animate-fadeIn');
      }
    });
  }, 10);
}
</script>

<style scoped>
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-out;
}

.scrollbar-thin::-webkit-scrollbar {
  height: 6px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #E5E7EB;
  border-radius: 3px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #D1D5DB;
}

/* Tailwind styles applied directly in the component */

/* Custom styling for the account statistics component */
.rounded-lg {
  box-shadow: 0px 3px 10px 0px rgba(211, 217, 236, 0.3);
}

/* Fullscreen button styling */
::v-deep .account-statistics .fullscreen-icon {
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

::v-deep .account-statistics:hover .fullscreen-icon {
  opacity: 1;
}

/* Tab hover and active states */
.overflow-x-auto {
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.overflow-x-auto::-webkit-scrollbar {
  display: none;
}

button {
  position: relative;
  transition: all 0.2s ease;
}

/* Tab styling - match design */
button.flex-1 {
  min-width: 120px;
  max-width: 150px;
}

/* Tab bottom border - blue for active */
button:not(:first-child).text-\[\#3B82F6\] {
  border-bottom: 2px solid #3B82F6;
}

/* Other tabs bottom border */
button:not(.text-\[\#3B82F6\]):not(:first-child) {
  border-bottom: 1px solid #EBEBEB;
}

.group:hover .group-hover\:opacity-10 {
  opacity: 0.1;
}

/* Smooth transitions */
.transition-colors {
  transition-property: color, background-color, border-color;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}
</style>
