import { mapGetters, mapActions } from 'vuex'
import {
  fetchVideoAutomationUrl,
  fetchVideoBlogAutomationsUrl,
  fetchVideoSocialAutomationsUrl,
  removeVideoAutomationUrl,
  saveVideoBlogAutomationUrl,
  saveVideoSocialAutomationUrl,
  changeVideoAutomationStatusUrl,
  videoAutomationBulkOperationUrl,
  restartVideoAutomationUrl,
} from '@src/modules/automation/config/api-utils'
import { video } from '@src/modules/automation/store/recipes/mutation-types'
import {blogPosting, social} from '@src/modules/publish/store/states/mutation-types'
import {
  AUTOMATION_CHANGE_STATUS_ERROR,
  AUTOMATION_PREFETCH_DAYS_GREATER_VALIDATION,
  AUTOMATION_PREFETCH_DAYS_LESS_VALIDATION,
  AUTOMATION_REMOVAL_ERROR,
  AUTOMATION_REMOVAL_SUCCESS,
  AUTOMATION_YOUTUBE_API_VALIDATION,
  AUTOMATION_RESTART_SUCCESS,
  AUTOMATION_RESTART_ERROR,
  FETCH_AUTOMATION_ERROR,
  NULL_AUTOMATION_NAME_ERROR,
  SAVE_AUTOMATION_ERROR,
  SAVE_AUTOMATION_SUCCESS,
  UNKNOWN_ERROR,
} from '../../constants/messages'
import integrations from '../../lib/integrations'
import { swalAttributes } from '../../constants/common-attributes'

export const videoAutomationMixin = {
  computed: {
    ...mapGetters([
      'getVideoAutomationDetails',
      'getWorkspaces',
      'getVideoBlogAutomationListing',
      'getVideoSocialAutomationListing',
      'getAutomationScheduleOptions',
      'getVideoAutomationListing',
      'getPlans',
      'getPublishSelection',
      'getWorkspaceHashtagValidation',
      'getAllAutomations',
      'getFetchIntegrationsAccountsLoader',
    ]),
  },
  methods: {
    ...mapActions([
      'setVideoAutomationFirstTabStatus',
      'setVideoAutomationSecondTabStatus',
      'setVideoAutomationThirdTabStatus',
      'setFetchVideoBlogAutomationsLoader',
      'setFetchVideoSocialAutomationsLoader',
      'setVideoBlogAutomationListingItem',
      'setVideoBlogAutomationListingPagination',
      'setVideoSocialAutomationListingPagination',
      'setVideoSocialAutomationListingItem',
      'setVideoBlogAutomationListingPage',
      'setVideoSocialAutomationListingPage',
      'setVideoBlogAutomationListingPage',
      'setFetchVideoAutomationLoader',
      'setSaveVideoAutomationLoader',
      'setVideoAutomation',
      'setChangeVideoAutomationStatusLoader',
      'setAutomationScheduleOptions',
      'setRemoveVideoAutomationLoader',
      'setVideoSelectedAutomations',
      'setVideoAutomationListingSelectedAll',
      'setVideoAutomationBulkOperationLoader',
      'setVideoAutomationListingSelectedAll',
      'validateHashtagInput',
      'setEditQueueStatus',
      'setSelectedHashtagSelection',
      'setSelectedReplugSelection',
      'setSelectedReplugSelectedItem',
      'setRestartVideoAutomationLoader',
    ]),

    createVideoAutomationProcess(routeName) {
      const result = this.checkSubscriptionLimits('automations')
      if (result) {
        return
      }
      this.resetVideoAutomationSection()
      this.$router.push({
        name: routeName,
        params: { workspace: this.getWorkspaces.activeWorkspace.slug },
      })
    },

    resetVideoAutomationSection() {
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
      this.$store.commit(blogPosting.RESET_BLOG_POSTING, null)
      this.setEditQueueStatus(false)
      this.setAccountSelection(null)
      this.setVideoAutomation(null)
      this.setAutomationScheduleOptions(null)

      this.setSelectedHashtagSelection(null)
      this.setSelectedReplugSelection(null)
    },

    changeVideoAutomationSelectAllStatus() {
      console.debug(
        'Method:changeVideoAutomationSelectAllStatus',
        this.$route.name
      )

      const selectedAutomation = []

      let automations = []
      if (this.$route.name === 'videoBlogAutomationListing') {
        automations = this.getVideoBlogAutomationListing.items
      } else {
        automations = this.getVideoSocialAutomationListing.items
      }

      if (this.getVideoAutomationListing.select_all) {
        $(automations).each(function (i, el) {
          if (el.lock === 0) {
            selectedAutomation.push(el._id)
          }
        })
      }

      this.setVideoSelectedAutomations(selectedAutomation)
    },

    // blog sections start

    /**
     * This method will get video automation.
     */
    fetchVideoBlogAutomations() {
      console.debug('Method:fetchVideoBlogAutomations')

      const listing = this.getVideoBlogAutomationListing

      const payload = { workspace_id: this.getWorkspaces.activeWorkspace._id }
      payload.search = listing.search
      payload.page = listing.page
      payload.limit = listing.limit
      payload.platformSelection = this.getPublishSelection.platforms.selection

      this.setFetchVideoBlogAutomationsLoader(true)
      this.postRequest(
        fetchVideoBlogAutomationsUrl,
        payload,
        (response) => {
          if (response.data.automations.data) {
            this.setVideoBlogAutomationListingItem(
              response.data.automations.data
            )
          } else {
            this.setVideoBlogAutomationListingItem([])
          }

          let next = false
          let previous = false
          if (response.data.automations.next_page_url) {
            next = true
          }

          if (response.data.automations.prev_page_url) {
            previous = true
          }

          this.setVideoBlogAutomationListingPagination({
            nextPage: next,
            previousPage: previous,
          })

          // this.setRssSchedulingAllSelectionStatus(false);
          // this.setSelectedRssSchedulingItems([]);
          this.setFetchVideoBlogAutomationsLoader(false)
        },
        (error) => {
          console.debug('Exception in fetchVideoBlogAutomations', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setFetchVideoBlogAutomationsLoader(false)
        }
      )
    },

    refetchVideoBlogAutomations() {
      console.debug('Method::refetchVideoBlogAutomations')
      this.setVideoBlogAutomationListingPage(1)
      this.fetchVideoBlogAutomations()
    },

    processVideoBlogAutomationPagination(direction) {
      if (direction === 'next') {
        this.setVideoBlogAutomationListingPage(
          this.getVideoBlogAutomationListing.page + 1
        )
      } else if (direction === 'previous') {
        this.setVideoBlogAutomationListingPage(
          this.getVideoBlogAutomationListing.page - 1
        )
      }
      this.fetchVideoBlogAutomations()
    },

    // blog sections end

    // social sections start

    /**
     * This method will get video automation.
     */
    fetchVideoSocialAutomations() {
      console.debug('Method:fetchVideoSocialAutomations')

      const listing = this.getVideoSocialAutomationListing

      const payload = { workspace_id: this.getWorkspaces.activeWorkspace._id }
      payload.search = listing.search
      payload.page = listing.page
      payload.limit = listing.limit
      payload.platformSelection = this.getPublishSelection.platforms.selection

      this.setFetchVideoSocialAutomationsLoader(true)
      this.postRequest(
        fetchVideoSocialAutomationsUrl,
        payload,
        (response) => {
          if (response.data.automations.data) {
            this.setVideoSocialAutomationListingItem(
              response.data.automations.data
            )
          } else {
            this.setVideoSocialAutomationListingItem([])
          }

          let next = false
          let previous = false
          if (response.data.automations.next_page_url) {
            next = true
          }

          if (response.data.automations.prev_page_url) {
            previous = true
          }

          this.setVideoSocialAutomationListingPagination({
            nextPage: next,
            previousPage: previous,
          })

          // this.setRssSchedulingAllSelectionStatus(false);
          // this.setSelectedRssSchedulingItems([]);
          this.setFetchVideoSocialAutomationsLoader(false)
        },
        (error) => {
          console.debug('Exception in fetchVideoSocialAutomations', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setFetchVideoSocialAutomationsLoader(false)
        }
      )
    },

    refetchVideoSocialAutomations() {
      console.debug('Method::refetchVideoBlogAutomations')
      this.setVideoSocialAutomationListingPage(1)
      this.fetchVideoSocialAutomations()
    },

    processVideoSocialAutomationPagination(direction) {
      if (direction === 'next') {
        this.setVideoSocialAutomationListingPage(
          this.getVideoSocialAutomationListing.page + 1
        )
      } else if (direction === 'previous') {
        this.setVideoSocialAutomationListingPage(
          this.getVideoSocialAutomationListing.page - 1
        )
      }
      this.fetchVideoSocialAutomations()
    },

    // social sections end

    changeActiveVideoAutomationTab(tab) {
      if (tab === 1) {
        this.setVideoAutomationFirstTabStatus(true)
        this.setVideoAutomationSecondTabStatus(false)
        this.setVideoAutomationThirdTabStatus(false)
        this.$store.commit(video.SET_AUTOMATION_FOURTH_TAB_STATUS, false)
      } else if (tab === 2) {
        this.setVideoAutomationFirstTabStatus(false)
        this.setVideoAutomationSecondTabStatus(true)
        this.setVideoAutomationThirdTabStatus(false)
        this.$store.commit(video.SET_AUTOMATION_FOURTH_TAB_STATUS, false)
      } else if (tab === 3) {
        this.setVideoAutomationFirstTabStatus(false)
        this.setVideoAutomationSecondTabStatus(false)
        this.setVideoAutomationThirdTabStatus(true)
        this.$store.commit(video.SET_AUTOMATION_FOURTH_TAB_STATUS, false)
      } else if (tab === 4) {
        this.setVideoAutomationFirstTabStatus(false)
        this.setVideoAutomationSecondTabStatus(false)
        this.setVideoAutomationThirdTabStatus(false)
        this.$store.commit(video.SET_AUTOMATION_FOURTH_TAB_STATUS, true)
      }
    },

    async processVideoAutomationValidation(tab) {
      console.debug('Method::processVideoAutomationValidation', tab)
      const automation = this.getVideoAutomationDetails

      if (tab > 0 || tab === 'All') {
        if (automation.name === '') {
          this.alertMessage(NULL_AUTOMATION_NAME_ERROR, 'error')
          return false
        }

        if (this.$route.name === 'saveVideoBlogAutomation') {
          if (!this.blogPrimarySecondarySelectionValidation()) {
            return false
          }

          if (!this.socialAccountsTokenValidation()) return false

          if (this.getPublishSelection.replug.status) {
            if (this.replugValidation() === false) {
              return false
            }
          }

          if (this.getPublishSelection.hashtag.status) {
            await this.validateHashtagInput('VideoAutomation')

            if (this.getWorkspaceHashtagValidation === false) {
              return false
            }
          }
        } else if (!this.socialAccountSelectionValidation('VideoAutomation')) {
          return false
        }

        this.changeActiveVideoAutomationTab(2)

        // if the user is editing already created campaign, get its preview
        if (
          this.getVideoAutomationDetails.id &&
          this.getVideoAutomationDetails.video.keyword
        ) {
          this.previewVideoSuggestion()
        }
      }
      if (tab > 1 || tab === 'All') {
        this.changeActiveVideoAutomationTab(3)
      }
      if (tab > 2 || tab === 'All') {
        if (this.getPublishSelection.replug.status) {
          if (this.replugValidation() === false) {
            return false
          }
        }

        if (this.getPublishSelection.hashtag.status) {
          await this.validateHashtagInput('VideoAutomation')

          if (this.getWorkspaceHashtagValidation === false) {
            return false
          }
        }

        if (this.$route.name === 'saveVideoBlogAutomation') {
          this.changeActiveVideoAutomationTab(3)
        } else {
          this.changeActiveVideoAutomationTab(4)
        }
      }
      if (tab > 3 || tab === 'All') {
        if (!this.automationScheduleOptionsValidation('VideoAutomation')) {
          return false
        }

        if (
          !automation.prefetch_days_limit ||
          (automation.prefetch_days_limit && automation.prefetch_days_limit < 2)
        ) {
          this.alertMessage(
            AUTOMATION_PREFETCH_DAYS_GREATER_VALIDATION,
            'error'
          )
          return false
        }

        if (
          automation.prefetch_days_limit &&
          automation.prefetch_days_limit > 100
        ) {
          this.alertMessage(AUTOMATION_PREFETCH_DAYS_LESS_VALIDATION, 'error')
          return false
        }
      }

      return true
    },

    async initializeSavingVideoAutomationProcess() {
      if (await this.processVideoAutomationValidation('All')) {
        this.videoAutomationSavingConfiramtion()
      }
    },

    videoAutomationSavingConfiramtion() {
      const automation = this.getVideoAutomationDetails
      if (automation.id && automation.status === 0) {
        this.confirmAction('saveVideoAutomation')
      } else {
        this.saveVideoAutomation()
      }
    },

    saveVideoAutomation(confirmationStatus) {
      const payload = this.getVideoAutomationDetails
      delete payload.status
      if (!payload.id) {
        delete payload.remove_posts
      }
      delete payload.next_posting

      let savingRequestUrl = saveVideoSocialAutomationUrl
      if (this.$route.name === 'save-video-blog-automation') {
        savingRequestUrl = saveVideoBlogAutomationUrl
        payload.blog_selection =
          this.getPrimarySecondaryBlogSelection('Automation')
      }

      const hashtag = this.getNewWorkspaceHashtag
      payload.hashtag = {}
      payload.hashtag.status = this.getPublishSelection.hashtag.status
      if (this.getPublishSelection.hashtag.status) {
        payload.hashtag.id = this.getPublishSelection.hashtag.selected
        payload.hashtag.name = hashtag.name
        payload.hashtag.items = hashtag.items
        payload.hashtag.count = hashtag.count
        payload.hashtag.position = hashtag.position
      }

      payload.replug = {}
      payload.replug.status = this.getPublishSelection.replug.status
      if (this.getPublishSelection.replug.status) {
        payload.replug.brand = this.getPublishSelection.replug.brand._id
        payload.replug.campaign = this.getPublishSelection.replug.campaign._id
        if (this.getPublishSelection.replug.campaign.domainId) {
          payload.replug.custom_domain = true
        } else {
          payload.replug.custom_domain = false
        }
      }

      payload.account_selection = this.getAccountSelection
      payload.schedule_options = this.getAutomationScheduleOptions

      payload.workspace_id = this.getWorkspaces.activeWorkspace._id
      if (typeof confirmationStatus !== 'undefined') {
        if (confirmationStatus.status) {
          payload.activate = true
        }
        $('#' + confirmationStatus.type).modal('hide')
      }

      this.setSaveVideoAutomationLoader(true)
      this.postRequest(
        savingRequestUrl,
        payload,
        (response) => {
          if (response.data.status === true) {
            this.alertMessage(SAVE_AUTOMATION_SUCCESS, 'success')

            this.resetVideoAutomationSection()

            if (this.$route.name === 'save-video-blog-automation') {
              this.$router.push({ name: 'video-blog-automation-listing' })
              this.refetchVideoBlogAutomations()
            } else {
              this.$router.push({ name: 'video-social-automation-listing' })
              this.refetchVideoSocialAutomations()
            }

            if (this.getAllAutomations.fetch_status) {
              // this.fetchAllVideoAutomations()
            }

            this.fetchAutomationsCounts()
            // this.setPlannerVideoAutomationsFetchStatus(false)
          } else {
            if (response.data.message) {
              this.alertMessage(response.data.message, 'error')
            } else {
              this.alertMessage(SAVE_AUTOMATION_ERROR, 'error')
            }
          }
          this.setSaveVideoAutomationLoader(false)
        },
        (error) => {
          console.debug('Exception in saveVideoAutomation', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setSaveVideoAutomationLoader(false)
        }
      )
    },

    removeVideoAutomation(confirmation, automation) {
      console.debug('Method:removeVideoAutomation', confirmation, automation)

      if (confirmation.status) {
        this.setRemoveVideoAutomationLoader(true)
        this.postRequest(
          removeVideoAutomationUrl,
          {
            automation_id: automation._id,
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          },
          (response) => {
            if (response.data.status === true) {
              this.setConfirmActionStage('success')
              this.alertMessage(AUTOMATION_REMOVAL_SUCCESS, 'success')

              if (this.$route.name === 'video-blog-automation-listing') {
                this.getVideoBlogAutomationListing.items.splice(
                  automation.removalIndex,
                  1
                )
              } else {
                this.getVideoSocialAutomationListing.items.splice(
                  automation.removalIndex,
                  1
                )
              }

              this.fetchAutomationsCounts()

              const stateObject = this
              if (this.getAllAutomations.fetch_status) {
                $(this.getAllAutomations.video).each(function (index, el) {
                  if (el._id === automation._id) {
                    stateObject.getAllAutomations.video.splice(index, 1)
                    return false
                  }
                })
              }
            } else {
              this.alertMessage(AUTOMATION_REMOVAL_ERROR, 'error')
              this.setConfirmActionStage('fail')
            }

            this.setRemoveVideoAutomationLoader(false)
            // this.setPlannerVideoAutomationsFetchStatus(false)
            this.closeConfirmAction(confirmation.type)
          },
          (error) => {
            console.debug('Exception in removeVideoAutomation', error)
            this.setConfirmActionStage('fail')
            this.setRemoveVideoAutomationLoader(false)
            this.closeConfirmAction(confirmation.type)
          }
        )
      } else {
        $('#' + confirmation.type).modal('hide')
      }
    },
    restartVideoAutomation(id, campaignName) {
      console.debug(
        'Method:restartVideoAutomation',
        id,
        campaignName,
        this.$route.name
      )

      // confirm removal request from the user
      this.$bvModal
        .msgBoxConfirm(
          'This action will remove your existing scheduled posts from "' +
            campaignName +
            '" and new posts will be scheduled. Would you like to continue?',
          {
            title: 'Restart Video Automation',
            ...swalAttributes(),
          }
        )
        .then((value) => {
          if (value) {
            this.setRestartVideoAutomationLoader(true)
            this.postRequest(
              restartVideoAutomationUrl,
              {
                automation_id: id,
                workspace_id: this.getWorkspaces.activeWorkspace._id,
              },
              (response) => {
                if (response.data.status === true) {
                  if (response.data.lock) {
                    if (this.$route.name === 'videoSocialAutomationListing') {
                      const socialAutomationIndex =
                        this.getVideoSocialAutomationListing.items.findIndex(
                          (x) => x._id === id
                        )
                      if (socialAutomationIndex > -1)
                        this.getVideoSocialAutomationListing.items[
                          socialAutomationIndex
                        ].lock = 1
                    }
                    t
                    if (this.$route.name === 'videoBlogAutomationListing') {
                      const blogAutomationIndex =
                        this.getVideoBlogAutomationListing.items.findIndex(
                          (x) => x._id === id
                        )
                      if (blogAutomationIndex > -1)
                        this.getVideoBlogAutomationListing.items[
                          blogAutomationIndex
                        ].lock = 1
                    }
                  }
                  this.alertMessage(AUTOMATION_RESTART_SUCCESS, 'success')
                } else {
                  this.alertMessage(AUTOMATION_RESTART_ERROR, 'error')
                }
                this.setRestartVideoAutomationLoader(false)
              },
              (error) => {
                console.debug('Exception in restartVideoAutomation', error)
                this.setRestartVideoAutomationLoader(false)
              }
            )
          }
        })
        .catch((err) => {
          // An error occurred
        })
    },

    fetchVideoAutomation(automation, copy) {
      console.debug('Method:fetchVideoAutomation')

      this.setFetchVideoAutomationLoader(true)
      this.postRequest(
        fetchVideoAutomationUrl,
        {
          id: automation,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
          copy,
        },
        (response) => {
          let count = -1
          if (response.data.status === true) {
            response.data.automation.copy = copy
            this.setVideoAutomation(response.data.automation)
            this.setAccountSelection(response.data.automation.account_selection)
            this.$store.commit(social.SET_PINTEREST_ACCOUNT_SELECTION,[])
            this.processAutomationScheduleOptions(
              response.data.automation.schedule_options
            )

            this.setSelectedHashtagSelection(response.data.automation)
            this.processHashtagSelection(response.data.automation.hashtag)
            if (response.data.automation.replug) {
              if (!this.getFetchIntegrationsAccountsLoader) {
                this.processReplugSelection(response.data.automation.replug)
              } else {
                this.setSelectedReplugSelectedItem(
                  response.data.automation.replug
                )
              }
            }

            if (this.$route.name === 'video-blog-automation-listing') {
              this.$store.commit(blogPosting.RESET_BLOG_SELECTION)
              this.$store.dispatch(
                'processPrimarySecondaryBlogSelection',
                response.data.automation.blog_selection
              )
              this.$router.push({ name: 'save-video-blog-automation' })
            } else {
              this.$router.push({ name: 'save-video-social-automation' })
            }
            if (this.getAutomationScheduleOptions.time_type !== 'Queue') {
              count = response.data.next_posting.length
            }
            if (this.getAutomationScheduleOptions.time_type === 'Queue') {
              count = 0
              const names = integrations.socialPlatformNames()
              names.forEach(function (integration) {
                if (
                  Object.keys(response.data.next_posting).includes(integration)
                ) {
                  response.data.next_posting[integration].forEach(function (
                    element,
                    index
                  ) {
                    count += element.length
                  })
                }
              })
            }
          } else {
            this.alertMessage(FETCH_AUTOMATION_ERROR, 'error')
          }
          this.$store.commit(video.SET_AUTOMATION_NEXT_POSTING, count)
          this.setFetchVideoAutomationLoader(false)
        },
        (error) => {
          console.debug('Exception in fetchVideoAutomation', error)
          this.$store.commit(video.SET_AUTOMATION_NEXT_POSTING, -1)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setFetchVideoAutomationLoader(false)
        }
      )
    },

    changeVideoAutomationStatus(automation, youtubeKey = '') {
      console.debug('Method:changeVideoAutomationStatus', automation)

      if (
        automation.video.source === 'Youtube' &&
        youtubeKey === '' &&
        (automation.status === true || automation.status === 1)
      ) {
        this.alertMessage(AUTOMATION_YOUTUBE_API_VALIDATION, 'error')
        automation.status = 0
        return false
      }
      let status = 0
      if (automation.status) {
        status = 1
      }

      this.setChangeVideoAutomationStatusLoader(true)
      this.postRequest(
        changeVideoAutomationStatusUrl,
        {
          status,
          id: automation._id,
          workspace_id: this.getWorkspaces.activeWorkspace._id,
        },
        (response) => {
          if (response.data.status === true) {
            const stateObject = this
            if (this.$route.name === 'videoBlogAutomationListing') {
              $(this.getVideoBlogAutomationListing.items).each(function (
                index,
                el
              ) {
                if (el._id === automation) {
                  stateObject.getVideoBlogAutomationListing.items[
                    index
                  ].status = status
                  return false
                }
              })
            } else {
              $(this.getVideoSocialAutomationListing.items).each(function (
                index,
                el
              ) {
                if (el._id === automation) {
                  stateObject.getVideoSocialAutomationListing.items[
                    index
                  ].status = status
                  return false
                }
              })
            }
          } else {
            this.alertMessage(AUTOMATION_CHANGE_STATUS_ERROR, 'error')
          }

          this.setChangeVideoAutomationStatusLoader(false)
        },
        (error) => {
          console.debug('Exception in changeVideoAutomationStatus', error)
          this.alertMessage(UNKNOWN_ERROR, 'error')
          this.setChangeVideoAutomationStatusLoader(false)
        }
      )
    },

    videoAutomationBulkOperation(confirmation, operation) {
      console.debug(
        'Method:videoAutomationBulkOperation',
        confirmation,
        operation
      )

      if (confirmation.status) {
        this.setVideoAutomationBulkOperationLoader(true)
        this.postRequest(
          videoAutomationBulkOperationUrl,
          {
            operation,
            automation_ids: this.getVideoAutomationListing.selected,
            workspace_id: this.getWorkspaces.activeWorkspace._id,
          },
          (response) => {
            if (response.data.status === true) {
              this.setConfirmActionStage('success')
              this.alertMessage(
                'Operation is performed successfully.',
                'success'
              )
              this.setVideoSelectedAutomations([])
              this.setVideoAutomationListingSelectedAll(false)
              if (this.$route.name === 'videoBlogAutomationListing') {
                this.refetchVideoBlogAutomations()
              } else {
                this.refetchVideoSocialAutomations()
              }

              this.fetchAutomationsCounts()
              if (operation === 'Delete') {
                // this.setPlannerVideoAutomationsFetchStatus(false)
              }
            } else {
              this.alertMessage(
                'Unable to perform operation.Please try again.',
                'error'
              )
              this.setConfirmActionStage('fail')
            }

            this.setVideoAutomationBulkOperationLoader(false)
            this.closeConfirmAction(confirmation.type)
          },
          (error) => {
            console.debug('Exception in videoAutomationBulkOperation', error)
            this.setConfirmActionStage('fail')
            this.setVideoAutomationBulkOperationLoader(false)
            this.closeConfirmAction(confirmation.type)
          }
        )
      } else {
        $('#' + confirmation.type).modal('hide')
      }
    },
  },
}
