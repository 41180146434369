<template>
  <div class="space-y-4">
    <div class="flex justify-between items-center">
      <label class="text-sm font-medium text-[#3A4557]"
        >Topics
        <i
          v-tooltip="'AI-generated topics help define the themes and subjects for your content. You can edit, delete, or add custom topics'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i>
      </label>
      <Button variant="outline" size="sm" @click="() => showModal()">
        <i class="fas fa-plus"></i>
        Add New Topic
      </Button>
    </div>

    <div class="space-y-3 max-h-[400px] overflow-y-auto no-scrollbar">
      <div
        v-for="(topic, index) in brandInfo.topics"
        :key="index"
        class="m-1 px-4 py-2 rounded-xl border border-[#E2E8F0] cst-editor cursor-pointer"
      >
        <div class="flex items-start justify-between gap-1">
          <div class="flex-1">
            <h3 class="text-sm font-medium text-[#3A4557]">{{
              topic?.name
            }}</h3>
            <p v-tooltip="topic?.description.length > 70 ? topic?.description : ''"  class="mt-1 text-sm text-[#64748B] line-clamp-1">{{
              truncateText(topic?.description)
            }}</p>
          </div>
          <div class="flex items-center my-auto relative">
            <Dropdown
              placement="bottom-end"
              button-classes="border-none hover:!border-none !px-0 !gap-0"
              container-classes="!w-auto"
              dropdown-classes="!z-[1050]"
              @click.stop
            >
              <template v-slot:selected>
                <i class="fa fa-ellipsis-v cursor-pointer text-gray-800 p-2 rounded-md hover:bg-[#F5F7FF]"></i>
              </template>
              <template v-slot:arrow>
                <span></span>
              </template>
              <DropdownItem
                class="flex items-center gap-2 !px-3 !py-2"
                @click="showModal(topic, index)"
              >
                <i class="far fa-pencil text-[#2294FF]"></i>
                <span>Edit</span>
              </DropdownItem>
              <DropdownItem
                class="flex items-center gap-2 !px-3 !py-2"
                @click="removeTopic(index)"
              >
                <i class="far fa-trash text-[#EE446E]"></i>
                <span>Delete</span>
              </DropdownItem>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>

    <div v-if="brandInfo.topics.length === 0" class="text-center py-8">
      <i class="fas fa-book text-4xl text-[#94A3B8]"></i>
      <p class="mt-2 text-[#64748B]"
        >No topics added yet. Click "Add New Topic" to get started.</p
      >
    </div>
  </div>

  <AddTopicModal
    ref="addTopicModal"
    @hide="handleModalHide"
    @save="handleSave"
  />
</template>

<script setup>
import { ref } from 'vue'
import { Button, Dropdown, DropdownItem } from '@contentstudio/ui'
import AddTopicModal from '@modules/publisher/ai-content-library/components/modals/AddTopicModal.vue'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'

const { brandInfo } = useSetup()
const addTopicModal = ref(null)
const editingIndex = ref(-1)

const showModal = (topic = null, index = -1) => {
  editingIndex.value = index
  addTopicModal.value?.show(topic)
}

const handleModalHide = () => {
  editingIndex.value = -1
}

const handleSave = (topicData) => {
  if (editingIndex.value === -1) {
    // Add new topic
    brandInfo.topics.unshift(topicData)
  } else {
    // Update existing topic
    brandInfo.topics[editingIndex.value] = topicData
  }
}

const removeTopic = (index) => {
  brandInfo.topics.splice(index, 1)
}

const truncateText = (text) => {
  if (!text) return '';
  return text.length > 70 ? text.substring(0, 70) + '...' : text;
}
</script>
