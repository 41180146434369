import { ref } from 'vue'
import { useStore } from '@/src/store/base'
import { useSetup } from '@/src/modules/publisher/ai-content-library/composables/useSetup'
import {
  addBrandVoiceURL,
  addStyleURL,
  analyzeBrandURL,
  deleteBrandVoiceURL,
  deleteStyleURL,
  updateBrandVoiceURL,
  updatePostGenerationSettingsURL,
  updateStyleURL,
} from '@/src/modules/publisher/config/api-utils'
import proxy from '@/src/modules/common/lib/http-common'

export const useBrandInfoSetting = () => {
  const { dispatch, getters } = useStore()
  const { AIUserProfile, setupInfo, brandInfo } = useSetup()

  const showLoader = ref(false)
  const styleAnalyzed = ref(false)
  const voiceAnalyzed = ref(false)

  // Add Style (Custom)
  const addStyleCustom = async () => {
    try {
      const payload = {
        workspace_id: getters.getActiveWorkspace._id,
        profile_id: AIUserProfile.value._id,
        name: setupInfo.custom.styleName,
        logo: setupInfo.custom.logo,
        colors: {
          brand_color: setupInfo.custom.brandColor,
          background_color: setupInfo.custom.backgroundColor,
          text_color: setupInfo.custom.textColor,
        },
        heading_font: setupInfo.custom.headingFont,
        body_font: setupInfo.custom.bodyFont,
      }

      const response = await proxy.post(addStyleURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to add style')
      }
      console.debug('addStyleCustom:response', response.data)
      dispatch('toastNotification', {
        message: 'Style added successfully',
        type: 'success',
      })

      AIUserProfile.value = response.data.data

      // reset states
      styleAnalyzed.value = false
      showLoader.value = false
    } catch (error) {
      console.error('Error adding style:', error)
      dispatch('toastNotification', {
        message: 'Failed to add style',
        type: 'error',
      })
    }
  }

  const handleAddStyle = async () => {
    const payload = {
      workspace_id: getters.getActiveWorkspace._id,
      website_url: setupInfo.website.url,
      social_accounts: setupInfo.social,
      type: 'style', // we will reuse this later for styles and brand voice
    }

    try {
      showLoader.value = true

      console.debug('saveSetupInfo: Sending payload:', payload)

      // Make the API call
      const response = await proxy.post(analyzeBrandURL, payload)

      console.debug('saveSetupInfo: Response:', response)

      if (!response.data || !response.data.status) {
        throw new Error('Failed to save setup information')
      }

      // Map API response to setupInfo custom state
      const data = response.data?.data

      // setupInfo.custom = data

      styleAnalyzed.value = true

      if (!data) {
        throw new Error('No profile data returned from the server')
      }

      console.debug('saveSetupInfo: Profile data:', data)

      setupInfo.custom = {
        id: data.id,
        brandColor: data.colors.brand_color,
        backgroundColor: data.colors.background_color,
        textColor: data.colors.text_color,
        styleName: data.name,
        logo: data.logo,
        headingFont: data.heading_font,
        bodyFont: data.body_font,
      }
    } catch (error) {
      styleAnalyzed.value = false
      console.error('Error saving setup information:', error)
      dispatch('toastNotification', {
        message: 'Failed to save setup information',
        type: 'error',
      })
    } finally {
      showLoader.value = false
    }
  }

  const updateStyle = async () => {
    try {
      const payload = {
        workspace_id: getters.getActiveWorkspace._id,
        profile_id: AIUserProfile.value._id,
        id: setupInfo.custom.id,
        name: setupInfo.custom.styleName,
        logo: setupInfo.custom.logo,
        colors: {
          brand_color: setupInfo.custom.brandColor,
          background_color: setupInfo.custom.backgroundColor,
          text_color: setupInfo.custom.textColor,
        },
        heading_font: setupInfo.custom.headingFont,
        body_font: setupInfo.custom.bodyFont,
        is_setup_complete: true
      }

      const response = await proxy.post(updateStyleURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to update style')
      }
      console.debug('updateStyle:response', response.data)
      dispatch('toastNotification', {
        message: 'Style updated successfully',
        type: 'success',
      })

      // update the profile
      AIUserProfile.value = response.data.data
    } catch (error) {
      console.error('Error updating style:', error)
      dispatch('toastNotification', {
        message: 'Failed to update style',
        type: 'error',
      })
    }
  }

  // Delete Style
  const deleteStyle = async (id) => {

    try {
      const response = await proxy.delete(`${deleteStyleURL}?workspace_id=${getters.getActiveWorkspace._id}&profile_id=${AIUserProfile.value._id}&id=${id}`)
      if (!response.data.status) {
        throw new Error('Failed to delete style')
      }
      console.debug('deleteStyle:response', response.data)
      dispatch('toastNotification', {
        message: 'Style deleted successfully',
        type: 'success',
      })

      // update the profile styles
      AIUserProfile.value.styles = AIUserProfile.value.styles?.filter((style) => style.id !== id)
    } catch (error) {
      console.error('Error deleting style:', error)
      dispatch('toastNotification', {
        message: 'Failed to delete style',
        type: 'error',
      })
    }
  }

  // Update post generation setting ( select style or brand voice for post generation)
  const updatePostGenerationSettings = async(type, id) => {
    try {
      const payload = {
        workspace_id: getters.getActiveWorkspace._id,
        profile_id: AIUserProfile.value._id,
        type,
        id
      }
      const response = await proxy.post(updatePostGenerationSettingsURL , payload)
      if (!response.data.status) {
        throw new Error('Failed to update post generation settings')
      }
      console.debug('updatePostGenerationSettings:response', response.data)

      console.debug('updatePostGenerationSettings:AIUserProfile before update', AIUserProfile.value)

      AIUserProfile.value = response.data?.data

      console.debug('updatePostGenerationSettings:AIUserProfile updated', AIUserProfile.value)
      dispatch('toastNotification', {
        message: response.data.message,
        type: 'success',
      })
    }catch(error){
      console.error('Error updating post generation settings:', error)
      dispatch('toastNotification', {
        message: 'Failed to update post generation settings',
        type: 'error',
      })
    }
  }


  const handleAnalyzeBrandVoice = async () => {
    const payload = {
      workspace_id: getters.getActiveWorkspace._id,
      website_url: setupInfo.website.url,
      files: setupInfo.uploads.files.map((file) => file.url),
      text: setupInfo.text.content,
      social_accounts: setupInfo.social,
      type: 'brand_voice',
    }

    try {
      showLoader.value = true
      const response = await proxy.post(analyzeBrandURL, payload)

      if (!response.data || !response.data.status) {
        throw new Error('Failed to analyze brand voice')
      }

      const data = response.data?.data
      voiceAnalyzed.value = true

      if (!data) {
        throw new Error('No profile data returned from the server')
      }

      brandInfo.strategy = {
        id: data.id || null, // Store strategy ID
        brandVoice: data.strategy?.voice || '',
        niche: data.strategy?.niche || '',
        audience: data.strategy?.audience || '',
        tone: Array.isArray(data.strategy?.tone) ? data.strategy.tone : [],
        language: Array.isArray(data.strategy?.language)
          ? data.strategy.language
          : [],
        emotion: Array.isArray(data.strategy?.emotion)
          ? data.strategy.emotion
          : [],
        competitors: Array.isArray(data.strategy?.competitors)
          ? data.strategy.competitors
          : [],
      }
      brandInfo.topics = data.topics || []
      brandInfo.name = data.name
      brandInfo.color = data.color



    } catch (error) {
      voiceAnalyzed.value = false
      console.error('Error analyzing brand voice:', error)
      dispatch('toastNotification', {
        message: error?.response?.data?.message || 'Failed to analyze brand voice',
        type: 'error',
      })
    } finally {
      showLoader.value = false
    }
  }


  const handleAddBrandVoice = async () => {
    try {
      const payload = {
        workspace_id: getters.getActiveWorkspace._id,
        profile_id: AIUserProfile.value._id,
        strategy: {
          ...brandInfo.strategy,
          voice: brandInfo.strategy.brandVoice,
        },
        topics: brandInfo.topics,
        name: brandInfo.name,
        color: brandInfo.color,
      }

      const response = await proxy.post(addBrandVoiceURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to add brand voice')
      }

      console.debug('handleAddBrandVoice:response', response.data)

      dispatch('toastNotification', {
        message:'Brand voice added successfully',
        type:'success'
      })

      // update the profile
      AIUserProfile.value = response.data.data

      // reset state
      voiceAnalyzed.value = false
    } catch (error) {
      console.error('Error adding brand voice:', error)
      dispatch('toastNotification',{
        message:'Failed to add brand voice',
        type:'error'
      })
    }
  }

  const handleUpdateBrandVoice = async () => {
    try {
      const payload = {
        workspace_id: getters.getActiveWorkspace._id,
        profile_id: AIUserProfile.value._id,
        id: brandInfo.brandVoiceId,
        strategy: {
          ...brandInfo.strategy,
          voice: brandInfo.strategy.brandVoice,
        },
        topics: brandInfo.topics,
        name: brandInfo.name,
        color: brandInfo.color,
      }

      const response = await proxy.post(updateBrandVoiceURL, payload)
      if (!response.data.status) {
        throw new Error('Failed to add brand voice')
      }

      console.debug('handleAddBrandVoice:response', response.data)

      dispatch('toastNotification', {
        message:'Brand voice updated successfully',
        type:'success'
      })

      // update the profile
      AIUserProfile.value = response.data.data
    } catch (error) {
      console.error('Error adding brand voice:', error)
      dispatch('toastNotification',{
        message:'Failed to add brand voice',
        type:'error'
      })
    }


  }

  const deleteBrandVoice = async (id) => {
    try {
      const response = await proxy.delete(`${deleteBrandVoiceURL}?workspace_id=${getters.getActiveWorkspace._id}&profile_id=${AIUserProfile.value._id}&id=${id}`)
      if (!response.data.status) {
        throw new Error('Failed to delete brand voice')
      }
      console.debug('deleteBrandVoice:response', response.data)
      dispatch('toastNotification', {
        message: 'Brand voice deleted successfully',
        type: 'success',
      })

      // update the profile styles
      AIUserProfile.value.brand_voices = AIUserProfile.value.brand_voices?.filter((style) => style.id !== id)
    } catch (error) {
      console.error('Error deleting brand voice:', error)
      dispatch('toastNotification', {
        message: 'Failed to delete brand voice',
        type: 'error',
      })
    }
  }


  return {
    addStyleCustom,
    handleAddStyle,
    showLoader,
    updateStyle,
    styleAnalyzed,
    voiceAnalyzed,
    deleteStyle,
    updatePostGenerationSettings,
    handleAddBrandVoice,
    handleAnalyzeBrandVoice,
    handleUpdateBrandVoice,
    deleteBrandVoice
  }
}
