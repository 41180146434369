<template>
  <div class="ai-insights-section">
    <div v-if="isLoadingStates.ai_insights" class="py-6 px-40">
      <div class="flex items-center justify-center mt-2">
        <AiGenerationLoader
          :loading="isLoadingStates.ai_insights"
          :loading-steps="loadingSteps"
          text="Our AI Bot is busy generating insights!"
          orientation="horizontal"
          />
      </div>
    </div>

    <div
      v-else-if="!aiInsightsData.key_insights?.length"
      class="flex items-center justify-center min-h-[300px] text-gray-900">
        <div class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>{{ aiInsightsData.message ?? 'No insights available at the moment' }}</p>
        </div>
    </div>

    <template v-else>
      <div v-for="(section, index) in aiInsightsData.key_insights" :key="index">
        <div v-if="props.indexesToShow.includes(index)" class="bg-gray-cs-50 mb-8 shadow-[0px_3px_10px_0px_rgba(211,217,236,0.30)]">
          <CstCollapsible
            :id="section.category"
            :open="openCollapsible(section.category)"
            @header-click="toggleSection(section.category)"
          >
            <template v-slot:header>
              <div class="flex items-center gap-3 py-2 px-2 cursor-pointer w-full">
                <img
                  :src="getCategoryIcon(section.category)"
                  :alt="section.category"
                  class="w-5 h-5"
                />
                <h2 class="font-medium" style="font-size: 16px">{{ section.category }}</h2>
              </div>
            </template>

            <div class="p-4">
              <!-- Key Insights Section -->
              <div class="mb-6">
                <div class="space-y-4">
                  <div
                    v-for="(insight, idx) in section.insights" :key="idx"
                    class="p-4 bg-white border border-gray-200 rounded-lg">
                    <h4 class="font-medium text-black mb-2" style="font-size: 14px">{{ insight.title }}</h4>
                    <p class="text-black" style="font-size: 13px">{{ insight.description }}</p>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <!-- What's Working Section -->
                <div class="bg-green-50 p-4 rounded-lg">
                  <h3 class="font-medium mb-3 flex items-center text-black" style="font-size: 14px">
                    <img
                      :src="TickIcon"
                      :alt="working"
                      class="w-4 h-4 mr-2"
                    />
                    What's Working
                  </h3>
                  <ul class="list-disc space-y-2">
                    <li v-for="(item, idx) in section.whats_working" :key="idx" class="text-black flex" style="font-size: 13px">
                      <span class="text-gray-900 mr-2 font-bold" style="font-size: 16px">•</span>
                      <span>{{ item }}</span>
                    </li>
                  </ul>
                </div>

                <!-- What's Not Working Section -->
                <div class="bg-red-50 p-4 rounded-lg">
                  <h3 class="font-medium mb-3 flex items-center text-red-700" style="font-size: 14px">
                    <img
                      :src="CrossIcon"
                      :alt="not_working"
                      class="w-4 h-4 mr-2"
                    />
                    What's Not Working
                  </h3>
                  <ul class="list-disc space-y-2">
                    <li v-for="(item, idx) in section.whats_not_working" :key="idx" class="text-black flex" style="font-size: 13px">
                      <span class="text-black mr-2" style="font-size: 13px">•</span>
                      <span>{{ item }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </CstCollapsible>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { debounce } from 'lodash'
import AiGenerationLoader from '@src/components/common/AiGenerationLoader.vue'
import CstCollapsible from '@ui/Collapse/CstCollapsible'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics'
import CrossIcon from '@/src/assets/img/icons/analytic/colored/cross-ai-insights-icon.svg'
import TickIcon from '@/src/assets/img/icons/analytic/colored/tick-ai-insights-icon.svg'
import ContentTypeIcon from '@/src/assets/img/icons/analytic/colored/content-type-ai-insights.svg'
import EngagementIcon from '@/src/assets/img/icons/analytic/colored/network-engagement-ai-insights.svg'
import PublishingBehaviourIcon from '@/src/assets/img/icons/analytic/colored/timing-analysis-ai-insights.svg'
import DemographicsIcon from '@/src/assets/img/icons/analytic/colored/following-icon.svg'

// Destructure necessary properties and methods from useLinkedinAnalytics composable
const {
  selectedAccount,
  routes,
  dateRange,
  isLoadingStates,
  aiInsightsData,
  fetchMedia,
  getAIInsightsIcons
} = useLinkedinAnalytics()

const props = defineProps({
  indexesToShow: {
    type: Array,
    default() {
      return [0, 1, 2, 3]
    },
  },
})

// Define initially open sections with LinkedIn-specific categories
const openSections = ref(new Set([
  'Network & Engagement Analysis',
  'Content Type & Theme Analysis',
  'Publishing Behavior & Timing Analysis',
  'Followers Demographics Analysis'
]))

const loadingSteps = ref([
  { message: 'Preparing queries', status: false },
  { message: 'Extracting data', status: false },
  { message: `AI doing its magic`, status: false },
  { message: `Generating insights!`, status: false },
])

const resetLoadingSteps = () => {
  loadingSteps.value = [
    { message: 'Preparing queries', status: false },
    { message: 'Extracting data', status: false },
    { message: `AI doing its magic`, status: false },
    { message: `Generating insights!`, status: false },
  ]
}

// Function to get the appropriate icon for each category
const getCategoryIcon = (category) => {
  // Fallback icon mapping
  const icons = {
    'Network & Engagement Analysis': EngagementIcon,
    'Content Type & Theme Analysis': ContentTypeIcon,
    'Publishing Behavior & Timing Analysis': PublishingBehaviourIcon,
    'Followers Demographics Analysis': DemographicsIcon,
  }
  return icons[category] || EngagementIcon
}

const fetchInsightsSummary = async () => {
  try {
    await fetchMedia(routes.AI_INSIGHTS, {type: 'insights_summary'})
    if (aiInsightsData?.value?.insights) {
      aiInsightsData.value = aiInsightsData?.value?.insights
      // Update open sections based on the returned categories
      openSections.value = new Set(aiInsightsData.value.key_insights.map(section => section.category))
    }
  } catch (error) {
    console.error('Error fetching LinkedIn AI insights:', error)
  } finally {
    isLoadingStates.ai_insights = false
  }
}

// Watch for changes in date range or selected account to refresh data
watch(
  () => [dateRange.value, selectedAccount.value],
  debounce(async () => {
    resetLoadingSteps()
    await fetchInsightsSummary()
  }, 100),
  { deep: true, immediate: true }
)

const openCollapsible = (section) => {
  return openSections.value.has(section)
}

const toggleSection = (section) => {
  if (openSections.value.has(section)) {
    openSections.value.delete(section)
  } else {
    openSections.value.add(section)
  }
}
</script>

<style scoped>
.ai-insights-section {
  @apply max-w-7xl mx-auto flex flex-col gap-4;
}
</style>
