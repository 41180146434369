import { apiUrl } from '@/src/config/api-utils'

export const uploadFileURL = apiUrl + 'aiContentLibrary/uploadfile'
export const analyzeBrandURL = apiUrl + 'aiContentLibrary/analyzeBrand'
export const updateBrandLogoURL = apiUrl + 'aiContentLibrary/uploadlogo'
export const updateBrandingURL = apiUrl + 'aiContentLibrary/profile/updateStyle'
export const updateStrategyURL =
  apiUrl + 'aiContentLibrary/profile/updateStrategy'
export const updateTopicsURL = apiUrl + 'aiContentLibrary/profile/updateTopics'
export const getProfileURL = apiUrl + 'aiContentLibrary/profile/get'
export const savePostSettingsURL =
  apiUrl + 'aiContentLibrary/profile/savePostGenerationSettings'
export const updateStyleNameURL =
  apiUrl + 'aiContentLibrary/profile/updateStyleName'
export const updateBrandVoiceNameURL =
  apiUrl + 'aiContentLibrary/profile/updateBrandVoiceName'
export const generatePostsURL = apiUrl + 'aiContentLibrary/post/generatePosts'
export const getPostsURL = apiUrl + 'aiContentLibrary/post/getPosts'
export const regeneratePostURL = apiUrl + 'aiContentLibrary/post/regeneratePost'
export const deletePostURL = apiUrl + 'aiContentLibrary/post/delete'
export const createSocialPostURL =
  apiUrl + 'aiContentLibrary/post/createSocialPost'
export const bulkActionURL = apiUrl + 'aiContentLibrary/post/bulkAction'
export const feedbackURL = apiUrl + 'aiContentLibrary/post/feedback'
export const addStyleURL = apiUrl + 'aiContentLibrary/profile/addStyle'
export const updateStyleURL = apiUrl + 'aiContentLibrary/profile/updateStyle'
export const deleteStyleURL = apiUrl + 'aiContentLibrary/profile/deleteStyle'
export const updatePostGenerationSettingsURL = apiUrl + 'aiContentLibrary/profile/updatePostGenerationSetting'
export const addBrandVoiceURL = apiUrl + 'aiContentLibrary/profile/addBrandVoice'
export const updateBrandVoiceURL = apiUrl + 'aiContentLibrary/profile/updateBrandVoice'
export const deleteBrandVoiceURL = apiUrl + 'aiContentLibrary/profile/deleteBrandVoice'
