<template>
  <div
  class="px-[13px] py-4 bottom-border-only cursor-pointer select-none flex gap-[14px] min-h-[120px] group"
  :class="{
    'bg-[#02B2FF0A]': isActive || isUnread,
    'border-l-2 border-[#02B2FF]': isActive,
    }"
  @click="onClick"
  >
  <div class="mt-2" @click.stop>
    <Checkbox
      :model-value="isChecked"
      @update:model-value="onCheckboxToggle"
    />
  </div>
    <div class="flex-1 flex gap-[14px]">
      <!-- account image here -->
       <div>
         <div class="relative">
           <img
             :src="postBy?.image || DEFAULT_IMAGE"
             :alt="postBy?.name"
             class="w-8 h-8 rounded-full border"
             @error="
               (event) => {
                 event.target.src = DEFAULT_IMAGE
               }
             "
           />
           <div class="absolute -bottom-1 -right-1">
             <img
               :src="getSocialImageRounded(item?.platform)"
               class="w-5 h-5 rounded-full"
               :alt="item?.platform"
             />
           </div>
         </div>
       </div>
      <div class="flex-1 flex flex-col gap-[14px]">
        <div class="flex flex-1 justify-between">
        <div class="flex flex-1 flex-col gap-[6px] w-[14.5rem]">
          <p class="text-sm font-semibold truncate" :class="{ 'opacity-70 group-hover:opacity-100': !isUnread && !isActive }">{{ postBy?.name }}</p>
          <p class="text-sm cstu-text-gray-500 truncate" :class="{ 'opacity-70 group-hover:opacity-100': !isUnread && !isActive, 'italic': lastMessage?.is_unsupported }" >
            <template v-if="isLastMessageNote">
              <img v-tooltip="'Note'" :src="inboxNoteIcon" alt="note icon" />
              {{ lastMessage?.message }}
            </template>
            <template v-else-if="lastMessage?.is_unsupported">
              {{ `This message may contain content types such as voice notes, stickers, GIFs, etc., which are currently unsupported due to API limitations.` }}
            </template>
            <template v-else>
              <img v-if="isLastMessageReply" v-tooltip="'Reply'" :src="inboxReplyIcon" alt="reply icon" />
              {{ lastMessageContent }}
            </template>
          </p>
        </div>
        <div class="flex flex-col justify-between items-end">
          <span
          :class="{
            'font-semibold text-sm': isUnread || isActive,
            'opacity-70': !isUnread && !isActive,
          }">{{ timeAgo }}</span>
          <template v-if="isUnread">
            <div class="">
              <div class="rounded-full bg-[#02B2FF] text-white text-xs flex justify-center items-center w-[19px] h-[19px] font-medium">
                {{ unreadCount }}
              </div>
            </div>
          </template>
        </div>
        </div>
        <div class="flex items-center justify-between">
          <template v-if="showItemType">
            <span
              class="px-[10px] py-1.5 flex items-center gap-[8px] text-xs rounded-lg h-[3]"
              :class="currentItemType.color"
             >
              <img :src="currentItemType.icon" alt="inbox type icon">
              {{ currentItemType.title }}
            </span>
          </template>
          <template v-else>
            <span></span>
          </template>
          <!-- last activity by photo -->
          <img
            v-tooltip="lastMessageBy?.name"
            class="w-8 h-8 rounded-full"
            :src="lastMessageBy?.profile_pic || DEFAULT_IMAGE"
            alt="user"
            @error="
              (event) => {
                event.target.src = DEFAULT_IMAGE
              }
            "
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { Checkbox } from '@contentstudio/ui'
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

const DEFAULT_IMAGE = 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
const { getSocialImageRounded, getUTCFormattedTimeZoneDate } = useComposerHelper()
const { inboxTypeChat, inboxTypeComment, inboxTypeReview, inboxNoteIcon, inboxReplyIcon } = useIcons()
const emit = defineEmits(['itemClick', 'checkboxToggle'])

const inboxItemType = {
  conversation: {
    title: 'Private Chat',
    color: 'bg-[#FD67271A]',
    icon: inboxTypeChat,
  },
  post: {
    title: 'Post Comments',
    color: 'bg-[#9747FF1A]',
    icon: inboxTypeComment,
  },
  review:{
    title: 'Reviews',
    color: 'bg-[#FFF7DE]',
    icon: inboxTypeReview,
  }
}

const props = defineProps({
  isUnread: {
    type: Boolean,
    default: false,
  },
  isActive: {
    type: Boolean,
    default: false,
  },
  item: {
    type: Object,
    required: true,
  },
  showItemType:{
    type: Boolean,
    default: true
  },
  isChecked: {
    type: Boolean,
    default: false,
  }
});

const checkboxValue = ref(false)

const lastMessage = computed(() => {
  return props.item?.last_message || props?.item?.last_comment
})

const isLastMessageAction = computed(() => {
  return lastMessage.value?.action
})

const hasAttachment = computed(() => {
  return lastMessage.value?.attachments?.length > 0 || lastMessage.value?.attachment?.length > 0
})

const isLastMessageNote = computed(() => {
  if(isLastMessageAction.value) {
    return lastMessage.value?.action?.type === 'NOTE'
  }
  return false
})

const isLastMessageReply = computed(() => {

  if(isReview.value && props.item?.review_reply?.review_message) return true

  const id = lastMessage.value?.from?.[0]?.id || lastMessage.value?.from?.[0]?.user_id
  return id === props.item?.platform_id
})

const isReview = computed(() => {
  return props?.item?.inbox_type === 'review'
})

const lastMessageBy = computed(() => {
  const res = {
    id: '',
    name: '',
    profile_pic: ''
  }
  if(isReview.value){
    // if review reply is available, then use platform details
    if(props?.item?.review_reply?.review_message ){
      res.id = props?.item?.platform_id
      res.name = props?.item?.platform_name
      res.profile_pic = props?.item?.platform_image || 'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
    }
    else{
      // else use post by details
      res.id = postBy.value?.user_id
      res.name = postBy.value?.name
      res.profile_pic = postBy.value?.image
    }
  }
  else if (isLastMessageAction.value) {
    res.id = lastMessage.value?.action?.action_performed_by?.user_id
    res.name = lastMessage.value?.action?.action_performed_by?.user_name
    res.profile_pic = lastMessage.value?.action?.action_performed_by?.user_image
  }
  else{
    res.id = lastMessage.value?.from?.[0]?.id
    res.name = `${lastMessage.value?.from?.[0]?.first_name} ${lastMessage.value?.from?.[0]?.last_name}`
    res.profile_pic = lastMessage.value?.from?.[0]?.profile_pic || lastMessage.value?.from?.[0]?.image
  }
  return res
})


const lastMessageContent = computed(() => {
  // Check if the message has an attachment
  if (hasAttachment.value) return 'Sent An Attachment'

  // Check if the last message is an action
  if (isLastMessageAction.value) return getActionMessage(lastMessage.value?.action)

  // Handle review-specific content
  if (isReview.value) {
    // Return review reply message if available
    if (props.item?.review_reply?.review_message) return props.item?.review_reply?.review_message
    // Otherwise, return the post message
    else return props.item?.element_details?.post_message
  }

  // Return the default message content
  return lastMessage.value?.message
})

const unreadCount = computed(() => {
  let count = 1
  if(props.item?.inbox_type === 'post'){
   count = props.item?.unread_comment_count
  }
  else{
    count = props.item?.unread_message_count
  }
  if (!count) return 1
  if (count > 9) return '9+'
  return count
})

const postBy = computed(() => {
  return props.item?.inbox_details?.posted_by
})

const currentItemType = computed(() => {
  return inboxItemType[props.item?.inbox_type] || inboxItemType.conversation
})

const timeAgo = computed(() => {
  return getUTCFormattedTimeZoneDate(props.item?.updated_at)?.fromNow();
})

const onClick = () => {
  emit('itemClick')
}

const getActionMessage = (action) => {
  const actionBy = action?.action_performed_by?.user_name || 'Unknown User'
  const assignedTo = action?.assigned_to?.user_name || 'Unknown User'

  switch(action.type) {
    case 'MARKED_AS_DONE':
      if(action?.is_marked_done) {
        return `Marked as Done by ${actionBy}`
      }
      else{
        return `Marked as Un-done by ${actionBy}`
      }
    case 'ARCHIVED':
      if(action?.is_archived) {
        return `Archived by ${actionBy}`
      }
      else{
        return `Unarchived by ${actionBy}`
      }
    case 'ASSIGNED':
      if(action?.is_assigned) {
        return `Assigned to ${assignedTo}`
      }
      else{
        return `Unassigned by ${actionBy}`
      }
    default:
      return ''
  }
}

const onCheckboxToggle = (value) => {
  checkboxValue.value = value
  emit('checkboxToggle', value)
}
</script>
