<template>
  <div class="mt-6">
    <div class="space-y-4">
      <!-- Brand Info Section -->
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Brand Info
          <i
            v-tooltip="
              'Manually input brand-related text. AI will analyze it to determine your brand’s attributes.'
            "
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <textarea
          v-model="setupInfo.text.content"
          placeholder="Add info about your brand..."
          rows="4"
          class="w-full border border-gray-300 rounded px-3 py-2.5 focus:outline-none focus:ring-2 focus:ring-blue-500 color-border cst-editor"
          :maxlength="maxCharacters"
        />
        <div class="flex justify-end mt-2">
          <span class="text-sm text-gray-700">
            {{ setupInfo.text.content.length }}/{{ maxCharacters }} characters
          </span>
        </div>
      </div>

      <!-- Text Examples Section -->
      <div>
        <label class="block mb-3 font-medium text-gray-900">
          Try some text examples below
          <i
            v-tooltip="'Click to use these examples as a starting point'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-3 mt-3">
          <div
            v-for="(example, index) in textExamples"
            :key="index"
            :class="[
              'relative p-4 border-gray-500 cursor-pointer transition-all duration-200 group text-example-container',
              { 'border-r': index !== textExamples.length - 1 },
            ]"
            @click="selectExample(example)"
          >
            <i
              class="far fa-copy absolute top-4 right-4 text-gray-400 group-hover:text-primary-cs"
            ></i>
            <p class="text-sm text-gray-800 pr-2 text-example">{{ example }}</p>
            <div class="text-fade-overlay"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'

const { setupInfo } = useSetup()
const maxCharacters = 1000

const textExamples = [
  'The easiest way to manage and grow your social channels. Stay organized and get more done in less time with our simple yet powerful tools.',
  'Transform your social media presence with our comprehensive management solution. Perfect for businesses looking to streamline their content strategy.',
  'Your all-in-one platform for social media success. Create, schedule, and analyze your content with professional tools designed for growth.',
]

const selectExample = (example) => {
  setupInfo.text.content = example
}
</script>

<style scoped>
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-example-container {
  position: relative;
  overflow: hidden;
  max-height: 120px;
}

.text-example {
  max-height: 100px;
  overflow: hidden;
}

.text-fade-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
  pointer-events: none;
}
</style>
