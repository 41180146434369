<script setup>
import { computed } from 'vue'
import AnalyticsFilterBarWrapper from '@src/modules/analytics/views/common/AnalyticsFilterBarWrapper.vue'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import MultipleAccountSelect from '@src/modules/analytics/views/common/MultipleAccountSelect.vue'
import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'

const { selectAllAccounts } = useAnalyticsUtils()

const { IGNORE_PLATFORMS, dateRange, selectedAccount, isAllAccountsSelected } =
useOverviewAnalytics()

const getAllAccounts = computed(() => {
  return selectAllAccounts(IGNORE_PLATFORMS)
})
const disabled = computed(() => {
  return getAllAccounts.value?.length === 0;
})

const handleDatechange = ({ dateRange }) => {
  setDateRange(dateRange)
}
const setDateRange = (newDate) => {
  dateRange.value = newDate.split(' - ')
}
const handleAccountsChange = (data) => {
  selectedAccount.value = data.value
  isAllAccountsSelected.value = data.allSelected
}

</script>

<template>
  <AnalyticsFilterBarWrapper
    type="overview"
    :selected-accounts="selectedAccount"
    :disabled="disabled"
    @date-change="handleDatechange"
  >
    <template v-slot:left>
      <div class="mr-3">
        <MultipleAccountSelect
          :ignore-accounts="IGNORE_PLATFORMS"
          @on-accounts-change="handleAccountsChange"
        />
      </div>
    </template>
  </AnalyticsFilterBarWrapper>
</template>
