<!-- eslint-disable vue/no-setup-props-destructure -->
<script setup>
import { computed, ref, watch } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/youtube/components/graphs/MainGraph.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useDateFormat from '@common/composables/useDateFormat'
import useYoutubeAnalytics from '@/src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import { EventBus } from '@common/lib/event-bus'
import TopPostsModal from '@src/modules/analytics/views/youtube/components/TopPostsModal.vue'
const props = defineProps({
  label: {
    type: String,
    default: 'Engagements',
  },
  defaultType: {
    type: String,
    default: 'Engagements',
    validator: (v) => {
      return ['Engagements', 'Video Views'].includes(v)
    },
  },
})

const {
  legendOptions,
  isLoadingStates,
  dataZoomOptions,
  videosAndEngagementCharts,
  barChartOptions,
  isReportView,
  analyticsDesignSystem,
  DUAL_GRAPHS,
  channelPerformanceAndVideoPostingScheduleData: chartData,
  getDualGraphTooltip,
  fetchMedia,
  routes,
  allTopPosts,
} = useYoutubeAnalytics(props.label)

const { momentWrapper } = useDateFormat()

const engagementChartTypes = [
  {
    name: 'Likes',
    stack: 'Engagements',
    api_response_key: 'likes',
    color: '#8AC1A2',
    yIndex: 0,
  },
  {
    name: 'Dislikes',
    stack: 'Engagements',
    api_response_key: 'dislikes',
    color: '#F67676',
    yIndex: 0,
  },
  {
    name: 'Comments',
    stack: 'Engagements',
    api_response_key: 'comments',
    color: '#8081B9',
    yIndex: 0,
  },

  {
    name: 'Shares',
    stack: 'Engagements',
    api_response_key: 'shares',
    color: '#F2CA6B',
    yIndex: 0,
  },
  {
    name: 'Videos',
    api_response_key: 'count',
    color: '#61BAE4',
    yIndex: 1,
  },
]

const videoViewsChartTypes = [
  {
    name: 'Subscriber Views',
    stack: 'Views',
    api_response_key: 'subscriber_views',
    color: '#8AC1A2',
    yIndex: 0,
  },
  {
    name: 'Non-Subscriber Views',
    stack: 'Views',
    api_response_key: 'non_subscriber_views',
    color: '#8081B9',
    yIndex: 0,
  },
  {
    name: 'Videos',
    api_response_key: 'count',
    color: '#61BAE4',
    yIndex: 1,
  },
]

const selectedOptionType = ref(
  videosAndEngagementCharts.value?.find((i) => i.name === props.defaultType)
)

const openedModal = ref(false)

const selectedChartType = computed(() =>
  selectedOptionType.value?.name === 'Engagements'
    ? engagementChartTypes
    : videoViewsChartTypes
)
const yAxisFirstIndex = computed(() => {
  return Array.isArray(barChartOptions.value?.yAxis)
    ? barChartOptions.value.yAxis[0]
    : barChartOptions.value.yAxis
})
const yAxisSecondIndex = computed(() => {
  return Array.isArray(barChartOptions.value?.yAxis)
    ? barChartOptions.value.yAxis[1]
    : barChartOptions.value.yAxis
})

const noDataFound = computed(() => {
  return !chartData.value[selectedOptionType.value?.api_response_key]?.buckets
    ?.length
})

/**
 * @description returns the yAxis options for the chart on the basis of the selected option type
 */
const getYAxisOptions = () => {
  if (selectedOptionType.value?.name === 'Engagements') {
    return [
      {
        ...yAxisFirstIndex.value,
        splitLine: {
          show: false,
        },
        name: `{likes|} {dislikes|} {comments|} {shares|} {b|${selectedOptionType.value?.name}}`,
        nameTextStyle: {
          rich: {
            b: { color: '#979CA0', fontSize: 12, padding: [0, 0, 0, 6] },
            likes: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#8AC1A2',
            },
            dislikes: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#F67676',
            },
            comments: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#8081B9',
            },
            shares: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#F2CA6B',
            },
          },
        },
        position: 'left',
      },
      {
        ...yAxisSecondIndex.value,
        position: 'right',
        name: `{videos|} {b|Videos}`,
        nameTextStyle: {
          rich: {
            b: { color: '#979CA0', fontSize: 12, padding: [0, 0, 0, 6] },
            videos: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#61BAE4',
            },
          },
        },
      },
    ]
  } else {
    return [
      {
        ...yAxisFirstIndex.value,
        splitLine: {
          show: false,
        },
        position: 'left',
        name: `{subscribers|} {nonSubscribers|} {b|${selectedOptionType.value?.name}}`,
        nameTextStyle: {
          rich: {
            b: { color: '#979CA0', fontSize: 12, padding: [0, 0, 0, 6] },
            subscribers: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#8AC1A2',
            },
            nonSubscribers: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#8081B9',
            },
          },
        },
      },
      {
        ...yAxisSecondIndex.value,
        position: 'right',
        name: `{videos|} {b|Videos}`,
        nameTextStyle: {
          rich: {
            b: { color: '#979CA0', fontSize: 12, padding: [0, 0, 0, 6] },
            videos: {
              ...analyticsDesignSystem?.graphs?.labelBoxStyle,
              backgroundColor: '#61BAE4',
            },
          },
        },
      },
    ]
  }
}

/**
 * @description returns
 * @param options
 * @param chart
 */
const getSeriesOptions = (options = {}, chart = {}) => {
  if (!chart || !options) return {}

  return {
    name: chart.name,
    type: 'bar',
    data: options[chart && chart?.api_response_key] || [],
    color: chart.color,
    colorBy: 'series',
    barMaxWidth: analyticsDesignSystem?.graphs?.dualBarMaxWidth,
    stack: chart.stack,
    yAxisIndex: chart.yIndex,
    areaStyle: { opacity: 0.4, cursor: 'pointer' },
    cursor: 'pointer',
  }
}

watch(
  () => [chartData, selectedOptionType],
  () => {
    const options =
      chartData.value[selectedOptionType.value?.api_response_key] || {}

    barChartOptions.value.legend = {
      ...legendOptions,
      data: selectedChartType.value?.map(
        (chartType) => !chartType.yIndex && chartType.name
      ),
    }

    barChartOptions.value.tooltip.formatter = tooltipFormatter

    barChartOptions.value.series = selectedChartType.value?.map((chart) => {
      return getSeriesOptions(options, chart)
    })

    barChartOptions.value.yAxis = getYAxisOptions()

    barChartOptions.value.xAxis.data = options?.buckets
  },
  {
    deep: true,
    immediate: true,
  }
)

const tooltipFormatter = (series) => {
  const { axisValue: axisDate } = series[0]
  const date = new Date(axisDate)
  let totalEngagement = 0
  let html = ''

  series.forEach((series) => {
    const { seriesName, data: value } = series
    html += `
    <div class="flex items-end justify-between mt-2.5">
      <span class="text-[979CA0] text-sm mr-1.5">${seriesName}: </span>
      <span class="text-[#69788E] text-sm font-bold">${value}</span>
    </div>`

    if (
      seriesName === 'Videos' &&
      selectedOptionType.value?.name === 'Engagements'
    )
      return

    totalEngagement += value
  })

  return `
      <div class="flex flex-col">
        <div class="flex items-center">
          <span class="text-[#000D21] font-weight-300">${momentWrapper(
            date
          ).formatDate()}</span>
        </div>
        ${
          selectedOptionType.value?.name === 'Video Views'
            ? ''
            : `<div class="flex items-end justify-between mt-2.5">
          <span class="text-[979CA0] text-sm mr-1.5">Engagements:  </span>
          <span class="text-[#69788E] text-sm font-bold">${totalEngagement}</span>
        </div>`
        }
        ${html}
      </div>
  `
}

const customClickHandler = async (params) => {
  if (params?.name) {
    const selectedDate = params?.name
    const dateRange = `${selectedDate} - ${selectedDate}`

    // Fetch videos for the selected date
    fetchMedia(routes.SORTED_TOP_POSTS, { date: dateRange, limit: 200, order_by: 'engagement' })

    if (allTopPosts?.value) {
      EventBus.$emit('show-top-posts-modal', {
        date: selectedDate,
        posts: selectedOptionType.value?.name === 'Video Views' ? 'Videos published on ' : 'Videos with engagements on '
      })
    }
  }
}
</script>

<template>
  <AnalyticsCardWrapper
    :type="`specific-type-${Math.random()}`"
    :enable-modal="true"
    :is-modal="openedModal"
    @toggle-is-modal="(value) => (openedModal = value)"
    :show-insights-button="!isReportView"
    :selected-dropdown-option="selectedOptionType.name"
    :enable-insights="true"
    platform="youtube"
  >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <CstDropdown
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            dropdown-classes="!rounded-md !border-[#70707029]"
            button-classes="flex !px-5 !w-[22rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
          >
            <template v-slot:arrow>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </template>
            <template v-slot:selected>
              <p class="text-base font-weight-500 capitalize">
                {{ selectedOptionType.name }} vs Daily Posting Pattern
              </p>
            </template>
            <template v-slot>
              <template
                v-for="(item, index) in videosAndEngagementCharts"
                :key="`activity_type_${index}`"
              >
                <CstDropdownItem @click="selectedOptionType = item">
                  <p class="text-gray-900 text-sm"
                    >{{ item.name }} vs Daily Posting Pattern</p
                  >
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>
          <v-menu
            v-if="!isReportView"
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                {{
                  getDualGraphTooltip(
                    DUAL_GRAPHS.PERFORMANCE,
                    selectedOptionType.api_response_key
                  )
                }}
              </p>
              <template v-if="selectedOptionType.name === 'Engagements'">
                <p class="text-xs mt-2 opacity-70"
                  >Note: These insights are calculated by ContentStudio and are
                  not directly sourced from YouTube.</p
                >
              </template>
            </template>
          </v-menu>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div v-if="noDataFound" class="analytics-no-data-found">
        <img
          src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
          alt="No analytical data available"
        />
        <p>No data found.</p>
      </div>
      <div v-else class="flex-1">
        <MainGraph
          :key="Math.random()"
          :type="selectedOptionType.chartType"
          :chart-options="
            slotProps.isModal
              ? {
                  ...barChartOptions,
                  ...dataZoomOptions,
                  grid: { ...barChartOptions.grid, bottom: 70 },
                }
              : barChartOptions
          "
          :is-modal="slotProps.isModal"
          :custom-click-handler="customClickHandler"
          :custom-modal-height="slotProps.isInsights ? '40vh' : '80vh'"
        />
      </div>
    </template>
  </AnalyticsCardWrapper>
  <TopPostsModal />
</template>
