<template>
  <button
    v-tooltip="tooltipText"
    class="flex items-center justify-center"
    @click="handleClick"
  >
    <i
      class="text-xs leading-none"
      :class="{
        'far fa-bookmark bg-white text-[#FFC107]': !isBookmarked,
        'fas fa-bookmark text-[#FFC107]': isBookmarked,
      }"
    ></i>
  </button>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  isBookmarked: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([ 'click'])

const tooltipText = computed(() => {
  return props.isBookmarked ? 'Remove from Bookmarks' : 'Add to Bookmarks'
})

const handleClick = () => {
  emit('click', !props.isBookmarked)
}
</script>
