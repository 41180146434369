<script setup>
import { computed, watch, ref } from 'vue'
import MainGraph from '@src/modules/analytics/views/overviewV2/components/graphs/MainGraph.vue'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'
import * as echarts from 'echarts/core';

const {
  lineChartOptions,
  dataZoomOptions,
  isReportView,
  isLoadingStates,
  routes,
  PLATFORM_TITLE_AND_COLOR,
  cardsData,
  isNoAnalyticsData
} = useOverviewAnalytics()

const props = defineProps({
  title: {
    type: String,
    default: 'impressions',
  },
})

const titleMapper = (title) => {
  const titleMapping = {
    Impressions: { key: "impressions", color: "#4CAF50" },
    Engagements: { key: "engagements", color: "#2196F3" },
    Posts: { key: "top_posts", color: "#FF9800" },
    Followers: { key: "followers", color: "#FF0000" }
  }
  return titleMapping[title] || { key: 'impressions', color: '#4CAF50' }
}

const chartHeight = ref('45px')
const chartWidth = ref('10rem')

const cardsGraphData = computed(() => {
  const data = cardsData.value?.graphs || {}
  if (!lineChartOptions.value) return {}

  // Create a new object instead of modifying the existing one
  const chartOptions = {
    ...lineChartOptions.value,
    grid: {
      top: '10%',
      left: '5%',
      right: '5%',
      bottom: '5%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: data.buckets || [],
      axisLine: { show: false },
      axisLabel: { show: false },
      splitLine: { show: false },
      axisTick: { show: false },
      boundaryGap: '0%',
    },
    yAxis: {
      min: 'dataMin',
      type: 'value',
      splitLine: true,
      alignTicks: true,
      axisLabel: { show: false },
      axisTick: {
        show: false,
        splitNumber: 4,
      },
    },
    series: [{
      name: props.title,
      data: data[titleMapper(props.title).key],
      type: "line",
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: titleMapper(props.title).color,  // Start color (top)
          },
          {
            offset: 1,
            color: '#FFFFFF',  // End color (bottom)
            opacity: 0.1
          }
        ])
       },
      lineStyle: {
        width: 2,
        opacity: 0.6,
      },
      showSymbol: false,
      emphasis: { focus: 'series' },
      animation: true,
      color: titleMapper(props.title).color
    }]
  }

  return chartOptions
})

const isNoData = computed(() => {
  return isNoAnalyticsData(cardsData.value?.graph)
})
</script>

<template>
  <div class="flex items-center">
      <div v-if="isLoadingStates[routes?.SUMMARY]" class="w-full">
        <SkeletonBox :height="chartHeight" :width="chartWidth" />
      </div>
      <MainGraph
        v-else-if="isNoData"
        type="line"
        :chart-options="cardsGraphData"
        :is-modal="isReportView"
        :style="{ height: chartHeight, width: chartWidth }"
      />
      <div
        v-else
        :style="{ height: chartHeight, width: chartWidth }"
        class="flex items-center justify-center text-gray-400"
      >
        No data available
      </div>
  </div>
</template>
