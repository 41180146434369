<template>
  <b-modal
    id="add-topic-modal"
    :title="isEditing ? 'Edit Topic' : 'Add Topic'"
    size="lg"
    hide-footer
    hide-header
    centered
    header-class="p-4 border-b"
    dialog-class="!w-[600px]"
    @show="handleShow"
    @hidden="handleHide"
  >
    <!-- Modal Head -->
    <div class="modal_head">
      <div class="flex item-center justify-between w-full">
        <h4 class="text-xl font-semibold">{{
          isEditing ? 'Edit Topic' : 'Add Topic'
        }}</h4>
      </div>
      <button
        type="button"
        class="modal_head__close bg-transparent"
        data-dismiss="modal"
        aria-label="Close"
        @click.stop="handleClose"
        >&times;</button
      >
    </div>

    <!-- Modal Body -->
    <div class="modal_body p-4">
      <div class="space-y-4">
        <div>
          <label class="block text-sm font-medium text-gray-700 mb-1">
            Title
            <i
              v-tooltip="'Each topic represents a key subject for your brand’s content.'"
              class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
            ></i>
          </label>
          <input
            v-model="formData.name"
            type="text"
            placeholder="Enter title of the topic.."
            class="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label class="block text-sm font-medium text-gray-700 mb-1">
            Description
            <i
              v-tooltip="'A short explanation of the topic’s focus. You can edit it to refine the AI-generated posts.'"
              class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
            ></i>
          </label>
          <textarea
            v-model="formData.description"
            required
            rows="4"
            placeholder="Add short description against your topic..."
            class="w-full border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          ></textarea>
          <div class="text-xs text-gray-700 mt-1 text-right">
            Maximum 1000 characters
          </div>
        </div>

        <div class="flex justify-end gap-3 mt-6">
          <Button variant="text" size="sm" @click="handleClose">
            Cancel
          </Button>
          <Button size="sm" :disabled="!isValid" @click="handleSave">
            {{ isEditing ? 'Update' : 'Save' }}
          </Button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { ref, computed, inject } from 'vue'
import { Button } from '@contentstudio/ui'

const root = inject('root')
const { $bvModal } = root

const emit = defineEmits(['hide', 'save'])

const formData = ref({
  name: '',
  description: '',
})

const isEditing = ref(false)

const isValid = computed(() => {
  return formData.value.name.trim() !== '' && formData.value.description.trim() !== ''
})

const handleShow = () => {
  // Any initialization when modal shows
}

const handleHide = () => {
  emit('hide')
}

const handleClose = () => {
  formData.value = {
    name: '',
    description: '',
  }
  isEditing.value = false
  $bvModal.hide('add-topic-modal')
}

const handleSave = () => {
  if (!isValid.value) return

  emit('save', {
    name: formData.value.name,
    description: formData.value.description,
  })

  handleClose()
}

const show = (topic = null) => {
  if (topic) {
    formData.value = { ...topic }
    isEditing.value = true
  } else {
    formData.value = { name: '', description: '' }
    isEditing.value = false
  }
  $bvModal.show('add-topic-modal')
}

// Expose methods for parent component
defineExpose({
  show,
})
</script>
