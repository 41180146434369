<script>
import { mapGetters, mapMutations } from 'vuex'
import { article } from '@src/modules/automation/store/recipes/mutation-types'
import { socialChannelsArray } from '@common/constants/common-attributes'
import AutoMixin from '@src/modules/automation/components/AutoMixin'
import AccountSelection from '../../../../publish/components/posting/social/AccountSelection'
import ScheduleOptions from '../../ScheduleOptions'
import ReplugSelection from '../../Selection/ReplugSelection'
import HashtagSelection from '../../Selection/HashtagSelection'
import PreviewSuggestion from '../../../../discovery/components/topics/PreviewSuggestion'
import ArticlesFiltersSelection from './ArticleAutomationFiltersSelection'

export default {
  components: {
    AccountSelection,
    ScheduleOptions,
    ArticlesFiltersSelection,
    ReplugSelection,
    HashtagSelection,
    PreviewSuggestion,
  },
  mixins: [AutoMixin],
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getArticleAutomationTabStatus',
      'getAutomationLoaders',
      'getAccountSelection',
      'getFetchNextPostingLoader',
      'getArticleAutomationValidations',
      'getArticleAutomationLoaders',
      'getPreviewSuggestion',
    ]),
    showNextScheduledPostsCount() {
      return (
        !this.getFetchNextPostingLoader &&
        this.getArticleAutomationDetails.next_posting > -1
      )
    },
    nextScheduledPostsMessage() {
      const nextPosting =
        this.getPreviewSuggestion.total >
        this.getArticleAutomationDetails.next_posting
          ? this.getArticleAutomationDetails.next_posting
          : this.getPreviewSuggestion.posts.length
      return this.getArticleAutomationDetails.id &&
        !this.getArticleAutomationDetails.remove_posts
        ? 'Note: ' +
            nextPosting +
            ' new posts will be scheduled for your accounts on the next refill.'
        : 'Note: ' +
            nextPosting +
            ' new posts will be scheduled for your accounts.'
    },
    /**
     * Removing the social accounts input validation if the accounts that are selected more than 0.
     *
     * @returns {number}
     */
    selectedAccountsForAutomation() {
      const selection = this.getAccountSelection
      let countItem = 0
      socialChannelsArray.forEach((item) => {
        countItem += selection[item.name].length
      })

      // check the count item.
      if (countItem > 0) {
        if (this.getArticleAutomationValidations.validations.social_accounts) {
          // if it is set to true , only then change the value.
          this.$store.commit(article.SET_ARTICLE_AUTOMATION_VALIDATION_VALUE, {
            key: 'social_accounts',
            value: false,
          })
        }
      }

      return countItem
    },
  },
  watch: {
    'getArticleAutomationDetails.name'(value) {
      if (value && value.length > 0) {
        if (this.getArticleAutomationValidations.validations.name) {
          this.$store.commit(article.SET_ARTICLE_AUTOMATION_VALIDATION_VALUE, {
            key: 'name',
            value: false,
          })
        }
      }
    },
    'getArticleAutomationTabStatus.fourth'(value) {
      if (value) this.scheduleOptionsChanges()
    },
  },
  created() {
    this.initializeSaveArticleSocialAutomationSection()
  },
  mounted() {
    document.addEventListener('keyup', this.keyPressListener)
  },
  unmounted() {
    document.removeEventListener('keyup', this.keyPressListener)
  },
  methods: {
    ...mapMutations([article.SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS]),
    // showConfirmation () {
    //   this.$bvModal.msgBoxConfirm('Please confirm that you want to exit automation.', {
    //     title: 'Please Confirm',
    //     ...swalAttributes()
    //   })
    //     .then(value => {
    //       if (value !== null) {
    //         if (value) {
    //           this.$router.push({ 'name': 'automation', params: { workspace: this.getWorkspaces.activeWorkspace.slug } })
    //         }
    //       }
    //     })
    //     .catch(err => {
    //       // An error occurred
    //       console.error('msgBoxConfirm', err)
    //     })
    // },
    keyPressListener(event) {
      console.debug('Method::keyPressListener', event.keyCode)
      const tabStatus = this.getArticleAutomationTabStatus
      if (this.$route.name === 'saveArticleSocialAutomation') {
        let tab = 'first'

        // escape key pressed.

        if (event.keyCode === 27) {
          if (tabStatus.first === true) {
            this.$router.push({
              name: 'articleSocialAutomationListing',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            })
            return false
          }
          tab = tabStatus.second
            ? 'first'
            : tabStatus.third
            ? 'second'
            : 'third'
          this.SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS(tab)
        }
        if (
          event.keyCode === 13 &&
          !event.target.classList.contains('prevent-listener')
        ) {
          if (tabStatus.fourth === true) {
            this.validateAndStoreArticleAutomation()
            return false
          }
          tab = tabStatus.first
            ? 'first'
            : tabStatus.second
            ? 'second'
            : 'third'
          this.validateArticleAutomation(tab)
        }
      }
    },

    scheduleOptionsChanges() {
      console.debug('Method::scheduleOptionsChanges')
      this.$store.dispatch('fetchAutomationNextPosting', this.$route.name)
    },
  },
}
</script>

<template>
  <div class="w-full overflow-y-auto no-scrollbar">
  <div class="automation_main_component max_container_1800">
    <div class="component_inner">
      <!--            <router-link class="close_icon float-right"-->
      <!--                         :to="{'name': 'automation', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <!--                &times;-->
      <!--            </router-link>-->
      <button
        class="close_icon float-right"
        @click="
          exitConfirmation(
            'article-social-automation-listing',
            'Article Links to Social Media',
            getWorkspaces.activeWorkspace.slug,
          )
        "
      >
        &times;
      </button>

      <!--<router-link class="close_icon float-right"-->
      <!--:to="{'name': 'articleSocialAutomationListing', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <!--&times;-->
      <!--</router-link>-->

      <div class="top_content">
        <!-- <h2>New Campaign</h2>
        <img
          src="../../../../../assets/img/automation/article_social.svg"
          alt=""
        /> -->
        <h3>Article Links to Social Media</h3>
        <p
          >Share article links on your social media profiles,pages and groups
        </p>
      </div>

      <div class="steps_count">
        <div class="count_inner">
          <a
            class="d-flex align-items-center justify-content-center"
            :class="{
              active: getArticleAutomationTabStatus.first === true,
              past: getArticleAutomationTabStatus.first === false,
            }"
            @click="SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS('first')"
          >
            <span>1</span>
          </a>
          <a
            class="d-flex align-items-center justify-content-center"
            :class="{
              active: getArticleAutomationTabStatus.second === true,
              past:
                getArticleAutomationTabStatus.first === false &&
                getArticleAutomationTabStatus.second === false,
            }"
            @click="validateArticleAutomation('first')"
          >
            <span>2</span>
          </a>
          <a
            class="d-flex align-items-center justify-content-center"
            :class="{
              active: getArticleAutomationTabStatus.third === true,
              past:
                getArticleAutomationTabStatus.first === false &&
                getArticleAutomationTabStatus.second === false &&
                getArticleAutomationTabStatus.third === false,
            }"
            @click="validateArticleAutomation('second')"
          >
            <span>3</span>
          </a>

          <a
            class="d-flex align-items-center justify-content-center"
            :class="{ active: getArticleAutomationTabStatus.fourth === true }"
            @click="validateArticleAutomation('third')"
          >
            <span>4</span>
          </a>
        </div>
      </div>

      <div
        class="page_1 connect_social_accounts"
        :class="{ 'd-none': getArticleAutomationTabStatus.first === false }"
      >
        <div class="automation_page width_medium">
          <span class="d-none">{{ selectedAccountsForAutomation }}</span>
          <div class="page_inner">
            <div class="step_heading">
              <h3>Step 1 - Campaign Name and Channels</h3>
            </div>

            <div class="field_group">
              <input
                v-model="getArticleAutomationDetails.name"
                :class="{
                  'input-field-error':
                    getArticleAutomationValidations.validations.name,
                }"
                type="text"
                placeholder="Campaign name e.g marketing posts"
                maxlength="70"
                data-cy="campaign-name"
              />
              <label class="label-animated">Campaign Name</label>
              <span
                v-if="getArticleAutomationValidations.validations.name"
                class="input-error"
              >
                {{ getArticleAutomationValidations.messages.name }}
              </span>
            </div>
            <br />

            <AccountSelection
              :module="'automation'"
              :show-label="true"
              :social_accounts_validation="
                getArticleAutomationValidations.validations.social_accounts
              "
            ></AccountSelection>

            <span
              v-if="getArticleAutomationValidations.validations.social_accounts"
              class="input-error"
              >{{
                getArticleAutomationValidations.messages.social_accounts
              }}</span
            >

            <div class="field_group step_btn">
              <button
                class="btn btn btn-studio-theme-transparent-grey"
                disabled="disabled"
              >
                <i class="icon_left cs-angle-left"></i>
                <span>Prev</span>
              </button>
              <button
                class="active btn btn-studio-theme-space"
                data-cy="step1-button"
                @click="validateArticleAutomation('first')"
              >
                <span>Next</span>
                <i class="icon_right arrow_right_active cs-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="page_2 content_rules_filter"
        :class="{ 'd-none': getArticleAutomationTabStatus.second === false }"
      >
        <!--width_small-->
        <div class="automation_page d-flex align-items-start">
          <div class="page_left" :class="{ open: !showPreviewSnippet() }">
            <div class="page_inner basic_form">
              <div class="step_heading">
                <h3>Step 2 -Content Matching Rules & Filters</h3>
              </div>

              <ArticlesFiltersSelection
                type="Social"
              ></ArticlesFiltersSelection>

              <div class="field_group step_btn">
                <button
                  class="btn btn-studio-theme-transparent-grey"
                  @click="SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS('first')"
                >
                  <i class="icon_left cs-angle-left"></i>
                  <span>Prev</span>
                </button>
                <button
                  class="active btn btn-studio-theme-space"
                  data-cy="step2-button"
                  @click="validateArticleAutomation('second')"
                >
                  <span>Next</span>
                  <i class="icon_right arrow_right_active cs-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="page_right" :class="{ open: showPreviewSnippet() }">
            <div class="page_inner basic_form">
              <PreviewSuggestion></PreviewSuggestion>
            </div>
          </div>
        </div>
      </div>

      <div
        class="page_3 post_optimization"
        :class="{ 'd-none': getArticleAutomationTabStatus.third === false }"
      >
        <div class="automation_page width_small">
          <div class="page_inner basic_form">
            <div class="step_heading">
              <h3>Step 3 - Optimize your posts</h3>
            </div>

            <div class="checkbox_list">
              <ReplugSelection></ReplugSelection>
              <HashtagSelection></HashtagSelection>

              <div
                v-if="getAccountSelection.twitter.length"
                class="checkbox_input_image block"
              >
                <input
                  id="mention_author"
                  v-model="getArticleAutomationDetails.mention_author"
                  type="checkbox"
                />
                <label for="mention_author" class="checkbox_left"
                  >Mention author</label
                >
              </div>
            </div>

            <div class="input_field">
              <label>Post Variations</label>
              <div class="radio_list">
                <div class="radio_input_image">
                  <input
                    id="automation_without_post_variations"
                    v-model="getArticleAutomationDetails.post_variations"
                    type="radio"
                    name="automation_type"
                    value="post_title"
                  />
                  <label
                    for="automation_without_post_variations"
                    class="radio_left"
                    >Use Post Titles + URL</label
                  >
                </div>
                <div class="radio_input_image">
                  <input
                    id="automation_with_post_variations"
                    v-model="getArticleAutomationDetails.post_variations"
                    type="radio"
                    name="automation_type"
                    value="body_content"
                  />
                  <label
                    for="automation_with_post_variations"
                    class="radio_left"
                    >Use intelligent post variation (extracted from body
                    content) as caption</label
                  >
                </div>
              </div>
            </div>

            <div class="field_group text-center">
              <button
                class="btn btn-studio-theme-transparent-grey"
                @click="SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS('second')"
              >
                <i class="icon_left cs-angle-left"></i>
                <span>Prev</span>
              </button>
              <button
                class="active btn btn-studio-theme-space"
                data-cy="step3-button"
                @click="validateArticleAutomation('third')"
              >
                <span>Next</span>
                <i class="icon_right arrow_right_active cs-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="page_4 schedule_campaign"
        :class="{ 'd-none': getArticleAutomationTabStatus.fourth === false }"
      >
        <div class="automation_page width_medium">
          <div class="page_inner basic_form">
            <div class="step_heading">
              <h3>Step 4 - Schedule and Finalize</h3>
            </div>

            <ScheduleOptions type="ArticleSocialAutomation"></ScheduleOptions>

            <div class="input_field">
              <div class="recurring_block extra_options">
                <div class="d-flex align-items-center">
                  <p class="text">Fetch posts for the next</p>
                  <input
                    v-model="getArticleAutomationDetails.prefetch_days_limit"
                    style="min-width: 200px"
                    type="number"
                    placeholder="Number of prefetched post"
                    min="2"
                    max="100"
                    @change="scheduleOptionsChanges"
                  />
                  <p class="last_text">days in advance</p>
                </div>
              </div>
            </div>

            <div class="input_field">
              <div class="checkbox_input_image">
                <input
                  id="reviewContent"
                  v-model="getArticleAutomationDetails.review_content"
                  type="checkbox"
                />
                <label for="reviewContent" class="checkbox_left"
                  >I’d like to review content before it is published</label
                >
              </div>
            </div>

            <div
              v-if="showNextScheduledPostsCount"
              class="input_field d-inline-block"
            >
              <div class="warning_box">
                <p>{{ nextScheduledPostsMessage }}</p>
              </div>
            </div>

            <div v-if="getArticleAutomationDetails.id" class="input_field">
              <div class="checkbox_input_image">
                <input
                  id="removePosts"
                  v-model="getArticleAutomationDetails.remove_posts"
                  type="checkbox"
                  @change="scheduleOptionsChanges"
                />
                <label for="removePosts" class="checkbox_left"
                  >Remove existing scheduled posts</label
                >
              </div>
            </div>

            <div class="field_group text-center">
              <button
                class="btn btn-studio-theme-transparent-grey"
                @click="SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS('third')"
              >
                <i class="icon_left cs-angle-left"></i>
                <span>Prev</span>
              </button>
              <button
                class="active btn btn-studio-theme-space"
                :class="{ btn_disable: getArticleAutomationLoaders.all.store }"
                data-cy="done-button"
                @click.prevent="validateAndStoreArticleAutomation"
              >
                <span>Done</span>
                <i class="icon_right arrow_right_active cs-angle-right"></i>
                <img
                  v-if="getArticleAutomationLoaders.all.store"
                  style="width: 20px"
                  src="../../../../../assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
