<template>
  <div>
    <b-modal
      id="post-preview-modal"
      content-class="bg-white"
      dialog-class="!w-[1200px]"
      size="xl"
      centered
      hide-header
      hide-footer
      @shown="handleModalShown"
      @hidden="handleModalHidden"
    >
      <div class="modal_head">
        <div class="flex item-center justify-between w-full">
          <h4 class="text-xl font-semibold">Post Preview</h4>
        </div>
        <button
          type="button"
          class="modal_head__close bg-transparent"
          data-dismiss="modal"
          aria-label="Close"
          @click.stop="handleClose"
          >&times;</button
        >
      </div>
      <!-- Main content container -->
      <div v-if="post" class="modal-body flex w-full h-[80vh] py-6 gap-x-4 relative">
        <!-- Previous post button (extreme left) -->
        <div class="absolute left-0 top-1/2 -translate-y-1/2 z-10">
          <button
            v-if="hasPrev"
            v-tooltip="'Previous post'"
            class="w-10 h-10 flex items-center justify-center bg-[#ECECEC]  hover:scale-105 rounded-xl text-[#868686] shadow-lg"
            @click="$emit('prev')"
          >
            <i class="fa fa-chevron-left text-base 2xl:text-xl"></i>
          </button>
        </div>

        <!-- Left side - Post preview and feedback -->
        <div class="flex flex-col gap-y-4 w-[500px] max-w-full h-full">
          <div
            class="bg-white border relative rounded-xl flex-1 overflow-hidden"
          >
            <PostPreview
              :post="post"
              :platform="getPlatform"
              :reverse-layout="reverseLayout"
              class="h-full w-full"
            />
          </div>

          <!-- Feedback Section -->
          <div class="flex items-center justify-center gap-x-4 py-3 px-2 border border-[#ebebeb] rounded-xl w-full bg-white">
            <div class="absolute inset-0 bg-gradient-to-r from-transparent via-[#f9f9f9] to-transparent opacity-0 hover:opacity-10 transition-opacity duration-500 rounded-xl pointer-events-none"></div>

            <span class="text-sm font-medium">Do you like this post?</span>
            <div class="flex gap-4">
              <!-- Like Button -->
              <button
                v-tooltip="{ content: post.feedback?.action === 'like' ? 'You liked this post' : 'Like this post', placement: 'top' }"
                :class="[
                  post.feedback?.action === 'like'
                    ? 'feedback-btn-liked'
                    : 'feedback-btn feedback-btn-like group',
                ]"
                :disabled="isLoading"
                :style="{}"
                @click="handleFeedback(post._id, 'like', $event)"
              >
                <template v-if="post.feedback?.action === 'like'">
                  <span class="flex items-center">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="feedback-icon">
                      <path d="M4.39591 5.95866V12.2087M8.56258 3.81283L8.04175 5.95866H11.0782C11.2399 5.95866 11.3994 5.99631 11.5441 6.06863C11.6887 6.14095 11.8145 6.24595 11.9115 6.37533C12.0086 6.5047 12.0741 6.65488 12.1031 6.81399C12.132 6.97309 12.1235 7.13675 12.0782 7.29199L10.8647 11.4587C10.8016 11.675 10.67 11.8651 10.4897 12.0003C10.3094 12.1356 10.0901 12.2087 9.86466 12.2087H2.83341C2.55715 12.2087 2.2922 12.0989 2.09685 11.9036C1.90149 11.7082 1.79175 11.4433 1.79175 11.167V7.00033C1.79175 6.72406 1.90149 6.45911 2.09685 6.26376C2.2922 6.06841 2.55715 5.95866 2.83341 5.95866H4.27091C4.46471 5.95856 4.65463 5.90439 4.81933 5.80226C4.98403 5.70013 5.11697 5.55408 5.20321 5.38053L7.00008 1.79199C7.24569 1.79503 7.48744 1.85354 7.70727 1.96314C7.9271 2.07273 8.11931 2.23059 8.26956 2.42491C8.41981 2.61923 8.5242 2.84499 8.57494 3.08533C8.62568 3.32566 8.62146 3.57435 8.56258 3.81283Z" stroke="#56C288" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="ml-2 text-[#56C288] text-xs font-medium feedback-text">Liked!</span>
                  </span>
                </template>
                <template v-else>
                  <div class="flex items-center">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" class="feedback-icon stroke-[#868686] group-hover:!stroke-[#56C288] transition-all duration-300" xmlns="http://www.w3.org/2000/svg">
                      <path d="M4.39591 5.95866V12.2087M8.56258 3.81283L8.04175 5.95866H11.0782C11.2399 5.95866 11.3994 5.99631 11.5441 6.06863C11.6887 6.14095 11.8145 6.24595 11.9115 6.37533C12.0086 6.5047 12.0741 6.65488 12.1031 6.81399C12.132 6.97309 12.1235 7.13675 12.0782 7.29199L10.8647 11.4587C10.8016 11.675 10.67 11.8651 10.4897 12.0003C10.3094 12.1356 10.0901 12.2087 9.86466 12.2087H2.83341C2.55715 12.2087 2.2922 12.0989 2.09685 11.9036C1.90149 11.7082 1.79175 11.4433 1.79175 11.167V7.00033C1.79175 6.72406 1.90149 6.45911 2.09685 6.26376C2.2922 6.06841 2.55715 5.95866 2.83341 5.95866H4.27091C4.46471 5.95856 4.65463 5.90439 4.81933 5.80226C4.98403 5.70013 5.11697 5.55408 5.20321 5.38053L7.00008 1.79199C7.24569 1.79503 7.48744 1.85354 7.70727 1.96314C7.9271 2.07273 8.11931 2.23059 8.26956 2.42491C8.41981 2.61923 8.5242 2.84499 8.57494 3.08533C8.62568 3.32566 8.62146 3.57435 8.56258 3.81283Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </template>
              </button>

              <!-- Dislike Button -->
              <button
                v-tooltip="{ content: post.feedback?.action === 'dislike' ? 'You disliked this post' : 'Dislike this post', placement: 'top' }"
                :class="[
                  post.feedback?.action === 'dislike'
                    ? 'feedback-btn-disliked'
                    : 'feedback-btn feedback-btn-dislike group',
                ]"
                :disabled="isLoading"
                :style="{}"
                @click="handleFeedback(post._id, 'dislike', $event)"
              >
                <template v-if="post.feedback?.action === 'dislike'">
                  <span class="flex items-center">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="feedback-icon">
                      <path d="M9.60408 8.04199V1.79199M5.43742 10.1878L5.95825 8.04199H2.92179C2.76008 8.04199 2.60059 8.00434 2.45595 7.93202C2.31131 7.8597 2.18549 7.7547 2.08846 7.62533C1.99143 7.49595 1.92586 7.34577 1.89693 7.18666C1.868 7.02756 1.87651 6.8639 1.92179 6.70866L3.13534 2.54199C3.19844 2.32562 3.33003 2.13556 3.51034 2.00033C3.69064 1.86509 3.90995 1.79199 4.13534 1.79199H11.1666C11.4429 1.79199 11.7078 1.90174 11.9032 2.09709C12.0985 2.29244 12.2083 2.55739 12.2083 2.83366V7.00033C12.2083 7.27659 12.0985 7.54154 11.9032 7.73689C11.7078 7.93225 11.4429 8.04199 11.1666 8.04199H9.72908C9.53529 8.04209 9.34537 8.09626 9.18067 8.19839C9.01597 8.30052 8.88303 8.44657 8.79679 8.62012L6.99992 12.2087C6.75431 12.2056 6.51255 12.1471 6.29273 12.0375C6.0729 11.9279 5.88068 11.7701 5.73044 11.5757C5.58019 11.3814 5.4758 11.1557 5.42506 10.9153C5.37432 10.675 5.37854 10.4263 5.43742 10.1878Z" stroke="#F74370" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="ml-2 text-[#F74370] text-xs font-medium feedback-text">Disliked!</span>
                  </span>
                </template>
                <template v-else>
                  <div class="flex items-center">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" class="feedback-icon stroke-[#868686] group-hover:!stroke-[#F74370] transition-all duration-300" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.60408 8.04199V1.79199M5.43742 10.1878L5.95825 8.04199H2.92179C2.76008 8.04199 2.60059 8.00434 2.45595 7.93202C2.31131 7.8597 2.18549 7.7547 2.08846 7.62533C1.99143 7.49595 1.92586 7.34577 1.89693 7.18666C1.868 7.02756 1.87651 6.8639 1.92179 6.70866L3.13534 2.54199C3.19844 2.32562 3.33003 2.13556 3.51034 2.00033C3.69064 1.86509 3.90995 1.79199 4.13534 1.79199H11.1666C11.4429 1.79199 11.7078 1.90174 11.9032 2.09709C12.0985 2.29244 12.2083 2.55739 12.2083 2.83366V7.00033C12.2083 7.27659 12.0985 7.54154 11.9032 7.73689C11.7078 7.93225 11.4429 8.04199 11.1666 8.04199H9.72908C9.53529 8.04209 9.34537 8.09626 9.18067 8.19839C9.01597 8.30052 8.88303 8.44657 8.79679 8.62012L6.99992 12.2087C6.75431 12.2056 6.51255 12.1471 6.29273 12.0375C6.0729 11.9279 5.88068 11.7701 5.73044 11.5757C5.58019 11.3814 5.4758 11.1557 5.42506 10.9153C5.37432 10.675 5.37854 10.4263 5.43742 10.1878Z" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </div>
                </template>
              </button>
            </div>
          </div>
        </div>


        <!-- Right side - Post information -->
        <div
          class="w-full max-w-[25rem] border-l border-gray-200 flex flex-col gap-6"
        >
          <div
            class="p-2 2xl:p-4 border border-[#ebebeb] rounded-xl h-full flex flex-col justify-between"
          >
           <div class="flex items-center justify-between px-4">
            <h3 class="text-base font-medium py-2"
            >Post Information</h3
          >
          <!-- Credit Usage icon -->
          <img
            v-if="post?.credits_used"
            v-tooltip="{ content: creditsTooltip, allowHTML: true }"
            class="w-5 h-5 mr-2"
            src="@src/assets/img/chat_bot/credit-icon.svg"
            alt="chat-credit"
          />
           </div>

            <div class="space-y-4 2xl:space-y-8 py-2 2xl:py-4 px-2">
              <!-- Post Info -->
              <div class="p-4 space-y-4 h-[20rem] 2xl:h-full overflow-y-auto">
                <div
                  v-if="post.style_name"
                  class="flex items-center pb-3 file-property"
                >
                  <p class="text-sm font-medium w-[40%]">Style</p>
                  <p class="text-sm w-[60%]">{{
                    capitalize(post?.style_name) || 'ContentStudio'
                  }}</p>
                </div>
                <div
                  v-if="post.brand_voice_name"
                  class="flex items-center pb-3 file-property"
                >
                  <p class="text-sm font-medium w-[40%]">Brand Voice</p>
                  <p class="text-sm w-[60%]">{{ capitalize(post?.brand_voice_name) }}</p>
                </div>
                <div
                  v-if="post.post_type"
                  class="flex items-center pb-3 file-property"
                >
                  <p class="text-sm font-medium w-[40%]">Post Type</p>
                  <p class="text-sm w-[60%]">{{ capitalize(post?.post_type) }}</p>
                </div>
                <div
                  v-if="post.post_generation_settings.emoji_usage"
                  class="flex items-center pb-3 file-property"
                >
                  <p class="text-sm font-medium w-[40%]">Emoji Usage</p>
                  <p class="text-sm w-[60%]">{{
                    capitalize(post?.post_generation_settings.emoji_usage)
                  }}</p>
                </div>
                <div
                  v-if="post.post_generation_settings.hashtag_usage"
                  class="flex items-center pb-3 file-property"
                >
                  <p class="text-sm font-medium w-[40%]">Hashtag Usage</p>
                  <p class="text-sm w-[60%]">{{
                    capitalize(post?.post_generation_settings.hashtag_usage)
                  }}</p>
                </div>
                <div
                  v-if="post.post_generation_settings.language"
                  class="flex items-center pb-3 file-property"
                >
                  <p class="text-sm font-medium w-[40%]">Language</p>
                  <p class="text-sm w-[60%]">{{
                    capitalize(post?.post_generation_settings.language)
                  }}</p>
                </div>
              </div>
            </div>

            <!-- Action buttons -->
            <!-- <img
              class="w-4 h-4 group-hover:hidden"
              :class="{ '!hidden': post.feedback?.action === 'dislike' }"
              src="@src/assets/img/icons/feedback.svg"
              alt="Dislike"
            />
            <img
              class="w-4 h-4 hidden group-hover:block"
              :class="{ '!block': post.feedback?.action === 'dislike' }"
              src="@src/assets/img/icons/feedback-white.svg"
              alt="Dislike"
            /> -->
            <div
              class="mt-auto 2xl:p-4 flex items-center justify-center space-x-2"
            >
              <!-- Regenerate -->
              <div class="flex flex-col items-center gap-y-1">
                <button
                  v-tooltip="regeneratingPostIds.has(post._id) ? 'Regenerating...' : 'Regenerate this post using the saved settings. This will consume credits.'"
                  class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#56c288] border border-gray-200 group"
                  :disabled="regeneratingPostIds.has(post._id)"
                  @click="regeneratePost(post._id)"
                >
                  <img
                    class="w-4 h-4 group-hover:hidden"
                    src="@src/assets/img/icons/regenerate.svg"
                    alt="Regenerate"
                  />
                  <img
                    class="w-4 h-4 hidden group-hover:block"
                    src="@src/assets/img/icons/regenerate-white.svg"
                    alt="Regenerate"
                  />
                </button>
              </div>

              <!-- Delete -->
              <div class="flex flex-col items-center gap-y-1">
                <button
                  v-tooltip="'Delete'"
                  class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#f5144c] border border-gray-200 group"
                  :disabled="isLoading"
                  @click="handleDelete"
                >
                  <img
                    class="w-4 h-4 group-hover:hidden"
                    src="@src/assets/img/icons/delete.svg"
                    alt="Delete"
                  />
                  <img
                    class="w-4 h-4 hidden group-hover:block"
                    src="@src/assets/img/icons/delete-white.svg"
                    alt="Delete"
                  />
                </button>
              </div>

              <!-- Feedback -->
               <!-- Like -->
               <!-- <div class="flex flex-col items-center gap-y-1">
                <button
                  v-tooltip="post.feedback?.action === 'like' ? 'Liked' : 'Like'"
                  class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#56c288] border border-gray-200 group"
                  :class="{ 'bg-[#56c288]': post.feedback?.action === 'like' }"
                  :disabled="isLoading"
                  @click="handleFeedback(post._id, 'like', $event)"
                >
                  <img
                    class="w-4 h-4 group-hover:hidden"
                    :class="{ '!hidden': post.feedback?.action === 'like' }"
                    src="@src/assets/img/icons/feedback.svg"
                    alt="Like"
                  />
                  <img
                    class="w-4 h-4 hidden group-hover:block"
                    :class="{ '!block': post.feedback?.action === 'like' }"
                    src="@src/assets/img/icons/feedback-white.svg"
                    alt="Like"
                  />
                </button>
              </div> -->

              <!-- Dislike -->
              <!-- <div class="flex flex-col items-center gap-y-1">
                <button
                  v-tooltip="post.feedback?.action === 'dislike' ? 'Disliked' : 'Dislike'"
                  class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#f5144c] border border-gray-200 group"
                  :class="{ 'bg-[#f5144c]': post.feedback?.action === 'dislike' }"
                  :disabled="isLoading"
                  @click="handleFeedback(post._id, 'dislike', $event)"
                >
                  <img
                    class="w-4 h-4 group-hover:hidden rotate-180"
                    :class="{ '!hidden': post.feedback?.action === 'dislike' }"
                    src="@src/assets/img/icons/feedback.svg"
                    alt="Dislike"
                  />
                  <img
                    class="w-4 h-4 hidden group-hover:block rotate-180"
                    :class="{ '!block': post.feedback?.action === 'dislike' }"
                    src="@src/assets/img/icons/feedback-white.svg"
                    alt="Dislike"
                  />
                </button>
              </div> -->

               <!-- Draft -->
               <div class="flex flex-col items-center gap-y-1">
                <button
                  v-tooltip="'Move this post to the drafts for later use.'"
                  class="h-10 w-10 flex items-center justify-center rounded-xl bg-gray-100 hover:bg-[#ff922b] border border-gray-200 group"
                  :disabled="isLoading"
                  @click="createSocialPost(post._id, true)"
                >
                  <img
                    class="w-4 h-4 group-hover:hidden"
                    src="@src/assets/img/icons/file.svg"
                    alt="Draft"
                  />
                  <img
                    class="w-4 h-4 hidden group-hover:block"
                    src="@src/assets/img/icons/file-white.svg"
                    alt="Draft"
                  />
                </button>
              </div>

              <!-- Compose -->
              <div class="flex items-center relative">
                <Button
                  size="sm"
                  :disabled="isLoading"
                  @click="createSocialPost(post._id)"
                >
                  <img
                    class="w-4 h-4 "
                    src="@src/assets/img/icons/compose-white.svg"
                    alt="Compose"
                  />
                 <span class="text-nowrap">Compose</span>
                </Button>
              </div>
            </div>
          </div>
        </div>

         <!-- Next post button (between preview and info) -->
         <div class="absolute right-0 top-1/2 -translate-y-1/2 z-10">
          <button
            v-if="hasNext"
            v-tooltip="'Next post'"
            class="w-10 h-10 flex items-center justify-center bg-[#ECECEC]  hover:scale-105 rounded-xl text-[#868686] shadow-lg"
            @click="$emit('next')"
          >
            <i class="fa fa-chevron-right text-base 2xl:text-xl"></i>
          </button>
        </div>

      </div>
    </b-modal>
  </div>

  <CstConfirmationPopup
    v-if="post"
    :modal-name="`delete-preview-post-${post._id}`"
    primary-button-text="Delete"
    @confirmed="confirmDeletePost"
  >
    <template v-slot:head>
      <h4 class="text-xl !flex items-center gap-x-2"><i class="far fa-trash"></i> Delete Post</h4>
    </template>
    <template v-slot:body>
      <p class="pt-4">Are you sure you want to delete this post? This action cannot be undone.</p>
    </template>
    <template v-slot:footer="{ onCancel, onConfirm }">
      <Button
      variant="outline"
      size="sm"
      @click="onCancel"
    >
      Cancel
    </Button>
    <Button
      color="danger"
      size="sm"
      @click="onConfirm"
    >
      Delete
    </Button>
    </template>
  </CstConfirmationPopup>
</template>

<script setup>
import { inject, computed } from 'vue'
import { Button} from '@contentstudio/ui'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'
import PostPreview from '@modules/publisher/ai-content-library/components/previews/PostPreview.vue'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'

const { AIUserProfile } = useSetup()

const { regeneratePost, regeneratingPostIds, deletePost, createSocialPost, addFeedback, isLoading } =
  useAIPostGeneration(AIUserProfile)

const props = defineProps({
  post: {
    type: Object,
    required: true,
  },
  hasPrev: {
    type: Boolean,
    default: false,
  },
  hasNext: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['prev', 'next'])
const root = inject('root')
const { $bvModal } = root

const getPlatform = computed(() => {
  return props.post?.post_generation_settings?.social_platform
})

const reverseLayout = computed(() => {
  // only instagram and tiktok should be false
  return !['instagram', 'tiktok'].includes(getPlatform.value)
})

const creditsTooltip = computed(() => {
  return `
  <div class="text-left">
      <span class="font-medium block">Credits Usage</span>
      <span class="block my-2">AI Text Credits (words): ${props.post?.credits_used?.text}</span>
      <span class="block my-2">AI Image Credits: ${props.post?.credits_used?.image}</span>
    </div>
  `

})

const handleModalShown = () => {
  window.addEventListener('keydown', handleKeyDown)
}

const handleModalHidden = () => {
  window.removeEventListener('keydown', handleKeyDown)
}

const handleKeyDown = (e) => {
  if (e.keyCode === 37 && props.hasPrev) emit('prev')
  if (e.keyCode === 39 && props.hasNext) emit('next')
}

const handleClose = () => {
  $bvModal.hide('post-preview-modal')
}

const handleDelete = () => {
  $bvModal.show(`delete-preview-post-${props.post._id}-confirmation-modal`)
}


const confirmDeletePost = async () => {
  if (!props.post._id) return

  $bvModal.hide(`delete-preview-post-${props.post._id}-confirmation-modal`)

  const status = await deletePost(props.post._id)
  if (status) {
    handleClose()
  }
}

// Removed confetti tracking

const handleFeedback = async (postId, action, event) => {
  // If clicking the same button that's already active, do nothing
  if (props.post?.feedback?.action === action) {
    return
  }

  const feedback = {
    action,
    comment: '',
  }
  await addFeedback(postId, feedback)
}

// Capitalize
const capitalize = (str) =>{
  return str.charAt(0).toUpperCase() + str.slice(1)
}


</script>

<style lang="scss">
.file-property {
  border-bottom: 1px solid #ebebeb;
}

.feedback-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transform-origin: center;
  will-change: transform, box-shadow, background-color, border-color;
}

.feedback-btn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: currentColor;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  z-index: 0;
  transform: scale(0.85);
  border-radius: 6px;
}


.feedback-btn:active {
  transform: translateY(-1px) scale(0.92);
  transition: all 0.1s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
}

.feedback-btn svg {
  position: relative;
  z-index: 1;
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), stroke 0.3s ease;
}

.feedback-btn-like:active {
  background-color: rgba(86, 194, 136, 0.1);
}


.feedback-btn-dislike:active {
  background-color: rgba(247, 67, 112, 0.1);
}

.feedback-btn-liked {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 8px;
  padding: 0 12px;
  background-color: #e6f7e9;
  border: 1px solid #d1f0d9;
  color: #56C288;
  min-width: 80px;
  animation: likeAnimation 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(86, 194, 136, 0.15);
  transform: translateY(-2px);
  position: relative;
  overflow: hidden;
}

.feedback-btn-liked:hover {
  background-color: #d8f2de;
  box-shadow: 0 4px 8px rgba(86, 194, 136, 0.15);
}

.feedback-btn-liked::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(86, 194, 136, 0.4) 0%, rgba(255, 255, 255, 0) 70%);
  transform: scale(0);
  opacity: 0;
  animation: rippleEffect 0.6s ease-out forwards;
}

.feedback-btn-liked svg {
  animation: iconPop 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  transform-origin: center;
}

.feedback-btn-disliked {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 8px;
  padding: 0 12px;
  background-color: #fce8ed;
  border: 1px solid #fad5df;
  color: #F74370;
  min-width: 80px;
  animation: dislikeAnimation 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  transition: all 0.3s ease;
  box-shadow: 0 4px 10px rgba(247, 67, 112, 0.15);
  transform: translateY(-2px);
  position: relative;
  overflow: hidden;
}

.feedback-btn-disliked:hover {
  background-color: #fcdee5;
  box-shadow: 0 4px 8px rgba(247, 67, 112, 0.15);
}

.feedback-btn-disliked::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(247, 67, 112, 0.4) 0%, rgba(255, 255, 255, 0) 70%);
  transform: scale(0);
  opacity: 0;
  animation: rippleEffect 0.6s ease-out forwards;
}

.feedback-btn-disliked svg {
  animation: iconPop 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
  transform-origin: center;
}


@keyframes slideIn {
  0% { transform: translateX(-10px); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}

@keyframes likeAnimation {
  0% { transform: scale(0.8); opacity: 0.5; }
  40% { transform: scale(1.15); }
  70% { transform: scale(0.95); }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes rippleEffect {
  0% { transform: scale(0); opacity: 0.7; }
  50% { transform: scale(1.5); opacity: 0.5; }
  100% { transform: scale(2); opacity: 0; }
}

@keyframes dislikeAnimation {
  0% { transform: scale(0.8); opacity: 0.5; }
  40% { transform: scale(1.15); }
  70% { transform: scale(0.95); }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes iconPop {
  0% { transform: scale(0.5) rotate(-5deg); opacity: 0.7; }
  50% { transform: scale(1.3) rotate(5deg); }
  70% { transform: scale(0.9) rotate(-2deg); }
  100% { transform: scale(1) rotate(0); opacity: 1; }
}


/* Text animation styles */
.feedback-text {
  animation: slideIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

.feedback-text-hidden {
  width: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform: translateX(-10px);
}

.group-hover .feedback-text-visible {
  width: auto;
  opacity: 1;
  transform: translateX(0);
}


</style>
