<script setup>
import {Button} from '@contentstudio/ui'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'

const { AIUserProfile } = useSetup()

const { generatePosts, postGenerationLoader } = useAIPostGeneration(AIUserProfile)
</script>

<template>
  <div class="w-[554px] h-[360px] rounded-xl shadow-lg bg-graffiti">
      <div class="flex flex-col justify-center items-center gap-y-4 pt-16">
        <img
          alt="white label successful icon"
          src="@assets/img/settings/white-label/success.svg"
          class="w-16 h-16 mt-4"
        />
        <h2 class="text-2xl font-semibold text-center text-[#3A4557] my-2"
          >Profile Created Successfully</h2>

          <p class="text-center text-lg text-[#757575] my-2">
            Your profile is all set up! <br>
            Now, lets elevate your content
          </p>
          <Button
          :loading="postGenerationLoader"
          :disabled="postGenerationLoader"
          size="md"
          @click="generatePosts"
        >
          <img
            src="@src/modules/publisher/assets/ai-icon.svg"
            class="h-4 w-4"
            alt=""
          />
          Generate Post
        </Button>
      </div>
  </div>
</template>

<style scoped>

/* Add bg image   */
.bg-graffiti {
  background-image: url('~@assets/img/grafitti-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
