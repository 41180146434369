<template>
  <div class="table_filter d-flex align-items-center">
    <div class="f_left">
      <a
        class="btn btn-studio-theme-space"
        @click="
          createVideoAutomationProcess(
            type === 'Blog'
              ? 'saveVideoBlogAutomation'
              : 'save-video-social-automation',
          )
        "
      >
        <i class="icon_left add_icon far fa-plus"> </i>
        <span>New Campaign</span>
      </a>
      <div
        v-if="getVideoAutomationListing.selected.length > 0"
        class="dropdown option_dropdown default_style_dropdown"
      >
        <div
          class="dropdown_header d-flex align-items-center ml-2"
          data-toggle="dropdown"
        >
          <span class="align-middle text">Select Operation</span>
          <i class="align-middle cs-angle-down icon_last"></i>
        </div>

        <div class="dropdown-menu dropdown-menu-left">
          <ul class="inner">
            <li
              class="list_item"
              @click.prevent="
                confirmAction('videoAutomationBulkOperation', 'Active')
              "
            >
              <a class="dropdown-item">Active</a>
            </li>
            <li
              class="list_item"
              @click.prevent="
                confirmAction('videoAutomationBulkOperation', 'Inactive')
              "
            >
              <a class="dropdown-item">Inactive</a>
            </li>
            <li
              class="list_item"
              @click.prevent="
                confirmAction('videoAutomationBulkOperation', 'Delete')
              "
            >
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="f_right ml-auto d-flex">
      <div
        class="platform_filters_planner prevent_close_dropdown social_dropdown dropdown option_dropdown default_style_dropdown"
      >
        <PlatformFilters
          :dropdown-position="'dropdown-menu-right'"
          :type="type"
          source="video"
        ></PlatformFilters>
      </div>

      <div class="search-bar-input ml-2">
        <div v-if="type === 'Blog'" class="search-bar-inner">
          <input
            v-model="getVideoBlogAutomationListing.search"
            type="text"
            placeholder="Search for campaign..."
            @keyup.enter="refetchVideoBlogAutomations"
          />
          <button
            class="search_btn"
            @click.prevent="refetchVideoBlogAutomations"
          >
            <i class="icon-Search"></i>
          </button>
        </div>

        <div v-else class="search-bar-inner">
          <input
            v-model="getVideoSocialAutomationListing.search"
            type="text"
            placeholder="Search for campaign..."
            @keyup.enter="refetchVideoSocialAutomations"
          />
          <button
            class="search_btn"
            @click.prevent="refetchVideoSocialAutomations"
          >
            <i class="icon-Search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PlatformFilters from '../../../../publish/components/PlatformFilters'
export default {
  components: {
    PlatformFilters,
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['type'],
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getVideoBlogAutomationListing',
      'getVideoSocialAutomationListing',
      'getVideoAutomationListing',
    ]),
  },
  methods: {},
}
</script>
