<template>
  <div
      class="analytic_component analytics-report-wrapper"
      style="background: #515558"
  >
    <!-- Page 1: Performance Summary Cards and Engagements Graph -->
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <div>
          <div class="analytics-section-header mb-4">
            <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white">All Profiles Performance Summary</h2>
            <p>Key profile performance metrics from the selected time period.</p>
          </div>

          <!-- Performance Cards -->
          <div class="grid grid-cols-4 gap-4 mb-7">
            <template v-for="card in cards" :key="card">
              <div class="flex flex-row gap-1">
                <NewStatsCard
                  :show-info-tooltip="false"
                  class="col-span-1"
                  :data="getCardData(card)"
                  :show-pulse="false"
                />
              </div>
            </template>
          </div>

          <!-- Reach Graph -->
          <div class="analytics-section-header mb-4">
            <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white">Reach Across Platforms</h2>
          </div>
          <div class="grid mb-7">
            <EngagementsGraph
              :is-loading="isLoadingStates[routes?.TOP_PERFORMING_GRAPH]"
              :is-no-data="isNoAnalyticsData(topPerformingGraphData)"
              :chart-options="multipleSeriesLineChartOptions"
              :legend-options="legendOptions"
              :data="topPerformingGraphData"
              :chart-types="chartTypes"
              :data-zoom-options="dataZoomOptions"
              :is-report-view="true"
              :default-type="'reach'"
              label="Reach"
            />
          </div>

          <!-- Engagement Graph -->
          <div class="analytics-section-header mb-4">
            <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white">Engagement Across Platforms</h2>
          </div>
          <div class="grid mb-7">
            <EngagementsGraph
              :is-loading="isLoadingStates[routes?.TOP_PERFORMING_GRAPH]"
              :is-no-data="isNoAnalyticsData(topPerformingGraphData)"
              :chart-options="multipleSeriesLineChartOptions"
              :legend-options="legendOptions"
              :data="topPerformingGraphData"
              :chart-types="chartTypes"
              :data-zoom-options="dataZoomOptions"
              :is-report-view="true"
              :default-type="'engagement'"
              label="Engagement"
            />
          </div>


        </div>
      </div>
      <ReportFooter :current-page="1" :total-pages="totalPages" />
    </div>

    <!-- Page 2: Impressions Graph and Platforms Engagement Breakdown Table -->
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <div>
          <!-- Impressions Graph -->
          <div class="analytics-section-header mb-4">
            <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white">Impressions Across Platforms</h2>
          </div>
          <div class="grid mb-7">
            <EngagementsGraph
              :is-loading="isLoadingStates[routes?.TOP_PERFORMING_GRAPH]"
              :is-no-data="isNoAnalyticsData(topPerformingGraphData)"
              :chart-options="multipleSeriesLineChartOptions"
              :legend-options="legendOptions"
              :data="topPerformingGraphData"
              :chart-types="chartTypes"
              :data-zoom-options="dataZoomOptions"
              :is-report-view="true"
              :default-type="'impressions'"
              label="Impressions"
            />
          </div>

          <div class="analytics-section-header mb-4">
            <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white">Platforms Engagement Breakdown</h2>
            <p>Compares performance metrics across different social media platforms to help optimize your content strategy.</p>
          </div>

          <div class="grid grid-cols-1 gap-4 mb-7">
            <PlatformsEngagementBreakdownTable
              :platform-data="platformData"
              :is-loading="isLoadingStates[routes?.GET_PLATFORM_DATA]"
            />
          </div>

          <!-- Performance Comparison Chart -->
          <!-- <div class="analytics-section-header mt-10 mb-4">
            <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white">Performance Comparison</h2>
            <p>Compare engagement and follower metrics across your social profiles.</p>
          </div>
          <div class="grid grid-cols-1 gap-4 mb-7">
            <PerformanceComparison
              title="Performance Comparison"
              :data="getAccountStatistics"
              :is-loading="isLoadingStates[routes?.GET_PLATFORM_DATA_DETAILED]"
              :is-all-data-fetched="false"
              chart-height="400px"
              x-axis-label="Followers"
              y-axis-label="Engagement Rate"
              :xy-axis-keys="{ x: 'followers', y: 'engagementByFollowers' }"
            />
          </div> -->
        </div>
      </div>
      <ReportFooter :current-page="2" :total-pages="totalPages" />
    </div>

    <!-- Page 3: Top Posts By Engagement -->
    <div class="page_view flex flex-col justify-between">
      <div class="subpage card_activity_top_post">
        <div class="analytics-section-header mb-4">
          <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white">Top Posts By Engagement</h2>
          <p>Review your top posts published during the selected time period, based on the story's lifetime performance.</p>
        </div>

        <TopPostsComponent
          :top-posts-data="topPostsData"
          :is-loading="isLoadingStates[routes?.GET_TOP_POSTS]"
        />
      </div>
      <ReportFooter :current-page="4" :total-pages="totalPages" />
    </div>

    <!-- Page 4: Account Statistics -->
    <div class="page_view flex flex-col justify-between">
      <div class="subpage">
        <div>
          <div class="analytics-section-header mb-4">
            <h2 class="bg-gradient-to-r from-[#01AAFF] to-[#0ACADD] py-2 px-3 rounded-lg text-white">Social Profiles Performance</h2>
            <p>Key metrics of the Social Profiles in the selected time period.</p>
          </div>

          <div class="grid grid-cols-1 gap-4 mb-7">
            <AccountStatistics
              v-for="item in visibleOverallAccounts"
              :key="item.id"
              class="account-statistics-item"
              :platform-image="item.platformImage"
              :account-image="item.image"
              :name="item.name"
              :platform-type="item.platform_type"
              :account-id="item.account_id"
              :platform-data-detailed="platformDataDetailed"
              :platform-data-graphs="platformDataGraphs"
              :is-loading="isLoadingStates"
            />
          </div>
        </div>
      </div>
      <ReportFooter :current-page="5" :total-pages="totalPages" />
    </div>
  </div>
</template>

<script setup>
// 1. File/Module imports
import { computed, watch, ref } from 'vue'
import ReportFooter from '@/src/modules/analytics/components/reports/ReportFooter.vue'
import NewStatsCard from '@/src/modules/analytics/views/common/NewStatsCard.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'
import EngagementsGraph from '@/src/modules/analytics/views/overviewV2/components/graphs/EngagementsGraph.vue'
import TopPostsComponent from '@/src/modules/analytics/views/overviewV2/components/TopPostsComponent.vue'
import PlatformsEngagementBreakdownTable from '@/src/modules/analytics/views/overviewV2/components/PlatformsEngagementBreakdownTable.vue'
import AccountStatisticsWrapper from '@src/modules/analytics_v3/components/AccountStatisticsWrapper.vue'
import PerformanceComparison from '@src/modules/analytics_v3/components/PerformanceComparison.vue'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'

// 2. Props
const props = defineProps({
  metrics: {
    type: Object,
    default: () => ({})
  },
  accountSelectFlags: {
    type: Object,
    default: () => ({
      facebook: false,
      instagram: false,
      linkedin: false,
      tiktok: false,
      twitter: false,
      pinterest: false,
      youtube: false
    })
  }
})

// 3. Composable destructs
const {
  routes,
  cards,
  getCardData,
  isLoadingStates,
  topPerformingGraphData,
  multipleSeriesLineChartOptions,
  legendOptions,
  dataZoomOptions,
  isNoAnalyticsData,
  chartTypes,
  platformData,
  platformDataDetailed,
  platformDataGraphs,
  topPostsData,
  accountStatistics
} = useOverviewAnalytics()

// 4. State
const totalPages = ref(5) // Total number of pages in the report

// 5. Methods
/**
 * Handles initialization when component is mounted
 */
function initializeReport() {
  // Set data to the useOverviewAnalytics composable if available from props
  if (props.metrics?.overview?.platformData) {
    platformData.value = props.metrics.overview.platformData
  }

  if (props.metrics?.overview?.platformDataDetailed) {
    platformDataDetailed.value = props.metrics.overview.platformDataDetailed
  }

  if (props.metrics?.overview?.platformDataGraphs) {
    platformDataGraphs.value = props.metrics.overview.platformDataGraphs
  }

  if (props.metrics?.top_posts) {
    topPostsData.value = props.metrics.top_posts
  }
}

// 6. Computed
/**
 * Computes the account flags for different platforms
 */
const accountFlags = computed(() => {
  return props.accountSelectFlags || {
    facebook: false,
    instagram: false,
    linkedin: false,
    tiktok: false,
    twitter: false,
    pinterest: false,
    youtube: false
  }
})

// 7. Watch

// 8. Lifecycle Hooks
// Initialize the report when the component is mounted
initializeReport()

const visibleAccountsCount = ref(3)

const visibleOverallAccounts = computed(() => {
  return accountStatistics.value.slice(0, visibleAccountsCount.value)
})
</script>

<style lang="scss" scoped>
.analytics-section-header {
  h2 {
    font-size: 1rem;
    font-weight: 500;
  }
  p {
    color: #4A4A4A;
    font-size: 0.875rem;
    margin-top: 0.5rem;
  }
}

.page_view {
  page-break-after: always;
  min-height: 100vh;

  .subpage {
    padding: 2rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
  }
}
</style>
