<template>
  <div class="p-6 w-[600px]">
    <!-- Steps Progress -->
    <div class="flex justify-center items-center mb-8">
      <template v-for="(step, index) in steps" :key="index">
        <div
          class="flex items-center cursor-pointer"
          @click="handleStepClick(index)"
        >
          <div
            class="w-10 h-10 rounded-full flex items-center justify-center border"
            :class="[
              currentStep === index ? `${step.borderColor}` : 'border-gray-300',
            ]"
          >
            <template
              v-if="completedSteps.includes(index) && currentStep !== index"
            >
              <svg
                :class="step.textColor"
                class="h-5 w-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                ></path>
              </svg>
            </template>
            <template v-else>
              <img :src="step.icon" :alt="step.name" class="w-5 h-5" />
            </template>
          </div>
          <span
            class="ml-2 text-sm"
            :class="[
              currentStep === index
                ? `${step.textColor} font-medium`
                : 'text-gray-900',
            ]"
            >{{ step.name }}</span
          >
        </div>
        <div
          v-if="index < steps.length - 1"
          class="mx-8 w-16 h-px border !border-dashed"
        ></div>
      </template>
    </div>

    <!-- Step Content -->
    <div ref="stepContainer" class="min-h-[400px]">
      <component :is="steps[currentStep].component" v-model="currentStepData" />
    </div>

    <!-- Navigation Buttons -->
    <div class="flex justify-between mt-6 px-4">
      <Button
        v-if="currentStep > 0"
        class="px-4 py-2 text-[#436AFF] hover:bg-[#F5F7FF] rounded-lg"
        size="sm"
        variant="light"
        color="secondary"
        @click="prevStep"
      >
        Back
      </Button>
      <div class="ml-auto">
        <Button size="sm" @click="handleNextStep">
          {{ currentStep === steps.length - 1 ? 'Finish!' : 'Next' }}
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Button } from '@contentstudio/ui'
import { ref, onMounted } from 'vue'
import { useSteps } from '@modules/publisher/ai-content-library/composables/useSteps'

const {
  steps,
  currentStep,
  completedSteps,
  currentStepData,
  handleStepClick,
  prevStep,
  handleNextStep,
  setComponentMounted,
} = useSteps()

const stepContainer = ref(null)

onMounted(() => {
  setComponentMounted(true)
})

defineEmits(['complete'])
</script>
