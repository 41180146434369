<template>
  <LayoutCard>
    <template v-slot:header>
      <p class="text-lg font-medium text-[#3A4557]">Post Generation Settings</p>
    </template>
    <template v-slot:body>
      <PostSettingsForm @update="handleUpdate" @cancel="handleCancel" />
    </template>
  </LayoutCard>
</template>

<script setup>
import PostSettingsForm from './form/PostSettingsForm.vue'
import LayoutCard from '@/src/modules/setting/components/white-label/LayoutCard.vue'

const handleUpdate = (formData) => {
  // Handle form update
  console.log('Form updated:', formData)
}

const handleCancel = () => {
  // Handle cancel action
  console.log('Form cancelled')
}
</script>
