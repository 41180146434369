// libs
import { ref } from 'vue'

// composables
import useFacebookAnalytics from '@src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics'
import useInstagramAnalytics from '@src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics'
import useYoutubeAnalytics from '@src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useTiktokAnalytics from '../../analytics/views/tiktok/composables/useTiktokAnalytics'
import usePinterestAnalytics from '../../analytics/views/pinterest/composables/usePinterestAnalytics'
import useOverviewAnalytics from '../../analytics/views/overviewV2/composables/useOverviewAnalytics'

// Platform-specific analytics mapper
const analyticsMapper = {
  facebook: useFacebookAnalytics,
  linkedin: useLinkedinAnalytics,
  instagram: useInstagramAnalytics,
  youtube: useYoutubeAnalytics,
  tiktok: useTiktokAnalytics,
  pinterest: usePinterestAnalytics,
  overview: useOverviewAnalytics
}

export default function useAiInsights(platform = 'facebook') {
  const { lineChartOptions } = useAnalytics()

  // Get the platform-specific analytics composable
  const analyticsComposable = (analyticsMapper[platform] || analyticsMapper.instagram)()
  const {
    fetchMedia,
    routes,
    isLoadingStates,
    aiInsightsData: platformAiInsightsData,
    AIInsightsSectionMapping,
    getAIInsightsIcons
  } = analyticsComposable

  const aiInsightsData = ref([])
  const activeSections = ref([])

  const formatDate = (date) => {
    if (date.includes('-')) {
      return new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })
    }
    return date
  }

  const getChartOptions = (data) => {
    const formattedDates = data.buckets.map(formatDate)
    lineChartOptions.value.xAxis.data = formattedDates
    lineChartOptions.value.series[0].data = data.values
    return lineChartOptions.value
  }

  const fetchInsights = async (section, selectedDropdownOption) => {
    try {
      const payload = {
        type:
          AIInsightsSectionMapping[section] ??
          AIInsightsSectionMapping[selectedDropdownOption] ??
          selectedDropdownOption
      }

      // Only add trend_type for YouTube platform
      if (platform === 'youtube' || platform === 'pinterest') {
        payload.trend_type = selectedDropdownOption
      }

      await fetchMedia(routes.AI_INSIGHTS, payload)

      if (platformAiInsightsData.value?.success) {
        aiInsightsData.value = platformAiInsightsData.value.insights.key_insights
        if (aiInsightsData.value) {
          activeSections.value.push(aiInsightsData.value[0]?.category)
        }
      }
    } catch (error) {
      console.error('Error fetching insights:', error)
      aiInsightsData.value = []
    } finally {
        isLoadingStates.value.ai_insights = false
    }
  }

  const openCollapsible = (section) => {
    return activeSections.value.includes(section)
  }

  const toggleSection = (section) => {
    if (activeSections.value.includes(section)) {
      activeSections.value = activeSections.value.filter(
        (activeSection) => activeSection !== section
      )
      return
    }
    activeSections.value.push(section)
  }

  return {
    // data
    aiInsightsData,
    isLoadingStates,
    activeSections,
    getAIInsightsIcons,

    // methods
    fetchInsights,
    getChartOptions,
    openCollapsible,
    toggleSection,
  }
}
