<script>
import { mapGetters, mapActions } from 'vuex'
import { confirmation } from '@common/store/mutation-types'
import PlatformFilters from '../../../../publish/components/PlatformFilters'
import { evergreen } from '../../../store/automation-mutation-type'

export default {
  components: {
    PlatformFilters
  },

  computed: {
    ...mapGetters([
      'getEvergreenAutomationListing',
      'getAutomationCounts',
      'getWorkspaces',
      'getProfile'
    ])
  },
  methods: {
    // ...mapActions(['refetchEvergreenAutomation']),
    ...mapActions([
      'setAccountSelection',
      'setAutomationScheduleOptions',
      'setEditQueueStatus',
      'refetchEvergreenAutomation'
    ]),

    bulkEvergreenAction (action) {
      console.debug('METHOD:bulkEvergreenAction')
      this.$store.commit(confirmation.SET_PAYLOAD, action)
      // eslint-disable-next-line no-undef
      $('#evergreenAutomationBulkOperation').modal('show')
    },
    newEvergreenAutomation () {
      this.$store.commit(evergreen.SET_EVERGREEN_DEFAULT_STATES)
      this.setEditQueueStatus(false)
      this.$store.commit('setAccountSelection', null)
      this.$store.commit('setAutomationScheduleOptions', null)
      this.$router.push({
        name: 'save-evergreen-automation'
      })
    }
  }
}
</script>

<template>
  <div class="table_filter d-flex align-items-center">
    <a
      class="btn btn-studio-theme-space"
      @click.prevent="newEvergreenAutomation"
    >
      <i class="icon_left add_icon far fa-plus"> </i>
      <span>New Campaign</span>
    </a>

    <div
      v-if="getEvergreenAutomationListing.selected.length > 0"
      class="f_left"
    >
      <div class="dropdown option_dropdown default_style_dropdown ml-2">
        <div
          class="dropdown_header d-flex align-items-center"
          data-toggle="dropdown"
        >
          <span class="align-middle text">Select Operation</span>
          <i class="align-middle cs-angle-down icon_last"></i>
        </div>

        <div class="dropdown-menu dropdown-menu-left">
          <ul class="inner">
            <li
              class="list_item"
              @click.prevent="bulkEvergreenAction('Active')"
            >
              <a class="dropdown-item">Active</a>
            </li>

            <li
              class="list_item"
              @click.prevent="bulkEvergreenAction('Inactive')"
            >
              <a class="dropdown-item">Inactive</a>
            </li>

            <li
              class="list_item"
              @click.prevent="bulkEvergreenAction('Delete')"
            >
              <a class="dropdown-item">Delete</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="d-flex f_right ml-auto">
      <div
        class="platform_filters_planner prevent_close_dropdown social_dropdown dropdown option_dropdown default_style_dropdown"
      >
        <PlatformFilters
          type="Social"
          :dropdown-position="'dropdown-menu-right'"
          source="evergreen"
        ></PlatformFilters>
      </div>

      <div class="ml-1 search-bar-input">
        <div class="search-bar-inner">
          <input
            v-model="getEvergreenAutomationListing.search"
            type="text"
            placeholder="Search for campaign..."
            @keyup.enter="refetchEvergreenAutomation"
          />
          <button
            class="search_btn"
            @click.prevent="refetchEvergreenAutomation"
          >
            <i class="icon-Search"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
