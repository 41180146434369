<script setup>
import { watch, computed } from 'vue'
import useEcharts from '@src/modules/analytics_v3/composables/useEcharts'
import debounce from 'lodash.debounce'

const props = defineProps({
  isModal: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'line',
  },
  chartOptions: {
    type: Object,
    required: true,
  },
  customHeight: {
    type: String,
    default: '350px',
  },
  customModalHeight: {
    type: String,
    default: '40vh',
  },
  customClickHandler: {
    type: Function,
    required: true,
  },
  showGraph: {
    type: Boolean,
    default: true,
  }
})

const { setup, chartRef } = useEcharts(props.chartOptions, props.type, props.customClickHandler)

// Reactive height for transitions
const height = computed(() => {
  return props.isModal ? props.customModalHeight : props.customHeight;
});

watch(
  () => [chartRef.value, props.chartOptions, props.showGraph],
  debounce(() => {
    if (!props.showGraph) return
    setup(props.chartOptions)
  }, 100)
)
</script>

<template>
  <transition name="fade">
    <div
      v-if="showGraph"
      ref="chartRef"
      :style="{ height: isModal ? customModalHeight : customHeight }"
    />
  </transition>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
