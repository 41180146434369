<template>
  <div class="p-5 relative">
    <div
      v-dragscroll
      class="h-full overflow-auto scroll-smooth"
      @scroll="handleScroll"
    >
      <!-- Table Container -->
      <div class="data-table border border-gray-200 rounded-lg shadow-sm mt-2">
        <!-- Table Header -->
        <div
          class="flex flex-nowrap flex-row sticky top-0 w-full bg-[#f3f4f4] z-50 border-b border-gray-200"
        >
          <!-- Checkbox Column -->
          <div
            class="f-table-cell bg-[#f3f4f4] !w-[50px] px-6 py-3 cursor-pointer capitalize font-medium sticky left-0 flex items-center z-40"
          >
            <Checkbox
              size="lg"
              :model-value="allSelected"
              @update:model-value="selectAll"
            />
          </div>

          <!-- Post Column -->
          <div
            class="f-table-cell bg-[#f3f4f4] !w-[400px] px-6 py-3 cursor-pointer capitalize font-medium sticky left-[50px] flex items-center z-40"
          >
            <span class="text-sm">Post</span>
          </div>

          <!-- Platform Column -->
          <div
            class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-medium"
          >
            <span class="text-sm">Platform</span>
          </div>

          <!-- Style Column -->
          <div
            class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-medium"
          >
            <span class="text-sm">Style</span>
          </div>

          <!-- Voice Column -->
          <div
            class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-medium"
          >
            <span class="text-sm">Brand Voice</span>
          </div>

          <!-- Created At Column -->
          <div
            class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-medium"
          >
            <span class="text-sm">Created At</span>
          </div>

          <!-- Actions Column -->
          <div
            class="f-table-cell bg-[#f3f4f4] px-6 py-3 cursor-pointer capitalize font-medium flex-1 text-center"
          >
            <span class="text-sm">Actions</span>
          </div>
        </div>

        <!-- Table Body -->
        <div>
          <!-- Loading State -->
          <template v-if="getPostsLoader">
            <div
              v-for="i in 5"
              :key="'get-skeleton-' + i"
              class="flex flex-nowrap flex-row w-full border-b border-gray-100"
            >
              <div
                class="f-table-cell !w-[50px] px-6 py-4 sticky left-0 bg-white z-30"
              >
                <div class="h-5 w-5 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div
                class="f-table-cell !w-[400px] px-6 py-4 sticky left-[50px] bg-white z-30"
              >
                <div class="h-16 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4 !w-[120px] flex-1">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          </template>

          <!-- Generation Loading State -->
          <template v-else-if="postGenerationLoader">
            <div
              v-for="i in parseInt(postSettingsForm.postsCount)"
              :key="'gen-skeleton-' + i"
              class="flex flex-nowrap flex-row w-full border-b border-gray-100"
            >
              <div
                class="f-table-cell !w-[50px] px-6 py-4 sticky left-0 bg-white z-30"
              >
                <div class="h-5 w-5 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div
                class="f-table-cell !w-[400px] px-6 py-4 sticky left-[50px] bg-white z-30"
              >
                <div class="h-16 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4 !w-[120px] flex-1">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div>
          </template>

          <!-- No Data State -->
          <div
            v-else-if="!posts.length"
            class="flex justify-center items-center h-64 w-full"
          >
            <div class="text-center">
              <div class="text-xl font-medium text-gray-700 mb-2">No posts found</div>
              <div class="text-sm text-gray-500">
                Generate new posts using the "Generate Post" button
              </div>
            </div>
          </div>

          <!-- Posts Data -->
          <template v-else>
            <div
              v-for="post in posts"
              :key="post._id"
              class="flex flex-nowrap flex-row w-full border-b border-gray-100 bg-white transition-colors duration-200 group"
            >
              <!-- Checkbox -->
              <div
                class="f-table-cell !w-[50px] px-6 py-4 sticky left-0 bg-white f-table-cell-center-y z-30"
              >
                <Checkbox
                  size="lg"
                  :model-value="selectedPosts.includes(post._id)"
                  @update:model-value="() => togglePostSelection(post._id)"
                />
              </div>

              <!-- Post Content -->
              <div
                v-tooltip="'Post Details'"
                data-role="post"
                class="f-table-cell !w-[400px] px-6 py-4 sticky left-[50px] bg-white cursor-pointer z-30"
                @click="openPreview(post._id)"
              >
                <div class="flex items-center gap-4">
                  <div v-if="post.image" class="mt-2">
                    <img
                      :src="post.image"
                      alt="Post image"
                      class="w-32 h-24 object-cover rounded shadow-sm"
                    />
                  </div>
                  <div class="text-sm line-clamp-4 ellipsis my-1">
                    {{ post.caption  }}
                  </div>
                </div>
              </div>

              <!-- Platform -->
              <div class="f-table-cell px-6 py-4 f-table-cell-center-y">
                <div class="flex space-x-1">
                  <div
                    class="w-6 h-6"
                  >
                    <img
                      v-tooltip="post.post_generation_settings?.social_platform"
                      :src="require('@assets/img/integration/' + post.post_generation_settings?.social_platform + '-rounded.svg')"
                      :alt="post.post_generation_settings?.social_platform"
                      class="w-full h-full"
                      @error="$event.target.style.display = 'none'"
                    />
                  </div>
                </div>
              </div>

              <!-- Style -->
              <div class="f-table-cell px-6 py-4 f-table-cell-center-y">
                <span class="text-sm ">
                  {{ post?.style_name }}
                </span>
              </div>

              <!-- Voice -->
              <div class="f-table-cell px-6 py-4 f-table-cell-center-y">
                <span class="text-sm ">
                  {{ post?.brand_voice_name }}
                </span>
              </div>

              <!-- Created At -->
              <div class="f-table-cell px-6 py-4 f-table-cell-center-y">
                <span class="text-sm ">
                  {{ momentWrapper(post.createdAt).formatDateTime() }}
                </span>
              </div>

              <!-- Actions -->
              <div class="f-table-cell px-6 py-4 flex-1 f-table-cell-center-y">
                <div class="w-full flex justify-center">
                  <Dropdown
                    placement="bottom-end"
                    button-classes="border-none hover:!border-none !px-0 !gap-0"
                    container-classes="!w-auto"
                    @click.stop
                  >
                    <template v-slot:selected>
                      <i class="fa fa-ellipsis-v cursor-pointer text-gray-800 p-2 rounded-md hover:bg-[#F5F7FF]"></i>
                    </template>
                    <template v-slot:arrow>
                      <span></span>
                    </template>
                    <DropdownItem
                      class="flex items-center gap-2 !px-3 !py-2"
                      @click="handleRegenerate(post._id)"
                    >
                      <i class="far fa-sync text-[#10B981]"></i>
                      <span>Regenerate</span>
                    </DropdownItem>
                    <DropdownItem
                      class="flex items-center gap-2 !px-3 !py-2"
                      @click="handleCompose(post._id)"
                    >
                      <i class="far fa-edit text-[#8B5CF6]"></i>
                      <span>Compose</span>
                    </DropdownItem>
                    <DropdownItem
                      class="flex items-center gap-2 !px-3 !py-2"
                      @click="handleDraft(post._id)"
                    >
                      <i class="far fa-file text-[#F59E0B]"></i>
                      <span>Save as Draft</span>
                    </DropdownItem>
                    <DropdownItem
                      class="flex items-center gap-2 !px-3 !py-2"
                      @click="handleDelete(post._id)"
                    >
                      <i class="far fa-trash text-[#EE446E]"></i>
                      <span>Delete</span>
                    </DropdownItem>
                  </Dropdown>
                </div>
              </div>
            </div>

            <!-- Load More Loader -->
            <!-- <div
              v-if="loadMorePostsLoader"
              class="flex flex-nowrap flex-row w-full border-b border-gray-100"
            >
              <div
                class="f-table-cell !w-[50px] px-6 py-4 sticky left-0 bg-white z-30"
              >
                <div class="h-5 w-5 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div
                class="f-table-cell !w-[400px] px-6 py-4 sticky left-[50px] bg-white z-30"
              >
                <div class="h-16 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
              <div class="f-table-cell px-6 py-4 !w-[120px] flex-1">
                <div class="h-5 w-20 bg-gray-200 rounded animate-pulse"></div>
              </div>
            </div> -->
          </template>
        </div>
      </div>
    </div>
  </div>

  <!-- Delete Confirmation Popup -->
  <CstConfirmationPopup
    modal-name="delete-post"
    primary-button-text="Delete"
    @confirmed="confirmDeletePost"
  >
  <template v-slot:head>
    <h4 class="text-xl !flex items-center gap-x-2"><i class="far fa-trash"></i> Delete Post</h4>
  </template>
  <template v-slot:body>
    <p class="pt-4">Are you sure you want to delete this post? This action cannot be undone.</p>
  </template>
  <template v-slot:footer="{ onCancel, onConfirm }">
    <Button
    variant="outline"
    size="sm"
    @click="onCancel"
  >
    Cancel
  </Button>
  <Button
    color="danger"
    size="sm"
    @click="onConfirm"
  >
    Delete
  </Button>
  </template>
  </CstConfirmationPopup>
</template>

<script setup>
import { ref, inject } from 'vue'
import { Checkbox, Dropdown, DropdownItem, Button } from '@contentstudio/ui'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import CstConfirmationPopup from '@ui/Popup/CstConfirmationPopup.vue'
import useDateFormat from '@/src/modules/common/composables/useDateFormat'

const props = defineProps({
  posts: {
    type: Array,
    required: true
  },
  selectedPosts: {
    type: Array,
    required: true
  },
  selectionMode: {
    type: Boolean,
    required: true
  },
  getPostsLoader: {
    type: Boolean,
    required: true
  },
  postGenerationLoader: {
    type: Boolean,
    required: true
  },
  postSettingsForm: {
    type: Object,
    required: true
  },
  loadMorePostsLoader: {
    type: Boolean,
    required: true
  },
  allSelected: {
    type: Boolean,
    required: true
  }
})

const emit = defineEmits([
  'preview',
  'select',
  'deselect',
  'select-all',
  'scroll'
])

// Inject root to access $bvModal
const root = inject('root')
const { $bvModal } = root

const { AIUserProfile } = useSetup()
const { regeneratePost, deletePost, createSocialPost } =
  useAIPostGeneration(AIUserProfile)

const { momentWrapper } = useDateFormat()
const selectedPostId = ref(null)

// Toggle post selection
const togglePostSelection = (postId) => {
  if (props.selectedPosts.includes(postId)) {
    emit('deselect', postId)
  } else {
    emit('select', postId)
  }
}

// Select all posts
const selectAll = () => {
  emit('select-all')
}

// Handle scroll event for infinite loading
const handleScroll = (event) => {
  if (!props.loadMorePostsLoader && !props.getPostsLoader) {
    const { scrollTop, scrollHeight, clientHeight } = event.target
    // If scrolled to bottom (with a small buffer)
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      emit('scroll', event)
    }
  }
}

// Event handlers
const openPreview = (postId) => {
  emit('preview', postId)
}

// Handle regenerate post
const handleRegenerate = (postId) => {
  regeneratePost(postId)
}

// Handle compose post
const handleCompose = (postId) => {
  createSocialPost(postId)
}

// Handle draft post
const handleDraft = (postId) => {
  createSocialPost(postId, true)
}

// Handle delete post
const handleDelete = (postId) => {
  console.log('Delete post:', postId)
  selectedPostId.value = postId
  $bvModal.show('delete-post-confirmation-modal')
}

// Confirm delete post
const confirmDeletePost = () => {
  deletePost(selectedPostId.value)
  $bvModal.hide('delete-post-confirmation-modal')
}

</script>

<style lang="scss" scoped>
.f-table-cell {
  @apply w-[200px] flex-shrink-0 transition-colors duration-200;

  @media (max-width: 1280px) {
    @apply w-[180px];
  }
}

.f-table-cell-center-y {
  @apply flex items-center;
}


/* DataTable specific styles */
.data-table {
  @apply bg-white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  height: calc(100vh - 60px - 7rem);
}

/* Hover effect for table rows */
.data-table .group:hover .f-table-cell {
  @apply bg-gray-50;
}
</style>
