import { ref, computed, defineAsyncComponent, inject } from 'vue'
import { useIcons } from '@modules/publisher/composables/useIcons'
import { useSetup } from './useSetup'
import { useStore } from '@/src/store/base'

// Define loading and error components
const LoadingComponent = {
  template:
    '<div class="flex items-center justify-center min-h-[400px]"><div class="animate-spin rounded-full h-8 w-8 border-b-2 border-[#436AFF]"></div></div>',
}

const ErrorComponent = {
  template:
    '<div class="flex items-center justify-center min-h-[400px] text-red-500">Error loading component</div>',
}

export function useSteps() {
  const { BrandIcon, StrategyIcon, TopicsIcon } = useIcons()
  const { brandInfo, isLoading, updateStepData, fetchProfile } = useSetup()
  const { dispatch } = useStore()

  const root = inject('root')
  const { $bvModal } = root

  const currentStep = ref(0)
  const completedSteps = ref([])
  const isComponentMounted = ref(false)

  // Async Components
  const BrandingStep = defineAsyncComponent({
    loader: () =>
      import(
        '@modules/publisher/ai-content-library/components/form/steps/BrandingStep.vue'
      ),
    loadingComponent: LoadingComponent,
    errorComponent: ErrorComponent,
    delay: 0,
    timeout: 30000,
  })

  const StrategyStep = defineAsyncComponent({
    loader: () =>
      import(
        '@modules/publisher/ai-content-library/components/form/steps/StrategyStep.vue'
      ),
    loadingComponent: LoadingComponent,
    errorComponent: ErrorComponent,
    delay: 0,
    timeout: 30000,
  })

  const TopicsStep = defineAsyncComponent({
    loader: () =>
      import(
        '@modules/publisher/ai-content-library/components/form/steps/TopicsStep.vue'
      ),
    loadingComponent: LoadingComponent,
    errorComponent: ErrorComponent,
    delay: 0,
    timeout: 30000,
  })

  const steps = [
    {
      name: 'Branding',
      component: BrandingStep,
      icon: BrandIcon,
      textColor: '!text-[#7272FF]',
      borderColor: '!border-[#7272FF]',
      color: '#7272FF',
    },
    {
      name: 'Strategy',
      component: StrategyStep,
      icon: StrategyIcon,
      textColor: 'text-[#4CCE88]',
      borderColor: '!border-[#4CCE88]',
      color: '#4CCE88',
    },
    {
      name: 'Topics',
      component: TopicsStep,
      icon: TopicsIcon,
      textColor: 'text-[#FF9472]',
      borderColor: '!border-[#FF9472]',
      color: '#FF9472',
    },
    // Design step commented out for future implementation
    // {
    //   name: 'Design',
    //   component: DesignStep,
    //   icon: DesignIcon,
    //   textColor: 'text-[#FFBB72]',
    //   borderColor: '!border-[#FFBB72]',
    //   color: '#FFBB72',
    // },
  ]

  // Validation rules for each step
  const validateStep = (step) => {
    switch (step) {
      case 0: // Branding
        if (!brandInfo.branding.businessName?.trim()) {
          dispatch('toastNotification', {
            message: 'Please enter your business name',
            type: 'error',
          })
          return false
        }
        return true

      case 1: // Strategy
        if (!brandInfo.strategy.brandVoice?.trim()) {
          dispatch('toastNotification', {
            message: 'Please select a brand voice',
            type: 'error',
          })
          return false
        }
        if (!brandInfo.strategy.niche?.trim()) {
          dispatch('toastNotification', {
            message: 'Please enter your business niche',
            type: 'error',
          })
          return false
        }
        if (!brandInfo.strategy.audience?.trim()) {
          dispatch('toastNotification', {
            message: 'Please describe your target audience',
            type: 'error',
          })
          return false
        }
        return true

      case 2: // Topics
        if (!brandInfo.topics?.length) {
          dispatch('toastNotification', {
            message: 'Please add at least one topic',
            type: 'error',
          })
          return false
        }
        return true

      default:
        return true
    }
  }

  const currentStepData = computed({
    get: () => {
      switch (currentStep.value) {
        case 0:
          return { branding: brandInfo.branding }
        case 1:
          return { strategy: brandInfo.strategy }
        case 2:
          // append the id to topics
          return { topics: brandInfo.topics }
        default:
          return {}
      }
    },
    set: async (newValue) => {
      const stepKey = Object.keys(newValue)[0]
      await updateStepData(stepKey, newValue[stepKey])
    },
})

  const handleStepClick = async (index) => {
    if (
      !isComponentMounted.value ||
      index === currentStep.value ||
      isLoading.value
    )
      return

    try {
      // Validate current step before moving
      if (!validateStep(currentStep.value)) {
        return
      }

      // Save current step data before moving
      const currentData = currentStepData.value
      const currentStepKey = Object.keys(currentData)[0]
      await updateStepData(currentStepKey, currentData[currentStepKey], false)

      if (index > currentStep.value) {
        // Mark all previous steps as completed when moving forward
        for (let i = currentStep.value; i < index; i++) {
          if (!completedSteps.value.includes(i)) {
            completedSteps.value.push(i)
          }
        }
      } else if (index < currentStep.value) {
        // Remove completion status for all steps after the target step
        completedSteps.value = completedSteps.value.filter(
          (step) => step <= index
        )
      }

      currentStep.value = index
    } catch (error) {
      console.error('Error handling step click:', error)
    }
  }

  const prevStep = async () => {
    if (!isComponentMounted.value || isLoading.value) return

    try {
      // Validate current step before moving back
      if (!validateStep(currentStep.value)) {
        return
      }
      // Save current step data before moving back
      const currentData = currentStepData.value
      const currentStepKey = Object.keys(currentData)[0]
      await updateStepData(currentStepKey, currentData[currentStepKey], false)

      if (currentStep.value > 0) {
        // Remove completion status for all steps after the previous step
        completedSteps.value = completedSteps.value.filter(
          (step) => step < currentStep.value - 1
        )
        currentStep.value--
      }
    } catch (error) {
      console.error('Error handling previous step:', error)
    }
  }

  const handleNextStep = async (emit) => {
    if (!isComponentMounted.value || isLoading.value) return

    try {
      // Validate current step before proceeding
      if (!validateStep(currentStep.value)) {
        return
      }

      // Save current step data
      const currentData = currentStepData.value
      const currentStepKey = Object.keys(currentData)[0]
      const isLastStep = currentStep.value === steps.length - 1

      console.debug('handleNextStep:currentData', currentData)
      await updateStepData(
        currentStepKey,
        currentData[currentStepKey],
        isLastStep
      )

      if (currentStep.value < steps.length - 1) {
        if (!completedSteps.value.includes(currentStep.value)) {
          completedSteps.value.push(currentStep.value)
        }
        currentStep.value++
      }

      if (isLastStep) {
        //  // fetch profile
        await fetchProfile()

        // show the post setting modal
        $bvModal.show('post-settings-modal')
      }
    } catch (error) {
      console.error('Error handling next step:', error)
    }
  }

  const setComponentMounted = (value) => {
    isComponentMounted.value = value
  }

  return {
    steps,
    currentStep,
    completedSteps,
    isComponentMounted,
    currentStepData,
    handleStepClick,
    prevStep,
    handleNextStep,
    setComponentMounted,
    validateStep,
  }
}
