<template>
  <div class="setting_component">
    <div class="component_inner">
      <div class="ps_side_bar_block">
        <SettingSidebar></SettingSidebar>
        <div class="max_container_1800 right_content">
          <transition name="settings-route" mode="out-in">
            <router-view :key="$route.fullPath"></router-view>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SettingSidebar from './SettingSidebar'


export default {
  components: {
    SettingSidebar,
  },

  data() {
    return {}
  },
  computed: {
    ...mapGetters(['getWorkspaces']),
  },
  created() {
    // if (this.$route.name !== 'profile' && this.$route.name !== 'notifications') {
    //   // redirect user ro planner if user role is approver in that workspace
    //   let activeWorkspaceMember = this.getLoggedUserRole(this.getWorkspaces.activeWorkspace)
    //   if (activeWorkspaceMember && activeWorkspaceMember.role === 'approver') {
    //     this.$router.push({ name: 'feed_view', params: { workspace: this.getWorkspaces.activeWorkspace.slug } })
    //   }
    // }
  },
  mounted() {},
  methods: {},
}
</script>

<style>
.settings-route {
  transition: all 300ms ease-in-out 50ms;
}

.settings-route-enter-active {
  transition: all 0.3s;
}

.settings-route-leave-active {
  transition: all 0s;
}

.settings-route-enter {
  opacity: 0;
  transform: translateX(-45px);
}

.settings-route-leave-to {
  opacity: 0;
  transform: translateX(-45px);
}
</style>
