<template>
  <b-modal
    id="post-settings-modal"
    title="Post Settings"
    size="lg"
    hide-footer
    hide-header
    centered
    header-class="p-4 border-b"
    dialog-class="!w-[600px]"
    :no-close-on-backdrop="!hasPostGenerationSettings"
    :no-close-on-esc="!hasPostGenerationSettings"
    @show="handleShow"
    @hidden="handleHide"
  >
    <!-- Modal Head -->
    <div class="modal_head">
      <div class="flex item-center justify-between w-full">
        <h4 class="text-xl font-semibold">Post Settings</h4>
      </div>
      <button
        type="button"
        class="modal_head__close bg-transparent"
        data-dismiss="modal"
        aria-label="Close"
        :disabled="!hasPostGenerationSettings"
        @click.stop="handleClose"
        >&times;</button
      >
    </div>

    <!-- Modal Body -->
    <div class="modal_body p-4">
      <PostSettingsForm
        :modal="true"
        @update="handleSettingsUpdate"
        @cancel="handleClose"
      />
    </div>
  </b-modal>
</template>

<script setup>
import { inject } from 'vue'
import PostSettingsForm from '@src/modules/publisher/ai-content-library/components/form/PostSettingsForm.vue'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'

const { hasPostGenerationSettings } = useSetup()

const root = inject('root')
const { $bvModal } = root

const emit = defineEmits(['hide', 'settings-updated'])


const handleShow = () => {
  // Any initialization when modal shows
}

const handleHide = () => {
  // Any cleanup when modal is hidden
  emit('hide')
}

const handleClose = () => {
  if (hasPostGenerationSettings.value) {
    $bvModal.hide('post-settings-modal')
  }
}

const handleSettingsUpdate = (settings) => {
  emit('settings-updated', settings)
  handleClose()
}

// Expose methods for parent component
defineExpose({
  show: () => $bvModal.show('post-settings-modal'),
  hide: () => {
    if (hasPostGenerationSettings.value) {
      $bvModal.hide('post-settings-modal')
    }
  },
})
</script>
