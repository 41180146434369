<template>
  <div class="flex flex-col gap-5 h-full py-8 overflow-auto no-scrollbar">
    <BrandInfo />
    <!-- <BrandThemes /> -->
    <AIPostSettings />
  </div>
  <AddStyleModal />
  <UpdateStyleModal />
  <AddBrandVoiceModal />
</template>

<script setup>
import BrandInfo from '@modules/publisher/ai-content-library/components/BrandInfo.vue'
// import BrandThemes from '@modules/publisher/ai-content-library/components/BrandThemes.vue'
import AIPostSettings from '@modules/publisher/ai-content-library/components/AIPostSettings.vue'
import AddStyleModal from '@modules/publisher/ai-content-library/components/modals/AddStyleModal.vue'
import UpdateStyleModal from '@modules/publisher/ai-content-library/components/modals/UpdateStyleModal.vue'
import AddBrandVoiceModal from '@modules/publisher/ai-content-library/components/modals/AddBrandVoiceModal.vue'
</script>
