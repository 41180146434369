<script setup>
import { computed, watch, ref } from 'vue'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/overviewV2/components/graphs/MainGraph.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import ChevronDownIcon from '@/src/assets/img/chevron-down.svg'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'

const props = defineProps({
  label: {
    type: String,
    default: 'Engagements',
  },
  defaultType: {
    type: String,
    default: 'line',
    validator: (v) => {
      return ['line', 'bar'].includes(v)
    },
  },
})

const {
  multipleSeriesLineChartOptions: lineChartOptions,
  legendOptions,
  topPerformingGraphData: engagementsData,
  PLATFORM_TITLE_AND_COLOR,
  isReportView,
  dataZoomOptions,
  isNoAnalyticsData,
  isLoadingStates,
  selectedGraphType,
  routes,
  fetchMedia,
  topPostsData
} = useOverviewAnalytics(props.label)

const openedModal = ref(false)

const isNoData = computed(() => {
  if (props.defaultType.value === 'reach') {
    return isNoAnalyticsData(engagementsData.value?.reach);
  } else if (props.defaultType.value === 'engagement') {
    return isNoAnalyticsData(engagementsData.value?.engagement);
  } else if (props.defaultType.value === 'impressions') {
    return isNoAnalyticsData(engagementsData.value?.impressions);
  }
  return true;
})

// Create dropdown options based on selectedGraphMapping
const optionsDropdown = [
  {
    label: 'Reach Across Platforms',
    key: 'reach',
    chartType: 'line',
    tooltip: 'Inspect the day-to-day reach metrics received each day during the selected time period, regardless of the post publication date.',
  },
  {
    label: 'Engagement Across Platforms',
    key: 'engagement',
    chartType: 'line',
    tooltip: 'Inspect the day-to-day engagement metrics received each day during the selected time period, regardless of the post publication date.',
  },
  {
    label: 'Impressions Across Platforms',
    key: 'impressions',
    chartType: 'line',
    tooltip: 'Inspect the day-to-day impression metrics received each day during the selected time period, regardless of the post publication date.',
  },
]

// Set the default selected option
if (!selectedGraphType.value) {
  selectedGraphType.value = 'reach'
}

const getSelectedType = computed(() => {
  return optionsDropdown.find((i) => i.key === selectedGraphType.value)?.label || 'Reach Across Platforms'
})

const isLoading = computed(() => {
  return isLoadingStates.value?.[routes.TOP_PERFORMING_GRAPH]
})

const chartTypes = [
  {
    name: PLATFORM_TITLE_AND_COLOR.facebook.title,
    api_response_key:'facebook' ,
    color: PLATFORM_TITLE_AND_COLOR.facebook.color,
  },
  {
    name: PLATFORM_TITLE_AND_COLOR.instagram.title,
    api_response_key: 'instagram',
    color: PLATFORM_TITLE_AND_COLOR.instagram.color,
  },
  {
    name: PLATFORM_TITLE_AND_COLOR.linkedin.title,
    api_response_key: 'linkedin',
    color: PLATFORM_TITLE_AND_COLOR.linkedin.color,
  },
  {
    name: PLATFORM_TITLE_AND_COLOR.pinterest.title,
    api_response_key: 'pinterest',
    color: PLATFORM_TITLE_AND_COLOR.pinterest.color,
  },
  {
    name: PLATFORM_TITLE_AND_COLOR.tiktok.title,
    api_response_key: 'tiktok',
    color: PLATFORM_TITLE_AND_COLOR.tiktok.color,
  },
  {
    name: PLATFORM_TITLE_AND_COLOR.youtube.title,
    api_response_key: 'youtube',
    color: PLATFORM_TITLE_AND_COLOR.youtube.color,
  },
]

const customClickHandler = async (params) => {
  console.log(params)
}

watch(
  [() => engagementsData, () => selectedGraphType],
  async () => {
    lineChartOptions.value.series = []

    // Get data based on selected graph type
    const xAxisData = engagementsData.value[props.defaultType]?.buckets || [];

    // Collect platforms that have data
    const platformsWithData = []

    // line chart options
    chartTypes.forEach((chartType) => {
      const apiKey = chartType?.api_response_key;
      const platformData = engagementsData.value[props.defaultType]?.[apiKey] || [];

      // Only include platforms with data
      if (platformData.length) {
        platformsWithData.push(chartType.name)
      }

      const lineSeries = {
        name: chartType.name,
        yAxisIndex: 0,
        type: 'line',
        data: engagementsData.value[props.defaultType]?.[apiKey] || [],
        color: chartType.color,
        areaStyle: { opacity: 0.3, cursor: 'auto'},
        lineStyle: { opacity : 0.6 },
        cursor: 'auto',
        showSymbol: true,
        symbolSize: 8,
        colorBy: 'series'
      }

      lineChartOptions.value.series.push(lineSeries)
    })

    lineChartOptions.value.xAxis.data = xAxisData

    // Update legend to only show platforms that have data
    lineChartOptions.value.legend = {
      ...legendOptions,
      data: platformsWithData
    }
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper
    type="Engagements-Graph"
    :enable-modal="true"
    :selected-dropdown-option="getSelectedType"
    :show-insights-button="!isReportView"
    :enable-insights="true"
    :is-modal="openedModal"
    :platform="'overview'"
    @toggle-is-modal="(value) => (openedModal = value)"
    >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <CstDropdown
              v-if="!isReportView"
              dropdown-placement="bottom"
              container-classes="!max-h-96"
              dropdown-classes="!rounded-md !border-[#70707029]"
              button-classes="flex !px-5 !w-[22rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
            >
              <template v-slot:arrow>
                <img :src="ChevronDownIcon" alt="chevron-down" />
              </template>
              <template v-slot:selected>
                <p class="text-[14px] font-weight-500 capitalize">
                  {{ getSelectedType }}
                </p>
              </template>
              <template v-slot>
                <template
                  v-for="(item, index) in optionsDropdown"
                  :key="`activity_type_${index}`"
                >
                  <CstDropdownItem @click="selectedGraphType = item.key">
                    <p class="text-gray-900 text-sm">{{ item.label }}</p>
                  </CstDropdownItem>
                </template>
              </template>
            </CstDropdown>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ optionsDropdown.find(item => item.key === selectedGraphType)?.tooltip ||
                     'Inspect the day-to-day metrics received each day during the selected time period, regardless of the post publication date.' }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div>
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="isNoData"
          :key="Math.random()"
          type="line"
          :chart-options="
            slotProps.isModal
              ? {
                  ...lineChartOptions,
                  ...dataZoomOptions,
                  grid: { ...lineChartOptions.grid, bottom: 70 },
                }
              : lineChartOptions
          "
          :is-modal="slotProps.isModal"
          :custom-click-handler="customClickHandler"
          :custom-modal-height="slotProps.isInsights ? '40vh' : '80vh'"
          :show-graph="true"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
