<script setup>
import {ref, inject } from 'vue'
import {
  SegmentedControl,
  Dropdown,
  DropdownItem,
  Button,
} from '@contentstudio/ui'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { useAIPostGeneration } from '@modules/publisher/ai-content-library/composables/useAIPostGeneration'

// Props
const props = defineProps({
  modal: {
    type: Boolean,
    default: false,
  },
})

const root = inject('root')
const { $bvModal } = root

const { AIUserProfile, hasPostGenerationSettings } = useSetup()
const {
  postSettingsForm,
  savePostSettings,
  postSettingLoader,
  usageLevels,
  platformOptions,
  languageOptions,
  postTypeOptions,
} = useAIPostGeneration(AIUserProfile)

const focusedForm = ref(false)

const validateDigits = () => {
  postSettingsForm.value.postsCount =
    postSettingsForm.value.postsCount?.replace(/\D/g, '')
}

const handleSavePostSettings = async () => {
  const result = await savePostSettings()
  console.log('result', result)
  if (props.modal && result) {
    // close modal
    $bvModal.hide('post-settings-modal')
  }
}

const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

defineEmits(['update', 'cancel'])
</script>

<template>
  <div class="flex flex-col gap-y-5" @click="focusedForm = true">
    <div class="grid grid-cols-2 gap-6">
      <!-- Style -->
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Style
          <i
            v-tooltip="'Select a different visual style for this session to match your branding.'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <Dropdown
          placement="bottom"
          radius="lg"
          container-classes="w-full"
          button-classes="border rounded-xl hover:!border-[#2294FF]"
          dropdown-classes="!z-[1050]"
          match-width="true"
        >
          <template v-slot:selected>{{
            postSettingsForm.style?.name || 'Select Style'
          }}</template>
          <DropdownItem
            v-for="option in AIUserProfile?.styles"
            :key="option.id"
            class="group flex justify-between items-center"
            :class="
              postSettingsForm.style?.id === option.id ? 'bg-[#f0f7ff]' : ''
            "
            @click="postSettingsForm.style = option"
          >
            {{ option?.name }}
            <i
              class="far fa-check"
              :class="
                postSettingsForm.style?.id === option.id
                  ? 'visible'
                  : 'invisible'
              "
            ></i>
          </DropdownItem>
        </Dropdown>
      </div>

      <!-- Brand Voice -->
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Brand Voice
          <i
            v-tooltip="'Choose a different brand voice to shape the tone of your generated content.'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <Dropdown
          radius="lg"
          container-classes="w-full"
          placement="bottom"
          match-width="true"
          button-classes="border rounded-xl hover:!border-[#2294FF]"
          dropdown-classes="!z-[1050]"
        >
          <template v-slot:selected>{{
            postSettingsForm.brandVoice?.label ||
            postSettingsForm.brandVoice?.name ||
            'Select Voice'
          }}</template>
          <DropdownItem
            v-for="option in AIUserProfile?.brand_voices"
            :key="option.id"
            class="group flex justify-between items-center"
            :class="
              postSettingsForm.brandVoice?.id === option.id
                ? 'bg-[#f0f7ff]'
                : ''
            "
            @click="postSettingsForm.brandVoice = option"
          >
            {{ option.name }}
            <i
              class="far fa-check"
              :class="
                postSettingsForm.brandVoice?.id === option.id
                  ? 'visible'
                  : 'invisible'
              "
            ></i>
          </DropdownItem>
        </Dropdown>
      </div>

      <!-- Social Platforms -->
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Social Platforms
          <i
            v-tooltip="'Select target social platforms'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <Dropdown
          radius="lg"
          container-classes="w-full"
          placement="bottom"
          match-width="true"
          button-classes="border rounded-xl hover:!border-[#2294FF]"
          dropdown-classes="!z-[1050]"
        >
          <template v-slot:selected>
            <span v-if="postSettingsForm.socialPlatform">
              <img
                :src="
                  require('@assets/img/integration/' +
                    postSettingsForm.socialPlatform +
                    '-rounded.svg')
                "
                :alt="postSettingsForm.socialPlatform"
                class="w-6 h-6 mr-2"
              />
              {{ capitalizeFirstLetter(postSettingsForm.socialPlatform) }}
            </span>
            <span v-else> Select Social Platform </span>
          </template>
          <DropdownItem
            v-for="option in platformOptions"
            :key="option.value"
            class="group flex justify-between items-center"
            :class="
              postSettingsForm.socialPlatform === option.value
                ? 'bg-[#f0f7ff]'
                : ''
            "
            @click="postSettingsForm.socialPlatform = option.value"
          >
            <span>
              <img
                :src="require('@assets/img/integration/' + option.icon)"
                :alt="option.label"
                class="w-6 h-6 mr-2"
              />
              {{ option.label }}
            </span>
            <i
              class="far fa-check"
              :class="
                postSettingsForm.socialPlatform === option.value
                  ? 'visible'
                  : 'invisible'
              "
            ></i>
          </DropdownItem>
        </Dropdown>
      </div>

      <!-- Language -->
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Language
          <i
            v-tooltip="'Choose content language'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <Dropdown
          radius="lg"
          container-classes="w-full"
          placement="bottom"
          match-width="true"
          button-classes="border rounded-xl hover:!border-[#2294FF]"
          dropdown-classes="!z-[1050]"
        >
          <template v-slot:selected>{{
            postSettingsForm.language?.label || 'Select Language'
          }}</template>
          <DropdownItem
            v-for="option in languageOptions"
            :key="option.value"
            class="group flex justify-between items-center"
            :class="
              postSettingsForm.language?.id === option.id ? 'bg-[#f0f7ff]' : ''
            "
            @click="postSettingsForm.language = option"
          >
            {{ option.label }}
            <i
              class="far fa-check"
              :class="
                postSettingsForm.language?.id === option.id
                  ? 'visible'
                  : 'invisible'
              "
            ></i>
          </DropdownItem>
        </Dropdown>
      </div>


      <!-- Post Type -->
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Post Type
          <i
            v-tooltip="'Select the format of the posts: text, image, or both.'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <Dropdown
          radius="lg"
          container-classes="w-full"
          placement="bottom"
          match-width="true"
          button-classes="border rounded-xl hover:!border-[#2294FF]"
          dropdown-classes="!z-[1050]"
        >
          <template v-slot:selected>{{
            postSettingsForm.postType?.label || 'Select Type'
          }}</template>
          <DropdownItem
            v-for="option in postTypeOptions"
            :key="option.value"
            v-tooltip="option.tooltip"
            class="group flex justify-between items-center"
            :class="
              postSettingsForm.postType?.value === option.value
                ? 'bg-[#f0f7ff]'
                : ''
            "
            @click="postSettingsForm.postType = option"
          >
            {{ option.label }}
            <i
              class="far fa-check"
              :class="
                postSettingsForm.postType?.value === option.value
                  ? 'visible'
                  : 'invisible'
              "
            ></i>
          </DropdownItem>
        </Dropdown>
      </div>

      <!-- No of Posts -->
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          No of Posts to Generate
          <i
            v-tooltip="'Choose how many posts to generate in this session (e.g., 5, 10).'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <input
          v-model="postSettingsForm.postsCount"
          type="text"
          placeholder="10"
          class="w-full border border-gray-300 !rounded-lg px-3 py-2 focus:outline-none focus:ring-1 focus:ring-[#2294FF] color-border hover:!border-[#2294FF]"
          @input="validateDigits"
        />
      </div>
    </div>

    <!-- Emoji & Hashtag Usage -->
    <div class="grid grid-cols-2 gap-6 mt-2">
      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Emoji Usage
          <i
            v-tooltip="'Set the frequency of emoji use in captions: low, medium, or high.'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <SegmentedControl
          :model-value="postSettingsForm.emojiUsage"
          radius="lg"
          size="sm"
          :data="usageLevels"
          @update:model-value="postSettingsForm.emojiUsage = $event"
        />
      </div>

      <div>
        <label class="block mb-2 font-medium text-[#3C4549]">
          Hashtag Usage
          <i
            v-tooltip="'Set the frequency of hashtag use in captions: low, medium, or high.'"
            class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
          ></i>
        </label>
        <SegmentedControl
          :model-value="postSettingsForm.hashtagUsage"
          radius="lg"
          size="sm"
          :data="usageLevels"
          @update:model-value="postSettingsForm.hashtagUsage = $event"
        />
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="flex justify-end mt-4 gap-4">
      <Button
        v-if="focusedForm"
        variant="light"
        color="light"
        size="sm"
        @click="$emit('cancel')"
      >
        Cancel
      </Button>
      <Button
        size="sm"
        :loading="postSettingLoader"
        :disabled="postSettingLoader || !focusedForm"
        @click="handleSavePostSettings"
      >
        {{ hasPostGenerationSettings ? 'Update' : 'Save' }}</Button
      >
    </div>
  </div>
</template>
