<template>
  <Dropdown
    v-tooltip="'Tags'"
    placement="bottom"
    :show-arrow="false"
    dropdown-classes="w-72 !max-h-[60vh]"
    size="sm"
    radius="lg"
    @close="handleSearchClose"
  >
    <template v-slot:selected>
      <template v-if="!tags?.length">
        <i class="far fa-tag cstu-text-gray-500 text-sm leading-none"></i>
        <span class="cstu-text-gray-500 text-sm font-normal">Tags</span>
      </template>
      <template v-else>
        <div class="flex items-center gap-2">
          <template v-for="(tag) in tags.slice(0, 2)" :key="tag._id">
            <div class="flex items-center gap-1">
              <TagIcon :class="`text_${tag?.tag_color}`" />
              <span v-tooltip.bottom="tag.tag_name" class=" hidden xl:inline-block text-sm truncate text-[#3A4557] max-w-[100px]">{{ tag.tag_name }}</span>
            </div>
          </template>
          <div
            v-if="tags.length > 2"
            class="flex items-center justify-center min-w-[20px] h-[20px] rounded-full bg-[#FFC555] text-xs"
          >
            {{ `+${tags.length - 2}` }}
          </div>
        </div>
      </template>
    </template>
    <div>
      <div class="flex items-center justify-between">
        <div v-if="!isSearchActive && !searchText" class="flex items-center gap-3">
          <span class="text-[#75797C] text-sm font-normal">Tags</span>
          <Button
            variant="text"
            color="secondary"
            size="sm"
            class="!px-0"
            @click="showCreateTag = !showCreateTag"
          >
            <img
              v-if="!showCreateTag"
              v-tooltip="'Add a new Tag'"
              src="@src/assets/img/common/add-circle.svg"
              class="h-4 w-4 cursor-pointer"
              alt="add Tag"
            />
            <img
              v-else
              v-tooltip="'Add a new Tag'"
              src="@src/assets/img/common/add-circle-disabled.svg"
              class="h-4 w-4 cursor-pointer"
              alt="add Tag disabled"
            />
          </Button>
        </div>
        <div
          id="search"
          :class="[
            'ml-auto flex items-center px-3 gap-3 cstu-bg-gray-100 rounded-lg transition-all duration-300 h-[35px]',
            isSearchActive || searchText ? 'flex-1' : 'w-[100px]',
          ]"
        >
        <div class="w-[14px]">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path d="M6.52148 11.332C9.21387 11.332 11.3965 9.14942 11.3965 6.45703C11.3965 3.76464 9.21387 1.58203 6.52148 1.58203C3.8291 1.58203 1.64648 3.76464 1.64648 6.45703C1.64648 9.14942 3.8291 11.332 6.52148 11.332Z" stroke="#8F8F8F" stroke-width="1.08333" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.4792 12.4167L10.3125 10.25" stroke="#8F8F8F" stroke-width="1.08333" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
          <input
            v-model="searchText"
            type="text"
            placeholder="Search"
            class="w-full bg-transparent outline-none py-2.5"
            @focus="isSearchActive = true"
            @blur="handleSearchBlur"
          />
          <template v-if="isSearchActive">
            <span class="cursor-pointer" @click="handleSearchClose">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M10.623 10L13.8428 6.78027C14.1357 6.4873 14.1357 6.0127 13.8428 5.71973C13.5498 5.42676 13.0752 5.42676 12.7822 5.71973L9.5625 8.93945L6.34277 5.71973C6.0498 5.42676 5.5752 5.42676 5.28223 5.71973C4.98926 6.0127 4.98926 6.4873 5.28223 6.78027L8.50195 10L5.28223 13.2197C4.98926 13.5127 4.98926 13.9873 5.28223 14.2803C5.42871 14.4268 5.62061 14.5 5.8125 14.5C6.00439 14.5 6.19629 14.4268 6.34277 14.2803L9.5625 11.0605L12.7822 14.2803C12.9287 14.4268 13.1206 14.5 13.3125 14.5C13.5044 14.5 13.6963 14.4268 13.8428 14.2803C14.1357 13.9873 14.1357 13.5127 13.8428 13.2197L10.623 10Z" fill="#4A4A4A"/>
              </svg>
            </span>
          </template>
        </div>
        <div
          v-tooltip="'Manage and edit your tags here.'"
          class="ml-2 cursor-pointer font-normal text-gray-900 hover:text-primary-variant-1 transition-colors"
          @click="redirectToSettings"
        >
          <i class="icon-settings-cs h-9 w-9 text-xl text-center pt-1"></i>
        </div>
      </div>
      <transition name="fade">
        <template v-if="showCreateTag">
          <LabeledColorInput
            placeholder="Tag name"
            :is-loading="isAddingTag"
            @on-create="onCreateTags"
            @on-cancel="onClose"
          >
            <template v-slot:prefix-icon>
              <TagIcon class="text-[#3C4549]"/>
            </template>
          </LabeledColorInput>
        </template>
      </transition>
    </div>
    <hr class="m-0">
    <template v-if="filteredTags.length">
      <DropdownItem
        v-for="(item) in filteredTags"
        :key="item._id"
        :close-on-click="false"
        class="flex items-center gap-[14px] group select-none h-[30px] hover:font-medium"
        :class="{ '!font-medium': isTagSelected(item._id) }"
        :selected="isTagSelected(item._id)"
        :disabled="isTagLoading[item._id]"
        @click="ToggleTagSelection(item._id)"
      >
        <template v-slot>
          <TagIcon :class="`text_${item?.tag_color}`" />
          <p class="flex-1 text-sm truncate text-[#3A4557]">
            {{ item.tag_name }}
          </p>
          <template v-if="isTagLoading[item._id]">
            <clip-loader
              class="align-middle inline-block"
              color="#4165ed"
              :size="'16px'"
            ></clip-loader>
          </template>
          <template v-else>
            <Checkbox :model-value="isTagSelected(item._id)" />
          </template>
        </template>
      </DropdownItem>
    </template>
    <template v-else>
      <div class="px-4 py-2 text-sm cstu-text-gray-500">
        {{ searchText ? 'No tags match your search query' : 'No tags available' }}
      </div>
    </template>
  </Dropdown>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { Button, Checkbox, Dropdown, DropdownItem } from '@contentstudio/ui';
import TagIcon from '@src/modules/inbox-revamp/components/TagIcon.vue';
import { useRouter } from 'vue-router';
import LabeledColorInput from '@/src/components/common/LabeledColorInput.vue';

const props = defineProps({
  tags: {
    type: Array,
    default: () => [],
  },
  availableTags: {
    type: Array,
    default: () => [],
  },
  isTagLoading: {
    type: Object,
    default: () => ({}),
  },
  isAddingTag: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['create-tag', 'toggle-tag', 'close']);

const router = useRouter();
const showCreateTag = ref(false);
const searchText = ref('');
const isSearchActive = ref(false);

const filteredTags = computed(() => {
  if (!searchText.value) return props.availableTags;
  return props.availableTags.filter(tag =>
    tag.tag_name.toLowerCase().includes(searchText.value.toLowerCase())
  );
});

watch(() => props.isAddingTag, (newVal) => {
  if (!newVal) {
    showCreateTag.value = false;
  }
});

const handleSearchBlur = () => {
  if (!searchText.value) {
    isSearchActive.value = false;
  }
};

const handleSearchClose = (emitClose = false) => {
  searchText.value = '';
  isSearchActive.value = false;
  if (emitClose) {
    emit('close');
  }
};

const onCreateTags = (data) => {
  emit('create-tag', data);
};

const onClose = () => {
  showCreateTag.value = false;
};

const isTagSelected = (tagId) => {
  return props.tags?.some((tag) => tag?._id === tagId);
};

const ToggleTagSelection = async (tagId) => {
  emit('toggle-tag', tagId, isTagSelected(tagId));
};

const redirectToSettings = () => {
  router.push({ name: 'miscellaneous', hash: 'tags' });
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
