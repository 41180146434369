<script setup>
import { ref, watch, computed } from 'vue'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics.js'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'
import TopPostCard from './TopPostCard.vue'
import CstDropdown from '@src/components/UI/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@src/components/UI/Dropdown/IconDropdownItem.vue'
import ChevronDownIcon from '@/src/assets/img/chevron-down.svg'
import EngagementIcon from "@/src/assets/img/icons/analytic/plain/total-engagement.svg"
import SavesIcon from "@/src/assets/img/icons/analytic/plain/saved-icon.svg"
import ViewsIcon from "@/src/assets/img/icons/analytic/plain/reach.svg"
import PointSelectIcon from "@/src/assets/img/icons/analytic/plain/post-clicks.svg"

// Props
const props = defineProps({
  type: {
    type: String,
    default: 'top_posts',
    validator: (value) => ['top_posts', 'least_posts'].includes(value)
  }
})

// Composable destructuring
const {
  routes,
  selectedAccount,
  topAndLeastEngagingPosts,
  isLoadingStates,
  isReportView,
  TopLeastEngagementDropdown,
  getTopLeastEngagingPayload,
  fetchMedia
} = usePinterestAnalytics()

// State
const selectedSortType = ref('impressions')

const isTopPosts = computed(() => props.type === 'top_posts')

const cardType = computed(() => props.type === 'top_posts' ? 'pinterest-top-posts' : 'pinterest-least-posts')

const displayPrefix = computed(() => props.type === 'top_posts' ? 'top' : 'least')

// Computed properties
const postsData = computed(() => {
  return topAndLeastEngagingPosts.value[props.type][selectedSortType.value]
})

const loadingStateKey = computed(() => {
  return props.type === 'top_posts' ? routes?.TOP_PERFORMING_PINS : routes?.LEAST_PERFORMING_PINS
})

// Define metrics rows for the post cards
const rows = [
  {
    icon: ViewsIcon,
    iconClass: '',
    title: 'Impressions',
    api_key: 'impressions',
  },
  {
    icon: EngagementIcon,
    iconClass: '',
    title: 'Engagement',
    api_key: 'engagement',
  },
  {
    icon: PointSelectIcon,
    iconClass: '',
    title: 'Pin Clicks',
    api_key: 'pinClicks',
  },
  {
    icon: PointSelectIcon,
    iconClass: '',
    title: 'Outbound Clicks',
    api_key: 'outboundClicks',
  },
  {
    icon: SavesIcon,
    iconClass: '',
    title: 'Saves',
    api_key: 'saves',
  }
]

// Watch for changes in the posts data and process it
watch(() => [selectedSortType.value], () => {
  const currentPosts = postsData.value
  if (currentPosts && currentPosts.length) {
    currentPosts.forEach((post) => {
      post.media_type = post?.media_type
    })
  }
},{
  immediate: true,
  deep: true
})

// Methods
const handleSortChange = (option) => {
  selectedSortType.value = option.api_key
  fetchMedia(loadingStateKey.value, {order_by: selectedSortType.value})
}
</script>

<template>
  <AnalyticsCardWrapper
    :type="cardType"
    :show-insights-button="!isReportView"
    :enable-modal="true"
    :enable-insights="true"
    :selected-dropdown-option="displayPrefix"
    :platform="'pinterest'"
  >
    <template v-slot:card-header>
      <div v-if="!isReportView" class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <CstDropdown
              dropdown-placement="bottom"
              container-classes="!max-h-96"
              dropdown-classes="!rounded-md !border-[#70707029]"
              button-classes="flex !px-5 !w-[18rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
              @onClose="() => {}"
            >
              <template v-slot:arrow>
                <img :src="ChevronDownIcon" alt="chevron-down" />
              </template>
              <template v-slot:selected>
                <p class="text-[14px] font-weight-500 capitalize">
                  {{ `${displayPrefix} Posts By ${selectedSortType === 'engagement' ? 'Engagement' :
                    selectedSortType === 'impressions' ? 'Views' :
                    selectedSortType === 'pin_clicks' ? 'Pin Clicks' :
                    selectedSortType === 'outbound_clicks' ? 'Outbound Clicks' : 'Saves'}` }}
                </p>
              </template>
              <template v-slot>
                <template
                  v-for="option in TopLeastEngagementDropdown"
                  :key="option.api_key"
                >
                  <CstDropdownItem @click="handleSortChange(option)">
                    <p class="text-gray-900 text-sm">{{ displayPrefix }} Posts By {{ option.label }}</p>
                  </CstDropdownItem>
                </template>
              </template>
            </CstDropdown>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  The {{ isTopPosts ? 'top' : 'least' }} performing Pinterest pins by {{
                    selectedSortType === 'engagement' ? 'engagement' :
                    selectedSortType === 'impressions' ? 'impressions' :
                    selectedSortType === 'pin_clicks' ? 'pin clicks' :
                    selectedSortType === 'outbound_clicks' ? 'outbound clicks' : 'saves'
                  }}, published during the selected time period.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:card-body="">
      <template v-if="!isLoadingStates[loadingStateKey] && postsData?.length === 0">
        <div class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No data found.</p>
        </div>
      </template>
      <template v-else>
        <div
          class="grid gap-5"
          :class="{ 'grid-cols-3': isReportView, 'grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 h-[535px] overflow-hidden px-8': !isReportView }"
        >
          <template v-if="isLoadingStates[loadingStateKey]">
            <template v-for="i in [1,2,3,4,5]" :key="i">
              <SkeletonBox
                :height="'500px'"
              />
            </template>
          </template>
          <template v-else>
            <template v-for="(post, i) in postsData" :key="i">
              <TopPostCard :selected-account="selectedAccount" :post="post" :metrics-rows="rows" />
            </template>
          </template>
        </div>
      </template>
    </template>
  </AnalyticsCardWrapper>
</template>
