<template>
  <div class="mt-6">
    <!-- Upload Area -->
    <div
      class="text-center mb-6 py-2 rounded-xl hover:bg-blue-50/50 !border !border-dashed hover:!border-[#2e7cff]"
      :class="{ 'drag-active': isDragging }"
      @dragenter.prevent="dragEnter"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="handleDrop"
      @paste.prevent="handlePaste"
    >
      <!-- Drag overlay -->
      <div
        v-if="isDragging"
        class="inset-0 bg-blue-50 rounded-xl flex items-center justify-center"
      >
        <div class="text-center">
          <img
            src="@assets/img/composer/upload-icon.svg"
            alt=""
            class="w-10 h-10 mx-auto mb-2"
          />
          <p class="text-gray-700">Drop here</p>
        </div>
      </div>

      <div v-else class="p-3">
        <img
          src="@assets/img/composer/upload-icon.svg"
          alt=""
          class="w-10 h-10 mx-auto cursor-pointer"
          @click="handleUploadClick"
        />
      </div>
      <h5 class="my-2 font-medium">
        Paste, Drop or
        <span
          class="text-[#2E7CFF] hover:underline cursor-pointer"
          @click="handleUploadClick"
          >
          Upload
          <i
          v-tooltip="'Upload PDF , Markdown or text files containing brand-related details. AI will extract relevant data automatically.'"
          class="far fa-question-circle p-0 cursor-pointer text-primary-cs ml-2"
        ></i>
          </span
        >
      </h5>
      <p class="flex items-center justify-center">
        <span class="font-normal text-sm text-gray-700">
          Markdown, TXT, PDF
        </span>
      </p>
      <!-- Media Library button -->
      <div
        v-tooltip="'Select Media from Media Library'"
        class="mx-auto my-2 w-36 cursor-pointer px-2.5 py-2 flex items-center justify-center bg-cs-ultra-violet hover:bg-opacity-75 transition-all ease-in-out rounded-md hover-trigger relative"
        @click.stop="openMediaLibrary"
      >
        <img src="@assets/img/composer/media-icon.svg" alt="" class="w-5" />
        <span
          class="ml-2 text-sm font-normal text-black-200 leading-none hidden 2xl:block"
        >
          Media Library
        </span>
      </div>
    </div>

    <!-- Files List -->
    <div class="space-y-3 max-h-60 overflow-y-auto">
      <div
        v-for="(file, index) in setupInfo.uploads.files"
        :key="index"
        class="flex items-center justify-between p-3 bg-white border border-gray-200 rounded-lg"
      >
        <div class="flex items-center w-full space-x-3">
          <div class="bg-gray-200 rounded-xl p-2">
            <img
              :src="require('@assets/img/icons/' + file.type + '-icon.svg')"
              alt=""
              class="w-8 h-8"
            />
          </div>
          <div class="flex flex-col flex-grow">
            <span class="text-sm font-medium text-gray-900">{{
              file.name
            }}</span>
            <div class="flex w-full items-center justify-between">
              <span class="text-xs text-gray-900">{{ file.size }}</span>
              <div
                v-if="file.progress !== undefined"
                class="flex items-center py-1 mr-4"
              >
                <progress
                  class="progress-bar rounded"
                  :value="file.progress"
                  :max="100"
                ></progress>
                <span class="text-xs text-gray-700">· {{ file.timeLeft }}</span>
              </div>
            </div>
          </div>
        </div>
        <button
          class="text-gray-700 hover:bg-gray-200 p-2 rounded-md"
          @click="removeUploadedFile(index)"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>

    <!-- Hidden File Input -->
    <input
      ref="fileInput"
      type="file"
      class="hidden"
      accept=".md,.txt,.pdf"
      @change="handleFileSelect"
    />
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { useStore } from '@state/base'
import { useSetup } from '@modules/publisher/ai-content-library/composables/useSetup'
import { EventBus } from '@/src/modules/common/lib/event-bus'

const {
  setupInfo,
  processFiles,
  processMediaLibraryFiles,
  removeUploadedFile,
} = useSetup()
const { dispatch } = useStore()

const fileInput = ref(null)
const isDragging = ref(false)
const dragCounter = ref(0)

onMounted(() => {
  EventBus.$on('add-pdf-from-library', processMediaLibraryFiles)
})

onBeforeUnmount(() => {
  EventBus.$off('add-pdf-from-library')
})

const handleUploadClick = () => {
  fileInput.value.click()
}

const dragEnter = (event) => {
  event.preventDefault()
  dragCounter.value++
  if (dragCounter.value === 1) {
    isDragging.value = true
  }
}

const dragOver = (event) => {
  event.preventDefault()
}

const dragLeave = (event) => {
  event.preventDefault()
  dragCounter.value--
  if (dragCounter.value === 0) {
    isDragging.value = false
  }
}

const handleDrop = (event) => {
  event.preventDefault()
  isDragging.value = false
  dragCounter.value = 0

  const droppedFiles = Array.from(event.dataTransfer.files)
  processFiles(droppedFiles)
}

const handlePaste = async (event) => {
  const items = Array.from(event.clipboardData.items)
  const textFiles = items
    .filter((item) => item.kind === 'file' || item.type === 'text/plain')
    .map((item) => {
      if (item.kind === 'file') {
        return item.getAsFile()
      } else {
        // Convert text content to a file
        const text = event.clipboardData.getData('text/plain')
        return new File([text], 'pasted-content.txt', { type: 'text/plain' })
      }
    })

  if (textFiles.length > 0) {
    processFiles(textFiles)
  } else {
    dispatch('toastNotification', {
      message: 'No valid content found in clipboard',
      type: 'warning',
    })
  }
}

const handleFileSelect = (event) => {
  const selectedFiles = Array.from(event.target.files)
  processFiles(selectedFiles)
  // Reset file input
  event.target.value = ''
}

// Open media library
const openMediaLibrary = () => {
  EventBus.$emit('show-media-library-modal', {
    source: 'ai-content-library',
    details: {},
    sideTabIndex: 1,
    modalId: 'global-upload-media-modal',
  })
}
</script>

<style scoped>
.drag-active {
  @apply !border-dashed !border-[1.5px] border-[#2e7cff] bg-blue-50;
}

.progress-bar {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  height: 6px;
  cursor: default;
  user-select: none;
  &::-webkit-progress-bar {
    border-radius: 8px;
    background: #d6d5d5;
  }
  &::-webkit-progress-value {
    background: #2294FF;
    border-radius: 8px;
    transition: width 0.3s ease;
  }
}
</style>
