<template>
  <div
    class="relative overflow-hidden rounded-xl my-2 cursor-pointer group asset border border-gray-400"
    :data-item-selected="image.select"
    @click.stop="handleImageSelect"
  >
    <img
      :src="image.base64 ? `data:image/png;base64,${image.base64.trim()}` : ''"
      class="w-full h-[15rem] object-contain rounded-xl cursor-pointer asset-img"
      :alt="'AI Generated Image'"
    />

    <!-- Hover overlay with actions -->
    <div
      class="absolute inset-0 transition-opacity duration-300 flex flex-col justify-between p-2 rounded-xl layout"
    >
      <!-- Top Left -->
      <span
        v-tooltip="'View Preview'"
        class="absolute top-3 left-3 cursor-pointer !z-10 hidden group-hover:!block"
        @click.stop="handlePreview(image)"
      >
        <img
          loading="lazy"
          src="@src/assets/img/planner/preview-file.svg"
          class="w-5 h-5 p-1 bg-[#efefef] rounded-md hover:bg-white transition-colors duration-300"
          alt="preview file"
        />
      </span>

      <!-- Top Right plus icon blue background   -->
      <div
        v-tooltip="'Add to Editor'"
        class="absolute top-3 right-3 cursor-pointer hidden group-hover:!block"
        @click.stop="handleAddToEditor"
      >
        <i class="fa fa-plus text-white bg-cs-primary p-1 rounded-lg"></i>
      </div>

      <!-- bottom right checkbox -->
      <div
        class="absolute bottom-3 right-3 flex items-center justify-center info-checkbox asset-action"
      >
        <img
          src="@src/assets/img/planner/checkbox-unselected.svg"
          class="w-6 h-6 unchecked"
          alt="unchecked"
        />
        <img
          src="@src/assets/img/planner/checkbox-hovered.svg"
          class="w-6 h-6 hovered"
          alt="hovered"
        />
        <img
          src="@src/assets/img/planner/checkbox-selected.svg"
          class="w-6 h-6 checked"
          alt="checked"
        />
      </div>
    </div>
  </div>
</template>

<script>
// libraries
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ViewImage',
  props: {
    image: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['add-to-editor', 'preview'],
  setup(props, { emit }) {
    // methods
    const handleImageSelect = () => {
      // eslint-disable-next-line vue/no-mutating-props
      props.image.select = !props.image.select
    }

    const handlePreview = (image) => {
      emit('preview', image)
    }

    const handleAddToEditor = () => {
      emit('add-to-editor', props.image)
    }

    return {
      handleImageSelect,
      handlePreview,
      handleAddToEditor,
    }
  },
})
</script>

<style lang="scss">
/* Mixin for shadow inset effect */
@mixin shadow-inset-center {
  -webkit-box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
}

/* Styles for unselected assets */
.asset {
  &:not([data-item-selected='true']) {
    .info-checkbox {
      visibility: hidden;
    }

    &:hover {
      .info-checkbox {
        visibility: visible;
      }
    }
  }
}

/* Styles for selected assets */
.asset[data-item-selected='true'] {
  .layout {
    background: linear-gradient(
      to bottom,
      rgba(15, 34, 77, 0.3) -26%,
      rgba(38, 40, 44, 0.6) 100%
    );
    z-index: 9;
    border: 3px solid #409aff !important;
  }

  .info-checkbox {
    visibility: visible;
  }

  &:hover {
    .layout {
      animation: shadow-inset-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      z-index: 9;
    }
  }
}

/* Asset action styles */
.asset-action {
  .unchecked {
    display: block;
  }
  .hovered {
    display: none;
  }
  .checked {
    display: none;
  }

  &:not([data-item-selected='true']):hover {
    .unchecked,
    .checked {
      display: none;
    }
    .hovered {
      display: block;
    }
  }
}

/* Selected state checkbox */
.asset[data-item-selected='true'] {
  .asset-action {
    .unchecked,
    .hovered {
      display: none;
    }
    .checked {
      display: block;
    }
  }
}

/* General styles for hover and animations */
.layout:hover {
  @include shadow-inset-center;
  animation: shadow-inset-center 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  background: linear-gradient(
    to bottom,
    rgba(38, 40, 44, 0.3) -26%,
    rgba(38, 40, 44, 0.6) 100%
  );
  transition: 0.3s all ease-in;
}

@keyframes shadow-inset-center {
  0% {
    -webkit-box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
    box-shadow: inset 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    -webkit-box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 0 0 14px 0 rgba(0, 0, 0, 0.5);
  }
}
</style>
