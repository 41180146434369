<template>
  <div class="analytic_common_component">
    <div v-if="loader" class="component_inner">
      <div class="page_view d-flex align-items-center justify-content-center">
        <div class="subpage">
          <div class="logo_block">
            <beat-loader :color="'#436aff'"></beat-loader>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!loader && !getReportsServiceCalls.retrieve"
      class="component_inner"
    >
      <CoverPage
        :type="type"
        :accounts="metrics.accounts"
        :report-date="report_date"
        :labels="metrics.labels"
        :campaigns="metrics.campaigns"
      ></CoverPage>
      <template v-if="type === 'twitter'">
        <TwitterReport :metrics="metrics"></TwitterReport>
      </template>
      <template v-else-if="type === 'pinterest'">
        <PinterestReport :metrics="metrics"></PinterestReport>
      </template>
      <template v-else-if="type === 'linkedin'">
        <LinkedinReport :metrics="metrics"></LinkedinReport>
      </template>
      <template v-else-if="type === 'facebook'">
        <FacebookReport :metrics="metrics"></FacebookReport>
      </template>
      <template v-else-if="type === 'instagram'">
        <InstagramReport :metrics="metrics"></InstagramReport>
      </template>
      <template v-else-if="type === 'tiktok'">
        <TiktokReport :metrics="metrics"></TiktokReport>
      </template>
      <template v-else-if="type === 'youtube'">
        <YoutubeReport :metrics="metrics"></YoutubeReport>
      </template>
      <template v-else-if="type === 'campaign-and-label'">
        <LabelCampaignReport :metrics="metrics"></LabelCampaignReport>
      </template>
      <template v-else-if="type === 'group' || type === 'individual'">
        <OverviewReport
          :metrics="metrics"
          :account-select-flags="accountSelectFlag"
        ></OverviewReport>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import OverviewReport from '@src/modules/analytics/views/reports/OverviewReport'
import LinkedinReport from '@src/modules/analytics/views/reports/LinkedinReport_v2'
import FacebookReport from '@src/modules/analytics/views/reports/FacebookReport_v2.vue'
import TwitterReport from '@src/modules/analytics/views/reports/TwitterReport'
import InstagramReport from '@src/modules/analytics/views/reports/InstagramReport_v2.vue'

import TiktokReport from '@src/modules/analytics/views/reports/TiktokReport'
import YoutubeReport from '@src/modules/analytics/views/reports/YoutubeReport'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types.js'
import { commonMethods } from '@common/store/common-methods'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import usePinterestAnalytics from '@src/modules/analytics/views/pinterest/composables/usePinterestAnalytics'
import useTwitterAnalytics from '@src/modules/analytics/views/twitter/composables/useTwitterAnalytics'
import { dataValues } from './common/helper'
import PinterestReport from './reports/channel/PinterestReport'
import CoverPage from './reports/CoverPage'
import analyticsUtilsMixin from './common/analyticsUtilsMixin'
import useTiktokAnalytics from '@/src/modules/analytics/views/tiktok/composables/useTiktokAnalytics'
import useYoutubeAnalytics from '@/src/modules/analytics/views/youtube/composables/useYoutubeAnalytics'
import useLabelAndCampaign from '@/src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'
import LabelCampaignReport from '@/src/modules/analytics/components/reports/channel/LabelCampaignReport.vue'
import useFacebookAnalytics from '@/src/modules/analytics/views/facebook_v2/composables/useFacebookAnalytics.js'
import useInstagramAnalytics from '@/src/modules/analytics/views/instagram_v2/composables/useInstagramAnalytics.js'
import useLinkedinAnalytics from '@/src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'
import useOverviewAnalytics from '@/src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'

const {
  routes: overviewRoutes,
  selectedAccount: selectedOverviewAccount,
  dateRange: overviewDateRange,
  isLoadingStates: overviewLoadingStates,
  fetchMedia: fetchOverviewMedia,
  topPostsData: overviewTopPostsData,
  platformData: overviewPlatformData,
  platformDataDetailed: overviewPlatformDataDetailed,
  platformDataGraphs: overviewPlatformDataGraphs,
} = useOverviewAnalytics()

const {
  routes: facebookRoutes,
  selectedAccount: selectedFacebookAccount,
  dateRange: facebookDateRange,
  postsLimit: facebookPostsLimit,
  fetchMedia: fetchFacebookMedia,
} = useFacebookAnalytics()

const {
  routes: instagramRoutes,
  selectedAccount: selectedInstagramAccount,
  dateRange: instagramDateRange,
  postsLimit: instagramPostsLimit,
  fetchMedia: fetchInstagramMedia,
} = useInstagramAnalytics()

const {
  routes: tiktokRoutes,
  selectedAccount: selectedTiktokAccount,
  isPostDataLoading: isTiktokPostDataLoading,
  dateRange: tiktokDateRange,
  postsLimit: tiktokPostLimit,
  fetchMedia: fetchTiktokMedia,
} = useTiktokAnalytics()

const {
  DEFAULT_TOP_AND_LEAST_POSTS_LIMIT,
  routes: youtubeRoutes,
  selectedAccount: selectedYoutubeAccount,
  isPostDataLoading: isYoutubePostDataLoading,
  dateRange: youtubeDateRange,
  postsLimit: youtubePostsLimit,

  fetchMedia: fetchYoutubeMedia,
} = useYoutubeAnalytics()

const {
  routes: pinterestRoutes,
  selectedAccount: selectedPinterestAccount,
  dateRange: pinterestDateRange,
  isPostDataLoading: pinterestIsPostDataLoading,
  postsLimit: pinterestPostsLimit,
  getPerformanceChartPayload,
  getTopLeastEngagingPayload,
  TopLeastEngagementDropdown,
  fetchMedia: fetchPinterestMedia,
} = usePinterestAnalytics()

const {
  routes: twitterRoutes,
  selectedAccount: selectedTwitterAccount,
  isPostDataLoading: isTwitterPostDataLoading,
  dateRange: twitterDateRange,
  postsLimit: twitterPostsLimit,

  fetchMedia: fetchTwitterMedia,
} = useTwitterAnalytics()

const {
  routes: labelCampaignRoutes,
  selectedAccount: selectedLabelCampaignAccount,
  dateRange: labelCampaignDateRange,
  isAllAccountsSelected: isAllLabelCampaignAccountsSelected,
  selectedCampaignsAndLabels,
  fetchMedia: fetchLabelCampaignMedia,
} = useLabelAndCampaign()

const {
  routes: linkedinRoutes,
  selectedAccount: selectedLinkedinAccount,
  dateRange: linkedinDateRange,
  postsLimit: linkedinPostsLimit,
  fetchMedia: fetchLinkedinMedia,
} = useLinkedinAnalytics()

const { isReportView } = useAnalytics()

export default {
  components: {
    CoverPage,
    TwitterReport,
    PinterestReport,
    LinkedinReport,
    FacebookReport,
    InstagramReport,
    TiktokReport,
    YoutubeReport,
    OverviewReport,
    LabelCampaignReport,
  },
  mixins: [analyticsUtilsMixin],
  data() {
    return {
      metrics: {},
      report_date: '',
      is_rendered: false,
      loader: true,
      type: '',
      workspace: [],
      accounts: [],
      top_post_loader: false,
      previous_date: '',
    }
  },
  computed: {
    ...mapGetters(['getReportsServiceCalls']),
  },
  watch: {},
  created() {
    // setTimeout(function () {
    //     window.print();
    // },5000)
  },
  async mounted() {
    isReportView.value = true

    setTimeout(function () {
      commonMethods.toggleWidgets(true)
    }, 5000)
    if (this.$route.params.reportId) {
      const response = await this.getReportsService({
        _id: this.$route.params.reportId,
      })

      if (response) {
        const {
          workspace_id: workspaceId,
          date,
          previous_date: previousDate,
          accounts,
          type,
        } = response
        this.metrics.topPostsLimit = response?.topPosts || 10
        this.metrics.campaigns = response?.campaigns || []
        this.metrics.labels = response?.labels || []
        this.metrics.allAccountsSelected =
          response?.allAccountsSelected || false

        this.workspace = await this.fetchWorkspace({
          id: response.workspace_id,
        })
        this.$store.commit(workspaceTypes.SET_ACTIVE_WORKSPACE, this.workspace)
        this.previous_date = previousDate
        await this.getAnalyticsForReports(
          {
            date,
            workspaceId,
            accounts,
            type,
            previousDate,
            timezone: this.workspace.timezone,
          },
          response.accounts
        )

        this.report_date = response.date
        this.type = response.type
        this.accounts = response.accounts

        if (!this.isEmpty(this.metrics)) {
          this.loader = false
        }

        const self = this
        setTimeout(() => {
          self.is_rendered = true
        }, 10000)
      }
    }
  },
  beforeUnmount() {
    isReportView.value = false
  },
  methods: {
    ...mapActions([
      'getAnalyticsService',
      'getReportsService',
      'getAnalyzeService',
      'getTwitterAnalyticsService',
    ]),
    isEmpty(obj) {
      return Object.keys(obj).length === 0
    },
    GoToSocial() {
      this.$router.push({ name: 'social' })
      // eslint-disable-next-line no-undef
      $('.modal').modal('hide')
    },
    async getAnalyticsForReports(payload = {}, accounts) {
      this.loader = true
      const payloadCopy = payload
      const res = payloadCopy.date.split(' - ')

      // Sets the value of the dateRange variable to the response received from the server.
      youtubeDateRange.value = res
      pinterestDateRange.value = res
      twitterDateRange.value = res
      labelCampaignDateRange.value = res
      tiktokDateRange.value = res
      facebookDateRange.value = res
      instagramDateRange.value = res
      linkedinDateRange.value = res

      payloadCopy.previous_date = `${this.previousAnalyticsPeriodDate(
        res[0],
        res[1]
      )} - ${res[0]}`

      if (payloadCopy.type === 'group' || payloadCopy.type === 'individual') {
        // Set the overview dateRange and account
        overviewDateRange.value = res
        selectedOverviewAccount.value = accounts
        
        // Use the useOverviewAnalytics composable to fetch data
        await fetchOverviewMedia(overviewRoutes.SUMMARY)
        
        // Fetch data for all three graph types
        await fetchOverviewMedia(overviewRoutes.TOP_PERFORMING_GRAPH, { type: 'reach' })
        await fetchOverviewMedia(overviewRoutes.TOP_PERFORMING_GRAPH, { type: 'engagement' })
        await fetchOverviewMedia(overviewRoutes.TOP_PERFORMING_GRAPH, { type: 'impressions' })
        
        await fetchOverviewMedia(overviewRoutes.GET_PLATFORM_DATA, { type: 'grouped' })
        await fetchOverviewMedia(overviewRoutes.GET_PLATFORM_DATA, { type: 'individual' })
        await fetchOverviewMedia(overviewRoutes.GET_PLATFORM_DATA_DETAILED)
        await fetchOverviewMedia(overviewRoutes.GET_PLATFORM_DATA_GRAPHS)
        await fetchOverviewMedia(overviewRoutes.GET_TOP_POSTS, { type: 'total_engagement' })
        
        // Process account types for flags
        const accountTypes = [
          'facebook',
          'instagram',
          'linkedin',
          'tiktok',
          'youtube',
          'twitter',
        ]
        for (const type of accountTypes) {
          this.accountSelectFlag[type] = accounts.some(
            (item) => item.account_type === type
          )
        }
        
        // Set metrics data from the composable
        this.$set(this.metrics, 'overview', {
          cards: overviewPlatformData.value,
          platformData: overviewPlatformData.value,
          platformDataDetailed: overviewPlatformDataDetailed.value,
          platformDataGraphs: overviewPlatformDataGraphs.value
        })
        this.$set(this.metrics, 'top_posts', overviewTopPostsData.value)
        
        // Create engagement_rollup data from platform data
        const engagementRollup = {};
        if (overviewPlatformData.value && overviewPlatformData.value.grouped) {
          overviewPlatformData.value.grouped.forEach(platform => {
            engagementRollup[platform.platform_type] = {
              reach: platform.reach || 0,
              impressions: platform.impressions || 0,
              engagement: platform.engagement || 0,
              posts: platform.posts || 0
            }
          })
        }
        this.$set(this.metrics, 'engagement_rollup', engagementRollup)
        
        // Set posts_engagements from platform data
        this.$set(this.metrics, 'posts_engagements', {
          data: overviewPlatformData.value?.grouped || []
        })
        
        // Set account_performance from platform data detailed
        this.$set(this.metrics, 'account_performance', {
          data: overviewPlatformDataDetailed.value || []
        })
        
        // Set accounts in metrics
        this.$set(this.metrics, 'accounts', payloadCopy.accounts)
      } else if (payloadCopy.type === 'facebook') {
        selectedFacebookAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]
        facebookPostsLimit.value =
          this.metrics?.topPostsLimit || DEFAULT_TOP_AND_LEAST_POSTS_LIMIT

        fetchFacebookMedia(facebookRoutes.SUMMARY)
        fetchFacebookMedia(facebookRoutes.OVERVIEW_AUDIENCE_GROWTH)
        fetchFacebookMedia(facebookRoutes.OVERVIEW_ACTIVE_USERS)
        fetchFacebookMedia(facebookRoutes.OVERVIEW_IMPRESSIONS)
        fetchFacebookMedia(facebookRoutes.OVERVIEW_ENGAGEMENT)
        fetchFacebookMedia(facebookRoutes.OVERVIEW_TOP_POSTS)
        fetchFacebookMedia(facebookRoutes.OVERVIEW_REELS_ANALYTICS)
        fetchFacebookMedia(facebookRoutes.OVERVIEW_VIDEO_INSIGHTS)
        fetchFacebookMedia(facebookRoutes.OVERVIEW_PUBLISHING_BEHAVIOUR)
        fetchFacebookMedia(facebookRoutes.TOP_POSTS, {
          limit: facebookPostsLimit.value,
          order_by: 'total_engagement',
        })
        fetchFacebookMedia(facebookRoutes.OVERVIEW_AUDIENCE_LOCATION)
        fetchFacebookMedia(facebookRoutes.OVERVIEW_DEMOGRAPHICS)
      } else if (payloadCopy.type === 'instagram') {
        selectedInstagramAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]
        instagramPostsLimit.value =
          this.metrics?.topPostsLimit || DEFAULT_TOP_AND_LEAST_POSTS_LIMIT

        fetchInstagramMedia(instagramRoutes.SUMMARY)
        fetchInstagramMedia(instagramRoutes.OVERVIEW_AUDIENCE_GROWTH)
        fetchInstagramMedia(instagramRoutes.OVERVIEW_ACTIVE_USERS)
        fetchInstagramMedia(instagramRoutes.OVERVIEW_TOP_POSTS)
        fetchInstagramMedia(instagramRoutes.OVERVIEW_HASHTAGS)
        fetchInstagramMedia(instagramRoutes.OVERVIEW_REELS_ANALYTICS)
        fetchInstagramMedia(instagramRoutes.OVERVIEW_STORY_ANALYTICS)
        fetchInstagramMedia(instagramRoutes.OVERVIEW_PUBLISHING_BEHAVIOUR)
        fetchInstagramMedia(instagramRoutes.TOP_POSTS, {
          limit: instagramPostsLimit.value,
          order_by: 'engagement',
        })
        fetchInstagramMedia(instagramRoutes.OVERVIEW_AUDIENCE_LOCATION)
        fetchInstagramMedia(instagramRoutes.OVERVIEW_DEMOGRAPHICS)
      } else if (payloadCopy.type === 'linkedin') {
        selectedLinkedinAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]
        linkedinPostsLimit.value =
          this.metrics?.topPostsLimit || DEFAULT_TOP_AND_LEAST_POSTS_LIMIT
        fetchLinkedinMedia(linkedinRoutes.SUMMARY)
        fetchLinkedinMedia(linkedinRoutes.OVERVIEW_AUDIENCE_GROWTH)
        fetchLinkedinMedia(linkedinRoutes.OVERVIEW_PUBLISHING_BEHAVIOUR)
        fetchLinkedinMedia(linkedinRoutes.OVERVIEW_TOP_POSTS, {
          limit: 5,
        })
        fetchLinkedinMedia(linkedinRoutes.OVERVIEW_HASHTAGS)
        fetchLinkedinMedia(linkedinRoutes.OVERVIEW_POSTS_PER_DAY)
        fetchLinkedinMedia(linkedinRoutes.OVERVIEW_FOLLOWERS_DEMOGRAPHICS)
        fetchLinkedinMedia(linkedinRoutes.TOP_POSTS, {
          limit: linkedinPostsLimit.value,
          order_by: 'total_engagement',
        })
      } else if (payloadCopy.type === 'tiktok') {
        selectedTiktokAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]
        tiktokPostLimit.value =
          this.metrics?.topPostsLimit || DEFAULT_TOP_AND_LEAST_POSTS_LIMIT

        fetchTiktokMedia(tiktokRoutes.POSTS_DATA, {
          limit: isReportView.value
            ? tiktokPostLimit.value
            : DEFAULT_TOP_AND_LEAST_POSTS_LIMIT,
          sort_order: 'total_engagement',
        })
        isTiktokPostDataLoading.value = false
        fetchTiktokMedia(tiktokRoutes.PAGE_AND_POST_INSIGHTS)
        fetchTiktokMedia(tiktokRoutes.PAGE_FOLLOWERS_AND_VIEWS)
        fetchTiktokMedia(tiktokRoutes.POSTS_AND_ENGAGEMENTS)
        fetchTiktokMedia(tiktokRoutes.TOP_AND_LEAST_PERFORMING_POSTS)
        fetchTiktokMedia(tiktokRoutes.DAILY_ENGAGEMENTS_DATA)
      } else if (payloadCopy.type === 'youtube') {
        selectedYoutubeAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]
        youtubePostsLimit.value =
          this.metrics?.topPostsLimit || DEFAULT_TOP_AND_LEAST_POSTS_LIMIT
        isYoutubePostDataLoading.value = false
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_SUBSCRIBER_TREND)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_SUMMARY)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_ENGAGEMENT_TREND)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_VIEWS_TREND)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_WATCH_TIME_TREND)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_FIND_VIDEO)
        fetchYoutubeMedia(youtubeRoutes.CHANNEL_VIDEO_SHARING)
        fetchYoutubeMedia(youtubeRoutes.SORTED_TOP_POSTS, {
          order_by: 'engagement',
          limit: isReportView.value
            ? youtubePostsLimit.value
            : DEFAULT_TOP_AND_LEAST_POSTS_LIMIT,
        })
        fetchYoutubeMedia(youtubeRoutes.TOP_PERFORMING_POSTS)
        fetchYoutubeMedia(youtubeRoutes.LEAST_PERFORMING_POSTS)
        fetchYoutubeMedia(youtubeRoutes.PERFORMANCE_AND_VIDEO_POSTING_SCHEDULE)
      } else if (payloadCopy.type === 'pinterest') {
        selectedPinterestAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]

        pinterestIsPostDataLoading.value = false
        pinterestPostsLimit.value = this.metrics?.topPostsLimit

        fetchPinterestMedia(pinterestRoutes.OVERVIEW_SUMMARY, {
          type: 'summary',
        })
        fetchPinterestMedia(pinterestRoutes.FOLLOWERS_TREND)
        fetchPinterestMedia(pinterestRoutes.IMPRESSIONS_TREND)
        fetchPinterestMedia(pinterestRoutes.ENGAGEMENTS_TREND)
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_PER_DAY, {
          filter_by: 'image',
        })
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_ROLLUP, {
          filter_by: 'image',
        })
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_PER_DAY, {
          filter_by: 'video',
        })
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_ROLLUP, {
          filter_by: 'video',
        })
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_PER_DAY)
        fetchPinterestMedia(pinterestRoutes.PIN_POSTING_ROLLUP)

        TopLeastEngagementDropdown.forEach((dropdownOption) => {
          fetchPinterestMedia(pinterestRoutes.LEAST_PERFORMING_PINS, {
            limit: 5,
            order_by: dropdownOption.api_key,
          })
          fetchPinterestMedia(pinterestRoutes.TOP_PERFORMING_PINS, {
            limit: 5,
            order_by: dropdownOption.api_key,
          })
        })

        fetchPinterestMedia(
          pinterestRoutes.TOP_PERFORMING_PINS,
          getTopLeastEngagingPayload('report', this.metrics?.topPostsLimit)
        )
        fetchPinterestMedia(
          pinterestRoutes.PIN_POSTING_PERFORMANCE,
          getPerformanceChartPayload()
        )
      } else if (payloadCopy.type === 'twitter') {
        selectedTwitterAccount.value = payloadCopy.accounts[0]
        this.metrics.accounts = payloadCopy.accounts[0]
        twitterPostsLimit.value =
          this.metrics?.topPostsLimit || DEFAULT_TOP_AND_LEAST_POSTS_LIMIT

        isTwitterPostDataLoading.value = false
        fetchTwitterMedia(twitterRoutes.PAGE_AND_POST_INSIGHTS)
        fetchTwitterMedia(twitterRoutes.ENGAGEMENTS_AND_IMPRESSIONS)
        fetchTwitterMedia(twitterRoutes.FOLLOWERS_TREND)
        fetchTwitterMedia(twitterRoutes.TOP_PERFORMING_TWEETS, {
          limit: isReportView.value
            ? twitterPostsLimit.value
            : DEFAULT_TOP_AND_LEAST_POSTS_LIMIT,
        })
        fetchTwitterMedia(twitterRoutes.LEAST_PERFORMING_TWEETS)
      } else if (payloadCopy.type === 'campaign-and-label') {
        selectedLabelCampaignAccount.value = payloadCopy.accounts
        this.metrics.accounts = payloadCopy.accounts
        isAllLabelCampaignAccountsSelected.value =
          this.metrics.allAccountsSelected
        selectedCampaignsAndLabels.value = {
          campaigns: this.metrics.campaigns,
          labels: this.metrics.labels,
        }
        // routes.ALL_LABELS_AND_CAMPAIGNS should always be awaited, before rest of the APIs
        await fetchLabelCampaignMedia(
          labelCampaignRoutes.ALL_LABELS_AND_CAMPAIGNS
        )
        fetchLabelCampaignMedia(labelCampaignRoutes.SUMMARY_ANALYTICS)
        fetchLabelCampaignMedia(labelCampaignRoutes.BREAKDOWN_DATA)
        fetchLabelCampaignMedia(labelCampaignRoutes.INSIGHTS_GRAPH_BREAKDOWN)
      } else {
        const accounts = payloadCopy.accounts[0]
        payloadCopy.accounts = payloadCopy.accounts[0].instagram_id
        const resp = await this.getAnalyticsService(payloadCopy)
        this.metrics = Object.assign(dataValues()[payloadCopy?.type], resp)
        this.metrics.accounts = accounts
        if (payloadCopy.type === 'instagram') {
          payloadCopy.section = 'demographics'
          this.metrics.demographics = await this.getAnalyticsService(
            payloadCopy
          )
        }
      }
      this.loader = false

      setTimeout(() => {
        window.print()
      }, 30000)
    },
  },
}
</script>

<style lang="scss">
.analytics_dp {
  /*background: #f5f9fc !important;*/
}

.profile_image {
  border-radius: 4px;
  width: 38px;
}
</style>
