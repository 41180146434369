<template>
  <div class="h-[4.25rem] bottom-border-only bg-white flex justify-between items-center px-6 py-4">
    <div class="flex items-center gap-3">
      <!-- <img
        :src="userDetails?.image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
        :alt="userDetails?.name"
        class="w-8 h-8 rounded-full border"
        @error="
          (event) => {
            event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
          }
        "
      />
      <div class="flex flex-col gap-1">
        <span v-tooltip="userDetails?.name" class="font-semibold text-base max-w-[11rem] truncate">
          {{ userDetails?.name }}
        </span>
        <span v-tooltip="assignedToUser?.user_name" class="font-normal text-xs max-w-[11rem] truncate">
          {{ assignedToUser && isAssigned ? `Assigned to: ${assignedToUser?.user_name}` : 'Unassigned' }}
        </span>
      </div> -->
      <Dropdown
        class="!w-full"
        placement="bottom"
        size="sm"
        radius="lg"
        dropdown-classes="!max-h-[60vh]"
        :disabled="isAssigning"
        >
        <template v-slot:selected>
          <div class="flex justify-between items-center">
            <span>
              <img
                :src="inboxAssignTo"
                class="mr-2"
                alt="assign"
              />
              {{ assignedToUser && isAssigned ? `${assignedToUser?.user_name}` : 'Unassigned' }}
            </span>
            <template v-if="isAssigning">
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'16px'"
              ></clip-loader>
            </template>
          </div>
        </template>
        <DropdownItem
          size="sm"
          class="flex items-center justify-between group"
          :selected="!isAssigned"
          :disabled="!isAssigned"
          @click="updateConversationsStatuses('assigned', false, null)"
        >
          <div class="flex items-center gap-2 ">
            <img
              :src="`https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`"
              alt="avatar"
              class="w-8 h-8 rounded-full"
            />
            Unassign
          </div>
          <img
            :src="inboxTickMark"
            alt="selected list icon"
            :class="{ 'hidden group-hover:block': isAssigned }"
          />
        </DropdownItem>
        <DropdownItem
          v-for="member in getters.getWorkspaceInboxTeamMembers"
          :key="member.user._id"
          size="sm"
          class="flex items-center justify-between group"
          :selected="isAssigned && member.user._id === assignedToUser?.user_id"
          :disabled="isAssigned && member.user._id === assignedToUser?.user_id"
          @click="updateConversationsStatuses('assigned', true, member.user)"
        >
          <div class="flex items-center gap-2 ">
            <img
              :src="member.user?.image || `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`"
              alt="avatar"
              class="w-8 h-8 rounded-full"
              @error="
                $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
              "
            />
            <span v-tooltip="member.user?.full_name" class="truncate max-w-[110px]">
              {{ member.user?.full_name }}
            </span>
          </div>
          <img
            :src="inboxTickMark"
            alt="selected list icon"
            :class="{ 'hidden group-hover:block': member.user._id !== assignedToUser?.user_id }"
          />
        </DropdownItem>
      </Dropdown>
      <i
        v-if="currentConversation?.inbox_type !== 'post'"
        v-tooltip="{
          content: userDetailsHtml(),
          allowHTML: true,
          theme: 'light',
        }"
        class="far fa-flag"
      ></i>
      <SyncButton
        v-if="currentConversation?.inbox_type !== 'review'"
        v-tooltip="`Sync ${currentConversation?.inbox_type} data`"
        :loading="isSyncing"
        @click="handleSync"
      />
    </div>
    <div class="flex items-center gap-4">
      <div class="flex items-center gap-1">
        <template v-if="isArchived">
          <Button
            variant="subtle"
            color="warning"
            size="sm"
            class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
            :disabled="disabledBulkActions"
            @click="updateConversationsStatuses('archived', false, null)"
          >
            <img
              v-tooltip="'Unarchive'"
              :src="inboxArchived"
              alt="archive"
            />
            <span class="hidden xl:inline-block">
              Unarchive
            </span>
            <template v-if="isArchiving" >
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'12px'"
              ></clip-loader>
            </template>
          </Button>
        </template>
        <template v-else>
          <Button
            v-if="!isMarkedAsDone"
            v-tooltip="'Mark as Done'"
            variant="subtle"
            color="success"
            size="sm"
            class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
            :disabled="isMarkingAsDone"
            @click="updateConversationsStatuses('marked_done', true, null)"
          >
            <img
              :src="inboxMarkDone"
              alt="done"
            />
            <span class="hidden xl:inline-block">
              Mark as Done
            </span>
            <template v-if="isMarkingAsDone" >
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'16px'"
              ></clip-loader>
            </template>
          </Button>
          <Button
            v-else
            v-tooltip="'Mark as Undone'"
            variant="subtle"
            color="success"
            size="sm"
            class="!font-normal !text-[#4a4a4a] !px-[0.48rem]"
            :disabled="isMarkingAsDone"
            @click="updateConversationsStatuses('marked_done', false, null)"
          >
            <img
              :src="inboxMarkDone"
              alt="done"
            />
            <span class="hidden xl:inline-block">
              Mark as Undone
            </span>
            <template v-if="isMarkingAsDone" >
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'16px'"
              ></clip-loader>
            </template>
          </Button>
        </template>
        <Dropdown
          v-if="!isMarkedAsDone && !isArchived"
          :unstyled="true"
          :show-arrow="false"
          button-classes="py-[12px] px-1"
          dropdown-classes="!min-w-[150px]"
          placement="bottom"
        >
          <template v-slot:selected>
            <i v-tooltip="'More'" class="far fa-ellipsis-v"></i>
          </template>

          <DropdownItem
            size="sm"
            :disabled="isArchiving"
            @click="updateConversationsStatuses('archived', true, null)"
          >
            <img
              :src="inboxArchived"
              class="mr-2"
              alt="archive"
            />
            Archive
            <template v-if="isArchiving" >
              <clip-loader
                class="align-middle inline-block"
                color="#4165ed"
                :size="'16px'"
              ></clip-loader>
            </template>
          </DropdownItem>
        </Dropdown>
      </div>
      <div>
        <TagsDropdown
          :tags="currentConversation?.inbox_details?.tags || []"
          :available-tags="availableTags"
          :is-tag-loading="isTagLoading"
          :is-adding-tag="isAddingTag"
          @create-tag="onCreateTags"
          @toggle-tag="ToggleTagSelection"
          @close="handleSearchClose"
        />
        </div>
        <div>
          <DetailsDrawer />
        </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Button, Dropdown, DropdownItem } from '@contentstudio/ui';
import { useIcons } from '@src/modules/inbox-revamp/composables/useIcons';
import { useInbox } from '@src/modules/inbox-revamp/composables/useInbox';
import { useStore } from '@state/base';
import { useRouter } from 'vue-router';
import { debounce } from 'lodash'
import DetailsDrawer from '@src/modules/inbox-revamp/components/DetailsDrawer.vue'
import SyncButton from '@src/modules/inbox-revamp/components/SyncButton.vue'
import { EventBus } from '@common/lib/event-bus'
import TagsDropdown from '@src/modules/inbox-revamp/components/TagsDropdown.vue'

const router = useRouter()
const { getters, dispatch } = useStore();
const { inboxMarkDone, inboxTickMark, inboxArchived, inboxAssignTo } = useIcons()

const {
  inboxTypes,
  selectedInboxType,
  isMarkingAsDone,
  isArchiving,
  isAssigning,
  currentConversation,
  currentConversationPlatform,
  tags,
  createWorkspaceTag,
  assignTagToInboxItem,
  assignInboxToUser,
  markAsDoneInboxItems,
  archiveInboxItems,
  fetchDetails,
  fetchConversationList,
  updateCurrentConversation
} = useInbox();

const isSyncing = ref(false)
const isTagLoading = ref({})
const isAddingTag = ref(false);

const userDetailsHtml = () => {
  return `
  <span class="text-gray-700 text-sm">Platform Detail:</span>
  <div class="flex items-center gap-3 mt-3">
    <img
      src="${currentConversationPlatform.value?.platform_image || 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'}"
      alt="${currentConversationPlatform.value?.platform_name}"
      class="w-8 h-8 rounded-full border"
    />
    <div class="flex flex-col gap-1">
      <span class="text-sm max-w-[17rem] truncate">
        ${currentConversationPlatform.value?.platform_name}
      </span>
    </div>
  </div>`
}

// const userDetails = computed(() => {
//   return currentConversation.value?.inbox_details?.posted_by;
// });

const isMarkedAsDone = computed(() => {
  return currentConversation.value?.inbox_details?.marked_done?.is_marked_done;
});

const isArchived = computed(() => {
  return currentConversation.value?.inbox_details?.archived?.is_archived;
});

const isAssigned = computed(() => {
  return currentConversation.value?.inbox_details?.assigned?.is_assigned;
});

const assignedToUser = computed(() => {
  return currentConversation.value?.inbox_details?.assigned?.assigned_to;
});

const availableTags = computed(() => tags.value || []);

const fetchDetailsDebounced = debounce(async (value) => {
  fetchDetails(inboxTypes?.[value]?.filter)
  await fetchConversationList(inboxTypes?.[selectedInboxType.value]?.filter, router?.currentRoute.value?.params?.filter)
}, 100)

const onCreateTags = async(data) => {
  isAddingTag.value = true;
  await createWorkspaceTag(data)
  isAddingTag.value = false;
}

const ToggleTagSelection = async (tagId) => {
  isTagLoading.value[tagId] = true
  await assignTagToInboxItem(tagId, currentConversation.value?.inbox_details?.tags?.some((tag) => tag?._id === tagId))
  isTagLoading.value[tagId] = false
}

const updateConversationsStatuses = async (action, value, user) => {
  const currentId = currentConversation.value?.element_details?.element_id
  switch (action) {
    case 'assigned':
      await assignInboxToUser(value, {
        user_id: user?._id || '',
        user_name: user?.full_name || '',
      }, [currentId])
      fetchDetailsDebounced(selectedInboxType.value)
      break
    case 'marked_done':
      await markAsDoneInboxItems(value, [ currentId ])
      fetchDetailsDebounced(selectedInboxType.value)
      break
    case 'archived':
      await archiveInboxItems(value, [ currentId ])
      fetchDetailsDebounced(selectedInboxType.value)
      break
    default:
      break
  }
}

const handleSync = async () => {
  isSyncing.value = true
  try {
    if(currentConversation.value?.inbox_type === 'post') {
      const res = await dispatch('syncPostData_v2', {
        platform_id: currentConversation.value?.platform_id,
        workspace_id: getters?.getWorkspaces?.activeWorkspace?._id,
        post_id: currentConversation.value?.element_details?.element_id,
        comment_count: 50,
        platform_type: currentConversation.value?.platform,
      })
      if(res?.isValid) {
        if(res?.data?.post_data?.[0]?.payload){
          currentConversation.value = res?.data?.post_data?.[0]?.payload
          updateCurrentConversation(currentConversation.value)
        }
        EventBus.$emit('sync-comment-list', res?.data?.comment_data)
      }
    }
    else {
      const res = await dispatch('syncConversationData_v2', {
        platform_id: currentConversation.value?.platform_id,
        workspace_id: getters?.getWorkspaces?.activeWorkspace?._id,
        conversation_id: currentConversation.value?.element_details?.element_id,
        message_count: 50,
        platform_type: currentConversation.value?.platform,
      })
      console.log('conversation',res)
      if(res?.isValid) {
        if(res?.data?.conversation_data?.[0]?.payload){
          currentConversation.value = res?.data?.conversation_data?.[0]?.payload
          updateCurrentConversation(currentConversation.value)
        }
        EventBus.$emit('sync-message-list', res?.data?.message_data)
      }
    }
  } catch (error) {
    console.error('Error syncing messages:', error)
  } finally {
    isSyncing.value = false
  }
}

</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
