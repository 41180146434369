<script setup>
import { computed, onBeforeMount,  onUnmounted } from 'vue'
import FilterBar from '@src/modules/analytics/views/overviewV2/components/FilterBar.vue';
import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import TabsComponent from '@src/modules/analytics/views/common/TabsComponent.vue'
import OverviewSection from '@src/modules/analytics/views/overviewV2/components/OverviewSection.vue'

import { useStore } from '@state/base'
import { EventBus } from '@common/lib/event-bus'


const { selectAllAccounts } = useAnalyticsUtils()
const {
  IGNORE_PLATFORMS
} = useOverviewAnalytics()

const { getters } = useStore()

onBeforeMount(() => {
  EventBus.$on('workspace-changed', () => {
    console.log('workspace changed')
  })
})

onUnmounted(() => {
  console.log('unmounted')
  EventBus.$off('workspace-changed')
})

const getAllAccounts = computed(() => {
  return selectAllAccounts(IGNORE_PLATFORMS)
})

</script>

<template>
  <div class="analytics-main-container">
    <FilterBar />
    <div
      v-if="!getAllAccounts.length "
      class="analytics-main-errors"
    >
      <img
        alt="No accounts connected"
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p>
        <span > No Account(s) Connected </span>
        <small>Connect a social account </small>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{
            path: `/${getters.getActiveWorkspace.slug}/settings/social`,
          }"
          >Connect Accounts
        </router-link>
      </p>
    </div>
    <div v-else>
      <TabsComponent :tabs="['#overview']" type="overview">
        <!-- overview tab -->
        <template v-slot:overview>
          <OverviewSection />
        </template>
      </TabsComponent>
    </div>

  </div>
</template>
