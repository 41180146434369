<template>
  <AnalyticsCardWrapper
    type="Top-Posts"
    :enable-modal="true"
    :enable-insights="showInsights"
    :show-insights-button="!isReportView"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <CstDropdown
              v-if="!isReportView"
              dropdown-placement="bottom"
              container-classes="!max-h-96"
              dropdown-classes="!rounded-md !border-[#70707029]"
              button-classes="flex !px-5 !w-[16rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
            >
              <template v-slot:arrow>
                <i class="fas fa-chevron-down text-xs"></i>
              </template>
              <template v-slot:selected>
                <p class="text-[14px] font-weight-500">
                  {{ getDropdownTitle }}
                </p>
              </template>
              <template v-slot>
                <template
                  v-for="(option, index) in sortOptions"
                  :key="`sort_option_${index}`"
                >
                  <CstDropdownItem @click="handleSortOptionChange(option.value)">
                    <p class="text-gray-900 text-sm">{{ option.label }}</p>
                  </CstDropdownItem>
                </template>
              </template>
            </CstDropdown>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">Posts with the highest {{ getTooltipText }} across your social media accounts</p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:card-body>
      <div class="flex flex-col gap-2">
        <!-- Tabs -->
        <div class="px-1">
          <b-tabs
            v-model="tabIndex"
            nav-class="analytics-tabs analytics-main-tabs border-t-0 border-r-0 border-l-0 border-b border-gray-200"
            content-class="analytics-tabs-content"
          >
            <b-tab
              v-for="tab in availableTabs"
              :key="tab.value"
              class="p-5"
              @click="selectedTab = tab.value"
            >
              <template v-slot:title>
                <div class="flex items-center justify-center gap-1">
                  {{ tab.label }}
                </div>
              </template>

              <!-- Tab Content -->
              <div :class="{'pt-4': !isReportView, 'pt-0': isReportView}" class="border-t-0 border-b-0">
                <div v-if="tab.value === selectedTab" class="animate-fadeIn">
                  <div v-if="isLoading" class="flex flex-col gap-5 py-5">
                    <!-- Skeletal loader for posts -->
                    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-5">
                      <div v-for="i in 5" :key="i" class="card-top-post p-4 flex flex-col gap-4">
                        <!-- Avatar and header skeleton -->
                        <div class="flex justify-between gap-3">
                          <div class="flex gap-3 items-center">
                            <div class="relative">
                              <SkeletonBox height="40px" width="40px" radius="50%" />
                              <SkeletonBox height="16px" width="16px" radius="50%" class="absolute -bottom-1 -right-1" />
                            </div>
                            <div class="flex flex-col gap-1">
                              <SkeletonBox height="16px" width="120px" radius="4px" />
                              <SkeletonBox height="12px" width="80px" radius="4px" />
                            </div>
                          </div>
                          <SkeletonBox height="16px" width="16px" radius="4px" />
                        </div>

                        <!-- Caption skeleton -->
                        <div class="flex flex-col gap-1">
                          <SkeletonBox height="12px" width="60px" radius="4px" />
                          <SkeletonBox height="14px" width="100%" radius="4px" />
                          <SkeletonBox height="14px" width="90%" radius="4px" />
                          <SkeletonBox height="14px" width="80%" radius="4px" />
                        </div>

                        <!-- Image skeleton -->
                        <SkeletonBox height="100px" width="100%" radius="7px" />

                        <!-- Metrics skeleton -->
                        <div class="flex flex-col gap-2">
                          <SkeletonBox height="14px" width="100%" radius="4px" />
                          <SkeletonBox height="14px" width="100%" radius="4px" />
                          <SkeletonBox height="14px" width="100%" radius="4px" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="!props.topPostsData.length" class="text-center py-10 text-gray-500">
                    No posts found for this platform
                  </div>
                  <div v-else>
                    <!-- Posts grid container -->
                    <div class="grid gap-5" :class="{
                      'grid-cols-3': isReportView,
                      'grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5': !isReportView
                    }">
                      <div
                        v-for="(post, index) in visiblePosts"
                        :key="index"
                        class="card-top-post p-4 flex flex-col gap-2 animate-fadeIn"
                    >
                      <!-- Avatar and Redirect Section -->
                      <div id="avatar_and_redirect" class="flex justify-between gap-3 flex-shrink-0">
                        <div class="flex gap-3 items-center w-[90%]">
                          <div class="relative">
                            <img
                              :src="getAccountImage(post.account_id, post.platform_type)"
                              class="w-10 h-10 rounded-full object-cover border shadow-sm"
                              alt="Account"
                              @error="handleImageError"
                            />
                            <img
                              :src="require(`@/src/assets/img/integration/${post.platform_type}-icon.svg`)"
                              class="absolute -bottom-1 -right-1 w-5 h-5 bg-white rounded-full border border-gray-200 shadow-sm"
                              alt="Platform"
                              @error="$event.target.src = require(`@/src/assets/img/integration/${post.platform_type}-rounded.svg`)"
                            />
                          </div>
                          <div class="flex flex-col gap-1 overflow-hidden">
                            <p
                              v-tooltip="{
                                content: tooltipHtml(getAccountName(post.account_id, post.platform_type)),
                                theme: 'light',
                                allowHTML: true,
                              }"
                              class="font-medium truncate max-w-[180px]"
                            >{{ getAccountName(post.account_id, post.platform_type) }}</p>
                            <p class="text-xs text-gray-700">{{ formatDate(post.created_time || post.date || new Date()) }}</p>
                          </div>
                        </div>
                        <div>
                          <a
                            v-if="post.permalink"
                            :href="post.permalink"
                            target="_blank"
                            rel="noopener"
                            class="text-[#3B82F6] hover:text-blue-700"
                          >
                            <i class="fas fa-external-link-alt"></i>
                          </a>
                        </div>
                      </div>

                      <!-- Caption and Preview Section -->
                      <div class="flex flex-col gap-4 flex-shrink-0">
                        <div
                          id="caption"
                          class="flex-shrink-0 h-[70px] overflow-hidden"
                        >
                          <div class="flex flex-col gap-1">
                            <p v-if="post.category" class="text-xs text-blue-600 font-medium">#{{ post.category }}</p>
                            <p class="text-sm line-clamp-3">{{ post.caption || ' ' }}</p>
                          </div>
                        </div>
                        <div id="preview" class="relative flex-shrink-0">
                          <img
                            class="w-full h-[100px] rounded-[7px] object-cover object-center"
                            :src="post.thumbnail || post.media_url || getPlaceholderImage(post.platform_type, index)"
                            alt="preview"
                            @error="$event.target.src = require('@/src/modules/analytics/assets/imgs/no_data_images/not-found.png')"
                          />
                          <div class="absolute top-2 right-2 bg-black/50 text-white text-xs px-2 py-1 rounded">
                            {{ formatMediaType(post.media_type || getDefaultMediaType(post.platform_type)) }}
                          </div>
                        </div>
                      </div>

                      <!-- Metrics Section -->
                      <div id="metrics" class="flex flex-col gap-2 flex-grow pr-1 py-1">
                        <div
                          v-for="(metric, mIndex) in getPlatformMetrics(post)"
                          :key="metric.title"
                          class="flex items-center gap-2 justify-between py-2 group select-none"
                          :class="{ 'bottom-border-gray': mIndex < getPlatformMetrics(post).length - 1 }"
                        >
                          <div class="flex items-center gap-2">
                            <img
                              :src="metric.icon"
                              alt="metric icon"
                              class="w-5 h-5"
                            />
                            <p class="text-[#676d70] text-sm font-normal leading-[13.30px] transition-all group-hover:font-medium">
                              {{ metric.title }}
                            </p>
                          </div>
                          <p class="text-right text-[#434343] text-sm font-medium leading-[13.30px]" :class="{ 'capitalize': !metric.isText }">
                            {{ metric.isText ? metric.value : (formatNumber(metric.value) ?? 'N/A') }}
                          </p>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>

<script setup>
// 1. File/Module imports
import { ref, computed, watch, onMounted } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import { BTabs, BTab } from 'bootstrap-vue'
import useOverviewAnalytics from '@/src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics'
import CstDropdown from '@/src/components/UI/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@/src/components/UI/Dropdown/IconDropdownItem.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'

// 2. Props
const props = defineProps({
  topPostsData: {
    type: Array,
    default: () => []
  },
  isLoading: {
    type: Boolean,
    default: false
  },
  postsPerView: {
    type: Number,
    default: 5
  },
  cardHeight: {
    type: Number,
    default: 580
  }
})

// Emits
const emit = defineEmits(['update:isLoading', 'update:topPostsData'])

// 3. Composable destructs
const {
  routes,
  fetchMedia,
  topPostsData: apiTopPostsData,
  isLoadingStates,
  formatNumber,
  formatDate,
  formatMediaType,
  getPlatformImage,
  getPlaceholderImage,
  getPlatformName,
  getAccountImage,
  getAccountName,
  getDefaultMediaType,
  getPlatformMetrics,
  getAvailableTabs,
  isReportView
} = useOverviewAnalytics()

const { tooltipHtml } = useComposerHelper()

// 4. State
const selectedSortOption = ref('reach')
const selectedTab = ref('overall')
const tabIndex = ref(0)
const visiblePostsCount = ref(props.postsPerView || 5)

// Sort options for the dropdown
const sortOptions = [
  { label: 'Top Posts By Reach', value: 'reach' },
  { label: 'Top Posts By Engagement', value: 'total_engagement' },
  { label: 'Top Posts By Impressions', value: 'total_impressions' }
]

// 5. Methods
/**
 * Handles sort option change
 * @param {string} value - The new sort option value
 */
const handleSortOptionChange = async (value) => {
  selectedSortOption.value = value
  await fetchTopPosts()
}

/**
 * Fetches top posts based on selected sort option
 */
const fetchTopPosts = async () => {
  emit('update:isLoading', true)
  try {
    await fetchMedia(routes.GET_TOP_POSTS, { type: selectedSortOption.value })
    emit('update:topPostsData', apiTopPostsData.value)
  } catch (error) {
    console.error('Error fetching top posts:', error)
  } finally {
    emit('update:isLoading', false)
  }
}

/**
 * Loads more posts for pagination
 */
const loadMore = () => {
  // Show 5 more posts or all remaining if less than 5
  const remaining = filteredPosts.value.length - visiblePostsCount.value
  visiblePostsCount.value += remaining > 5 ? 5 : remaining
}

// 6. Computed
/**
 * Gets the dropdown title based on selected sort option
 */
const getDropdownTitle = computed(() => {
  const option = sortOptions.find(opt => opt.value === selectedSortOption.value)
  return option ? option.label : 'Top Posts By Reach'
})

/**
 * Gets tooltip text based on selected sort option
 */
const getTooltipText = computed(() => {
  switch (selectedSortOption.value) {
    case 'total_engagement':
      return 'engagement'
    case 'total_impressions':
      return 'impressions'
    case 'reach':
    default:
      return 'reach'
  }
})

/**
 * Gets available tabs that have data
 */
const availableTabs = computed(() => {
  // Get all possible tabs from the composable
  return getAvailableTabs()

  // TODO: Filter tabs to only include those with data
})

/**
 * Filters posts based on selected tab
 */
const filteredPosts = computed(() => {
  return props.topPostsData.filter(post =>
    selectedTab.value === 'overall' || post.platform_type === selectedTab.value
  )
})

/**
 * Gets visible posts with pagination
 */
const visiblePosts = computed(() => {
  return filteredPosts.value.slice(0, visiblePostsCount.value)
})

/**
 * Determines if there are more posts to show
 */
const hasMorePosts = computed(() => {
  return filteredPosts.value.length > visiblePostsCount.value
})

// 7. Watch
/**
 * Watch for tab index changes to update selectedTab
 */
watch(tabIndex, (newIndex) => {
  selectedTab.value = availableTabs.value[newIndex]?.value || 'overall'
  visiblePostsCount.value = props.postsPerView || 5 // Reset to default posts when changing tabs
})

/**
 * Watch for selectedTab changes to update tabIndex
 */
watch(selectedTab, (newValue) => {
  const index = availableTabs.value.findIndex(tab => tab.value === newValue)
  if (index !== -1) {
    tabIndex.value = index
  } else {
    // If the selected tab is not available, switch to overall
    selectedTab.value = 'overall'
    tabIndex.value = 0
  }
  visiblePostsCount.value = props.postsPerView || 5 // Reset to default posts when changing tabs
})


// 8. Lifecycle Hooks
/**
 * Initialize when component mounts
 */
onMounted(() => {
  visiblePostsCount.value = props.postsPerView || 5 // Ensure we start with correct number of posts
  fetchTopPosts() // Fetch top posts when component mounts
})


</script>

<style lang="scss" scoped>
.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Single animation definition */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(8px); }
  to { opacity: 1; transform: translateY(0); }
}

.animate-fadeIn {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.card-top-post {
  border-radius: 10px;
  border: 1px solid #f2f5f8;
  transition: all 0.2s ease-in-out;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  height: 580px; /* Dynamic height based on prop */
  overflow: hidden; /* Prevent overflow */
  gap: 1rem;

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    transform: translateY(-2px);
  }

  #avatar_and_redirect {
    img {
      object-fit: cover;
    }
  }

  #caption {
    p {
      color: #434343;
      line-height: 1.5;
    }
  }

  #preview {
    img {
      border: 1px solid #f2f5f8;
    }
  }

  #metrics {
    margin-top: auto;

    p {
      transition: all 0.2s ease;
    }
  }
}

.bottom-border-gray {
  border-bottom: 1px solid rgba(136, 136, 136, 0.15);
}

/* Scrollbar styling */
.scrollbar-thin::-webkit-scrollbar {
  height: 6px;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background: transparent;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background: #E5E7EB;
  border-radius: 3px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background: #D1D5DB;
}

</style>
