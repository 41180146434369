<script>
import { mapGetters, mapMutations } from 'vuex'
import { article } from '@src/modules/automation/store/recipes/mutation-types'
import AutoMixin from '@src/modules/automation/components/AutoMixin'
import ScheduleOptions from '../../ScheduleOptions'
import BlogDistribute from '../../../../publish/components/posting/blog/BlogDistribute'
import PreviewSuggestion from '../../../../discovery/components/topics/PreviewSuggestion'
import ArticlesFiltersSelection from './ArticleAutomationFiltersSelection'

export default {
  components: {
    BlogDistribute,
    ArticlesFiltersSelection,
    ScheduleOptions,
    PreviewSuggestion,
  },
  mixins: [AutoMixin],
  data() {
    return {}
  },
  computed: {
    ...mapGetters([
      'getArticleAutomationTabStatus',
      'getArticleAutomationDetails',
      'getAutomationLoaders',
      'getWorkspaces',
      'getBlogSelection',
      'getFetchNextPostingLoader',
      'getArticleAutomationLoaders',
      'getArticleAutomationValidations',
      'getArticleAutomationLoaders',
      'getPreviewSuggestion',
    ]),
    showNextScheduledPostsCount() {
      return (
        !this.getFetchNextPostingLoader &&
        this.getArticleAutomationDetails.next_posting > -1
      )
    },
    nextScheduledPostsMessage() {
      const nextPosting =
        this.getPreviewSuggestion.total >
        this.getArticleAutomationDetails.next_posting
          ? this.getArticleAutomationDetails.next_posting
          : this.getPreviewSuggestion.posts.length

      return this.getArticleAutomationDetails.id &&
        !this.getArticleAutomationDetails.remove_posts
        ? 'Note: ' +
            nextPosting +
            ' new posts will be scheduled for your blogs on the next refill.'
        : 'Note: ' +
            nextPosting +
            ' new posts will be scheduled for your blogs.'
    },
  },
  watch: {
    'getArticleAutomationDetails.name'(value) {
      if (value && value.length > 0) {
        if (this.getArticleAutomationValidations.validations.name) {
          this.$store.commit(article.SET_ARTICLE_AUTOMATION_VALIDATION_VALUE, {
            key: 'name',
            value: false,
          })
        }
      }
    },
    'getArticleAutomationTabStatus.third'(value) {
      if (value) this.scheduleOptionsChanges()
    },
  },
  created() {
    this.initializeSaveArticleBlogAutomationSection()
  },
  mounted() {
    document.addEventListener('keyup', this.keyPressListener)
  },
  unmounted() {
    document.removeEventListener('keyup', this.keyPressListener)
  },
  methods: {
    ...mapMutations([article.SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS]),
    keyPressListener(event) {
      console.debug('Method::keyPressListener', event.keyCode)
      const tabStatus = this.getArticleAutomationTabStatus
      if (this.$route.name === 'saveArticleBlogAutomation') {
        let tab = 1
        if (event.keyCode === 27) {
          if (tabStatus.first === true) {
            this.$router.push({
              name: 'articleBlogAutomationListing',
              params: { workspace: this.getWorkspaces.activeWorkspace.slug },
            })
            return false
          }

          tab = tabStatus.second ? 'first' : 'second'
          this.SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS(tab)
        }
        if (
          event.keyCode === 13 &&
          !event.target.classList.contains('prevent-listener')
        ) {
          if (tabStatus.third === true) {
            this.validateAndStoreArticleAutomation()
            return false
          }
          tab = tabStatus.first ? 'first' : 'second'
          this.validateArticleAutomation(tab)
        }
      }
    },

    scheduleOptionsChanges() {
      console.debug('Method::scheduleOptionsChanges')
      this.$store.dispatch('fetchAutomationNextPosting', this.$route.name)
    },
  },
}
</script>

<template>
  <div class="automation_main_component create_automation_main_component">
    <div class="component_inner">
      <!--            <router-link class="close_icon float-right"-->
      <!--                         :to="{'name': 'automation', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <!--                &times;-->
      <!--            </router-link>-->

      <button
        class="close_icon float-right"
        @click="
          exitConfirmation(
            'article-blog-automation-listing',
            'Article to Blog',
            getWorkspaces.activeWorkspace.slug,
          )
        "
      >
        &times;
      </button>

      <!--<router-link class="close_icon float-right"-->
      <!--:to="{'name': 'articleBlogAutomationListing', params: { workspace: getWorkspaces.activeWorkspace.slug}}">-->
      <!--&times;-->
      <!--</router-link>-->

      <div class="top_content">
        <h2>New Campaign</h2>
        <img
          src="../../../../../assets/img/automation/article_blog.svg"
          alt=""
        />
        <h3>Article to Blog</h3>
        <p>Share articles on your blog with smart rules and filters.</p>
      </div>

      <div class="steps_count">
        <div class="count_inner">
          <a
            class="d-flex align-items-center justify-content-center"
            :class="{
              active: getArticleAutomationTabStatus.first === true,
              past: getArticleAutomationTabStatus.first === false,
            }"
            @click="SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS('first')"
          >
            <span>1</span>
          </a>
          <a
            class="d-flex align-items-center justify-content-center"
            :class="{
              active: getArticleAutomationTabStatus.second === true,
              past:
                getArticleAutomationTabStatus.first === false &&
                getArticleAutomationTabStatus.second === false,
            }"
            @click="validateArticleAutomation('first')"
          >
            <span>2</span>
          </a>
          <a
            class="d-flex align-items-center justify-content-center"
            :class="{ active: getArticleAutomationTabStatus.third === true }"
            @click="validateArticleAutomation('second')"
          >
            <span>3</span>
          </a>
        </div>
      </div>

      <div
        class="page_1 connect_social_accounts"
        :class="{ 'd-none': getArticleAutomationTabStatus.first === false }"
      >
        <div class="automation_page width_medium">
          <div class="page_inner basic_form">
            <div class="step_heading">
              <h3>Step 1 - Campaign Name and Channels</h3>
            </div>
            <div class="input_field">
              <label>Campaign Name</label>
              <input
                v-model="getArticleAutomationDetails.name"
                :class="{
                  'input-field-error':
                    getArticleAutomationValidations.validations.name,
                }"
                type="text"
                placeholder="Campaign name e.g marketing posts"
                maxlength="70"
                data-cy="campaign-name"
              />
              <span
                v-if="getArticleAutomationValidations.validations.name"
                class="input-error"
              >
                {{ getArticleAutomationValidations.messages.name }}
              </span>
            </div>

            <div class="input_field">
              <BlogDistribute
                :no-model="true"
                type="Automations"
                :variations-options="true"
              ></BlogDistribute>
            </div>

            <div class="field_group text-center">
              <button
                class="btn btn-studio-theme-transparent-grey mr-1"
                disabled="disabled"
              >
                <i class="icon_left cs-angle-left"></i>
                <span>Prev</span>
              </button>
              <button
                class="btn btn-studio-theme-space"
                @click="validateArticleAutomation('first')"
              >
                <span>Next</span>
                <i class="icon_right arrow_right_active cs-angle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="page_2 content_rules_filter"
        :class="{ 'd-none': getArticleAutomationTabStatus.second === false }"
      >
        <div class="automation_page d-flex align-items-start">
          <div class="page_left" :class="{ open: !showPreviewSnippet() }">
            <div class="page_inner basic_form">
              <div class="step_heading">
                <h3>Step 2 - Content Matching Rules & Filters</h3>
              </div>

              <ArticlesFiltersSelection type="Blog"></ArticlesFiltersSelection>

              <div class="field_group text-center">
                <button
                  class="btn btn-studio-theme-transparent-grey mr-1"
                  @click="SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS('first')"
                >
                  <i class="icon_left cs-angle-left"></i>

                  <span>Prev</span>
                </button>
                <button
                  class="btn btn-studio-theme-space"
                  data-cy="step1-button"
                  @click="validateArticleAutomation('second')"
                >
                  <span>Next</span>
                  <i class="icon_right arrow_right_active cs-angle-right"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="page_right" :class="{ open: showPreviewSnippet() }">
            <div class="page_inner basic_form">
              <PreviewSuggestion></PreviewSuggestion>
            </div>
          </div>
        </div>
      </div>

      <div
        class="page_3 schedule_campaign"
        :class="{ 'd-none': getArticleAutomationTabStatus.third === false }"
      >
        <div class="automation_page width_medium">
          <div class="page_inner basic_form">
            <div class="step_heading">
              <h3>Step 3 - Schedule and Finalize</h3>
            </div>

            <ScheduleOptions type="ArticleBlogAutomation"></ScheduleOptions>

            <div class="input_field">
              <div class="recurring_block extra_options">
                <div class="d-flex align-items-center">
                  <p class="text">Fetch posts for the next</p>
                  <input
                    v-model="getArticleAutomationDetails.prefetch_days_limit"
                    style="min-width: 200px"
                    type="number"
                    placeholder="Number of prefetched post"
                    min="2"
                    max="100"
                    @change="scheduleOptionsChanges"
                  />
                  <p class="last_text">days in advance</p>
                </div>
              </div>
            </div>

            <div class="input_field">
              <div class="checkbox_input_image">
                <input
                  id="reviewContent"
                  v-model="getArticleAutomationDetails.review_content"
                  type="checkbox"
                />
                <label for="reviewContent" class="checkbox_left"
                  >I’d like to review content before it is published</label
                >
              </div>
            </div>

            <div
              v-if="showNextScheduledPostsCount"
              class="input_field d-inline-block"
            >
              <div class="warning_box">
                <p>{{ nextScheduledPostsMessage }}</p>
              </div>
            </div>

            <div v-if="getArticleAutomationDetails.id" class="input_field">
              <div class="checkbox_input_image">
                <input
                  id="removePosts"
                  v-model="getArticleAutomationDetails.remove_posts"
                  type="checkbox"
                  @change="scheduleOptionsChanges"
                />
                <label for="removePosts" class="checkbox_left"
                  >Remove existing scheduled posts</label
                >
              </div>
            </div>

            <div class="field_group text-center">
              <button
                class="btn btn-studio-theme-transparent-grey mr-1"
                @click="SET_ARTICLE_AUTOMATION_ACTIVE_TAB_STATUS('second')"
              >
                <i class="icon_left cs-angle-left"></i>
                <span>Prev</span>
              </button>
              <button
                class="btn btn-studio-theme-space"
                :class="{ btn_disable: getArticleAutomationLoaders.all.store }"
                @click.prevent="validateAndStoreArticleAutomation"
              >
                <span>Done</span>
                <i class="icon_right arrow_right_active cs-angle-right"></i>
                <img
                  v-if="getArticleAutomationLoaders.all.store"
                  style="width: 20px"
                  src="../../../../../assets/img/common/gif_loader_white.gif"
                  alt=""
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
